import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import axios from '../../../utils/axios';


const cashDividend = [
  {value:'CashDividend', key:'Cash Dividend'},
  {value:'PublicSubscriptionMoney', key:'Public Subscription Money'},
  {value:'FractionDividend', key:'Fraction Dividend'},
  {value:'All', key:'All'}
];

const stockDividend = [
  {value:'StockRightShare' ,key:'Stock Right Share'},
  {value:'StockBonusShare' ,key:'Stock Bonus Share'},
  {value:'DividendOnDividend' ,key:'Dividend On Dividend'},
  {value:'All', key:'All'}
];

const allDividend = [
  {value:'CashDividend', key:'Cash Dividend'},
  {value:'PublicSubscriptionMoney', key:'Public Subscription Money'},
  {value:'FractionDividend', key:'Fraction Dividend'},
  {value:'StockRightShare' ,key:'Stock Right Share'},
  {value:'StockBonusShare' ,key:'Stock Bonus Share'},
  {value:'DividendOnDividend' ,key:'Dividend On Dividend'},
  {value:'FCCashDividend', key:'FC Cash Dividend'},
  {value:'FCPublicSubscriptionMoney' ,key:'FC PublicSubscription Money'},
  {value:'All', key:'All'}
];
const options = [{ tradecode: "No Data Available", name: "" },
];

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
      // height: 250,
      overflowY: 'scroll',
      overflowX: 'scroll'
    },
  },
};

const columns = [
  { id: 'sn', label: 'Investors Name',  },
  { id: 'boid', label: 'BO ID', },
  {
    id: 'foid',
    label: 'Folio',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'dyear',
    label: 'Cash Div Year',
    // align: 'right',
    minWidth: 150,
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'cashamt',
    label: 'Cash',
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'fracamt',
    label: 'Fraction',
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'ipoamt',
    label: 'IPO',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'netamt',
    label: 'Net Cash',
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'dyearstock',
    label: 'Stock Div Year',
    // align: 'right',
  },
  {
    id: 'bshare',
    label: 'Bonus Share',
    // align: 'right',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'rightshare',
    label: 'Right Share',
    // align: 'right',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'dshare',
    label: 'Div on div',
    // align: 'right',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'netshare',
    label: 'Net Share',
    // align: 'right',
    // format: (value) => value.toFixed(2),
  },
  

];
const columnsCash = [
  { id: 'sn', label: 'Investors Name',  },
  { id: 'boid', label: 'BO ID', },
  {
    id: 'foid',
    label: 'Folio',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'dyear',
    label: 'Cash Div Year',
    // align: 'right',
    minWidth: 150,
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'cashamt',
    label: 'Cash',
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'fracamt',
    label: 'Fraction',
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'ipoamt',
    label: 'IPO',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'netamt',
    label: 'Net Cash',
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
];

const columnsStock = [
  { id: 'sn', label: 'Investors Name',  },
  { id: 'boid', label: 'BO ID', },
  {
    id: 'foid',
    label: 'Folio',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'dyearstock',
    label: 'Stock Div Year',
    // align: 'right',
  },
  {
    id: 'bshare',
    label: 'Bonus Share',
    // align: 'right',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'rightshare',
    label: 'Right Share',
    // align: 'right',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'dshare',
    label: 'Div on div',
    // align: 'right',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'netshare',
    label: 'Net Share',
    // align: 'right',
    // format: (value) => value.toFixed(2),
  },
  

];

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

const rows = [
//   createData('India', 'IN', 1324171354, 3287263),
//   createData('China', 'CN', 1403500365, 9596961),
//   createData('Italy', 'IT', 60483973, 301340),
//   createData('United States', 'US', 327167434, 9833520),
//   createData('Canada', 'CA', 37602103, 9984670),
//   createData('Australia', 'AU', 25475400, 7692024),
//   createData('Germany', 'DE', 83019200, 357578),
//   createData('Ireland', 'IE', 4857000, 70273),
//   createData('Mexico', 'MX', 126577691, 1972550),
//   createData('Japan', 'JP', 126317000, 377973),
//   createData('France', 'FR', 67022000, 640679),
//   createData('United Kingdom', 'GB', 67545757, 242495),
//   createData('Russia', 'RU', 146793744, 17098246),
//   createData('Nigeria', 'NG', 200962417, 923768),
//   createData('Brazil', 'BR', 210147125, 8515767),

{
    "foid": "",
    "boid": "1201470000055604",
    "sn": "Mr. Sirajul Islam",
    "dyear": "2010, 2011, 2010, 2009",
    "cashamt": 5760,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 5760,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000071496",
    "sn": "Mr. Md. Aminul Islam, Janata bank Ltd.",
    "dyear": "2011",
    "cashamt": 5850,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 5850,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000092650",
    "sn": "Mrs. Hasi Akter.",
    "dyear": "2013",
    "cashamt": 900,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 900,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000219579",
    "sn": "Mr. Md. Saidur Rahman Mia",
    "dyear": "2019, 2020",
    "cashamt": 3060,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 3060,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000227542",
    "sn": "MR. S. Z. M. RAHMAN",
    "dyear": "2009",
    "cashamt": 1080,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 1080,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000227858",
    "sn": "Mr. Abu Zafar Shamsuddin",
    "dyear": "2015",
    "cashamt": 1360,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 1360,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000233659",
    "sn": "Mr. Chandan Bhowmick",
    "dyear": "2020, 2010, 2019, 2013",
    "cashamt": 5320,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 5320,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000254286",
    "sn": "Md. Monzur Hossain",
    "dyear": "2014",
    "cashamt": 1105,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 1105,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000254350",
    "sn": "Mrs. Rahima Begum",
    "dyear": "2010, 2010",
    "cashamt": 2160,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 2160,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000271814",
    "sn": "MR. SHAFIQUL ISLAM",
    "dyear": "2009",
    "cashamt": 1080,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 1080,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000290295",
    "sn": "MR. AJIT CHANDRA AICH",
    "dyear": "2009",
    "cashamt": 3240,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 3240,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000296530",
    "sn": "Mr. Mobarak Ali",
    "dyear": "2017, 2015, 2018, 2016, 2009, 2014, 2014, 2017, 2015, 2016, 2018",
    "cashamt": 17400,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 17400,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000354370",
    "sn": "MRS. SUPARNA HOSSAIN",
    "dyear": "2009",
    "cashamt": 1080,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 1080,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
},
{
    "foid": "",
    "boid": "1201470000354445",
    "sn": "MRS. TAHMINA AFROZE",
    "dyear": "2009",
    "cashamt": 1080,
    "fracamt": 0,
    "ipoamt": 0,
    "netamt": 1080,
    "bshare": 0,
    "rightshare": 0,
    "dshare": 0,
    "netshare": 0,
    "dyearstock": null
}
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderColor:'red'
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [apiData,setApiData] = useState([])

  const { t } = useTranslation();
  const [newData, setNewData] = useState([]); // for autocomplete
  const [issuerValue, setIssuerValue] = useState(null)
  const [date, setDate] = useState('')
  const [yearShow, setYearShow] = useState([]); 
  const [thisCompanysYearType, setThisCompanysYearType] = useState([]);
  const [yearTypeAvailable, setYearTypeAvailable] = useState('');
  const [statusValue,setStatusValue]= useState("All") // inwardDividendType
  const [dateValue, setDateValue] = useState([])
  const [checkValue, setCheckValue] = useState([])
  const [key, setKey] = useState(Date.now());
  const [filteredData, setFilteredData] = useState([]);
  const [grandTotalResults,setGrandTotalResults] = useState([])
  const [inwardDividendType, setInwardDividendType] = useState('All');

  // const [issuerValue, setIssuerValue] = useState("c5509cb9-a361-4770-841b-8288c816379b")
  // const [statusValue,setStatusValue]= useState("All") // inwardDividendType
  // const [date, setDate] = useState('All')

  let customedValue= ""
  const paramsData = {
    issuerid: issuerValue,
    inwardDividendType:statusValue,
    investorsdividendType:"All",
    yearDividend: date,
    // page: currentPage,
    // take: showPageSize,
     
}

useEffect(()=>
{axios.get('./issuer/get-info')
.then((res)=>{
  setNewData(res.data.payload)
})
.catch((err) =>{
  alert(err.response.data.message)
})
},[])

useEffect(()=>{
  setPage(1)
},[filteredData])

const newData2 = newData?newData:options
var sortedNewData = newData2

const handleChange = (event,newValue) => {

    // console.log(newValue.key)
    // console.log(newValue.props.value.year)
  
    // setYearShow(
      // On autofill we get a stringified value.
    //    typeof value === 'string' ? event.target.value.split(',') : value,
    //   event.target.value
    // );

    //  customedValue = event.target.value.map(item => item.year).join(',');

    const value = event.target.value;
    if (value.some(item => item.id === 'all')) {
      setYearShow([{ id: 'all', year: 'All' }]);
      customedValue="All"
    } else {
      setYearShow(value);
      customedValue = event.target.value.map(item => item.year).join(',');
    }

   
    setDate(customedValue)

    // setData({...data, dividendYear: event.target.value})
     setDateValue((event.target.value))
    //  console.log((event.target.value.year))
     checkValue.push(newValue.props.value.year)
    //  console.log(checkValue)
    

  };




const searchResethandler = () =>{
setIssuerValue(null)
setKey(Date.now());
setStatusValue("")
setFilteredData([])
setDateValue([])
setDate('')

}
const searchHandler = () => {
  let timerInterval;
  Swal.fire({
    title: "Searching for your requested data",
    html: "Please be patient <b><b> .",
    timer: 5000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading();
      const timer = Swal.getPopup().querySelector("b");
      timerInterval = setInterval(() => {
        timer.textContent = `${Swal.getTimerLeft()}`;
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    }
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log("I was closed by the timer");
    }
  });
if ((issuerValue!=='')) {
    // axios.post(`investor-informations/issuer-wise-investors`, paramsData)
    // .then((res) =>{
    //     // setDataStore(res.data.payload.result)
    //     handleInwardDividendStateChange(res.data.payload.inwardDividendType)  
    //     setCheckValue([])
        
    //     if(res.data.payload.length===0)
    //     Swal.fire({
    //         title: "Oops..",
    //         text: "No Data found with the Given Information!",
    //         icon: "warning"
    //       })
    //     ;
    //     else console.log("no data")
       
    // })
    // .catch((err) =>{
    // })
    
    axios.post(`investor-informations/issuer-wise-investors`,paramsData)
    .then((res) =>{
          setFilteredData(res.data.payload.result)
          setGrandTotalResults(res.data.payload.grandTotal)
          setInwardDividendType(res.data.payload.inwardDividendType)
          
        // console.log(res.data.payload)
    })
    .catch((err) =>{
    })
}
else{
Swal.fire("Select atleast Company Name,and BO ID or Folio ")
}

}
console.log(filteredData )
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const excelDownloadHandler = () => {
    // Process the data and convert it to an array of arrays for XLSX
    const worksheetData = filteredData.map(item => [
      item.sn,
      item.boid,
      item.foid,

      item.dyear,
      item.cashamt,
      item.fracamt,
      item.ipoamt,
      item.netamt,

      item.dyearstock,
      item.bshare,
      item.rightshare,
      item.dshare,
      item.netshare,


    ]);

    const worksheetDataCash = filteredData.map(item => [
        item.sn,
        item.boid,
        item.foid,

        item.dyear,
        item.cashamt,
        item.fracamt,
        item.ipoamt,
        item.netamt,
      ]);

      const worksheetDataStock = filteredData.map(item => [
      item.sn,
      item.boid,
      item.foid,

      item.dyearstock,
      item.bshare,
      item.rightshare,
      item.dshare,
      item.netshare,
    ]);

    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(inwardDividendType==='All'?[
      ['Investor Name', 'BO ID','Folio','Year for Cash Dividend','Cash','Fraction','IPO','Net Cash','Year for Stock Dividend','Bonus Share','Right Share','Dividend on Dividend','Net Share'], // Header row
      ...worksheetData
    ]:inwardDividendType==='Cash'?[
        ['Investor Name', 'BO ID','Folio','Year for Cash Dividend','Cash','Fraction','IPO','Net Cash'], // Header row
        ...worksheetDataCash
      ]:[
        ['Investor Name', 'BO ID','Folio','Year for Stock Dividend','Bonus Share','Right Share','Dividend on Dividend','Net Share'],
        ...worksheetDataStock
      ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'Issuer-wise Investor Data Report.xlsx');
  };
console.log(apiData)
  return (
    <>
     <Grid container spacing={2}>
       
       <Grid item lg={3} md={3} sm={2} xs={12}>
        <FormControl fullWidth >
         <InputLabel id="issuer-id" ></InputLabel>
           <Autocomplete
            key={key} // Use the unique key here
               //   value={issuerValue}
               label="Select Issuer"
               onChange={(event, newValue) => {
                   setIssuerValue( newValue.id);
                   axios.get(`/issuer-year-format/${newValue.id}`)
                       .then((res)=>{
                           setThisCompanysYearType([{ id: 'all', year: 'All' },...res.data.payload])
                           if(res?.data?.payload?.length === 0) 
                               {
                                   setYearTypeAvailable('false')
                                   setCheckValue([])
                                   setDateValue([])
                                  //  console.log("hi")
                               }
                           else {
                               setYearTypeAvailable('true')
                               setCheckValue([])
                               setDateValue([])
                              //  console.log("bye")
                           }     
                           })
                       .catch((err) =>{
                           alert(err.response.data.message)
                       })
               }}
               disableClearable
               // options={top100Films}
               // getOptionLabel={(option) => "(" + option.title + ") " +option.year}
               options={sortedNewData?sortedNewData:newData?newData:options}
               getOptionLabel={(option) => "(" + option.tradecode + ") " +option.name}
              
               renderInput={(params) => (
               <TextField
                   {...params}
                   label="Select Issuer Name"
                   // margin="normal"
                   // variant="outlined"
                   // InputProps={{ ...params.InputProps, type: 'search' }}
               />
               )}
           />
       </FormControl>
       </Grid>

       {<Grid item lg={3} md={3} sm={12} xs={12}>
           <FormControl fullWidth >
             <InputLabel id="issuer-id" >Dividend Year</InputLabel>
               <Select style={{marginTop:'18px'}}
                 labelId="demo-multiple-checkbox-label"
                 id="demo-multiple-checkbox"
                 rows={2}
                 multiple
                 value={dateValue}
                 onChange={(e,newValue)=>handleChange(e,newValue)}
               //   renderValue={(selected) => selected.map(item => item.year).join(', ')}
               renderValue={(selected) => 
                   selected.some(item => item.id === 'all') 
                     ? 'All' 
                     : selected.map(item => item.year).join(', ')
                 }
                 MenuProps={MenuProps}
                 >
                    {yearTypeAvailable=== 'true' && thisCompanysYearType.map((i) => (
                       <MenuItem key={i.id} value={i}>
                           {/* <Checkbox checked={yearShow.indexOf(i) > -1} /> */}
                           <Checkbox checked={yearShow.some(selected => selected.id === i.id)} />
                         <ListItemText primary={i.year} />
                       </MenuItem>
                     ))}
                     {yearTypeAvailable=== 'false' && 
                       <MenuItem key="Year Type not defined yet" value="Year Type not defined yet">
                       Year Type not defined yet</MenuItem>
                     }
           
               </Select>
           </FormControl>
       </Grid>} 

       <Grid item lg={2} md={2} sm={2} xs={12}> 
           <FormControl fullWidth >
               <InputLabel id="issuer-id" >Inward Dividend Type</InputLabel>
                   <Select 
                   required
                   labelId="status"
                   id="status"
                   value={statusValue}
                   label="Select status"
                   // onChange={(e) => setStatusValue(e.target.value)}  
                   onChange={(e) => {
                       // handleInwardDividendStateChange(e.target.value);
                       setStatusValue(e.target.value)}}  
                   >
                       <MenuItem value='Cash' key='CashDividend'>Cash</MenuItem>
                       <MenuItem value='Stock' key='StockRightShare'>Stock</MenuItem>
                       <MenuItem value='All' key='FCCashDividend'>All</MenuItem>
                   </Select>
           </FormControl> 
       </Grid>

       <Grid item lg={1} md={2} sm={2} xs={12}>
           <span> 
           <Button
           variant="contained"
                   color="primary"
                   size="medium"
                   style={{margin:"10px", marginTop:"20px"}}
                   // disabled={ issuerValue===""|| statusValue==="" || checkValue===""}
                   disabled={ issuerValue===""|| date===""}
                   onClick={searchHandler}
                   >Search
           </Button>
           </span>
       </Grid>

       <Grid item lg={1} md={2} sm={2} xs={12}>
           <span>
           <Button
           variant="contained"
                   color="primary"
                   size="medium"
                   style={{margin:"10px", marginTop:"20px"}}
                   onClick={searchResethandler}
                   >{ t('common.reset')}
                   </Button>
                   
           </span>
       </Grid>
   </Grid>

    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {inwardDividendType==='All' && columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth,
                    backgroundColor:'#A7C7E7',
                    // backgroundColor:'#d0d5da',
                    fontWeight:'700'}}
                >
                  {column.label}
                </TableCell>
              ))}
               {inwardDividendType==='Cash' && columnsCash.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth,backgroundColor:'#A7C7E7',fontWeight:'700'}}
                >
                  {column.label}
                </TableCell>
              ))}
               {inwardDividendType==='Stock' && columnsStock.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth,backgroundColor:'#A7C7E7',fontWeight:'700'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {inwardDividendType==='All' && columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}

                   {inwardDividendType==='Cash' && columnsCash.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}

                   {inwardDividendType==='Stock' && columnsStock.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    <Button
                variant="contained"
                        color="primary"
                        size="medium"
                        style={{margin:"10px", marginTop:"20px"}}
                        onClick={excelDownloadHandler}
                        >{ t('Download')}
                        </Button>
    </>
  );
}
