import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/Wrappers/Wrappers";
import FilterTableComponent from "./FilterTableComponent";


export default function ReportOfInvestorSearch(){
 
    const { t } = useTranslation();
   

    return(
        <>
            <PageTitle title={ "Report of Investor"}  />
            <FilterTableComponent />
        </>
    )
}