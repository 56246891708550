import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import InvestorData from './InvestorData';

export default function ViewInvestorInfo(){
  const { t } = useTranslation();
   
    return(
        <div>
        <PageTitle title={ t('investorsInformation.view.pageTitle')} button={<Link to="/app/investor-informations"> <Button
   variant="contained"
   size="medium"
   color="primary"
 >
     { t('investorsInformation.view.listLink')}
 </Button></Link>}  />
        <InvestorData />
    </div>
    )
}