import { Box, Button, Grid, InputAdornment, OutlinedInput, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Lock, LockOpen } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Link, Link as RouterLink, useLocation } from 'react-router-dom';
import { forgetPasswordByOtp, useUserDispatch } from "../../context/UserContext";
import Logo from './AuthBackground';
import Notification from './Notification';
import CmsfLogo from './cmsf.jpg'; // Replace with the path to your logo image
import './style.css';
import axios from "../../utils/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(4),
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // height:'600px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    width: 180,
    height: 80,
  },
}));

const ForgetPasswordOtpVerify = (props) => {
  const classes = useStyles();
  const [otpCode, setOtpCode] = useState('');
  var dispatch = useUserDispatch();
  const location = useLocation();
  const data = location.state;
  const phoneNumber = data;
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const [isCountdownActive, setIsCountdownActive] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(true);
  const countdownRef = useRef(null);
  const [isErrorResendOtp, setIsErrorResendOtp] = useState(false);
  const message = "The number that you've entered doesn't match your code. Please try again."
  const messageValidation = "You've only entered 4 numbers. Please check your code and try again."
  const messageOtp = "We are unable to send sms to this phone number. Please check your phone number.";


  const [isFormValid, setIsFormValid] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordNotFillsRequirement , setPasswordNotFillsRequirement] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("Please match password");
    const [passwordNotMatched, setPasswordNotMatched] = useState(true);

    
      
   
      const validateConfirmPassword = (value) => {
        if (value !== password) {
          setConfirmPasswordError("Passwords do not match");
          return false;
        }
        if (confirmPassword === '') {
          setConfirmPasswordError("");
          return false;
        } 
        if (value === password){
          setPasswordNotMatched(false)
          setConfirmPasswordError("")
        }
        else {
          setConfirmPasswordError("");
         
          return true;
        }
      };

      const validatePassword = (value) => {
        const passwordRegex = /^((?=.*[0-9])(?=.*[A-Z])(?=.*\W)).{6,32}$/;
        
         if(!passwordRegex.test(value)){
          setPasswordError("Password should be at least 6 characters with at least one number, one special character and one upper case character");
          return false;
        } 
        if (password === '') {
          setPasswordError("");
          return false;
        }
        else {
          setPasswordError("");
          setPasswordNotFillsRequirement(false)
          //return true;
        }
      };

      const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        validatePassword(e.target.value);
      };
      const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        validateConfirmPassword(e.target.value);
      };

      

  useEffect(() => {
    if (isCountdownActive) {
      countdownRef.current = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) { 
            setIsCountdownActive(false);
            clearInterval(countdownRef.current);
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownRef.current);
    };
  }, [isCountdownActive]);

  const handleSubmit =() =>{
    if(otpCode.length !== 4){
        setIsValid(true)
    }
    // else if(password.length < 6 || confirmPassword.length < 6){
    //     setPasswordError("Password length not fulfilled")
    //     setConfirmPasswordError("Password can't be empty")
    // }
    else{
        forgetPasswordByOtp( dispatch,otpCode,password, props.history, setIsLoading, setIsError, phoneNumber )
    }

    // if(otpCode.length === 4){
    //   verifyOtpToLogin( setIsLoading, setIsError,otpCode, phoneNumber, props.history, userDispatch);
    // }else{
    // //   setIsValid(true)
    // }
  }


  const handleClose = () => {
    setIsError(false);
    setIsValid(false);
  };
  //console.log(((passwordNotFillsRequirement) && !(confirmPasswordError==="Passwords do not match")));
  console.log(passwordNotFillsRequirement);
  console.log(confirmPasswordError==="Passwords do not match");
  //console.log(passwordNotFillsRequirement && passwordMatched);

  return (
    <div >
      <Box className="left-align">
      <Logo />
    </Box>
    
    <div className={classes.root}>
      <img  src={CmsfLogo} alt="Logo" className={classes.logo} /> 
      <Notification message={messageValidation} open={isValid} handleClose={handleClose} color='red' />
        <Notification message={message} open={isError} handleClose={handleClose} color='red' />
        <Notification message={messageOtp} open={isErrorResendOtp} handleClose={handleClose} color='red' />
      <Box style={{transform:'scale(0.8)'}}>
      <Box style={{marginBottom:'3px'}}>
      <Paper>
        <Box style={{textAlign:'left', color:'black',padding:'20px'}}>
          <Typography variant='h4' component='h4'>Reset Password</Typography>
        </Box>
        </Paper>
      </Box>

      <Box>
      <Paper className={classes.paper} style={{marginBottom:'3px'}}>
        
        
        <Typography style={{color:'black', marginBottom:'20px'}} variant="h5" component="h1" gutterBottom>
        Let us know that this phone number belongs to you. Enter the code from the sms sent to <strong>{phoneNumber || "XXXXXXXXXX"}</strong>.
        </Typography>
        
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <OutlinedInput
                
                fullWidth
                id="phone"
                type="number"
                value={otpCode}
                onChange={e => setOtpCode(e.target.value)}
                placeholder="OTP CODE"
                startAdornment={
                  <InputAdornment position="start">
                    <LockOpen />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <Grid style={{margin:'10px 0px'}} container spacing={2}>
          <Grid item xs={6}>
          <OutlinedInput
            fullWidth
            id="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="New Password"
            startAdornment={
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            }
          />
          {passwordError && (
            <Typography variant="caption" color="error">
              {passwordError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <OutlinedInput
          required
            fullWidth
            id="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            startAdornment={
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            }
          />
          {confirmPasswordError && (
            <Typography variant="caption" color="error">
              {confirmPasswordError}
            </Typography>
          )}
        </Grid>
          </Grid>

          <Grid container justify="flex-start" style={{padding:'10px 0px 10px 0px'}}>
            <Grid item>
              
              <Box>
              <Grid container justify="flex-start" style={{ padding: '10px 0px 10px 0px' }}>
            <Grid item>
              {isOtpSent && !isCountdownActive && (
                <Link to='forget-password'>
                <Button variant="contained" >
                  Send OTP Again
                </Button></Link>
              )}
              {isOtpSent && isCountdownActive && (
                <Typography variant="body1" component="span">
                  Resend SMS in {Math.floor(remainingTime / 60)} minutes {remainingTime % 60} seconds
                </Typography>
              )}
            </Grid>
          </Grid>
      </Box>
            </Grid>
          </Grid>
      </Paper>
      </Box>

      <Box>
      <Paper>
        <Box style={{textAlign:'center', color:'white'}}>
        <Grid container spacing={2}>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={4} style={{marginRight:'5px'}}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="default"
                className={classes.submit}
                component={RouterLink}
                to="login"
                >
                Back
                </Button>
            </Grid>
          
            <Grid item xs={4} style={{marginRight:'5px'}}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                color="primary"
                className={classes.submit}
                disabled={((otpCode.length<4) || (passwordNotFillsRequirement) || (confirmPasswordError === 'Passwords do not match' || confirmPasswordError === 'Please match password' ) || (password !== confirmPassword))}
    
                >
                Change Password
                </Button>
            </Grid>
            </Grid>
        </Box>
        </Paper>
      </Box>
      
      </Box>
    </div>
    </div>
  );
};

export default ForgetPasswordOtpVerify;



