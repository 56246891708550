  import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#f1f3f4',
      '& .MuiOutlinedInput-root': {
        borderRadius: 24,
        '&:hover fieldset': {
          borderColor: '#dadce0',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1a73e8',
          borderWidth: 2,
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 14px',
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 14px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
    requiredAsterisk: {
      color: 'red',
    },
  }));
  export default useStyles;