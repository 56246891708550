// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button,Grid } from "@material-ui/core";
import { ViewListOutlined } from "@material-ui/icons";
import Swal from 'sweetalert2';
import PageTitle from "../../../components/PageTitle/PageTitle";
import axios from "../../../utils/axios";
import Table from './Table';


function FilterTableComponent(data) {
    const user = localStorage.getItem('user-name')
    const userFormatted = JSON.parse(user);
    const userType = userFormatted.userTypes[0]

    const { t } = useTranslation();
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    const [activeState, setActiveState] = useState();
    const [refreshState,setRefreshState]=useState(1);
    // const [testState, setTestState] = useState([]);
  

    console.log('hi');
    

    useEffect(()=>{
        axios.get(`/bank-bo/list-all/Stock?take=${showPageSize}&page=${currentPage}`, )
        .then((res) =>{
            //  if (res.data.payload.type === 'Cash' || res.data.payload.type === 'FC')
            //  console.log(res.data.payload);
            //  for (let i = 0; i < res.data.payload.length; i++) {
            //     //console.log(res.data.payload[i]);
            //     if(res.data.payload[i].type === 'Stock')
            //    // setTestState(...testState, res.data.payload[i])
            //    testState.push(res.data.payload[i]);
            // }
            setDataStore(res?.data?.payload)
            setCurrentPage(1);
             
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
            .catch((err) =>{
            })
      
    },[data,refreshState]) 

    const approveHandler = (id) =>{
        Swal.fire({
            title: "Are you sure to Activate the BO Account?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Sure!"
          }).then((result) => {
            if (result.isConfirmed) {
         axios.put(`/bank-bo/approved-bank-bo/${id}`,{status: 1}) 
         .then((res) =>{
            setRefreshState(res.data.statusCode+Math.random());
            
                Swal.fire({
                    icon:"success",
                    title:'Activated',
                    timer: 1500
                })

           
         })
             .catch((err) =>{
             })
            }
        })
        
    }
// console.log(testState);

    const declineHandler = (id) =>{
        Swal.fire({
            title: "Are you sure to Deactivate the BO Account?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Sure!"
          }).then((result) => {
            if (result.isConfirmed) {

        axios.put(`/bank-bo/deactive-bank-bo/${id}`,{status: 0}) 
         .then((res) =>{
            setRefreshState(res.data.statusCode+Math.random())
                Swal.fire({
                    icon:"success",
                    title:'Deactivated',
                    timer: 1500  
                })
           
         })
             .catch((err) =>{
            })
        }
    })
    
}

    const getDataSync = (refreshState, page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.get(`/bank-bo/list-all/Stock?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
            console.log('after');
        })
            .catch((err) =>{
                alert("There was a error occured!")
            })
    }
   
    const columns = React.useMemo(
        () => [
           {
                Header: `CMSF's BO Accounts`,
                columns: [
                    {
                        Header: 'Sl',
                        accessor: 'autoId',
                    },{
                        Header: 'Type',
                        accessor: 'type',
                    },
                    {
                        Header: 'Bank/Broker Name',
                        accessor: 'companyName'
                    },
                    {
                        Header: 'Account Name',
                        accessor: 'title'
                    },
                    {
                        Header: 'BO ID',
                        accessor: 'accountNumber'
                    },
                    {
                        Header: 'Branch',
                        accessor: 'branch'
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        Cell:({row}) => <>
                        {row.values.status===0? 'Deactive': row.values.status===1? 'Active': ''}
                        </>
                    },
                    { 
                        Header: 'Action',
                        accessor: 'id',
                        Cell: ({row}) => <>
                        
                        {(userType === 'Super_Admin'|| userType === 'Admin_CMSF' ) && row.original.status === 0?<Button
                                style={{margin: '2px',maxWidth:"90px",maxWidth:"90px"}} 
                               size="small" title='Activate it' variant="contained" color="primary" 
                                onClick={e=>approveHandler(row.original.id)}
                                > Activate
                                
                            </Button> :
                             (userType === 'Super_Admin'|| userType === 'Admin_CMSF' ) && row.original.status === 1?<Button
                              style={{margin: '2px',maxWidth:"90px",maxWidth:"90px"}} 
                             size="small" title='Deactivate it' variant="contained" color="secondary" 
                              onClick={e=>declineHandler(row.original.id)}
                              > 
                             Deactivate
                          </Button>:''  }
                        </>
                    }

                ],
            },
           
        ],
        []
    )

    return (
        <>
         <PageTitle title={ "Accounts"} />
            <>
            <Grid>
                    <Table 
                        getDataSync={getDataSync} 
                        showPageSize={showPageSize} 
                        setShowPageSize={setShowPageSize} 
                        setNumberOfpage={setNumberOfpage} 
                        numberOfPage={numberOfPage} 
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage} 
                        setDataStore={setDataStore} 
                        columns={columns} 
                        data={dataStore} 
                        params={params}
                    />
                    </Grid>
            </>
               
        </>
    )
}

export default FilterTableComponent;