import { TextField } from "@material-ui/core";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReplyIcon from '@mui/icons-material/Reply';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import NewIcons from "../../pages/icons/NewIcons";
import axios from '../../utils/axios';
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #eee',
    boxShadow: '2px 2px 2px 2px #eee',
    borderRadius:"10px",
    p: 4
  };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  


function CreateMenu(){



  let initialValue = 
    {
        title: "",
        description: "",
        menuName: "",
        icon: "",
        targetUrl: "",
        parentId: 0,
        routePath:"",
        status: 7
      };
    const [open, setOpen] = useState(false);
    const [IconList,setIconList] = useState([]);
  const handleOpen = () => {
    setOpen(true);
    setState(initialValue);
    // Object.values(NewIcons).map((icon,index)=> IconArray.push({id:index,label:icon.label}) )
    Object.entries(NewIcons).map(([key, IconComponent], index) =>IconArray.push({
      id:index,
      label: IconComponent.name, // Label without "Fc" prefix, can adjust as needed
      icon: IconComponent
    }))
    setIconList(IconArray);
    console.log(IconArray)
  };
  ;
  const handleClose = () => {
    setOpen(false);
    setState(initialValue);
  };
    const baseURL =  process.env.REACT_APP_API_BASE_URL;
    

      let initialParentList = {id:0,label:""};
      
      

    const [show, setShow] = useState(false);
    const [add,setAdd] = useState(false);
    const [menuList , setMenuList ] = useState([{}]);
    const [ParentList,setParentList] = useState([initialParentList])

    const [enableAdd,setEnableAdd] = useState(false);

    const handleMenu = () =>{
        setShow(true);
        axios.get('permissions/get-all-menu-list')
    .then((res) =>{
      setMenuList(res?.data?.payload);
      // console.log(res.data.payload);
      const parents = res?.data?.payload.map((item)=>{
        return {id:item.id,label:item.label};
      })
        setParentList(parents);
        
    })
    .catch((err) =>{
      console.log(err)
    })
    }

    const onSubmit = (event) =>{
        event.preventDefault();
        axios.post('permissions/create',state)
    .then((res) =>{
      if(res)
      {
        handleClose();
        Swal.fire({
          icon: 'success',
          title: 'New Menu has been added',
          showConfirmButton: false,
          timer: 1500
        })
        setState(initialValue); 
      }
      
    })
    .catch((err) =>{
      console.log(err)
    })
    
    
    }

    const HandleChangeIcon = (val) =>
      {
        console.log(val)
        const {id,label} = val;
        setState((prevProps) => ({
          ...prevProps,
          icon: label
        }));
      }
    const HandleChangeParent = (val) =>
    {
      const {id,label} = val;
      setState((prevProps) => ({
        ...prevProps,
        parentId: id
      }));
    }

    const [state, setState] = useState(initialValue);



    useEffect(() => {
      const { title, description, menuName, icon, targetUrl, routePath } = state;
      if (title!=="" && description!=="" && menuName!=="" && icon!=="" && targetUrl!=="" && routePath!=="") {
        setEnableAdd(true);
      } else {
        setEnableAdd(false);
      }
      handleMenu();
    }, [state]);
    
      
      const Popup = styled('div')`
        z-index: 1;
      `;
      let IconArray = [];

      const IconOption = ({ data, innerRef, innerProps }) => {
        return (
          <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center',padding:"5px",border:'1px solid grey',margin:"1px"}}>
            <h5><data.icon style={{ marginRight: 10 }} /> {/* Render the icon */}
            {data.label}</h5>
          </div>
        );
      };
      const SingleValue = ({ data, ...props }) => {
        return (
          
            <h5 style={{margin:"none",padding:"none"}}><data.icon style={{ marginRight:10 }} />{data.label}</h5>
       
        );
      };
      



    return(
        <div>
            {/* <button type = "button" onClick={handleMenu}>see menu</button>
            <button type = "button" onClick = {handleAdd}>Create New Menu</button> */}
            <h2>Menu List</h2>
            <Stack spacing={2} direction="row" style={{justifyContent:"center"}}>
            <Button variant="contained" color="error" onClick = {handleOpen}>Create New Menu</Button>
            <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                    backdrop: {
                        timeout: 500,
                    }}}
                >
                    <Fade in={open}>
                    <Box sx={style} >
                        <h3>New Menu Information</h3>
                        <form onSubmit={onSubmit}>
                            <div className="form-control">
                            
                            <TextField required fullWidth label="Menu Title" id="title" type="text" name="title" value={state.title} 
                            onChange={(event)=>
                            {
                              setState((prevProps) => ({
                                ...prevProps,
                                title: event.target.value
                              }));
                            }
                            } />
                            </div>
                            <div className="form-control">
                            <TextField required fullWidth label="Description" id="description" type="text" name="description" value={state.description} 
                            onChange={(event)=>
                              {
                                setState((prevProps) => ({
                                  ...prevProps,
                                  description: event.target.value
                                }));
                              }
                              } />
                            </div>
                            <div className="form-control">
                            <TextField required fullWidth label="Menu Name" placeholder="(It will appear in Sidebar)" id="menuName" type="text" name="menuName" value={state.menuName} 
                            onChange={(event)=>
                              {
                                setState((prevProps) => ({
                                  ...prevProps,
                                  menuName: event.target.value
                                }));
                              }} />
                            </div>
                            <div className="form-control">
                            {/* <TextField required fullWidth label="Menu Icon" id="icon" type="text" name="icon" value={state.icon} 
                           onChange={(event)=>
                              {
                                setState((prevProps) => ({
                                  ...prevProps,
                                  icon: event.target.value
                                }));
                              }} /> */}
                              <Select
                                placeholder={"Select Icons"}
                                  cacheOptions
                                  defaultOptions
                                  styles={{menu: (provided) => ({ ...provided, zIndex: 9999 }),}}
                                  options={IconList}
                                  onChange={(val) => HandleChangeIcon(val)}
                                  components={{ Option: IconOption,SingleValue }}
                                  
                                  
                                />
                            </div>
                            <div className="form-control">
                            <TextField required fullWidth label="Menu URL" placeholder="e.g: /app/dashboard" id="targetUrl" type="text" name="targetUrl" value={state.targetUrl} 
                            onChange={(event)=>
                              {
                                setState((prevProps) => ({
                                  ...prevProps,
                                  targetUrl: event.target.value
                                }));
                              }} />
                            </div>
                            <div className="form-control">
                            <TextField required fullWidth label="Component Route" placeholder="e.g: {path: '/app/CreateMenu', name: 'CreateMenu', exact: true, component: CreateMenu}" id="targetUrl" type="text" name="targetUrl" value={state.routePath} 
                            onChange={(event)=>
                              {
                                setState((prevProps) => ({
                                  ...prevProps,
                                  routePath: event.target.value
                                }));
                              }} />
                            </div>
                            <div className="form-control">
                                <Select
                                placeholder={"Select Parent if it is child"}
                                  cacheOptions
                                  defaultOptions
                                  maxMenuHeight={160}
                                  styles={{menu: (provided) => ({ ...provided, zIndex: 9999 }),}}
                                  options={ParentList}
                                  onChange={(val) =>HandleChangeParent(val)}
                                />
                            </div>
                            <div className="form-control" style={{textAlign:"center"}}>
                            
                              
                              <Button type="submit" disabled={!enableAdd}  variant="contained" color="success" style={{color:"white"}}><AddCircleOutlineIcon/> Add New </Button>
                            
                            &nbsp;&nbsp;
                            <Button type="button" onClick={handleClose} variant="contained" color="info" style={{color:"white"}}><ReplyIcon/> Go Back</Button>
                            </div>
                        </form>
                    </Box>
                    </Fade>
                </Modal>




            {/* <Button variant="contained" color="success" onClick = {handleMenu}>See Menu</Button> */}
            </Stack>
            {show ?
            <TableContainer component={Paper} style={{marginTop:"25px"}}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
              
                <StyledTableCell align="center">MenuName</StyledTableCell>
                <StyledTableCell align="center">MenuURL</StyledTableCell>
                <StyledTableCell align="center">MenuID</StyledTableCell>
                <StyledTableCell align="center">ParentMenu</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {menuList.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {row.label}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.link}</StyledTableCell>
                  <StyledTableCell align="center">{row.id}</StyledTableCell>
                  <StyledTableCell align="center">{menuList.find((item)=>{ return item.id===row.parentId ? (item.label) : null})}</StyledTableCell>
                  
                  
                </StyledTableRow>
              ))} */}
              {menuList.map((row) => {
                const parentItem = menuList.find((item)=>{ return item.id === row.parentid});
                return (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {row.label}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.link}</StyledTableCell>
                    <StyledTableCell align="center">{row.id}</StyledTableCell>
                    <StyledTableCell align="center">
                      {parentItem ? parentItem.label : null}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
            
            :
            null
            }
        </div>
    )
}

export default CreateMenu