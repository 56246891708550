import React from 'react'
import { useTranslation } from 'react-i18next';
// import claimSettlePhoto from '../../../src/components/images/claimSettlePhoto.jpeg'


const DashboardGeneral = () => {
  const { t } = useTranslation();

    return (
      <div>
              {/* <h1>Welcome to INSAF<br /></h1> */}
              <h3 style={{textAlign:'center'}}>
                <b>{t('Dashboard.heading')}</b><br /><br />
              </h3> 
              
              <h5 align="justify">{t('Dashboard.detailsP1')} <br /><br /> 
              {t('Dashboard.detailsP2')}
                <br /><br />
                {t('Dashboard.linkNarration')} <a href="https://cmsfbd.org/investment-claim-settlement">Required Documents</a>
              </h5>

                  {/* <h4 style={{textAlign:'center'}}>
                    <b>First Claim Settlement of CMSF</b>
                  </h4>
                  <img src={claimSettlePhoto} alt="Logo 5" style={{  height: '500px', width: '1100px' , textAlign:'center'}} /> */}
                  {/* <h1 style={{textAlign:'left'}}>ABOUT CMSF</h1>
                  <h5>
                  Capital Market Stabilization Fund (CMSF) was established by the Bangladesh Securities and Exchange Commission (Capital Market Stabilization Fund) Rules, 2021 (CMSF Rule 2021) by Bangladesh Securities and Exchange Commission (BSEC) in exercise of the powers conferred by section 33(1) of the Securities and Exchange Ordinance, 1969. CMSF acts as a custodian of undistributed cash and stock dividend, non-refunded public subscription money and un-allotted rights shares from the Issuer of listed securities. Cash and stocks in the fund will be returned back on due claim by the Shareholders or Investors at any time in the indefinite perpetuity.
                  The resources from CMSF will be utilized to stabilize the capital market ensuring liquidity to the market by buying and selling of listed securities, making investment in other securities, providing loan to market intermediaries, lending and borrowing of listed securities and settlement of investors' claim. Maximum 40% of cash balance of the CMSF may be used for direct buying and selling of listed securities, at least 50% of cash balance of the fund shall be used for providing loan to market intermediaries for refinancing as margin loan, and maximum 10% of cash balance of the fund may be used for investment in other securities i.e. in fixed deposits, government securities, fixed income securities and mutual funds and so on. These ratio of use of cash balance of the fund may be varied by the Board of Governors (BOG) with prior approval of the Commission.
                  </h5>
                  <h5>
                  The Bangladesh Securities and Exchange Commission (Capital Market Stabilization Fund) Rules, 2021 is available online: <a href="https://sec.gov.bd/slaws/Notification_05.07.2021.pdf">BSEC (CMSF)Rules,2021</a>

                  </h5> */}
        </div>
  )
}

export default DashboardGeneral