import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import Swal from "sweetalert2";
import { useGetIssuerInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import axios from "../../../utils/axios";
import UiOfLedgerReport from './UiOfLedgerReport'
// import Select from 'react-select'

const options = [{ tradecode: "No Data Available", name: "" },
// { tradecode: "No Data Available", name: "" },
];

// ijefief
function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize,setNumberOfpage,params }) {

    const {  data:issuer} = useGetIssuerInfoQuery();
    const { t } = useTranslation();
    const [inwardDividendType,setInwardDividendType]= useState("")
    const [newData, setNewData] = useState([]);
    const [ledgerLoad, setLedgerLoad] = useState([]);
    const [filteredData, setFilteredData] = useState([]);


    useEffect(()=>
  {axios.get('./issuer/get-info')
  .then((res)=>{
    setNewData(res.data.payload)
  })
  .catch((err) =>{
    alert(err.response.data.message)
  })
//   axios.get(`ileder/issuer-wise-ledger-load/${issuerValue}`)
//   .then((res)=>{
//     setLedgerLoad(res.data.payload)
//   })
//   .catch((err) =>{
//     alert(err.response.data.message)
//   })
},[])

//    console.log(ledgerLoad)

    const newData2 = newData?newData:options
    var sortedNewData = newData2

    const defaultColumn = React.useMemo(
        () => ({
            Filter: "",
        }),
        []
    )

    const issuerData = issuer?.payload
    const issuerData2 = issuerData;
    var sortedIssuerData = issuerData
    if (issuerData2) {
     sortedIssuerData = issuerData2.slice().sort((a, b) => {
        if (a.tradecode < b.tradecode) {
          return -1;
        }
      });
     
    }
    

const ITEM_HEIGHT = 100;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 250,
            // height: 250,
            overflowY: 'scroll',
            overflowX: 'scroll'
          },
        },
      };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const nextPageHandler = () =>{        
        if(numberOfPage > currentPage){
            setCurrentPage(++currentPage);      
        }
    }

    const prevPageHandler = () =>{        
        if(currentPage > 1){
            setCurrentPage(--currentPage);
        }
    }
    
    const handleFirstPage = () =>{
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }

    const handleLastPage = () =>{
        if(currentPage !== numberOfPage){
            setCurrentPage(numberOfPage)
        }
    }

    const goToPageHandler = (e) =>{
        getDataSync(e,showPageSize, issuerValue)
    }

    

    const pSize= [5, 10, 20, 30, 40, 50];

   
    const [issuerValue, setIssuerValue] = useState(null)
    const [key, setKey] = useState(Date.now()); 
    const [reset, setReset] = useState("false")
    const [date, setDate] = useState('')
    const [grandTotalResults,setGrandTotalResults] = useState([])
    const [divTypeFromResponse,setDivTypeFromResponse] = useState([])
    const [ledgerNumber, setLedgerNumber] = useState('')
    
    
   let customedValue= ""
  
  
    const paramsData = {
        issuerid: issuerValue,
        inwardDividendType:inwardDividendType,
        ldgacno:ledgerNumber,
        
        // yearDividend: date,

        take: 500,  
    }

   

console.log(filteredData)
    const searchResethandler = () =>{
        setIssuerValue(null)
        setInwardDividendType("")
        setLedgerNumber("")
        setKey(Date.now());
        setReset(true)
        setFilteredData([])
        setDate('')
    }
    


    const searchHandler = () => {
            axios.post(`ileder/issuer-wise-ledger-details-report`, paramsData)
            .then((res) =>{
                setFilteredData(res.data.payload.result)
                setGrandTotalResults(res.data.payload.grandTotal)
                setDivTypeFromResponse(res.data.payload.inwardDividendType)
                    // if(res.data.payload.result.length===0)
                    //     {
                    //         console.log(res)
                    //         Swal.fire({
                    //             title: "Oops..",
                    //             text: "No Data found with the Given Information!",
                    //             icon: "warning"
                    //         })
                    //     }
                    // if(res.data.payload[0].issuername===null)
                    //     {
                    //         Swal.fire({
                    //             title: "Oops..",
                    //             text: "No Data found with the Given Information!",
                    //             icon: "warning"
                    //         })
                    //     }
                    ;
                    if(res.data?.page){
                        setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
                        
                    }
                })
            .catch((err) =>{
                Swal.fire("Something went wrong!")
            })
    }

    useEffect(()=>{
        setCurrentPage(1)
    },[issuerValue])

console.log(issuerValue)
    return (  
        <>
        <Grid container spacing={2}>
       
            <Grid item lg={3} md={3} sm={2} xs={12}>
             <FormControl fullWidth >
              <InputLabel id="issuer-id" ></InputLabel>
                <Autocomplete
                 key={key} // Use the unique key here
                    //   value={issuerValue}
                    label="Select Issuer"
                    onChange={(event, newValue) => {
                        setIssuerValue( newValue.id);
                        // axios.get(`/issuer-year-format/${newValue.id}`)
                        //     .then((res)=>{
                        //         // setThisCompanysYearType([{ id: 'all', year: 'All' },...res.data.payload])
                        //         // if(res?.data?.payload?.length === 0) 
                        //         //     {
                                  
                                      
                        //         //     }
                        //         // else {
                                 
                                    
                        //         // }     
                        //         })
                        //     .catch((err) =>{
                        //         alert(err.response.data.message)
                        //     })
                        axios.get(`ileder/issuer-wise-ledger-load/${newValue.id}`)
                        .then((res)=>{
                          
                          if(res?.data?.payload?.length === 0) 
                          {
                            setLedgerLoad([
                                {
                                    "ldgacname": "No Ledger Found ",
                                    "ldgAcNo": ""
                                }])  
                                      
                        }else{setLedgerLoad(res.data.payload)}
                        
                        })
                        .catch((err) =>{
                          alert(err.response.data.message)
                        })
                    }}
                    disableClearable
                    options={sortedNewData?sortedNewData:newData?newData:options}
                    getOptionLabel={(option) =>  option.name+" (" + option.tradecode + ") "}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Issuer Name"
                    />
                    )}
                />
            </FormControl>
            </Grid>
   
            
            <Grid item lg={2} md={2} sm={2} xs={12}> 
                <FormControl fullWidth >
                    <InputLabel id="issuer-id" >Inward Dividend Type</InputLabel>
                        <Select 
                        required
                        labelId="status"
                        id="status"
                        value={inwardDividendType}
                        label="Select status"
                        onChange={(e) => setInwardDividendType(e.target.value)}  
                        >
                            <MenuItem value='Cash' key='CashDividend'>Cash</MenuItem>
                            <MenuItem value='Stock' key='StockRightShare'>Stock</MenuItem>
                          
                        </Select>
                </FormControl> 
            </Grid>

            <Grid item lg={2} md={2} sm={2} xs={12}> 
                <FormControl fullWidth >
                    <InputLabel id="issuer-id" >Ledger Name</InputLabel>
                        <Select 
                        required
                        labelId="status"
                        id="status"
                        value={ledgerNumber}
                        label="Select status"
                         onChange={(e) => setLedgerNumber(e.target.value)}  
                        >
                           {ledgerLoad?.map((i)=><MenuItem value={i.ldgAcNo} key={i.ldgAcNo}>{i.ldgacname}</MenuItem>)}
                          
                        </Select>
                </FormControl> 
            </Grid>

            <Grid item lg={1} md={2} sm={2} xs={12}>
                <span> 
                <Button
                variant="contained"
                        color="primary"
                        size="medium"
                        style={{margin:"10px", marginTop:"20px"}}
                         disabled={ issuerValue==='' || inwardDividendType==='' || ledgerNumber===''}
                        onClick={searchHandler}
                        >Search
                </Button>
                </span>
            </Grid>

            <Grid item lg={1} md={2} sm={2} xs={12}>
                <span>
                <Button
                variant="contained"
                        color="primary"
                        size="medium"
                        style={{margin:"10px", marginTop:"20px"}}
                        onClick={searchResethandler}
                        >{ t('common.reset')}
                        </Button>
                        
                </span>
            </Grid>
        </Grid>
           
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

           
            {(filteredData.length!==0 && filteredData[0].investorname!==null) && <Grid style={{marginTop:'80px'}}><UiOfLedgerReport data={filteredData} inwardDividendType={inwardDividendType} grandTotal={grandTotalResults} divTypeFromResponse={divTypeFromResponse}/></Grid>}
  
        </>
    )
}

export default Table;