import { Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "../../../../utils/axios";




const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'right',
  },


  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    height: "650px",
    width:"800px",
    padding: theme.spacing(2, 2, 2),
    overflow: 'scroll',
  },
}));

export default function RolePermission({ open,data, control, getData}) {

      const classes = useStyles();
      const { t } = useTranslation();
      var disableId = 10
      const {id,title} = data;
      const roleId = id;
      let assign=[];
      let unassign=[];
    
      
      const [canAssign, setCanAssign] = useState(10);
      const [canUnassign, setCanUnassign] = useState();
      
      const [data2,setData2] = useState([]);
      const [statusUpdated,setStatusUpdated] = useState([]);
      


    const getData2 = () =>{
      axios.get(`permissions/role-unassign-permissions/${id}`)
      .then((res) =>{
          setData2(res.data.payload.unAssignedPermissions)
          console.log(res.data.payload.unAssignedPermissions)
          // 23225035
          if(res.data?.page){
          }
      })
      .catch((err) =>{
      })
  }


    useEffect(()=>{
      getData2();
    },[id,statusUpdated]);
    



    const assignHandler = (idd) => {

      //setCanUnassign(idd.rowIndex)
      
      // console.log(`role id is ${roleId}`)
      // console.log(`permission id is ${idd}`)
      const values = {
        role: roleId,
        permissions: [idd]
      };
      
     
      axios.post('/roles/assign-permissions',values     
      ).then((res)=>{
        setStatusUpdated(res)
        Swal.fire({
          position: "bottom-right",
          icon: "success",
          title: "Assigned successfully",
          showConfirmButton: false,
          timer: 2000
        });
    
      }).catch((err)=>{
          console.log(err);
      });
      ; 
    };

    // const unassignHandler = (idd) => {
      
    //  // setCanUnassign(idd.rowIndex)
      
      
    //   console.log(`role id is ${roleId}`)
    //   console.log(`permission id is ${idd}`)
    //   const values = {
    //     role: roleId,
    //     permissions: [idd]
    //   };
    //   console.log(values);
      
     
    //   axios.post('/roles/remove-permissions',values     
    //   ).then((res)=>{
    //     console.log(values);
    //     //setCanUnassign(false)
    
    //   }).catch((err)=>{
    //       console.log(err);
    //   });
    //   ; 
    // };
   

    const testHandler = (d) => {

    };
    
    const columns = [
          {
            name: "title",
            label: "Permissions",
            options: {
            filter: true,
            sort: true,
            }
          },
          {
            name: "permissionType.title",
            label: "Permission Type",
            options: {
            filter: true,
            sort: true,
            }
          },
          {
            name: "description",
            label: "Description",
            options: {
            filter: true,
            sort: true,
            }
          },

          {
            name: "id",
            label: "Action",
            options: {
         
                  customBodyRender: (value, tableMeta, updateValue) => {
                    assign[value]= true;
                    unassign[value]= true;

                    // console.log(value)
                    // console.log(roleId)
                    
                    return (
                      <div className={classes.root}>
                        
                      <Button style={{margin: '5px'}}  size="small"  variant="contained" color='primary' 
                     // disabled={tableMeta.rowIndex} 
                      onClick={()=> assignHandler(value)
                      }>
                     
                      Assign
                      </Button>
                      
                      {/* <Button style={{margin: '5px'}}  size="small"  variant="contained" color='primary' 
                       
                      onClick={()=>unassignHandler(tableMeta.rowData[2])} 
                     // disabled={canUnassign}
                      >
                      Unassign
                      </Button> */}
                      </div>
                    )
                  }
            }
          }  
      ]
  
  
  
      const options = {
        selectableRows: 'none',
        enableNestedDataAccess: '.',
      };

 
  return (
    <div>
        
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={control}
          disableEscapeKeyDown
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
          timeout: 500,
          }}
        >
      
        <Fade in={open}>
          <div className={classes.paper}>
            <MUIDataTable
                  //title={"Permissions"}
                  data={data2}
                  columns = {columns}
                  options={options}
                  highlightOnHover={true}
                  fixedHeader
                  paginate={false}
                  pagination={false}
                  //rowsPerPage ={5} 
                  //isRowSelectable
                  caseSensitive={false} 
                  print={true}       
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
