import { Box, Button, Grid, InputAdornment, OutlinedInput, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Phone } from '@material-ui/icons';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { forgetPasswordSendOtp } from '../../context/UserContext';
import Logo from './AuthBackground';
import Notification from './Notification';
import CmsfLogo from './cmsf.jpg'; // Replace with the path to your logo image
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(4),
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // height:'600px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    width: 180,
    height: 80,
  },
}));

const OtpComponent = (props) => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isSuccess, setIsSuccess] =useState(false)
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('Successfullly Otp send');
  const [isErrorMsg, setIsErrorMsg] = useState('Successfullly Otp send');

  const handleClose = () => {
    setIsSuccess(false);
    setIsError(false);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    
    if(phoneNumber === ''){
      setErrorMessage("Phone number can't be empty");
      setIsValid(true);
    }else if(phoneNumber.length === 10  ){
      setIsValid(false);

    }else{
      setErrorMessage('Enter registered phone number only');
      setIsValid(true);

    }
  };

  const handleSendOTP = () => {
    if (!phoneNumber) {
      setIsValid(true);
      setErrorMessage("Phone number can't be empty");
      return;
    }
    if (phoneNumber.length === 11) {
      setTimeout(() => {
        forgetPasswordSendOtp(phoneNumber, props.history, setIsLoading, setIsError,setIsSuccess,setIsErrorMsg)     
      }, 500);
    }else{
      setIsValid(true);
      setErrorMessage('Phone number is invalid');
    }
    
  };

  return (
    <div>
      <Box className="left-align">
      <Logo />
    </Box>
    <Box>
        <Notification message={successMessage} open={isSuccess} handleClose={handleClose} color='green' />
        <Notification message={isErrorMsg} open={isError} handleClose={handleClose} color='red' />
        </Box>
    
    <div className={classes.root}>
      <img  src={CmsfLogo} alt="Logo" className={classes.logo} /> 
      <Box>
      <Box style={{marginBottom:'3px'}}>
      <Paper>
            <Box style={{ textAlign: 'left', color: 'black', padding: '20px' }}>
        <Typography variant="h4" component="h4" style={{ fontWeight: 'bold' }}>
            Reset Your Account
        </Typography>
        </Box>
        </Paper>
      </Box>

      <Box>
      <Paper className={classes.paper} style={{marginBottom:'3px'}}>
        
        <Typography style={{color:'black', marginBottom:'30px'}} variant="h5" component="h1" gutterBottom>
        Please enter your mobile number to reset for your account.
        </Typography>

          <Grid container spacing={2}>
            <Grid item xs={10}>
              <OutlinedInput
                fullWidth
                id="phone"
                type="number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Phone Number"
                startAdornment={
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                }
              />
              {isValid && <Typography variant="caption" color="error">{errorMessage}</Typography>}
            </Grid>

          </Grid>

          <Grid container justify="flex-start" style={{padding:'10px 0px 10px 0px'}}>
                
          </Grid>
      </Paper>
      </Box>

      <Box>
      <Paper>
        <Box style={{textAlign:'center', color:'white'}}>
        <Grid container spacing={2}>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={4} style={{marginRight:'5px'}}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="default"
                className={classes.submit}
                component={RouterLink}
                to="login"
                >
                Back
                </Button>
            </Grid>
            <Grid item xs={4} style={{marginRight:'5px'}}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSendOTP}
                >
                Send OTP
                </Button>
            </Grid>
            </Grid>
        </Box>
        </Paper>
      </Box>
      </Box>
    </div>
    </div>
  );
};

export default OtpComponent;



