import { Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Swal from 'sweetalert2';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "../../../../utils/axios";




const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'right',
  },


  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    height: "650px",
    width:"800px",
    padding: theme.spacing(2, 2, 2),
    overflow: 'scroll',
  },
}));

export default function RolePermissionViewOnly({ open,data, control}) {

      const classes = useStyles();
      const { t } = useTranslation();

      const {id} = data;
      const roleId = id;
   
    const [data3,setData3] = useState([]);
    const [statusUpdated,setStatusUpdated] = useState([]);
    

    const unassignHandler = (idd) => {
      
      // setCanUnassign(idd.rowIndex)
       
       
      // console.log(`role id is ${roleId}`)
   
       const values = {
         role: roleId,
         permissions: [idd]
       };

       axios.post('/roles/remove-permissions',values     
       ).then((res)=>{
        setStatusUpdated(res)
         //setCanUnassign(false)
         Swal.fire({
          position: "bottom-right",
          icon: "success",
          title: "Unassigned successfully",
          showConfirmButton: false,
          timer: 2000
        });
       }).catch((err)=>{
           console.log(err);
       });
       ; 
     };
  
   
   
   

    const getData3 = () =>{
      if (id){
      axios.get(`permissions/role-permissions/${id}`)
      .then((res) =>{
          setData3(res.data.payload)
          
      })
      .catch((err) =>{
      })
    }
    }

   

  useEffect(()=>{
    
    getData3();
    
  },[id,statusUpdated]);
  
  

    const columns = [
          {
            name: "title",
            label: "Module / Panel",
            options: {
            filter: true,
            sort: true,
            }
          },
          {
            name: "permissionType.title",
            label: "Permission Type",
            options: {
            filter: true,
            sort: true,
            }
          },
          {
            name: "description",
            label: "Description",
            options: {
            filter: true,
            sort: true,
            }
          },
          {
            name: "isActive",
            label: "status",
            options: {
            filter: true,
            sort: true,
            }
          }, {
            name: "id",
            label: "Action",
            options: {
         
                  customBodyRender: (value, tableMeta, updateValue) => {
                    //assign[value]= true;
                    //unassign[value]= true;

                    // console.log(value)
                    // console.log(roleId)
                   // console.log(tableMeta.original);
                    return (
                      <div className={classes.root}>
                      
                          <Button style={{margin: '5px', backgroundColor: 'darkRed'}}  size="small"  variant="contained" color='primary' 
                            onClick={()=>unassignHandler(value)} 
                            // disabled={canUnassign}
                          >
                          Unassign
                          </Button>

                      </div>
                    )
                  }
            }
          }
      ]
  
  
  
      const options = {
        selectableRows: 'none',
        enableNestedDataAccess: '.',
      };

      
      
  return (
    <div>
        
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={control}
          disableEscapeKeyDown
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
          timeout: 500,
          }}
        >
      
        <Fade in={open}>
          <div className={classes.paper}>
            <MUIDataTable
                  //title={"Permissions"}
                  data={data3}
                  columns = {columns}
                  options={options}
                  highlightOnHover={true}
                  fixedHeader
                  paginate={false}
                  pagination={false}
                  //rowsPerPage ={5} 
                  isRowSelectable
                  caseSensitive={false} 
                  print={true}       
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
