import {
  Button, CircularProgress, TextField, Typography
} from "@material-ui/core";
import React, { useState } from "react";
import AuthHeader from "../../services/AuthHeader";
import { changedPassword } from "../../services/AuthService";
import useStyles from "./styles";

export default function ChangePassword(){
    var classes = useStyles();
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState(false);
    var [success, setSuccess] = useState(false);

    
    var [currentPassword, setCurrentPassword] = useState("");
    var [newPassword, setNewPassword] = useState("");
    var [confirmPassword, setConfirmPassword] = useState("");

    const PasswordChanged = (currentPassword,confirmPassword,setIsLoading,setError)=>{
        const userName = AuthHeader();
       const passwordDetails = {
          "userName" : userName.userName,
          "oldPassword" : currentPassword,
          "newPassword" : confirmPassword,
        }
        changedPassword(passwordDetails ).then((response) => {
          
      if(response.success){
        
        setError(response.msg)
        setSuccess('')
      }
      if(!response.success){
        
        setError(response.msg)
        setSuccess('')
      }
      if(response.isPasswordChanged){
        setError('');
        setSuccess(response.msg);
        setConfirmPassword("");
        setCurrentPassword("");
        setNewPassword('')
      }
        }).catch((err) =>{
          setError('Internal Server Error!')
        setSuccess('')
        })
    }

    return(
        <div className="">
          <div className="">

        
            <React.Fragment>
              <Typography variant="h2" className={classes.greeting}>
                Welcome to CMSF
              </Typography>
            
               
                <Typography style={{color:'green', textAlign:'left',marginTop:'20px'}}>
                  {success}
                </Typography>
                <Typography style={{color:'red', textAlign:'left',marginTop:'20px'}}>
                  {error}
                </Typography>
              
              
                <TextField
              id="curentPassword"
              label="Current Password"
              variant="outlined"
              placeholder="Enter Your Current Password"
              type="password"
              fullWidth
              required
              value={currentPassword}
              margin="normal"
              // onChange={(e) => setData({...data, fullName: e.target.value})}
              onChange={e => setCurrentPassword(e.target.value)}
            />
                <TextField
              id="newPassword"
              label="Current Password"
              variant="outlined"
              placeholder="Enter Your Current Password"
              type="password"
              fullWidth
              required
              value={newPassword}
              margin="normal"
              // onChange={(e) => setData({...data, fullName: e.target.value})}
              onChange={e => setNewPassword(e.target.value)}
            />
                <TextField
              id="confirmPassword"
              label="Current Password"
              variant="outlined"
              placeholder="Enter Your Current Password"
              type="password"
              fullWidth
              required
              value={confirmPassword}
              margin="normal"
              // onChange={(e) => setData({...data, fullName: e.target.value})}
              onChange={e => setConfirmPassword(e.target.value)}
            />
             
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                        currentPassword.length <= 5 ||
                        confirmPassword.length <= 5 ||
                        newPassword.length <= 5 ||
                        confirmPassword !== newPassword
                    }
                    onClick={() =>
                      
                      PasswordChanged(
                        currentPassword,
                        confirmPassword,
                        setIsLoading,
                        setError,
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Password Change
                  </Button>
                )}
              
              </div>
            </React.Fragment>
            </div>
        </div>
    )
}