import { Button } from '@material-ui/core';
import { DeleteForeverTwoTone, EditTwoTone, ListAltOutlined } from "@material-ui/icons";
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../utils/axios";
import Table from './Table';

function FilterTableComponent() {
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };

    useEffect(() =>{
        // axios.post(`claim-settlement/list`)
        axios.post(`claim-application/list`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    },[])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Lodged Claim List Table',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'id',
                    },
                    {
                        Header: 'Full Name',
                        accessor: 'fullName'
                    },
                    {
                        Header: 'Dividend Type',
                        accessor: 'investorsdividendType'
                    },
                    {
                        Header: 'Claim Type',
                        accessor: 'claimType'
                    },
                    {
                        Header: 'NID',
                        accessor: 'nid'
                    },
                    {
                        Header: 'Date of Birth',
                        accessor: 'dob'
                    },
                    {
                        Header: 'BO Id',
                        accessor: 'boID'
                    },
                    {
                        Header: 'Folio Number',
                        accessor: 'folioNumber'
                    },
                    {
                        Header: 'Warrant Number',
                        accessor: 'warrantNumber'
                    },
                    {
                        Header: 'Phone Number',
                        accessor: 'phoneNumber'
                    },
                    {
                        Header: 'Status',
                        accessor: 'status'
                    },
                    {
                        Header: 'Action',
                        accessor: '',
                        Cell: ({row}) => <>
                        <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/lodged-claim/view/${row.values.id}`}> <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='View the Lodged Claim' variant="contained" color="primary" ><ListAltOutlined  /> </Button></Link>
                        
                        <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/lodged-claim/edit/${row.values.id}`}> <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Edit the Lodged Claim' variant="contained" color="primary" ><EditTwoTone  /> </Button></Link>
                    
                        <Button style={{ margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Delete' variant="contained" color="secondary" > <DeleteForeverTwoTone /> </Button>
                        </>
                    },
                ],
            },
        ],
        []
    )
    return (
        <div>
            <Table 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            />
        </div>
    )
}

export default FilterTableComponent;