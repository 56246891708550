// import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Grid } from "@material-ui/core";
import { ViewListOutlined } from "@material-ui/icons";
import React, { useEffect, useState ,useRef} from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../utils/axios";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Table from './Table';
import { makeStyles } from '@material-ui/core/styles';
import UiOfReport from "../../../reports/ClaimReport/InvestorReport/UiOfReport";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


function FilterTableComponent() {
    const pdfRef = useRef();
  const pdfRef2 = useRef();
    const classes = useStyles();
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };

    const paramsData ={
        page: currentPage,
        take: showPageSize,
    }

    // useEffect(() =>{
    //     axios.post(`/investor-informations/search-for-report`, paramsData)
    //     .then((res) =>{
    //         setDataStore(res.data.payload)
           
    //         setCurrentPage(1);
    //         if(res.data?.page){
    //             setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
    //         }
    //     })
    //     .catch((err) =>{
    //     })
    // },[])

  
    
    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.post('/investor-informations/search-for-report', paramsData)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
            
        })
        .catch((err) =>{
            alert("There was a error occured!")
        })
    }
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Particular',
                columns: [
                    // {
                    //     Header: 'ID',
                    //     accessor: 'autoId',
                    // },
                    {
                        Header: 'Full Name',
                        accessor: 'sn'
                    },
                    {
                        Header: 'BO Id',
                        accessor: 'boid'
                    },
                    {
                        Header: 'Folio Id',
                        accessor: 'foid'
                    }, 
                    {
                        Header: 'Dividend Type',
                        accessor: 'idividendtype',
                        Cell: ({row}) => <>
                        {row.values.idividendtype === 'CashDividend' ? (
                            'Cash Dividend'
                          ) : row.values.idividendtype === 'FCCashDividend' ? 
                          (
                             `FC Cash Dividend`

                          ) : row.values.idividendtype === 'StockRightShare' ? (
                             `Stock Right Share`

                          ) : row.values.idividendtype === 'StockBonusShare' ? (
                              `Stock Bonus Share`
                          ) : row.values.idividendtype === 'PublicSubscriptionMoney'? (
                              `Public Subscription Money`

                         ) : row.values.idividendtype === 'FCPublicSubscriptionMoney' ? (
                              `FC Public Subscription Money`

                          )  : row.values.idividendtype === 'CashDividend' ? (
                            `Cash Dividend`
                        ) : row.values.idividendtype === 'FCCashDividend' ? (
                            `FC Cash Dividend`
                        ) : row.values.idividendtype === 'FractionDividend' ? (
                            `Fraction Dividend`
                        ) : row.values.idividendtype === 'DividendOnDividend' ? (
                            `Dividend On Dividend`
                        )
                        :(
                             <></>
                         )}</>
                    },
                    {
                        Header: 'Net Dividend',
                        accessor: 'net'
                    },
                    {
                        Header: 'No of Share',
                        accessor: 'shold',
                        Cell: ({row}) => <>
                        {row.values.shold === null && (
                            '0'
                          )}</>
                    },
                    {
                        Header: 'Year',
                        accessor: 'dyear'
                    },
                    // {
                    //     Header: 'Issuer Name',
                    //     accessor: 'issuer.name'
                    // }
    
                 
                    
                   
                ],
            },
        ],
        []
    )
   
    return (
        <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={11} sm={12} md={12} lg={12} xl={12}>
                <Table 
                getDataSync={getDataSync} 
                showPageSize={showPageSize} 
                setShowPageSize={setShowPageSize} 
                setNumberOfpage={setNumberOfpage} 
                numberOfPage={numberOfPage} 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage} 
                setDataStore={setDataStore} 
                columns={[]} 
                data={dataStore} 
                params={params}
            />
             </Grid>
        </Grid>
             
           
        </div>
    )
}

export default FilterTableComponent;