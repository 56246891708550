import React, { useEffect, useState } from 'react';
import axios from "../../utils/axios";
import AddBankInformation from './AddBankInformation';
import UpdateBankInformation from './UpdateBankInformation';
import ViewBankInfo from './ViewBankInfo';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

const BankInformations = () => {

  const [bankData, setBankData] = useState({})
  const [totalResponse, setTotalResponse] = useState()

  function getBankInfo () {
    axios.get(`bank-informations`)
      .then((res) => {
        setBankData(res.data.payload[0])
        setTotalResponse(res.data.total)
        // setBankDataId((res.data.id))
        // setIsSuccess(true)
            
      })
      .catch((err) =>{
        console.log(err)
      })
}


useEffect(()=>{
getBankInfo();
},[]);


if (totalResponse === 0){
return (
  <div>
<AddBankInformation/>
</div>
)
}

else if (bankData.id !== undefined){
return (
  <div>
<UpdateBankInformation data={bankData}/>
{/* <ViewBankInfo data={bankData} /> */}


</div>
)
}
}

export default BankInformations
