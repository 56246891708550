import {
  Button, Step,
  StepLabel, Stepper, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import {
  FormProvider
} from "react-hook-form";
import ReduceContext from "./ReduceContext";

import lodgeCliam from "../../services/LodgeClaimService";
import { BankInformation } from "./BankInformation";
import { DividendInformation } from "./DividendInformation";
import { PersonalInformation } from "./PersonalInfo";
import { ReviewInformation } from "./ReviewInformation";

    
    const useStyles = makeStyles((theme) => ({
      button: {
        marginRight: theme.spacing(1),
      },
    }));

    const initialState = {
      success: '',
      error: '',
      fullName: "",
      emailAddress: "",
      phoneNumber: "",
      nId: "",
      dateOfBirth: "2001-08-15",
      gender: "",
      claimantType: "",
      claimType: "",
      boId: "",
      issuerCompanyName: "",
      dividendYear: "",
      amount: "",
      nIdFile: "",
      address: "",
      successorCertificateFile: "",
      deathCertificateFile:"",
      accountHolderName:"",
      accountNumber:"",
      bankName: "",
      branchName:"",
      routingNumber: "",
      chequePageFile: "",
      boAcknowledgementFile:"",      
  };
    
    function getSteps() {
      return [
        "Personal Information",
        "Dividend/Share Information",
        "Bank Account Information",
        "Review",
      ];
    }
   
 
    function getStepContent(step) {
      switch (step) {
        case 0:
          return <PersonalInformation />;
    
        case 1:
          return <DividendInformation />;
        case 2:
          return <BankInformation />;
        case 3:
          return <ReviewInformation />;
        default:
          return "unknown step";
      }
    }

    const reducer = (state, action) => {
      switch (action.type) {
          case 'error':
              return {
                  loading: false,
                  post: action.result,
                  error: '',
              };
          case 'success':
              return {
                  loading: false,
                  post: {},
                  error: 'There was a problem fetching!',
              };
          default:
              return state;
      }
  };
    
    const LodgeClaim = () => {
     
      const [data, setData] = useState(initialState);
      const classes = useStyles();
      
  
      const [activeStep, setActiveStep] = useState(0);
      const steps = getSteps();
      const [message,setMessage] = useState('')

      const isStepOptional = (step) => {
        return step === 1 || step === 2;
      };
    
      
      

      const HandleNext = (deta) => {
        deta.preventDefault();

        const user = localStorage.getItem('user-name');
        const token = localStorage.getItem('barrier-token');
        if (activeStep == steps.length - 1) {

          const formData = new FormData();
          formData.append('nIdFile',data.nIdFile);
          formData.append('successorCertificateFile',data.successorCertificateFile);
          formData.append('deathCertificateFile',data.deathCertificateFile);
          formData.append('chequePageFile',data.chequePageFile);
          formData.append('boAcknowledgementFile',data.boAcknowledgementFile);
          formData.append('data',JSON.stringify(data));

          lodgeCliam(formData)
            .then((res) => {

              setActiveStep(activeStep + 1);
              setMessage(res?.msg);
            })
            .catch((err) => {
              alert('Internal Server Error!')
            });
            
          } else {
          setActiveStep((prevState => prevState + 1))
        }
      };
    
      const handleBack = () => {  
      
        setActiveStep(activeStep - 1);
      };

      return (
        <div>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step, index) => {
              const labelProps = {};
              const stepProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography
                    variant="caption"
                    align="center"
                    style={{ display: "block" }}
                  >
                    {/* optional */}
                  </Typography>
                );
              }

              return (
                <Step {...stepProps} key={index}>
                  <StepLabel {...labelProps}>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
    
          {activeStep === steps.length ? (
            <Typography variant="h3" align="center">
              {message}
            </Typography>
          ) : (
            <>
            <ReduceContext.Provider value={{data, setData}}>
              <FormProvider >
                <form onSubmit={HandleNext}>

                  {getStepContent(activeStep)}
    
                  <Button
                    className={classes.button}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                 
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                   
                    type="submit"
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </form>
              </FormProvider>
              </ReduceContext.Provider>
            </>
          )}
        </div>
      );
    };
    
    export default LodgeClaim;