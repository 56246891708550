import {
  Button, Step,
  StepLabel, Stepper, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import {
  FormProvider
} from "react-hook-form";


    
    const useStyles = makeStyles((theme) => ({
      button: {
        marginRight: theme.spacing(1),
      },
    }));

    const initialState = {
      success: '',
      error: '',
           
  };
    
    function getSteps() {
      return [
        "Lodge Claim",
        "Issuer Verified",
        "CMSF ICSD Veried",
        "Settled",
      ];
    }
   
    export const LodgeClaim = () => {
      return (
        <div>hello</div>
      );
    };
    export const IssuerVeried = () => {
      return (
        <div></div>
      );
    };
    export const CmsfIcsdVeried = () => {
      return (
        <div></div>
      );
    };
    export const Settled = () => {
      return (
        <div></div>
      );
    };

    function getStepContent(step) {
      switch (step) {
        case 0:
          return <LodgeClaim />;
    
        case 1:
          return <IssuerVeried />;
        case 2:
          return <CmsfIcsdVeried />;
        case 3:
          return <Settled />;
        default:
          return "unknown step";
      }
    }

    const reducer = (state, action) => {
      switch (action.type) {
          case 'error':
              return {
                  loading: false,
                  post: action.result,
                  error: '',
              };
          case 'success':
              return {
                  loading: false,
                  post: {},
                  error: 'There was a problem fetching!',
              };
          default:
              return state;
      }
  };
    
    const TrackClaimStepper = () => {
     
      const [data, setData] = useState(initialState);
      const classes = useStyles();
      
  
      const [activeStep, setActiveStep] = useState(0);
      const [skippedSteps, setSkippedSteps] = useState([]);
      const steps = getSteps();

      const isStepOptional = (step) => {
        return step === 1 || step === 2;
      };
    
      
      const isStepSkipped = (step) => {
        return skippedSteps.includes(step);
      };

      const HandleNext = (deta) => {
        deta.preventDefault();
          setActiveStep((prevState => prevState + 1))
        
      };
    
      const handleBack = () => {  
      
        setActiveStep(activeStep - 1);
      };

      return (
        <div>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step, index) => {
              const labelProps = {};
              const stepProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography
                    variant="caption"
                    align="center"
                    style={{ display: "block" }}
                  >
                    {/* optional */}
                  </Typography>
                );
              }

              // if (isStepSkipped(index)) {
              //   stepProps.completed = false;
              // }
              return (
                <Step {...stepProps} key={index}>
                  <StepLabel {...labelProps}>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
    
          {activeStep === steps.length ? (
            <Typography variant="h3" align="center">
              <h1 style={{color: 'green'}}>Congratulations! </h1>
              <p>Your due dividend has been settled.</p>

            </Typography>
          ) : (
            <>
           
              <FormProvider >
                <form onSubmit={HandleNext}>

                  {getStepContent(activeStep)}
    
                  <Button
                    className={classes.button}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                 
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={HandleNext}
                    type="submit"
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </form>
              </FormProvider>
             
            </>
          )}
        </div>
      );
    };
    
    export default TrackClaimStepper;