import React from 'react'
import FilterTableComponent from './FilterTableComponent'

const ListIssuerType = () => {
  return (
    <div>
      <FilterTableComponent/>
    </div>
  )
}

export default ListIssuerType