import { Button } from "@material-ui/core";
import i18next from 'i18next';
import React from 'react';

function Localization() {
  // function handleClick(lang) {
  //   i18next.changeLanguage(lang)
  // }
  const lan = localStorage.getItem("i18nextLng")
  function handleClick(lang) {
    if(lan === 'en' || lan === 'en-US'){
      i18next.changeLanguage('bn')
    }else{
      i18next.changeLanguage('en')
    }
  }

  return (
    <div >
        
        <Button style={{ marginTop: '5px', marginBottom: '5px' , marginRight: '5px', marginLeft: '5px'}} color="default" size="small" variant="contained" onClick={()=>handleClick('bn')} >
            {lan === 'bn' ? "English" : "Bangla"}
          </Button>
    </div>
  );
}

export default Localization;