import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useEditInwardDividendMutation, useGetIssuerInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import { default as axios, default as axiosInstance } from "../../../utils/axios";
import useStyles from "./../../../utils/validations/useStyles";
import Swal from "sweetalert2";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const dividendYear = [
  '2001-2002',
  '2002-2003',
  '2003-2004',
  '2004-2005',
  '2005-2006',
  '2006-2007',
  '2007-2008',
  '2008-2009',
  '2009-2010',
  '2010-2011',
  '2011-2012',
  '2012-2013',
  '2013-2014',
  '2014-2015',
  '2015-2016',
  '2016-2017',
  '2017-2018',
  '2018-2019',
  '2019-2020',
  '2020-2021',
  '2021-2022',
  '2022-2023',
  
];

function EditForm(props){
  const classes = useStyles();

  const [photoOfTransaction, setPhotoOfTransaction] = useState('');
  const { t } = useTranslation();
    const [editInwardDividend, {isSuccess,isError}] = useEditInwardDividendMutation();
    const {  data:issuer} = useGetIssuerInfoQuery();
    const history = useHistory();
    let currentDate = new Date().toJSON().slice(0, 10);
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const [data,setData] = useState({
      ref:'',
      tradeCode: '',
      date: '',
      decisionType:'',  
      totalCash: '',
      cash_9_1: '',
      cash_9_4: '',
      totalShare: '',
      stock_9_3: '',
      stock_9_2: '',
      startYear: '',
      totalFc:'',
      fc_9_1:'',
      fc_9_4:'',
      endYear: '',
      remarks: '',
      dividendYear:'',
      images: '',
    });

    useEffect(()=>{
        axios.get(`inward-dividend/${params.id}`)
        .then((res) =>{
            if(res.data.success){
                const date3 = new Date(res?.data?.payload?.date);
                const imageName = res?.data?.payload?.images[0].substring(1);
const formattedDate = date3.toISOString().slice(0, 10);
                setData({...data,
                     ref:res?.data?.payload?.ref, 
                     tradeCode:res?.data?.payload?.issuerid,
                     date:formattedDate,
                     decisionType:res?.data?.payload?.dividendType,
                     totalCash:res?.data?.payload?.total_cash,
                     cash_9_1:res?.data?.payload?.cash_9_1,
                     cash_9_4:res?.data?.payload?.cash_9_4,
                     stock_9_3:res?.data?.payload?.stock_9_3,
                     stock_9_2:res?.data?.payload?.stock_9_2,
                     totalShare:res?.data?.payload?.total_stock,
                     totalFc:res?.data?.payload?.total_fc,
                     fc_9_1:res?.data?.payload?.fc_9_1,
                     fc_9_4:res?.data?.payload?.fc_9_4,
                     remarks:res?.data?.payload?.remarks,
                     dividendYear:res?.data?.payload?.year,
                     images:imageName
                     })
                     console.log(res?.data?.payload);
                     console.log(imageName)
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
    },[])

    function checkFileType(file) {
      const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
      if(allowedFileTypes.includes(file.type)){
        return true;
      } else{
        alert( " Plese check your upload file type. Only allow (PNG/JPG/JPEG/PDF)")
        return false
      }
    }

    const submitHandler = async (e) =>{
        e.preventDefault();

        const formData = new FormData();
          formData.append('images',data.images);
          formData.append('issuerid',data.tradeCode);
          formData.append('ref',data.ref);
          formData.append('date',data.date);
          formData.append('dividendType',data.decisionType);
          formData.append('total_cash',data.totalCash || 0);
          formData.append('cash_9_1',data.cash_9_1 || 0);
          formData.append('cash_9_4',data.cash_9_4 || 0);
          formData.append('total_fc',data.totalFc || 0);
          formData.append('fc_9_1',data.fc_9_1 || 0);
          formData.append('fc_9_4',data.fc_9_4 || 0);
          formData.append('total_stock',data.totalShare || 0);
          formData.append('stock_9_2',data.stock_9_2 || 0);
          formData.append('stock_9_3',data.stock_9_3 || 0);
          formData.append('year',data.dividendYear);
          formData.append('remarks',data.remarks);
          // editInwardDividend(formData,params.id);
          Swal.fire({
            title: "Inward Dividend Updated!",
            icon: "success",
            confirmButtonText: "Okay",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/app/inward-dividend/list");
            }
          });
          ;

          try {
            const response = await axiosInstance.put(`inward-dividend/${params.id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
        
            // setIsLoading(false);
        
            if (response.data.success) {
              // setIsSuccess(true);
              console.log(response)
            }
          } catch (error) {
            // setIsLoading(false);
            // setIsError(true);
          }





    }
    if(isSuccess){
    
      alert("Inward Dividend has been successfully saved.");
      history.push('/app/inward-dividend/list')   
    }
    if(isError){
      alert("There was a error occured!");
    }
    
    let dividendContent ='' ;
    if(data.decisionType === 'Cash'){
      dividendContent = <div>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}> 
        
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              
               fullWidth
              id="cashDividend"
              label="Cash Dividend"
              variant="outlined"
              placeholder="Enter Cash Dividend"
              required
              margin="normal"
              value={data.cash_9_1}
              onChange={(e) => setData({...data, cash_9_1: e.target.value})}
            /> <br />
             <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="ipoAmount"
              label="IPO"
              variant="outlined"
              placeholder="Enter IPO"
              required
              margin="normal"
              value={data.cash_9_4}
              onChange={(e) => setData({...data, cash_9_4: e.target.value})}
            /> <br />
               
             <TextField
             InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
          fullWidth
              id="totalCash"
              label="Total Cash"
              variant="outlined"
              placeholder="Enter Total Cash"
              required
              margin="normal"
              value={data.totalCash}
              onChange={(e) => setData({...data, totalCash: e.target.value})}
            /> <br />
            </Grid>
            </Grid>
      </div>
    }
    
    if(data.decisionType === 'FC'){
      dividendContent = <div>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}> 
         
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="cashDividend"
              label="FC Cash Dividend"
              variant="outlined"
              placeholder="Enter FC Cash Dividend"
              required
              margin="normal"
              value={data.fc_9_1}
              onChange={(e) => setData({...data, fc_9_1: e.target.value})}
            /> <br />
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="ipoAmount"
              label="FC IPO Amount"
              variant="outlined"
              placeholder="Enter FC IPO Amount"
              required
              margin="normal"
              value={data.fc_9_4}
              onChange={(e) => setData({...data, fc_9_4: e.target.value})}
            /> <br />
            <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
          fullWidth
              id="totalFC"
              label="Total FC"
              variant="outlined"
              placeholder="Enter Total FC"
              required
              margin="normal"
              value={data.totalFc}
              onChange={(e) => setData({...data, totalFc: e.target.value})}
            /> <br />
         </Grid>
    </Grid>
      </div>
    }
    if(data.decisionType === 'Stock'){
      dividendContent = <div>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}> 
         
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="bonusShare"
              label="Bonus Share"
              variant="outlined"
              placeholder="Enter Bonus Share"
              required
              margin="normal"
              value={data.stock_9_2}
              onChange={(e) => setData({...data, stock_9_2: e.target.value})}
            /> <br />
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="rightShare"
              label="Right Share"
              variant="outlined"
              placeholder="Enter Right Share"
              required
              margin="normal"
              value={data.stock_9_3}
              onChange={(e) => setData({...data, stock_9_3: e.target.value})}
            /> <br />
            <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
          fullWidth
              id="totalShare"
              label="Total Share"
              variant="outlined"
              placeholder="Enter Total Share"
              required
              margin="normal"
              value={data.totalShare}
              onChange={(e) => setData({...data, totalShare: e.target.value})}
            /> <br />
            </Grid>
            </Grid>
      </div>
    }
    if(data.decisionType === 'All'){
      dividendContent = <div>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            classes: {
              outlined: classes.root,
            },
          }}
               fullWidth
              id="cashDividend"
              label="Cash Dividend"
              variant="outlined"
              placeholder="Enter Cash Dividend"
              required
              margin="normal"
              value={data.cash_9_1}
              onChange={(e) => setData({...data, cash_9_1: e.target.value})}
            /> <br />
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="ipoAmount"
              label="IPO Amount"
              variant="outlined"
              placeholder="Enter IPO Amount"
              required
              margin="normal"
              value={data.cash_9_4}
              onChange={(e) => setData({...data, cash_9_4: e.target.value})}
            /> <br />
             <TextField
             InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
              fullWidth
              id="totalCash"
              label="Total Cash"
              variant="outlined"
              placeholder="Enter Total Cash"
              required
              margin="normal"
              value={data.totalCash}
              onChange={(e) => setData({...data, totalCash: e.target.value})}
            /> <br />
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            classes: {
              outlined: classes.root,
            },
          }}
               fullWidth
              id="cashDividend"
              label="FC Cash Dividend"
              variant="outlined"
              placeholder="Enter FC Cash Dividend"
              required
              margin="normal"
              value={data.fc_9_1}
              onChange={(e) => setData({...data, fc_9_1: e.target.value})}
            /> <br />
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="ipoAmount"
              label="FC IPO Amount"
              variant="outlined"
              placeholder="Enter FC IPO Amount"
              required
              margin="normal"
              value={data.fc_9_4}
              onChange={(e) => setData({...data, fc_9_4: e.target.value})}
            /> <br />
            <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
              fullWidth
              id="totalFC"
              label="Total FC"
              variant="outlined"
              placeholder="Enter Total FC"
              required
              margin="normal"
              value={data.totalFc}
              onChange={(e) => setData({...data, totalFc: e.target.value})}
            /> <br />
          </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            classes: {
              outlined: classes.root,
            },
          }}
               fullWidth
              id="bonusShare"
              label="Bonus Share"
              variant="outlined"
              placeholder="Enter Bonus Share"
              required
              margin="normal"
              value={data.stock_9_2}
              onChange={(e) => setData({...data, stock_9_2: e.target.value})}
            /> <br />
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="rightShare"
              label="Right Share"
              variant="outlined"
              placeholder="Enter Right Share"
              required
              margin="normal"
              value={data.stock_9_3}
              onChange={(e) => setData({...data, stock_9_3: e.target.value})}
            /> <br />
            <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
              fullWidth
              id="totalShare"
              label="Total Share"
              variant="outlined"
              placeholder="Enter Total Share"
              required
              margin="normal"
              value={data.totalShare}
              onChange={(e) => setData({...data, totalShare: e.target.value})}
            /> <br />
          </Grid>
          
        </Grid>  
      </div>
    }


const [personName, setPersonName] = useState([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
    setData({...data, dividendYear: typeof value === 'string' ? value.split(',') : value})
  };
    return (
        <div >

          <form onSubmit={submitHandler}>  

          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormControl fullWidth >
                <InputLabel id="issuer-id" >Select Issuer Name</InputLabel>
                <Select 
                required
                    labelId="issuer-id"
                    id="demo-simple-select"
                    value={data.tradeCode}
                    disabled={true}
                    label="Select Issuer"
                    onChange={(e) => setData({...data, tradeCode: e.target.value})}
                    >
                    {issuer?.payload.map((i)=><MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>)}
                </Select>
              </FormControl>
                <h4 style={{marginTop:"10px"}}> </h4>
               
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>                
            <TextField
            {...props}
            InputLabelProps={{
              classes: {
                asterisk: classes.requiredAsterisk,
                outlined: classes.root,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            fullWidth
              id="ref"
              label="Ref"
              variant="outlined"
              placeholder="Enter the Ref ID"
              required
              margin="normal"
              value={data.ref}
              onChange={(e) => setData({...data, ref: e.target.value})}
            /> 
               
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* <div>
                        <FormControl sx={{ m: 1, width: 300 }} fullWidth>
                        <InputLabel id="demo-multiple-checkbox-label">Dividend Year</InputLabel>
                        <Select
                        required
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput label="dividendYear" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            >
                            {dividendYear.map((name) => (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={personName.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </div> */}

            <TextField
            {...props}
            InputLabelProps={{
              classes: {
                asterisk: classes.requiredAsterisk,
                outlined: classes.root,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            inputProps={{
              max: `${currentDate}`
            }}
            fullWidth
              id="date-year"
              label="Dividend Year"
              variant="outlined"
              required
              type='text'
              multiline
              rows={2}
              margin="normal"
              value={data.dividendYear}
              onChange={(e) => setData({...data, dividendYear: e.target.value})}
            />
                
                </Grid> 
                <Grid item lg={6} md={6} sm={12} xs={12}>                
                <FormControl fullWidth >
                <InputLabel id="issuer-id" >Dividend Type</InputLabel>
                <Select
                required 
                    labelId="issuer-id"
                    id="demo-simple-select"
                    disabled={true}
                    value={data.decisionType}
                    label="Select Issuer"
                    onChange={(e) => setData({...data, decisionType: e.target.value})}
                    >
                <MenuItem value={"Cash"} >Cash</MenuItem>
                <MenuItem value={"FC"} >FC</MenuItem>
                <MenuItem value={"Stock"} >Stock</MenuItem>
                <MenuItem value={"All"} >All</MenuItem>
                </Select>
                </FormControl>
                <h4 style={{marginTop:"1px"}}> </h4>
            </Grid>           
            <Grid item lg={6} md={6} sm={12} xs={12}>
           
            <TextField
            {...props}
            InputLabelProps={{
              classes: {
                asterisk: classes.requiredAsterisk,
                outlined: classes.root,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            inputProps={{
              max: `${currentDate}`
            }}
            fullWidth
              id="date-submit"
              label="Payment Date"
              variant="outlined"
              required
              type='date'
              margin="normal"
              value={data.date}
              onChange={(e) => setData({...data, date: e.target.value})}
            /><br />
            </Grid>             
          </Grid>

             {dividendContent}

             <TextField
             {...props}
             InputLabelProps={{
               classes: {
                 asterisk: classes.requiredAsterisk,
                 outlined: classes.root,
               },
             }}
             InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                 </InputAdornment>
               ),
             }}
              id="file"
              label="Transaction (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Enter Your Transaction File"
              fullWidth
              required={!data.images}
              focused
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, images: e.target.files[0]})
                  setPhotoOfTransaction(e.target.files[0]);
                  console.log(e.target.files);
                }else{
                  e.target.value = null;
                  setData({...data, images: []});
                }
              } }
            />

            <div style={{marginBottom: "10px"}}>
                {data.images.length === 0 ? null : 'upload not required if want to keep the previous file'
              //   data.images[0] 
              //   && (
              //   <span >
              //   {data.images}
              //   </span>
              // )
              }
            </div>

             <TextField
             {...props}
             InputLabelProps={{
               classes: {
                 //asterisk: classes.requiredAsterisk,
                 outlined: classes.root,
               },
             }}
             InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                 </InputAdornment>
               ),
             }}
             fullWidth
              id="remarks"
              label="Remarks"
              variant="outlined"
              placeholder="Enter Remarks"
              //required
              multiline
              rows={2}
              margin="normal"
              value={data.remarks}
              onChange={(e) => setData({...data, remarks: e.target.value})}
            /> <br />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={
            
                Number(data.totalCash)  !== Number(data.cash_9_1) + Number(data.cash_9_4) ||
                Number(data.totalFc)  !== Number(data.fc_9_1) + Number(data.fc_9_4) ||
                Number(data.totalShare)  !== Number(data.stock_9_2) + Number(data.stock_9_3) ||
                loading
              }
            >
              { t('common.submit')}
            </Button>
                </form>                        
        </div>
    )
}
export default EditForm;