import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import axios from "../../../utils/axios";
import FilterTableComponent from './FilterTableComponent';
import Swal from 'sweetalert2'

const Permission = () => {
 
  const getData = () => {
    axios.get(`permission-types?take=0`)
        .then((res) =>{
          setData(res.data.payload)
        
        })
        .catch((err) =>{
        })
      
    }

  const [data, setData]=useState([]);
  const [allValues, setAllValues] = useState({
    title:'',
    permissionType:'',
    description:'',
  });

  const changeHandler = e => {
    setAllValues( prevValues => {
    return { ...prevValues,[e.target.name]: e.target.value}
    // console.log(allValues)
 })
 }



 const submitHandler = (e) =>{ 
          e.preventDefault();
          Swal.fire({
            title: 'Do you want to save the changes?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              axios.post('/permissions',allValues     
          ).then((res)=>{
            getData();
            // console.log(allValues);
            Swal.fire('Saved!', '', 'success')
          }).catch((err)=>{
            console.log(err);
        });
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
          //getData();
        }

      

  useEffect(()=>{
      getData();
    
  },[allValues])


      return (
        <div>
           <form onSubmit={submitHandler}>
           {/* {console.log(allValues)} */}
            <h2>Create Permissions</h2>
             <Grid container spacing={4}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="title"
                        name="title"
                        label="Panel/Module"
                        variant="outlined"
                        placeholder="Enter desired Panel/Module"
                        required
                        margin="normal"
                        onChange={changeHandler}
                        />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <FormControl fullWidth >
                    <InputLabel style={{  marginTop: '10px',marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}} id="issuer-id" >Select Permission Type</InputLabel>
                      <Select style={{ marginTop: '40px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                            required
                            id="permissionType"
                            name="permissionType"
                            label="Permission Type"
                            placeholder="Select Permission Type"
                            variant="standard"
                            onChange={changeHandler}
                            defaultValue=''
                            >
                            {data.map((i)=>
                                <MenuItem value={i.id} key={i.id}>{i.title}</MenuItem>
                              )}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="description"
                        name="description"
                        label="Description"
                        variant="outlined"
                        placeholder="Description of the selected permission type"
                        //required
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>
              </Grid>
              
            <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                // onClick={submitHandler}
                disabled={(allValues.title.length<3) || (allValues.permissionType.length<3)}
               
              >
                    Submit
            </Button>
                <FilterTableComponent/>
          </form>
        </div>
      )
    }
    
    export default Permission