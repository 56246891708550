export const getUserId =()=> {
    const storage = window.localStorage;
    if (!storage) {
      return null;
    }
    const userJson = storage.getItem("user-id");
    if (!userJson) {
      return null;
    }
    return JSON.parse(userJson);
  }
  export const getUserName =()=> {
    const storage = window.localStorage;
    if (!storage) {
      return null;
    }
    const userJson = storage.getItem("user-name");
    if (!userJson) {
      return null;
    }
    return JSON.parse(userJson);
  }
export const getToken =()=> {
    const storage = window.localStorage;
    if (!storage) {
      return null;
    }
    const userJson = storage.getItem("bearer-token");
    if (!userJson) {
      return null;
    }
    return JSON.parse(userJson);
  }

export const getPermissionArray =()=> {
    const storage = window.localStorage;
    if (!storage) {
      return null;
    }
    const userJson = storage.getItem("permission");
    if (!userJson) {
      return null;
    }
    return JSON.parse(userJson);
  }

  // export const permissionArray = JSON.parse(localStorage.getItem("permission")) || "";


