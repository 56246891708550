

function AuthHeader() {
    const user = localStorage.getItem('user-name');
    const token = localStorage.getItem('barrier-token');
    const userTypes = localStorage.getItem('userTypes');

    if (user && token) {
      return { 
        authorizationToken: token,
        userName: user,
        userType:userTypes,
     };  
    } else {
      return {};
    }
  }
  export default AuthHeader;