import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "../../../utils/axios";


export default function ViewInvestorInfo(){
  const { t } = useTranslation();
    const [isLoading, setIsLoding] =useState(true)
    const [isSuccess, setIsSuccess] =useState(false)
    const [data, setData] = useState("")

    const params = useParams();
   

    useEffect(() => {
        axios.get(`investor-informations/${params.id}`)
        .then((res) =>{
            if(res.data.success){
                setIsSuccess(true)
                setIsLoding(false)
                setData(res.data.payload)
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
      }, [params.id]);

      let content = "";
      content = <div > 
                 <div >
                  <Paper >
                    <Table>
                      <TableHead>
                      </TableHead>
                        <TableBody>
                          <TableRow style={{backgroundColor:'#96B6C5'}}>
                            <TableCell>Upload Date</TableCell>
                            <TableCell>Last Update</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{new Date(data?.createdAt).toLocaleDateString("en-GB")}</TableCell>
                            <TableCell>{new Date(data?.updatedAt).toLocaleDateString("en-GB")}</TableCell>
                            <TableCell>
                            {data?.status === 1 ? "Approved" : data?.status === 0 ? "Not Approved" :"" }
                            </TableCell>
                          </TableRow>      
                      </TableBody>
                    </Table>
                  </Paper>
                  
                  <h1 style={{marginBottom: "20px"}}> </h1>
                </div>
<div style={{display: 'flex',flexDirection: 'row'}}>
        <Paper style={{display: 'flex',flexDirection: 'row',margin: '7px',width: '49%',height: '80%',borderRadius: 20, borderColor: '#a4de6c',borderBottomWidth: '10px',alignItems:'center'}}>
          <Table size="">
            <TableHead>
            </TableHead>
              <TableBody>
                <TableRow>
                    <TableCell style={{dispaly: "flex" , fontSize:"27px", backgroundColor:"#96B6C5",}}></TableCell>
                    <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"20px", backgroundColor:"#96B6C5",}}>Mandatory Information</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>{data?.autoId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Issuer Company</TableCell>
                    <TableCell>{data?.issuer?.name?data?.issuer?.name:''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell>{data?.fullName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>BO Id</TableCell>
                    <TableCell>{data?.boID}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Folio Number</TableCell>
                    <TableCell>{data?.folioNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dividend Year</TableCell>
                    <TableCell>{data?.yearDividend}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Investors Dividend Type</TableCell>
                    <TableCell>{data.investorsdividendType==='StockRightShare'? 'Stock Right Share' 
                          :data.investorsdividendType==='CashDividend'? 'Cash Dividend' 
                          :data.investorsdividendType==='FCCashDividend'? 'FC Cash Dividend'
                          :data.investorsdividendType==='PublicSubscriptionMoney' ? 'Public Subscription Money' 
                          :data.investorsdividendType==='FCPublicSubscriptionMoney'? 'FC Public Subscription Money'
                          :data.investorsdividendType==='StockBonusShare'? 'Stock Bonus Share' 
                          : 'N/A'}</TableCell>           
                  </TableRow>
                  <TableRow>
                    <TableCell>Share Holding</TableCell>
                    <TableCell>{data?.shareholding}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gross Dividend</TableCell>
                    <TableCell>{data?.grossDividend?(data.grossDividend).toFixed(3):''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TDS</TableCell>
                    <TableCell>{data?.tds}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Net Dividend</TableCell>
                    <TableCell>{data?.netDividend}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>No of Share</TableCell>
                    <TableCell>{data?.nofshare}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
        </Paper>
        
        <Paper style={{display: 'flex',flexDirection: 'row',margin: '7px',width: '49%',borderRadius: 20, borderColor: '#a4de6c',borderBottomWidth: '10px',alignItems:'center'}}>
          <Table>
            <TableHead>
            </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{dispaly: "flex" , fontSize:"27px", backgroundColor:"#96B6C5",}}></TableCell>
                  <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"20px", backgroundColor:"#96B6C5",}}>Optional Information</TableCell>
                </TableRow>
                <TableRow>
                        <TableCell>Father Name</TableCell>
                        <TableCell>{data?.fathersName}</TableCell>
                </TableRow>
                <TableRow>
                        <TableCell>Mother Name</TableCell>
                        <TableCell>{data?.mothersName}</TableCell>
                </TableRow>
                <TableRow>
                        <TableCell>Date of Birth</TableCell>
                        <TableCell>{data?.DoB}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Phone Number</TableCell>
                        <TableCell>{data?.phoneNumber}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Address</TableCell>
                        <TableCell>{data?.address}</TableCell>
                      </TableRow>
                    
                      <TableRow>
                        <TableCell>Warrant Number</TableCell>
                        <TableCell>{data?.warrantNumber}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Account Name</TableCell>
                        <TableCell>{data?.AccountName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Account Number</TableCell>
                        <TableCell>{data?.AccountNumber}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Bank Name</TableCell>
                        <TableCell>{data?.BankName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Branch Name</TableCell>
                        <TableCell>{data?.BranchName}</TableCell>
                      </TableRow>
                      
                      <TableRow>
                        <TableCell>Routing Number</TableCell>
                        <TableCell>{data?.RoutingNumber}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Remarks</TableCell>
                        <TableCell>{data?.remarks}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>status</TableCell>
                        <TableCell>{data?.status === 1 ? "Approved" : data?.status === 0 ? "Not Approved" :"" }</TableCell>
                      </TableRow>
                      
                    
                    
                    </TableBody>
                </Table>
        </Paper>
    </div>
    <h1 style={{marginBottom: "50px"}}> </h1></div>;


    return(
        <div>
 {isSuccess && content}
     </div>
    )
}