import {
  IconButton,
  Menu,
  MenuItem, Paper, Typography
} from "@material-ui/core";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import classnames from "classnames";
import React, { useState } from "react";
import {Link} from 'react-router-dom';

// styles
import useStyles from "./styles";

export default function Widget({
  children,
  title,
  url,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  header,
  noHeaderPadding,
  headerClass,
  style,
  noWidgetShadow,
  ...props
}) {
  var classes = useStyles();

  // local
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  return (
    <div className={classes.widgetWrapper} style={style && {...style}}>
      <Paper  variant="outlined" style={{borderRadius:"10px",backgroundColor:'#bbd0ff', borderRadius: 15, borderColor: '',
                       borderTopWidth: '10px'}} className={classes.paper} classes={{ root: classnames(classes.widgetRoot, {
        [classes.noWidgetShadow]: noWidgetShadow
        }) }}>
        <div className={classnames(classes.widgetHeader, {
          [classes.noPadding]: noHeaderPadding,
          [headerClass]: headerClass
        })}>
          {header ? (
            header
          ) : (
            <React.Fragment>
              <Typography style={{color:"black", fontWeight:'15px'}} variant="h9" color="textSecondary" 
              // noWrap
               >
                {title}
              </Typography>
              {!disableWidgetMenu && (
                <IconButton
                  color="primary"
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={() => setMoreMenuOpen(true)}
                  buttonRef={setMoreButtonRef}
                >
                  <MoreIcon />
                </IconButton>
              )}
            </React.Fragment>
          )}
        </div>
        <div style={{direction:'rtl'}}
          className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
          })}
        >
          {children}
        </div>
      </Paper>
      <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem >
          {/* <Typography >Details</Typography> */}
          <Typography 
            className={classes.title}
            variant="h7"
            noWrap
            component={Link}
            to={url}
            underline="none"
            sx={{ textDecoration: 'none' }}
            >
            Details
          </Typography>
        </MenuItem>
        
       
      </Menu>
    </div>
  );
}
