import {Button, Grid, Card, Paper} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import { useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import axios from "../../utils/axios";
import GridItem from "./components/grids/GridItem";
// import CandleChart from "./graph/CandleChart";
import AreaCharts from "./graph/AreaCharts";
import BarchartStock from "./graph/BarchartStock";



const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  const [totalDataCash, setTotalDataCash] = useState("")
  const [totalDataFC, setTotalDataFC] = useState("")
  const [totalDataStock, setTotalDataStock] = useState("")
  const [areaChartData, setAreChartData] = useState("")

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  const userName = localStorage.getItem('user-specific-name');
  const strUserName = JSON.parse(userName);
  const issuerId = localStorage.getItem('issuer-id');

  useEffect(()=>{
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
        axios.get(`dashboard/issuer/card/${issuerId}`)
        .then((res) =>{
            if(res.data.success){
              setTotalDataCash(res?.data?.payload[0])
              setTotalDataFC(res?.data?.payload[1])
              setTotalDataStock(res?.data?.payload[2])
            }
        })
        .catch((err) =>{
            alert("Since you are not an Issuer User, you may not view certain details")
        })
        axios.get(`/dashboard/management/chart/cash`)
        .then((res) =>{
            if(res.data.success){
              setAreChartData(res?.data?.payload?.chart)
            }
        })
        .catch((err) =>{
        })

  },[]);

  const { t } = useTranslation();
  var classes = useStyles();
  var theme = useTheme();

//console.log(setMatches)
//console.log(issuerId);


  return (
    <>
    {/* <PageTitle title={t('Dashboard.title1')} 
      button={<Button
      variant="contained"
      size="small"
      color="primary"
    ></Button>}/> */}
      {/* {matches && (<h1>Big Screen</h1>)}
      {!matches && (<h3>Small Screen</h3>)} */}

  <PageTitle title={t('Dashboard.title1')} />
    <h3>{t('Project.Welcome')}, {strUserName}</h3>
      <Grid style={{marginTop:"30px"}}><h4>{t('Dividends.Cash')}</h4></Grid>
      <Grid container spacing={4}>
        <GridItem data={totalDataCash?totalDataCash.cashSummary:0} name={ t('Dashboard.cardIssuer.totalFileUpload')} url={'/app/inward-dividend/list'} />
        <GridItem data={totalDataCash?totalDataCash.cashInvestors:0} name={ t('Dashboard.cardIssuer.totalInvestors')} url={'/app/inward-dividend/list'}/>
        <GridItem data={totalDataCash?totalDataCash.lodgedClaims:0} name={ t('Dashboard.cardIssuer.totalClaims')} url={'/app/claim-settlement/claim/issuer/list'}/>
        <GridItem data={totalDataCash?totalDataCash.settledClaims:0} name={ t('Dashboard.cardIssuer.settledClaims')} url={'/app/claim-settlement/claim/issuer/list'}/>
      </Grid>

      <Grid style={{marginTop:"30px"}}><h4>{t('Dividends.Stock')}</h4></Grid>
      <Grid container spacing={4}>
        <GridItem data={totalDataStock?totalDataStock.stockSummary:0} name={ t('Dashboard.cardIssuer.totalFileUpload')} url={'/app/inward-dividend/list'}  />
        <GridItem data={totalDataStock?totalDataStock.stockInvestors:0} name={ t('Dashboard.cardIssuer.totalInvestors')} url={'/app/inward-dividend/list'} />
        <GridItem data={totalDataStock?totalDataStock.lodgedClaims:0} name={ t('Dashboard.cardIssuer.totalClaims')} url={'/app/claim-settlement/claim/issuer/list'}/>
        <GridItem data='N/A' name={ t('Dashboard.cardIssuer.settledClaims')} url={'/app/claim-settlement/claim/issuer/list'}/>
  
      </Grid>

      <Grid style={{marginTop:"30px"}}><h4>{t('Dividends.FCCash')}</h4></Grid>
          <Grid container spacing={4}>
            <GridItem data="0" name={ t('Dashboard.cardIssuer.totalFileUpload')} url={'/app/inward-dividend/list'} />
            <GridItem data="0" name={ t('Dashboard.cardIssuer.totalInvestors')} url={'/app/inward-dividend/list'}/>
            <GridItem data="0" name={ t('Dashboard.cardIssuer.totalClaims')} url={'/app/claim-settlement/claim/issuer/list'}/>
            <GridItem data="0" name={ t('Dashboard.cardIssuer.settledClaims')} url={'/app/claim-settlement/claim/issuer/list'}/>
            <h2 style={{margin:"50px", marginLeft:"400px",  marginBottom:"20px", color:"black"}}>{t('Project.CMSF claims settlement statistics')} </h2>
          </Grid>

          {/* <Grid item xs={12} md={6} style={{backgroundColor:"white",margin:"20px"}}>
            <ClaimGraph />
          </Grid> */}

          {/* <Grid item xs={12} md={5} style={{backgroundColor:"white",margin:"20px"}}>
            <ClaimGraph2 />
          </Grid> */}

        {/* <Grid container spacing={2}>
          <Grid item xs={6} md={6} style={{backgroundColor:"white", marginTop:"20px"}}>
            <ColumnGraph />
          </Grid>
          <GridItem direction="column" data='640' name={ t('Dashboard.card.totalClaims')} />
          <GridItem direction="column" data='640' name={ t('Dashboard.card.totalClaims')} />
          

         </Grid> <Grid item xs={6} md={6} container spacing={2}  direction="column" >
          <GridItem  data='640' name={ t('Dashboard.card.totalClaims')} />
          <GridItem  data='600' name={ t('Dashboard.card.settledClaims')}/>
          <Grid item xs={6} md={6} style={{backgroundColor:"white", marginTop:"20px"}}>
            <ColumnGraph />
          </Grid>
          </Grid>
        </Grid> */}
        
        <Grid container spacing={3} >
              <Paper  variant="outlined"  style={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '7px',
                      width: matches ?'48%':'90%',
                       borderRadius: 20, 
                       borderColor: '#a4de6c',
                       borderTopWidth: '10px',
                       
                    }}>
                <Grid >
                <BarchartStock data={areaChartData}/>
                </Grid>
              </Paper>

              <Paper  variant="outlined" square={true} style={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '7px',
                      width: matches ?'48%':'90%',
                       borderRadius: 20, 
                       borderColor: '#82ca9d',
                       borderBottomWidth: '10px',
                      
                  }}>
                <Grid>
                  <AreaCharts data={areaChartData}/>
                  {/* <RadialBarChart/> */}
                </Grid>
              </Paper>
           </Grid>
      
    </>
  );
}

function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
