import axios from 'axios';

export function post1(url, body) {
    // var apiUrl = `${process.env.NEST_APP_API_BASE_URL + '/auth'}/${url}`;
    // var apiUrl = `${process.env.NEST_APP_API_BASE_URL + '/api/v1/auth'}/${url}`;
    var apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/${url}`;
    var data = axios.post(apiUrl,body).then(function(response){
      return response.data;
    })
    return data;
  }

export function post(url, body) {

  const user = localStorage.getItem('user-name');
    const token = localStorage.getItem('barrier-token');

    var apiUrl = `${process.env.REACT_APP_API_BASE_URL + '/users'}/${url}`;
    var data = axios.post(apiUrl,body,{
      headers: {
        'x-auth-token': token,
        'user-name': user
      }} ).then(function(response){
      return response.data;
    })
    return data;
  }
export function patch(url, body) {

  const user = localStorage.getItem('user-name');
    const token = localStorage.getItem('barrier-token');

    var apiUrl = `${process.env.REACT_APP_API_BASE_URL + '/dividend'}/${url}`;
    var data = axios.post(apiUrl,body,{
      headers: {
        'x-auth-token': token,
        'user-name': user
      }} ).then(function(response){
      return response.data;
    })
    return data;
  }

  
export function postWitHeader(url, body, headers) {

  const user = localStorage.getItem('user-name');
    const token = localStorage.getItem('barrier-token');

    var apiUrl = `${process.env.REACT_APP_API_BASE_URL + '/users'}/${url}`;
    var data = axios.post(apiUrl,body,{
      headers: {
        'x-auth-token': token,
        'user-name': user
      }} ).then(function(response){
      return response.data;
    })
    return data;
  }
  
export function postWitHeaderLoge(url, body, headers) {

  const user = localStorage.getItem('user-name');
    const token = localStorage.getItem('barrier-token');
    

    var apiUrl = `${process.env.REACT_APP_API_BASE_URL + '/claims'}/${url}`;
    var data = axios.post(apiUrl,body,{
      headers: {
        'x-auth-token': token,
        'user-name': user,
        "Content-Type": "multipart/form-data",
      }} ).then(function(response){
      return response.data;
      })
    return data;
  }
