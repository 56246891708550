import { post, post1 } from '.'

export function checkLogin(body) {
    return post1('login',body)
  }

export function passwordChangeToLogin(body) {
    return post1('forgot-password/otp/verify',body)
  }

export function forgetPassword(body) {
    return post1('forgot-password/otp/send',body)
  }

export function createUser(body) {
    return post1('register',body)
  }

export function changedPassword(body) {
    return post('change-password',body)
  }


