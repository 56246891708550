import { Button ,Grid} from '@material-ui/core';
import { CallReceived, CancelRounded, DoneAll as DoneAllIcon, LibraryAddCheck as LibraryAddCheckIcon, ListAltOutlined, PlaylistAddCheck as PlaylistAddCheckIcon, Spellcheck as SpellcheckIcon, VerifiedUser } from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "../../utils/axios";
import PermissionModal from './PermissionModal';
import Table from './Table';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


function FilterTableComponent() {
    const classes = useStyles();
    const userpermission = localStorage.getItem("permission")
    const history = useHistory();
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
      )

  /* 20 */ var canGiveScrutinyApproval = userpermission.includes("Claim-Scrutiny-Approval")
  /* 21 */ var canGiveOMCApproval = userpermission.includes("Claim-OMC-Approval")
  /* 22 */ var canGiveBOGApproval = userpermission.includes("Claim-BOG-Approval")
  /* 23 */ var canGiveSettledApproval = userpermission.includes("Claim-Settled-Approval")

    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

  const [searchParameters,setSearchParameters] = useState({
    boID: "",
    folioNumber: "",
    yearDividend: "",
    investorsdividendType:"",
    issuerid: ""
})

  const controlModal2 = (e) => {  
    setOpened((prevState) => !prevState);
    setModalData(e);
    
}

    useEffect(() =>{
        window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches( e.matches ));
        // axios.post(`claim-settlement/list`)
        axios.post(`claim-application/list`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })

    },[opened])

  
   


      let requiredContent = ""
      requiredContent = <div>
      <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox1"/>
            <label class="form-check-label" for="checkbox1">
                Investors' NID
            </label>
            </div>

            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox2"/>
            <label class="form-check-label" for="checkbox2">
                Investors BO/Folio Setup
            </label>
            </div>

            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox2"/>
            <label class="form-check-label" for="checkbox2">
                Cheque Leaf
            </label>
            </div>
    
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox2"/>
            <label class="form-check-label" for="checkbox2">
                Signed Power of Attorney Letter/Authorization Letter
            </label>
            </div>

            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox2"/>
            <label class="form-check-label" for="checkbox2">
                 Power of Attorney Person/ Authorized Person NID
            </label>
            </div>
            
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox2"/>
            <label class="form-check-label" for="checkbox2">
                Death Certificate
            </label>
            </div>
            
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox2"/>
            <label class="form-check-label" for="checkbox2">
                Original Notarized Certificate of Inheritance
            </label>
            </div>
            
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox2"/>
            <label class="form-check-label" for="checkbox2">
                Court Succession 
            </label>
            </div>
            
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="checkbox2"/>
            <label class="form-check-label" for="checkbox2">
                  Successors' NID
            </label>
            </div>
            </div>
// issuer received claim
    const receiveByIssuer =(id)=>{

        // axios.put(`claim-settlement/claim-received-by-issuer/${id}`)
        axios.put(`claim-application/claim-received-by-issuer/${id}`)
        .then((res) =>{
            if(res?.data?.payload?.status === 1){
                alert("Claim has been successfully received")
                // axios.post(`claim-settlement/claim-list-by-issuer`)
                axios.post(`claim-application/claim-list-by-issuer`)
        .then((res) =>{
            //setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })

    }
    const approveByIssuer =(id)=>{
   
        // axios.put(`claim-settlement/claim-approved-by-issuer/${id}`)
        axios.put(`claim-application/claim-approved-by-issuer/${id}`)
        .then((res) =>{
            if(res?.data?.payload?.status === 2){
                alert("Claim has been successfully Approved")
                // axios.post(`claim-settlement/claim-list-by-issuer`)
                axios.post(`claim-application/claim-list-by-issuer`)
        .then((res) =>{
            //setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })

    }

    // cmsf received claim
    const receiveByCMSF =(id)=>{
   
        // axios.put(`claim-settlement/claim-received-by-cmsf/${id}`)
        axios.put(`claim-application/claim-received-by-cmsf/${id}`)
        .then((res) =>{
            if(res?.data?.payload?.status === 4){
                console.log("Claim has been successfully received by CMSF")
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Claim has been successfully received by CMSF',
                    showConfirmButton: false,
                    timer: 1800
                  })
                // axios.post(`claim-settlement/list`)
                axios.post(`claim-application/list`)
        .then((res) =>{
           setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
            alert(err)
        })
            }
        })
        .catch((err) =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
              })
        })

    }

    const cancelByCMSF =(id)=>{
   
        Swal.fire({
            title: 'Why this claim is rejected?',
            text: "(for example: write your reasons)",
            input: 'text',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            preConfirm: (input) => {
                if (!input || input.trim() === "") {
                    Swal.showValidationMessage("Mentioning the reasons is mandatory");
                }
                if(input.length>30){
                    Swal.showValidationMessage("Please state within 30 characters");
                }
            },
           
          }).then((result) => {
            if (result.isConfirmed) {

        // axios.put(`/claim-settlement/claim-canceled-by-cmsf/${id}`,{"comments":`${result.value}`})
        axios.put(`/claim-application/claim-canceled-by-cmsf/${id}`,{"comments":`${result.value}`})
        .then((res) =>{
            if(res?.data?.payload?.status === 8){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Claim has been rejected by CMSF',
                    // showConfirmButton: tr,
                    // timer: 2000
                  })
                console.log("Claim has been rejected by CMSF")
                // axios.post(`claim-settlement/list`)
                axios.post(`claim-application/list`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })

    }
})
}

    const scrutinizedByCMSF =(id)=>{
        // axios.put(`claim-settlement/claim-scrutinized-by-cmsf/${id}`)
        axios.put(`claim-application/claim-scrutinized-by-cmsf/${id}`)
        .then((res) =>{
            if(res?.data?.payload?.status === 5){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Claim status updated. New Status -  scrutinized by CMSF',
                    showConfirmButton: false,
                    timer: 2000
                  })
                console.log("Claim has been successfully scrutinized by CMSF")
                // axios.post(`claim-settlement/list`)
                axios.post(`claim-application/list`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
            
        })
    }


    const omcApprovedByCMSF =(id)=>{
      
        Swal.fire({
            title: 'Please mention the OMC meeting number and its date',
            text: "(for example: OMC No-16 & Date: 14/06/2023)",
            input: 'text',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            preConfirm: (input) => {
                if (!input || input.trim() === "") {
                    Swal.showValidationMessage("Please mention the details of the OMC meeting");
                }
            },
           
          }).then((result) => {
            if (result.isConfirmed) {
                console.log(result.value)
        // axios.put(`claim-settlement/claim-omc-approved-by-cmsf/${id}`,{"comments":`${result.value}`})
        axios.put(`claim-application/claim-omc-approved-by-cmsf/${id}`,{"comments":`${result.value}`})
        .then((res) =>{
            if(res?.data?.payload?.status === 6){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Claim status updated. New Status -  Approved by OMC',
                    // showConfirmButton: tr,
                    // timer: 2000
                  })
                console.log("Claim has been successfully OMC Approved by CMSF")
                // axios.post(`claim-settlement/list`)
                axios.post(`claim-application/list`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })

    }
})
}
    const bogApprovedByCMSF =(id)=>{
   
        Swal.fire({
            title: 'Please mention the BOG meeting number and its date',
            text: "(for example: BOG No-16 & Date: 14/06/2023)",
            input: 'text',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            preConfirm: (input) => {
                if (!input || input.trim() === "") {
                    Swal.showValidationMessage("Please mention the details of the BOG meeting");
                }
                if(input.length>30){
                    Swal.showValidationMessage("Please state within 30 characters");
                }
            },
          }).then((result) => {
            if (result.isConfirmed) {
        // axios.put(`claim-settlement/claim-bog-approved-by-cmsf/${id}`,{"comments":`${result.value}`})
        axios.put(`claim-application/claim-bog-approved-by-cmsf/${id}`,{"comments":`${result.value}`})
        .then((res) =>{
            if(res?.data?.payload?.status === 7){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    // title: 'Claim status updated. New Status -  Approved by BOG',
                    title: 'Claim status updated. New Status -  Approved by Chairman, BOG',
                    // showConfirmButton: false,
                    // timer: 2000
                  })
                // axios.post(`claim-settlement/list`)
                axios.post(`claim-application/list`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })

    }
})
}


    const claimSettledByCMSF =(id)=>{
       
        Swal.fire({
            title: 'Please mention the Settlement number and date of Settlement',
            text: "(for example: Settlement No-16 & Date: 14/06/2023)",
            input: 'text',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            preConfirm: (input) => {
                if (!input || input.trim() === "") {
                    Swal.showValidationMessage("Please mention the details of the BOG meeting");
                }
                if(input.length>40){
                    Swal.showValidationMessage("Please state within 40 characters");
                }
            },
          }).then((result) => {
            if (result.isConfirmed) {
                console.log(result.value)
        // axios.put(`claim-settlement/claim-settled-by-cmsf/${id}`,{"comments":`${result.value}`})
        axios.put(`claim-application/claim-settled-by-cmsf/${id}`,{"comments":`${result.value}`})
        .then((res) =>{
            if(res?.data?.payload?.status === 9){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Claim status updated. New Status - settled by CMSF',
                    // showConfirmButton: false,
                    // timer: 2000
                  })
                console.log("Claim is successfully settled by CMSF")
                // axios.post(`claim-settlement/list`)
                axios.post(`claim-application/list`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
            Swal.fire({
                icon: 'error',
                title:`There was an error occured!`,
            })
        })
            }
        })
        .catch((err) =>{
            Swal.fire({
                icon: 'error',
                title:`There was an error occured!`,
            })
        })

    }
})
}


    const receivedHandler = (id) => alert("Do not have permission!")

    // const findFromDatabaseHandler = (searchData) => {
    //     console.log(searchData)
    //     setSearchParameters( {
    //         boID: searchData.boID,
    //         folioNumber: searchData.folioNumber,
    //         yearDividend: searchData.yearDividend,
    //         investorsdividendType:searchData.investorsdividendType,
    //         issuerid: searchData.issuerid   
    // })
    //   console.log(searchParameters) 
    //   axios.post(`investor-informations/search`,{boID: searchData.boID,
    //     folioNumber: searchData.folioNumber,
    //     yearDividend: searchData.yearDividend,
    //     investorsdividendType:searchData.investorsdividendType,
    //     issuerid: searchData.issuerid })
    //   .then((res)=>{
    //     console.log(res)
    //     if(res.data.payload.length===0){
    //         Swal.fire("No record Found")
    //     }
    //     if(res.data.payload.length>0){
    //         var IdOFoundedReacord = res.data.payload[0].id
    //         console.log(IdOFoundedReacord)
    //         Swal.fire({
    //             title: 'Record Found',
    //               confirmButtonColor: "#527853",    
    //               confirmButtonText: 'Go to record',
    //           }).then((result) => {
    //             if (result.isConfirmed) 
    //             {history.push(`/app/investor-informations/views/${IdOFoundedReacord}`);}
    //             }) 
    //     }
    //   })
    //   .catch((err)=>{
    //     Swal.fire(err)
    //   })
    // }

 
    const columns = React.useMemo(
        () => [
            {
                Header: 'Lodged Claim List Table',
                columns: [
                    {
                        Header: 'Sl',
                        accessor: 'autoId',
                        
                    },
                    {
                        Header: 'Claim ID',
                        accessor: 'claimToken',
                        
                    },
                    {
                        Header: 'Claim Date',
                        accessor: 'createdAt',
                        Cell: ({ value }) => {
                            const date = new Date(value);
                            return <>{date.toLocaleDateString("en-GB")}</>;
                          },
                    },
                    {
                        Header: 'Full Name',
                        accessor: 'fullName'
                    },
                    {
                        Header: 'Dividend Year',
                        accessor: 'yearDividend'
                    },
                    {
                        Header: 'Claim Amount',
                        accessor: 'netDividend'
                    },
                    {
                        Header: 'Dividend Type',
                        accessor: 'investorsdividendType',
                        Cell: ({row}) => <>
                        {row.values.investorsdividendType === 'CashDividend' ? (
                            'Cash Dividend'
                          ) : row.values.investorsdividendType === 'FCCashDividend' ? 
                          (
                             `FC Cash Dividend`

                          ) : row.values.investorsdividendType === 'StockRightShare' ? (
                             `Stock Right Share`

                          ) : row.values.investorsdividendType === 'StockBonusShare' ? (
                              `Stock Bonus Share`
                          ) : row.values.investorsdividendType === 'PublicSubscriptionMoney'? (
                              `Public Subscription Money`

                         ) : row.values.investorsdividendType === 'FCPublicSubscriptionMoney' ? (
                              `FC Public Subscription Money`

                          )  : (
                             <></>
                         )}</>
                    },
                    {
                        Header: 'Claim Type',
                        accessor: 'claimType'
                    },
                    
                    {
                        Header: 'BO Id',
                        accessor: 'boID',
                        wrap:true
                    },
                    {
                        Header: 'Folio Number',
                        accessor: 'folioNumber'
                    },
                    // {
                    //     Header: 'Warrant Number',
                    //     accessor: 'warrantNumber'
                    // },
                    
                    
                    {
                        Header: 'Current Status',
                        accessor: 'status',
                        Cell: ({row}) => <>
                        {row.values.status === 0 ? (
                               'Initiated by Investor'
                             ) : row.values.status === 1 ? 
                             (
                                `Received by Issuer `
                                // (${row.values.status})

                             ) : row.values.status === 2 ? (
                                `Approved by Issuer`

                             ) : row.values.status === 3 ? (
                                 `Cancelled by Issuer`
                             ) : row.values.status === 4 ? (
                                 `Received by CMSF`

                            ) : row.values.status === 5 ? (
                                 `Scrutinized by CMSF`

                             ) : row.values.status === 6 ? (
                                 `Approved by OMC`

                             ) : row.values.status === 7 ? (
                                //  `Approved by BOG`
                                `Approved by Chairman, BOG`
                                
                             ) : row.values.status === 8 ? (
                                 `Claim Cancelled by CMSF`

                             ) : row.values.status === 9 ? (
                                 ` Claim Setteled by CMSF`
                            ) : (
                                <> </>
                            )}

                        </>
                        },
                    {
                        Header: 'Action(s)',
                        accessor: '',
                        Cell: ({row}) => <>
                        {row.values.status === 0 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Receive by Issuer' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><CallReceived  /></Button>
                            ) : row.values.status === 1 ? 
                            (
                                <>
                                 <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Approve By Issuer' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><VerifiedUser  /></Button>
                                 <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By Issuer' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><CancelRounded /></Button>
                                </>

                            ) :row.values.status === 2 ? 
                            (
                                <>
                                 <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Recieve By CMSF' variant="contained" color="primary" onClick={e=>receiveByCMSF(row.original.id)} ><VerifiedUser  /></Button>
                                 <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By CMSF' variant="contained" color="primary" onClick={e=>cancelByCMSF(row.original.id)} ><CancelRounded /></Button>
                                </>

                            ) : row.values.status === 3 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By Issuer' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled ><CancelRounded style={{backgroundColor: "#DEC20B" , fill: "white"}}/></Button>
                            ) : row.values.status === 4 ? (
                                <> 
                                {/* scrutinized button */}
                                 {canGiveScrutinyApproval && 
                                    <>
                                        <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Scrutinize' variant="contained" color="primary" onClick={e=>controlModal2(row.original.id)} ><PlaylistAddCheckIcon   /></Button> 
                                        <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By CMSF' variant="contained" color="primary" onClick={e=>cancelByCMSF(row.original.id)} ><CancelRounded /></Button>
                                    </>}
                                
                                 {/* <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By Issuer' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} ><CancelRounded /></Button> */}
                                </>

                            ) : row.values.status === 5 ? (
                                <> 
                                {/* omc approved button */}
                                 {canGiveOMCApproval && 
                                 <>
                                    <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Approve By OMC' variant="contained" color="primary" onClick={e=>omcApprovedByCMSF(row.original.id)} ><LibraryAddCheckIcon/></Button>
                                    <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By CMSF' variant="contained" color="primary" onClick={e=>cancelByCMSF(row.original.id)} ><CancelRounded /></Button>
                                    </>}
                                 {/* <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By Issuer' variant="contained" color="primary" onClick={e=>cancelByIssuer(row.original.id)} ><CancelRounded /></Button> */}
                                </>
                            ) : row.values.status === 6 ? (
                                <>
                                {/* bog approved button */}
                                {canGiveBOGApproval && 
                                <>
                                    <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Approve By Chairman, BOG' variant="contained" color="primary" onClick={e=>bogApprovedByCMSF(row.original.id)} ><SpellcheckIcon/></Button>
                                    <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By CMSF' variant="contained" color="primary" onClick={e=>cancelByCMSF(row.original.id)} ><CancelRounded /></Button>
                                    </>}
                                 {/* <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By Issuer' variant="contained" color="primary" onClick={e=>cancelByIssuer(row.original.id)} ><CancelRounded /></Button> */}
                                </>

                            ) : row.values.status === 7 ? (
                                <>
                                {/* claim settled button */}
                                {canGiveSettledApproval && <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Add Settlement Detail' variant="contained" color="primary" onClick={e=>claimSettledByCMSF(row.original.id)} ><DoneAllIcon /></Button>}
                                 {/* <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By Issuer' variant="contained" color="primary" onClick={e=>cancelByIssuer(row.original.id)} ><CancelRounded /></Button> */}
                                </>     
                            ) : row.values.status === 8 ? (
                                <> 
                                
                                 {/* <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By Issuer' variant="contained" color="primary" onClick={e=>cancelByIssuer(row.original.id)} disabled><CancelRounded /></Button> */}
                                 <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By CMSF' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><CancelRounded style={{backgroundColor: "#d33" , fill: "white"}}/></Button>
                                </>
                            ) : row.values.status === 9 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Claim Settled By CMSF' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><DoneAllIcon style={{backgroundColor: "#7A9D54" , fill: "white"}} /></Button>
                            ) : (
                                <> </>
                            )}
                       
                        <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/claim-settlement/claim/cmsf/view/${row.original.id}`}> <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='View the Claim' variant="contained" color="primary" ><ListAltOutlined  /> </Button></Link>
                        {/* <Button style={{ margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Delete' variant="contained" color="secondary" onClick={e=>findFromDatabaseHandler(row.original)}> <SearchIcon /> </Button> */}
                        {/* <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/lodged-claim/edit/${row.original.id}`}> <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Edit the Lodged Claim' variant="contained" color="primary" ><EditTwoTone  /> </Button></Link> */}
                    
                        {/* <Button style={{ margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Delete' variant="contained" color="secondary" > <DeleteForeverTwoTone /> </Button> */}
                        </>
                    },
                ],
            },
        ],
        []
    )
    return (
        <>
           <Grid>
                <Table 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            matches={matches}
            />
            </Grid>
           
             <PermissionModal open={opened} data={modalData} 
            //  control={controlModal} 
             control2={controlModal2} scrutinizedByCMSF={scrutinizedByCMSF}/>
        </>
    )
}

export default FilterTableComponent;