import React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
  
  
const BarchartStock = (props) => {
  
// Sample data
const data = [
    {
      "name": "May",
      "cash transferred": 14,
      "cash settled": 2
    },
    {
      "name": "Jun",
      "cash transferred": 13,
      "cash settled": 12
    },
    {
      "name": "Jul",
      "cash transferred": 16,
      "cash settled": 4
    },
    {
      "name": "Aug",
      "cash transferred": 22,
      "cash settled": 2
    },
    {
      "name": "Sep",
      "cash transferred": 7,
      "cash settled": 4
    },
    {
      "name": "Oct",
      "cash transferred": 7,
      "cash settled": 4
    },{
      "name": "Nov",
      "cash transferred": 2,
      "cash settled": 1
    }
   
  ]
  
  
return (
<ResponsiveContainer width="95%" height={400}>
{/* <Grid item  xl={6} lg={6} md={6} sm={12} > */}

    <BarChart width={900} height={450} spacing ={2} data={props.data} margin={{ top: 60, right: 20,  bottom: 20 , left: 30}}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="monthyear">
        <Label value="Month" offset={-10} position="insideBottomRight" />
            <Label value="Cash claim settlement" offset={340} position="insideBottomRight" />
        </XAxis>
        <YAxis label={{ value: 'Settled Amount (in Lac)', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalclaim" name="Lodged" fill="#5063de" />
        <Bar 
        // label={{ position:"insideBottomRight"  }} 
        dataKey="settled" name="Settled" fill="#4bca63" />
    </BarChart>
    {/* </Grid> */}
</ResponsiveContainer>

);
}

export default BarchartStock;