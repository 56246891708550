import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import FilterTableComponent from "./FilterTableComponent";
import axios from '../../../utils/axios'
import NewTable from "./NewTable"




export default function IssuerWiseInvestorR(){
    const { t } = useTranslation();

    return(
        <>
            <PageTitle title={ "Issuer Company wise Investor List"}  />
            {/* <FilterTableComponent /> */}
            <NewTable/>
        </>
    )
}