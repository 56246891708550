import { Button } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
    flexDirection: 'column',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    height: '60vh',
    width: '70%',
    padding: theme.spacing(3, 3, 3),
    justifyContent: 'justified',
    textAlign:'justify',
    overflow: "scroll"

  },
}));

export default function PermissionModal({ open,data, control}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoding] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);

  const history = useHistory();

  const submitHandler = (e) =>{
    
    e.preventDefault();
    control();
  }

  const handleControl = () =>{
    history.push('/app/search-dividend/claim')
  }
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const handleCheckboxChange1 = (event) => {
    setIsChecked1(event.target.checked);
  };
  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
           
          <div className={classes.paper}>
          {/* <img style={{height:"50px", textAlign:'center',}} src={CmsfLogo} alt="CMSF-Logo" /> */}
            <h6 style={{padding: "5px"}}>{ t('ClaimLodgeConfirmationPopUp.description1')} </h6>
            <h6 >{ t('ClaimLodgeConfirmationPopUp.description2')} </h6>
             <h5 style={{fontWeight: "bold"}}> { t('ClaimLodgeConfirmationPopUp.reqDocCash')} </h5>
            <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash1')} </h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash2')} </h6>
              <h6 style={{paddingLeft: "15px"}}> { t('ClaimLodgeConfirmationPopUp.cash3')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash4')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash5')}</h6>

            <h6 style={{color: "green"}}>{ t('ClaimLodgeConfirmationPopUp.addlSuccAttornney')}</h6>

              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash6')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash7')}</h6>
            
            <h6 style={{color: "green"}}>{ t('ClaimLodgeConfirmationPopUp.addlDecease')}</h6>
             
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash8')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash9')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash10')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash11')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.cash12')}</h6>
             
              <h5 style={{fontWeight: "bold"}}>{ t('ClaimLodgeConfirmationPopUp.reqDocStock')}</h5>
    
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock1')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock2')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock3')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock4')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock5')}</h6>

            <h6 style={{color: "green"}}>{ t('ClaimLodgeConfirmationPopUp.addlSuccAttornney')}</h6>

              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock6')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock7')}</h6>
            
            <h6 style={{color: "green"}}>{ t('ClaimLodgeConfirmationPopUp.addlDecease')}</h6>
             
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock8')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock9')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock10')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock11')}</h6>
              <h6 style={{paddingLeft: "15px"}}>{ t('ClaimLodgeConfirmationPopUp.stock12')}</h6>



             <h6  style={{paddingRight: "15px" , color: "green"}}>{ t('ClaimLodgeConfirmationPopUp.beforeCheckboxMsg')}</h6> 

              {/* <img style={{height:"220px",width: "550px"}} src={cashImag} alt="CMSF-Logo" /> */}
              {/* <img style={{height:"220px",width: "550px"}} src={cashImag} alt="CMSF-Logo" /> */}
              
            <label>
              <input 
                type="checkbox"
                checked={isChecked1}
                onChange={handleCheckboxChange1}
              /> &nbsp;
            { t('ClaimLodgeConfirmationPopUp.checkBox1')}

      </label>
<br/>
      <label>
        <input
          type="checkbox"
          checked={isChecked2}
          onChange={handleCheckboxChange2}
        /> &nbsp;
        { t('ClaimLodgeConfirmationPopUp.checkBox2')}

      </label>
            
       <form onSubmit={submitHandler}>

                <h4 style={{marginTop:"15px"}}> </h4>
              
                <Button style={{float:"left", margin:'5px'}}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={!(isChecked1 && isChecked2)}
                  >
                    Confirm
                  </Button> 
                 </form> 
                 <Button style={{margin:'5px'}}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    onClick={handleControl}
                  >
                    Cancel
                  </Button>
            <div>
              {/* <FormFileUploadComponent  handleClose ={control}/> */}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
