import { Box, IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import React from 'react';

export default function Notification({ isValid, message, open, setIsError, setIsValid, handleClose, color }) {
  return (
    <Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: `${color}`,
          }}
          action={[
            <IconButton key="info" color="inherit" onClick={handleClose}>
            </IconButton>,
            <IconButton key="close" color="inherit" onClick={handleClose}>
              <Close />
            </IconButton>,
          ]}
          message={
            <span>
              <Info style={{ marginRight: '8px' }} />
              {message}
            </span>
          }
        />
      </Snackbar>
    </Box>
  );
}
