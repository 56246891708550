import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import axios from "../../../utils/axios";
import UiOfCompanyStatusReport from "./UiOfCompanyStatusReport";
// import Select from 'react-select'

const options = [{ tradecode: "No Data Available", name: "" },
// { tradecode: "No Data Available", name: "" },
];


function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize,setNumberOfpage,params }) {

    const { t } = useTranslation();
    const [filteredData, setFilteredData] = useState([]);

    const defaultColumn = React.useMemo(
        () => ({
            Filter: "",
        }),
        []
    )

   
//console.log(sortedIssuerData);
const ITEM_HEIGHT = 100;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 250,
            // height: 250,
            overflowY: 'scroll',
            overflowX: 'scroll'
          },
        },
      };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const nextPageHandler = () =>{        
        if(numberOfPage > currentPage){
            setCurrentPage(++currentPage);      
        }
    }

    const prevPageHandler = () =>{        
        if(currentPage > 1){
            setCurrentPage(--currentPage);
        }
    }
    
    const handleFirstPage = () =>{
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }

    const handleLastPage = () =>{
        if(currentPage !== numberOfPage){
            setCurrentPage(numberOfPage)
        }
    }
    const ReportType =[
        {id: 'summary',category: "Summary"},
        {id: 'details',category: "Details"},
      ]

    const [employeeAndReportType, setEmployeeAndReportType] = useState({
        empId:'All',
        reportType:'summary',
    })
    const [employeeList,setEmployeeList] = useState([])
    const [employeeName,setEmployeeName] = useState("");

    

    
    const changeHandler = e => {
        setEmployeeAndReportType( prevValues => {
          return { ...prevValues,[e.target.name]: e.target.value.toString()}
       })
       setEmployeeName(employeeList.find(item => e.target.value == item.autoId)?.fullName)
    }
    
    const searchHandler = () =>{
        axios.post('/settlement/get-settlement-status-user-wise-report',employeeAndReportType)
            .then((res)=>{
                setFilteredData(res.data.payload);
            })
            .catch(e=>{console.log(e)})
    }


    useEffect(()=>{
        const getEmployeeList = () =>{
            
            axios.get('/users/employee')
            .then((res)=>{
                setEmployeeList([{"autoId": "All","fullName": "All","department": "All"},...res.data.payload]);
            })
            .catch(e=>{console.log(e)})
        }
        getEmployeeList();
        setCurrentPage(1)
    },[])


    return (  
        <>
        <Grid container spacing={2}>
       
        <Grid item lg={3} md={3} sm={12} xs={12}>
                <FormControl fullWidth >
                    <InputLabel  id="issuer-id" > Employee ID </InputLabel>
                        <Select 
                            required
                            id="empId"
                            name="empId"
                            label="Employee ID"
                            defaultValue={'All'}
                            //fullWidth
                            variant="standard"
                            onChange={changeHandler}
                        >
                            {/* api of employee list will be given.then map that */}
                            {employeeList.map((i)=><MenuItem value={i.autoId} key={i.id}>{i.fullName}</MenuItem>)}
                        </Select>
                </FormControl>
            </Grid>

            <Grid item lg={2} md={2} sm={12} xs={12}>
                <FormControl fullWidth >
                    <InputLabel  id="issuer-id" > Report Type </InputLabel>
                        <Select 
                            required
                            id="reportType"
                            name="reportType"
                            label="reportType"
                            defaultValue={'summary'}
                            //fullWidth
                            variant="standard"
                            onChange={changeHandler}
                        >
                            {ReportType.map((i)=><MenuItem value={i.id} key={i.id}>{i.category}</MenuItem>)}
                        </Select>
                </FormControl>
            </Grid>
            <Grid item lg={1} md={2} sm={2} xs={12}>
           <span> 
           <Button
           variant="contained"
                   color="primary"
                   size="medium"
                   style={{margin:"10px", marginTop:"20px"}}
                   onClick={searchHandler}
                   >Search
           </Button>
           </span>
       </Grid>
       </Grid>
           
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {/* {filteredData.length!==0 &&  */}
            <Grid style={{marginTop:'80px'}}><UiOfCompanyStatusReport data={filteredData} employeeName = {employeeName} reportType={employeeAndReportType.reportType}/>
            </Grid>
            {/* } */}
  
        </>
    )
}

export default Table;