import { Button } from '@material-ui/core';
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "../../../utils/axios";
import Create from './add/Create';
import RolePermission from './rolePermission/RolePermission';
import RolePermissionViewOnly from './rolePermission/RolePermissionViewOnly';
import Table from './Table';


function FilterTableComponent() {
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);

    const [opened, setOpened] = useState(false);
    const [modalData, setModalData] = useState('');
    
    const [rolePermissionOpened, setRolePermissionOpened] = useState(false);      //RolePermission 
    const [rolePermissionModalData, setRolePermissionModalData] = useState('');   //RolePermission

    const [rolePermissionViewOnlyOpened, setPermissionViewOnlyOpened] = useState(false);      //RolePermission View 
    const [rolePermissionViewOnlyModalData, setPermissionViewOnlyModalData] = useState('');   //RolePermission View

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };
    
    const rolePermissionModal = (e) => {                         
        setRolePermissionOpened((prevState) => !prevState);
        setRolePermissionModalData(e);
         console.log(e);
    };

    const rolePermissionViewOnlyModal = (e) => {                         
        setPermissionViewOnlyOpened((prevState) => !prevState);
        setPermissionViewOnlyModalData(e);
         console.log(e);
    };

    const confirmHandler = (idd) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/roles/${idd}`,{
                }).then((res)=>{
                   getData();
                });
                    console.log(`${idd}  deleted successfully`)
              Swal.fire(
                'Deleted!',
                'Selected Role has been deleted.',
                'success'
              )
            }
          })
    }
const getData = () => {
    axios.get(`/roles/?take=${showPageSize}&page=${currentPage}`)
    .then((res) =>{
        setDataStore(res.data.payload)
        console.log('use');
        setCurrentPage(1);

        if(res.data?.page){
            setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
        }
    })
        .catch((err) =>{
        })
}

    useEffect(()=>{
      
    },[dataStore]) 

    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.get(`/roles/?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
            console.log('after');
        })
            .catch((err) =>{
                alert("There was a error occured!")
            })
    }
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Particular',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'autoId',
                    },
                    {
                        Header: 'Role',
                        accessor: 'title'
                    },
                    {
                        Header: 'Status',
                        accessor: 'isActive'
                    },
                    ,
                    {
                        Header: 'Actions',
                        //accessor: 'title',
                        Cell: ({row}) =>
                      
                            <span>

                                <Button 
                                    style={{ margin: '5px', backgroundColor: 'purple'}}   
                                    variant="contained" 
                                    color="primary" 
                                    onClick={()=>rolePermissionViewOnlyModal(row.original)}
                                    
                                >
                                    Assigned                         
                                </Button>

                                <Button 
                                    style={{ margin: '5px', backgroundColor: 'green'}}  
                                    variant="contained" 
                                    color="primary" 
                                    onClick={()=>rolePermissionModal(row.original)}
                                >
                                    New Assign                      
                                </Button>

                                {/* <Button
                                    style={{ margin: '5px', backgroundColor: 'blue'}}  
                                    variant="contained" 
                                    color="primary" 
                                    onClick={()=>console.log(row.original)}
                                >
                                    Edit                          
                                </Button> */}
        
                                <Button 
                                    style={{ margin: '5px', backgroundColor: 'red'}}   
                                    variant="contained" 
                                    color="primary" 
                                    onClick={()=>confirmHandler(row.original.id)}
                                >
                                    Delete                          
                                </Button>

                               

 
                            
                            </span>
                            
                    },
    
                ],
            },
           
        ],
        []
    )

    return (
        <>
            <div>
                <h2>Roles</h2>
                    <Button 
                        style={{ margin: '5px', backgroundColor: 'green'}}  
                        variant="contained" 
                        color="primary" 
                        onClick={()=>controlModal()}
                        >
                        Create                          
                    </Button>

                    <Table 
                        getDataSync={getDataSync} 
                        showPageSize={showPageSize} 
                        setShowPageSize={setShowPageSize} 
                        setNumberOfpage={setNumberOfpage} 
                        numberOfPage={numberOfPage} 
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage} 
                        setDataStore={setDataStore} 
                        columns={columns} 
                        data={dataStore} 
                        params={params}
                    />
            </div>
                <Create open={opened} data={modalData} control={controlModal} 
                getData={getData} 
                />
                {/* New Assign */}
                <RolePermission  open={rolePermissionOpened} data={rolePermissionModalData} control={rolePermissionModal} 
                getData={getData} 
                />
                {/* Assigned */}
                <RolePermissionViewOnly  open={rolePermissionViewOnlyOpened} data={rolePermissionViewOnlyModalData} control={rolePermissionViewOnlyModal}/>
        </>
    )
}

export default FilterTableComponent;