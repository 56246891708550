import { Button, Grid } from '@material-ui/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ResponsiveContainer } from 'recharts';
import Golden50 from '../../../reports/ClaimReport/50y_logo.jpg';
import ReportStyle from '../../../reports/ClaimReport/Report.module.css';
import styles from '../../../reports/ClaimReport/Table.module.css';
import CmsfLogo from '../../../reports/ClaimReport/logo.png';
import Mujib100 from '../../../reports/ClaimReport/mujib_100_logo.jpg';




const UiOfCompanySummaryReport = ({ data ,inwardDividendType,grandTotal,divTypeFromResponse}) => {

  console.log (data);
  const params = useParams();
  const pdfRef = useRef();
  const pdfRef2 = useRef();



  const contentRef = useRef();

  const generatePdf = () => {
    const pdf = new jsPDF('p', 'mm', 'a4'); // Create a new PDF document
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    html2canvas(pdfRef.current, {
      scale: 2,
      useCORS: true,
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const aspectRatio = imgHeight / imgWidth;
      const scaleFactor = pdfWidth / imgWidth;
      let remainingHeight = imgHeight;
      let position = 0;

      while (remainingHeight > 0) {
        const currentHeight = Math.min(remainingHeight, pdfHeight / scaleFactor);

        pdf.addImage(
          imgData,
          'PNG',
          0,
          position,
          pdfWidth,
          pdfWidth * aspectRatio * (currentHeight / imgHeight),
          undefined,
          'FAST'
        );

        remainingHeight -= currentHeight;
        position = -((imgHeight - remainingHeight) / scaleFactor);

        if (remainingHeight > 0) {
          pdf.addPage();
          position = 0;
        }
      }

      pdf.save('Company_Summary_Report.pdf');
    });
  };

  const generatePdf2 = async () => {
    const content = pdfRef.current;

    const pdf = new jsPDF('p', 'mm', 'a4');
    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL('image/png');

    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save('Company_Summary_Report.pdf');
  };
  

  return (
    <>
  

<ResponsiveContainer style={{ width: '85%', maxWidth: '100%', padding: '10px' }}>
    
<Grid ref={pdfRef2} className={ReportStyle.form}> 

</Grid>

<Grid ref={pdfRef} className={ReportStyle.form}>

<Grid className={ReportStyle.headerLogo}>
          <div className={ReportStyle.CmsfLogo}>
        {/* <img className={ReportStyle.Golden50} src={Golden50} alt='CMSF Logo' /> */}
          </div>
          <div className={ReportStyle.CmsfLogo}>
        <img className={ReportStyle.CmsfLogo} src={CmsfLogo} alt='CMSF Logo' />
          </div>
          <div className={ReportStyle.Mujib100}>
        {/* <img className={ReportStyle.Mujib100} src={Mujib100} alt='CMSF Logo' /> */}
          </div>

      </Grid>

  <Grid className={ReportStyle.address} style={{ textAlign: 'center', fontSize: '1rem', margin: '10px 0' }}>
    <span>Dhaka Chamber Building(3rd Floor), 65-66 Motijheel C/A, Dhaka-1000</span><br />
    <span>Tel: +880241052430, Email: claim@cmsfbd.org</span>
  </Grid>

  <h1 style={{ textAlign: 'center', fontSize: '2rem' }}><strong>Company-wise Dividend Summary Report</strong></h1>

  <h5 className={ReportStyle.GategoryName} style={{ textAlign: 'center' }}> Dividends sent to CMSF</h5>

  <h5>CMSF received the following amount of {divTypeFromResponse} Dividends by the mentioned Company/Companies:</h5>

  <Grid className={ReportStyle.GategoryName2} style={{ overflowX: 'auto' }}>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th className={styles.td} colSpan={3}>Sl</th>
          <th className={styles.td} colSpan={3}>Issuer Name</th>
          <th className={styles.td} colSpan={3}>{divTypeFromResponse==='Cash' ? 'Received Cash' : 'Received Stock' }</th>
          <th className={styles.td} colSpan={3}>{divTypeFromResponse==='Cash' ? 'Settled Cash' : 'Settled Stock' }</th>
          <th className={styles.td} colSpan={3}>{divTypeFromResponse==='Cash' ? 'Outstanding Cash' : 'Outstanding Stock' }</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} style={{ backgroundColor: item.sett !== null ? '#50C878' : '' }}>
            <td className={styles.td} colSpan={3}>{index + 1}</td>
            <td className={styles.td} colSpan={3}>{item.issuername}</td>
            <td className={styles.td} colSpan={3}>{item.nst !== null ? item.nst.toFixed(2) : 0}</td>
            <td className={styles.td} colSpan={3}>{item.sett !== null ? item.sett.toFixed(2) : 0}</td>
            <td className={styles.td} colSpan={3}>{item.outs !== null ? item.outs.toFixed(2) : 0}</td>
          </tr>
        ))}
        <tr>
          <td className={styles.td} colSpan={6} style={{ fontSize: '1.3rem', textAlign: 'center', fontWeight: 'bold' }}>Total</td>
          <td className={styles.td} colSpan={3} style={{ fontSize: '1rem', fontWeight: 'bold' }}>{grandTotal.nst.toFixed(2)}</td>
          <td className={styles.td} colSpan={3} style={{ fontSize: '1rem', fontWeight: 'bold' }}>{grandTotal.sett.toFixed(2)}</td>
          <td className={styles.td} colSpan={3} style={{ fontSize: '1rem', fontWeight: 'bold' }}>{grandTotal.outs.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  </Grid>

  <Grid className={ReportStyle.msgDiv}>
    <span className={ReportStyle.msg}><i>This is a system-generated report.</i></span>
  </Grid>
</Grid>

<Button 
  style={{ margin: '10px', marginTop: '20px', backgroundColor: '#45CFDD' }}  
  variant="contained" 
  color="default" 
  onClick={generatePdf2}
>
  Download Summary Report
</Button>

</ResponsiveContainer>
</>
  );
};

export default UiOfCompanySummaryReport;
