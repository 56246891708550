import { Button } from '@material-ui/core';
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "../../../utils/axios";
import Table from './Table';
import Create from './add/Create';


function FilterTableComponent() {
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e); 
    };

    const confirmHandler = (idd) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/permission-types/${idd}`,{
                }).then((res)=>{
                      //setDeleteOpened((prevState) => !prevState);
                          getData();
                                });
                console.log(`${idd}  deleted successfully`)
              Swal.fire(
                'Deleted!',
                'Selected Role has been deleted.',
                'success'
              )
            }
          })
    }

    const getData = () =>{
        axios.get(`permission-types?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            console.log('use');
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    }

    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.get(`permission-types?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
            console.log('after');
        })
        .catch((err) =>{
            alert("There was a error occured!")
        })
    }
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Particular',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'autoId',
                    },
                    {
                        Header: 'Type of Permission',
                        accessor: 'title'
                    },
                    // {
                    //     Header: 'Status',
                    //    accessor: 'id'
                    // },
                    {
                        Header: 'Actions',
                        //accessor: 'title',
                      Cell: ({row}) =>
                      
                      <span>
                      {/* <Button 
                      style={{ margin: '5px', backgroundColor: 'green'}}  title='Claim your dividend now' 
                      variant="contained" 
                      color="primary" 
                      onClick={()=>console.log(row.values.id)}
                      >
                      Assign                          
                      </Button> */}

                      {/* <Button
                      style={{ margin: '5px', backgroundColor: 'blue'}}  title='Claim your dividend now' 
                      variant="contained" 
                      color="primary" 
                      onClick={()=>console.log(row.original.id)}
                      >
                      Edit                          
                      </Button> */}
 
                      <Button 
                      style={{ margin: '5px', backgroundColor: 'red'}}  title='Claim your dividend now' 
                      variant="contained" 
                      color="primary" 
                      onClick={()=>confirmHandler(row.original.id)}
                      >
                      Delete                          
                      </Button>
                     
                     </span>
                     
                    },
                   
                    
                ],
            },
           
        ],
        []
    )

    return (
        <>
        <div>
            <Button 
                        style={{ margin: '5px', backgroundColor: 'green'}}  
                        variant="contained" 
                        color="primary" 
                        onClick={()=>controlModal()}
                        >
                        Create                          
                    </Button>
            <Table 
            getDataSync={getDataSync} 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            />
           
        </div>
        <Create open={opened} data={modalData} control={controlModal} getData={getData} />
        </>
    )
}

export default FilterTableComponent;