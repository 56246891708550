import { apiSlice } from "../api/apiSlice";

export const inwardDividendAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMessages: builder.query({
            query: (id) =>
                `/messages?conversationId=${id}&_sort=timestamp&_order=desc&_page=1&_limit=${process.env.REACT_APP_MESSAGES_PER_PAGE}`,
        }),
        
        addInwardDividend: builder.mutation({
            query: (data) => ({
                url: "/inward-dividend",
                method: "POST",
                body: data,
            }),
        }),
        editInwardDividend: builder.mutation({
            query: (data,id) => ({
                url: `{/inward-dividend/${id}`,
                method: "PUT",
                body: data,
            }),
        }),
        getInwardDividend: builder.query({
            query: () => "inward-dividend?take=10",
        }),
        getInwardFileInfo: builder.query({
            query: () => "inward-dividend",
        }),
        getIssuerInfo: builder.query({
            query: () => "issuer",
        }),
        
    }),
});

export const { useGetInwardDividendQuery, useAddInwardDividendMutation,useEditInwardDividendMutation, useGetMessagesQuery, useAddMessageMutation, useGetInwardFileInfoQuery,useGetIssuerInfoQuery } = inwardDividendAPI;