import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from "../../../utils/axios";

export default function ListModalBody({handleClose,modalData, getUserData}) {

// const {id, name, email, gender, status} = modalData;

const [allValues, setAllValues] = useState({
    userId: '',
    name:'',
    tradecode: '',
    debutdate : '',
    category:'',
    sector: ''
      
    
});
const { t } = useTranslation();

const Category =[
  {id: 1,category: "A"},
  {id: 2,category: "B"},
  {id: 3,category: "G"},
  {id: 4,category: "N"},
  {id: 5,category: "Z"},
]
const Sectors = [
  
  {id: 1,sector: "Bank"},
  {id: 2,sector: "Cement"},
  {id: 3,sector: "Ceramics Sector"},
  {id: 4,sector: "Corporate Bond"},
  {id: 5,sector: "Debenture"},
  {id: 6,sector: "Engineering"},
  {id: 7,sector: "Financial Institutions"},
  {id: 8,sector: "Food & Allied"},
  {id: 9,sector: "Fuel & Power"},
  {id: 10,sector: "G-SEC (T.Bond)"},
  {id: 11,sector: "Insurance"},
  {id: 12,sector: "IT Sector"},
  {id: 13,sector: "Jute"},
  {id: 14,sector: "Miscellaneous"},
  {id: 15,sector: "Mutual Funds"},
  {id: 16,sector: "Paper & Printing"},
  {id: 17,sector: "Pharmaceuticals & Chemicals"},
  {id: 18,sector: "Services & Real Estate"},
  {id: 19,sector: "Tannery Industries"},
  {id: 20,sector: "Telecommunication"},
  {id: 21,sector: "Textile"},
  {id: 22,sector: "Travel & Leisure"}

];

const changeHandler = e => {
    setAllValues( prevValues => {
    return { ...prevValues,[e.target.name]: e.target.value}
    // console.log(allValues)
 })
 }
  
    
    const submitHandler = (e) =>{
      
      e.preventDefault();
      console.log('addHandler');
      
      axios.post('/issuer',allValues     
      ).then((res)=>{
        // console.log(allValues);

    
      }).catch((err)=>{
          // console.log(err);
      });
      ;
      
        
        console.log(`New Information is updated successfully`);
        handleClose();
        alert('Added successfully');
      getUserData();
      // console.log(allValues);
       }
   

    return(
        <div>
            <form onSubmit={submitHandler}>
           {/* {console.log(allValues)} */}
           

   
      <TextField style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
     required
      id="userId"
        name="userId"
        label="ID"
        fullWidth
        autoComplete="id"
        variant="outlined"
        onChange={changeHandler}
        
      />

<TextField style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
 
        required
        id="name"
        name="name"
        label="Company Name"
        //defaultValue={name}
        fullWidth
        autoComplete="title"
        variant="outlined"
        
        onChange={changeHandler}
      />
      <TextField style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}

        required
        id="tradecode"
        name="tradecode"
        label="Trade Code"
        fullWidth
        autoComplete="title"
        variant="outlined"
        onChange={changeHandler}
      />

<TextField  style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
     
        id="debutdate"
        type="date"
        name="debutdate"
        //label=""
        fullWidth
        autoComplete="id"
        variant="outlined"
        onChange={changeHandler}
        
      />

<FormControl fullWidth >
        <InputLabel style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}} id="issuer-id" >Select Sector</InputLabel>
         <Select style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                required
                id="sector"
                name="sector"
                label="Sector"
                defaultValue=""
                fullWidth
                variant="outlined"
                onChange={changeHandler}
                    >
                    {Sectors.map((i)=><MenuItem value={i.sector} key={i.id}>{i.sector}</MenuItem>)}
           </Select>
          </FormControl>

       <FormControl fullWidth >
        <InputLabel style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}} id="issuer-id" >Select Category</InputLabel>
          <Select style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                required
                id="category"
                name="category"
                label="Category"
                defaultValue=""
                fullWidth
                variant="outlined"
                onChange={changeHandler}
                    >
                    {Category.map((i)=><MenuItem value={i.category} key={i.id}>{i.category}</MenuItem>)}
           </Select>
          </FormControl>
    

           
                 <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                 variant="contained"
                 color="primary"
                 type="submit"
                 size="large"
                 onSubmit={submitHandler}
                
                 
               >
                 { t('Issuer.submitButton')}
               </Button>

                 <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                 variant="contained"
                 color="primary"
                 size="large"
                 onClick={handleClose}
               >
                  { t('Issuer.cancelButton')}
               </Button>
            </form>
        </div>
    )
}