import { Grid , Button } from "@material-ui/core";
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { useTranslation } from "react-i18next";
import { useGetIssuerInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import axios from "../../../utils/axios";
import AgreeDisagreePopup from "./view/AgreeDisagreePopup";

function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize,setNumberOfpage,params,open,setOpen,id,testState ,setTestState}) {

    const defaultColumn = React.useMemo(
        () => ({
            Filter: "",
        }),
        []
    )
    const {  data:issuer} = useGetIssuerInfoQuery();
    const { t } = useTranslation();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const nextPageHandler = () =>{        
        if(numberOfPage > currentPage){
            setCurrentPage(++currentPage);      
        }
    }

    const prevPageHandler = () =>{        
        if(currentPage > 1){
            setCurrentPage(--currentPage);
        }
    }
    
    const handleFirstPage = () =>{
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }

    const handleLastPage = () =>{
        if(currentPage !== numberOfPage){
            setCurrentPage(numberOfPage)
        }
    }

    const goToPageHandler = (e) =>{
        if( e === 0) {
            
        }
        else if(e < 0){
        }
        else if( e => 1){
            setCurrentPage(e);
        }else{
            setCurrentPage(1); 
        }
    }

    const pSize= [5, 10, 20, 30, 40, 50];

    const [fullName, setFullName] = useState("")
    const [folioNumberValue, setFolioNumberValue] = useState("")
    const [boIDValue, setBoIDValue] = useState("")

    const [dateValue, setDateValue] = useState("")
    const [issuerValue, setIssuerValue] = useState("")
    const [totalCashValue, setTotalCashValue] = useState("")
    const [totalFcValue, setTotalFcValue] = useState("")
    const [netDividend, setNetDividend] = useState("")
    
    
    const [shareUpper, setShareUpper] = useState("")
    const [shareLower, setShareLower] = useState("")

    const [netDividendUpper, setNetDividendUpper] = useState()
    const [netDividendLower, setNetDividendLower] = useState()

    const totalStockValue = shareLower && shareUpper ? [shareLower, shareUpper] : null;
    const totalNetDividendValue = netDividendLower && netDividendUpper ? [netDividendLower, netDividendUpper] : null;
    
    const paramsData = {
        page: currentPage,
        take: showPageSize,
        fullName,
        boID: boIDValue,
        folioNumber: folioNumberValue,
        nofshare: totalStockValue,
        netDividend: totalNetDividendValue,
    }

    useEffect(()=>{
            axios.post(`investor-informations/inward-dividend/${params.id}`,paramsData)
        .then((res) =>{
            setDataStore(res.data.payload)
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
           
    },[shareUpper, shareLower,dateValue, issuerValue, totalCashValue, totalFcValue, netDividendUpper,netDividendLower, setDataStore,setCurrentPage,setNumberOfpage,currentPage,showPageSize,fullName,folioNumberValue,boIDValue, setCurrentPage,testState])
    
    useEffect(()=>{
        setCurrentPage(1)
    },[shareUpper, shareLower, netDividendUpper,netDividendLower,fullName,folioNumberValue,boIDValue])

    // const [open, setOpen] = useState(false);
  const [agree, setAgree] = useState(false);
//   const [id, setId] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (agree) => {
    setOpen(false);
    setAgree(true);
    if (agree) {
    //   axios.put(`investor-informations/${id}`,{status:1})
    //     .then((res) => {
    //       if (res.data.success) {
    //         axios.post(`investor-informations/inward-dividend/${params.id}`,paramsData)
    //         .then((res) =>{
    //             setDataStore(res.data.payload)
    //             if(res.data?.page){
    //                 setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
    //             }
    //         })
    //         .catch((err) =>{
    //             alert("There was an error occured!")
    //         })
    //       }
    //     })
    //     .catch((err) => {
    //         alert("There was an error occured!")
    //     });
    axios.put(`investor-informations/update-status/${id}`,{status:1})
        .then((res) => {
          if (res.data.success) {
            //to go back and forth of the table
            axios.post(`investor-informations/inward-dividend/${params.id}`,paramsData)
            .then((res) =>{
                setDataStore(res.data.payload)
                setTestState(!testState)
                if(res.data?.page){
                    setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
                }
            })
            .catch((err) =>{
                alert("There was an error occured!")
            })
          }
        })
        .catch((err) => {
            alert("There was an error occured!")
        });
    }
    
  };

  const searchResethandler = () =>{
    setFullName("")
    setFolioNumberValue("")
    setBoIDValue("")
    setDateValue("")
    setIssuerValue("")
    setTotalCashValue("")
    setTotalFcValue("")
    //setNetDividend("")
    setNetDividendUpper("")
    setNetDividendLower("")
    // setNoOfShare("")
    setShareUpper("")
    setShareLower("")
}
    return (
        <div>
            <Grid container spacing={2}>
          {/* <Grid item lg={2} md={2} sm={2} xs={12}>
          <FormControl fullWidth >
                <InputLabel id="issuer-id" >Select Issuer</InputLabel>
                <Select 
                required
                    labelId="issuer-id"
                    id="demo-simple-select"
                    value={data.tradeCode}
                    label="Select Issuer"
                    onChange={(e) => setIssuerValue(e.target.value)}
                    >
                    {issuer?.payload.map((i)=><MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>)}
                </Select>
              </FormControl>
          
          </Grid> */}
          <Grid item lg={2} md={2} sm={2} xs={12}>
          <span>
            Full Name:{' '}
            <input
                className="form-control"
                value={fullName || ""}
                onChange={e => {
                    setFullName(e.target.value);
                }}
                placeholder={` search...`}
            />
        </span>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
          <span>
            BO Id:{' '}
            <input
                className="form-control"
                type='number'
                value={boIDValue || ""}
                onChange={e => {
                    setBoIDValue(e.target.value.trim());
                }}
                placeholder={` search...`}
            />
        </span>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
          <span>
            Folio Id:{' '}
            <input
                className="form-control"
                value={folioNumberValue || ""}
                onChange={e => {
                    setFolioNumberValue(e.target.value);
                }}
                placeholder={`search...`}
            />
        </span>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
          {/* <span>
            Net Dividend:{' '}
            <input
                className="form-control"
                type='number' 
                value={netDividend || ""}
                onChange={e => {
                    setNetDividend(e.target.value);
                }}
                placeholder={` search...`}
            />
        </span> */}
        <span>
          Net Dividend:{' '}
            <input
                className="form-control"
                type='number'
                // step="0.1"
                // min='0.0'
                value={netDividendLower || ""}
                onChange={e => {
                    setNetDividendLower((e.target.value));
                }}
                placeholder={` from...`}
            />
        </span>
        <span>
          {' '}
            <input
                className="form-control"
                type='number'
                value={netDividendUpper || ""}
                onChange={e => {
                    setNetDividendUpper((e.target.value));
                }}
                placeholder={` to...`}
            />
        </span>
        
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
          {/* <span>
          No of Share:{' '}
            <input
                className="form-control"
                type='number' 
                value={noOfShare || ""}
                onChange={e => {
                    setNoOfShare(e.target.value);
                }}
                placeholder={` search...`}
            />
        </span> */}
        <span>
            No of Share:{' '}
            <input
                className="form-control"
                type='number'
                value={shareLower || "" }
                onChange={e => {
                    // noOfSharesHandler();
                    setShareLower(e.target.value)
                    //setStockValue(`${shareLower}, ${shareUpper}`);
                   
                }}
                placeholder={`from...`}
            />
        </span>
        <span>
            {' '}
            <input style={{marginTop:'4px'}}
                className="form-control"
                type='number'
                value={shareUpper|| "" }
                onChange={e => {
                    // noOfSharesHandler();
                    setShareUpper(e.target.value);
                  //  setStockValue(`${shareLower}, ${shareUpper}`);
                   
                }}
                placeholder={` to...`}
            />
             
        </span>
          </Grid>
          <Grid item lg={1} md={2} sm={2} xs={12}>
          <span>
          <Button
           variant="contained"
                 color="primary"
                 size="medium"
                 style={{margin:"10px", marginTop:"20px"}}
                 onClick={searchResethandler}
                  >{ t('common.reset')}</Button>
        </span>
        </Grid>
          </Grid>
           
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    {/* Render the columns filter UI */}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <ul className="pagination">
                <li className="page-item" onClick={handleFirstPage} disabled={!canPreviousPage}>
                    <span style={{cursor: "grab"}} className="page-link">First</span>
                </li>
                <li className="page-item" onClick={prevPageHandler}
                 disabled={!canPreviousPage}
                 >
                    <span style={{cursor: "grab"}} className="page-link">{'<'}</span>
                </li>
                <li className="page-item" onClick={nextPageHandler}
                 disabled={!canNextPage}
                 >
                    <span style={{cursor: "grab"}} className="page-link">{'>'}</span>
                </li>
                <li className="page-item" onClick={handleLastPage} disabled={!canNextPage}>
                    <span style={{cursor: "grab"}} className="page-link">Last</span>
                </li>
                <li>
                    <a className="page-link">
                        Page{' '}
                        <strong>
                            {currentPage} of {numberOfPage}
                        </strong>{' '}
                    </a>
                </li>
                <li>
                    <a className="page-link">
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                goToPageHandler(e.target.value)
                            }}
                            style={{ width: '100px', height: '20px' }}
                        />
                    </a>
                </li>{' '}
                <select
                    className="form-control"
                    value={showPageSize}
                    onChange={e => {
                        setShowPageSize(Number(e.target.value))
                    }}
                    style={{ width: '120px', height: '38px' }}
                >
                    {pSize.map(pageSize => (
                        <option key={pageSize} value={pageSize} >
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </ul>
            <AgreeDisagreePopup open={open} onClose={handleClose} />
        </div>
    )
}

export default Table;