import React from 'react';
import {
  Area, AreaChart, CartesianGrid, Label, Legend, Tooltip, XAxis,
  YAxis, ResponsiveContainer
} from 'recharts';


const data = [
  {
    "name": "May",
    "stock transferred": 14,
    "stock settled": 2
  },
  {
    "name": "Jun",
    "stock transferred": 13,
    "stock settled": 12
  },
  {
    "name": "Jul",
    "stock transferred": 16,
    "stock settled": 4
  },
  {
    "name": "Aug",
    "stock transferred": 22,
    "stock settled": 12
  },
  {
    "name": "Sep",
    "stock transferred": 7,
    "stock settled": 4
  },{
    "name": "Oct",
    "stock transferred": 7,
    "stock settled": 4
  },{
    "name": "Nov",
    "stock transferred": 2,
    "stock settled": 1
  }
 
]

const AreaCharts = (props) => {
  return (

   
    <ResponsiveContainer width="93%" height={400}>
    <AreaChart width={900} height={450}  spacing ={2} data={props.data} margin={{ top: 60, right: 20,  bottom: 20 , left: 30}}>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
        </linearGradient>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
        </linearGradient>
      </defs>
        
      <XAxis dataKey="monthyear">
      <Label value="Month" offset={-10} position="insideBottomRight" />
      <Label value="Stock Claim Settlement" offset={340} position="insideBottomRight" />
      </XAxis>
      {/* <Label value="Month" offset={-10} position="insideBottomRight" /> */}
      <YAxis label={{ value: 'Number of  Stock', angle: -90, position: 'insideLeft', textAnchor: 'middle' }}/>
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend />
      <Area type="monotone" dataKey="totalclaim" name="Lodged" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
      <Area type="monotone" dataKey="settled" name="Settled" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
    </AreaChart>
    
    </ResponsiveContainer >

  )
}

export default AreaCharts;

