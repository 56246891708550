import { Grid } from "@material-ui/core";
import React from "react";
  
  // styles
  import useStyles from "../../styles";




  
  // styles
  
  // components
 import Widget from "../../../../components/Widget/Widget";
import { Typography } from "../../../../components/Wrappers";


export default function GridItem({data, name, url}){


    var classes = useStyles();

    return(
        <Grid item lg={3} md={4} sm={6} xs={12}>
        <Widget 
          title={name}
          url={url}
          upperTitle
          bodyClass={classes.fullHeightBody}
          className={classes.card}
        >
          <div className={classes.visitsNumberContainer}>
            <Grid container item alignItems={"center"}>
              <Grid item xs={12}>
            <Typography  size="xl" weight="medium" noWrap style={{ fontWeight: "bold", fontSize: "30px" }}>
              {data}
            </Typography>
              </Grid>
            </Grid>
          </div>
         
        </Widget>
      </Grid>
    )
}