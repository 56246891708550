import { Box, Button, Grid, InputAdornment, OutlinedInput, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Lock } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { sendOtp, useUserDispatch, verifyOtpToLogin } from "../../context/UserContext";
import Logo from './AuthBackground';
import Notification from './Notification';
import CmsfLogo from './cmsf.jpg'; // Replace with the path to your logo image
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(4),
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // height:'600px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    width: 180,
    height: 80,
  },
}));

const OtpComponent = (props) => {
  const classes = useStyles();
  const [otpCode, setOtpCode] = useState('');
  var userDispatch = useUserDispatch();
  const location = useLocation();
  const data = location.state;
  const phoneNumber = data;
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [remainingTime, setRemainingTime] = useState(300);
  const [isCountdownActive, setIsCountdownActive] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(true);
  const countdownRef = useRef(null);
  const [isErrorResendOtp, setIsErrorResendOtp] = useState(false);
  const message = "The number that you've entered doesn't match your code. Please try again."
  const messageValidation = "You've only entered 4 numbers. Please check your code and try again."
  const messageOtp = "We are unable to send sms to this phone number. Please check your phone number."

  useEffect(() => {
    if (isCountdownActive) {
      countdownRef.current = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) { 
            setIsCountdownActive(false);
            clearInterval(countdownRef.current);
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownRef.current);
    };
  }, [isCountdownActive]);

  const handleSubmit =() =>{
    if(otpCode.length === 4){
      verifyOtpToLogin( setIsLoading, setIsError,otpCode, phoneNumber, props.history, userDispatch);
    }else{
      setIsValid(true)
    }
  }

  const handleResendOTP = () => {
    setIsOtpSent(true);
    setIsCountdownActive(true);
    setRemainingTime(180);
    sendOtp(setIsErrorResendOtp,phoneNumber)
  };

  const handleClose = () => {
    setIsError(false);
    setIsValid(false);
  };
  
  return (
    <div >
      <Box className="left-align">
      <Logo />
    </Box>
    
    <div className={classes.root}>
      <img  src={CmsfLogo} alt="Logo" className={classes.logo} /> 
      <Notification message={messageValidation} open={isValid} handleClose={handleClose} color='red' />
        <Notification message={message} open={isError} handleClose={handleClose} color='red' />
        <Notification message={messageOtp} open={isErrorResendOtp} handleClose={handleClose} color='red' />
      <Box style={{transform:'scale(0.8)'}}>
      <Box style={{marginBottom:'3px'}}>
      <Paper>
        <Box style={{textAlign:'left', color:'black',padding:'20px'}}>
          <Typography variant='h4' component='h4'>Enter the code from your Phone Number</Typography>
        </Box>
        </Paper>
      </Box>

      <Box>
      <Paper className={classes.paper} style={{marginBottom:'3px'}}>
        
        
        <Typography style={{color:'black', marginBottom:'20px'}} variant="h5" component="h1" gutterBottom>
        Let us know that this phone number belongs to you. Enter the code from the sms sent to <strong>{phoneNumber || "XXXXXXXXXX"}</strong>.
        </Typography>
        
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <OutlinedInput
                fullWidth
                id="phone"
                type="number"
                value={otpCode}
                onChange={e => setOtpCode(e.target.value)}
                placeholder="OTP CODE"
                startAdornment={
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>

          <Grid container justify="flex-start" style={{padding:'10px 0px 10px 0px'}}>
            <Grid item>
              
              <Box>
              <Grid container justify="flex-start" style={{ padding: '10px 0px 10px 0px' }}>
            <Grid item>
              {isOtpSent && !isCountdownActive && (
                <Button variant="contained" onClick={handleResendOTP}>
                  Send SMS Again
                </Button>
              )}
              {isOtpSent && isCountdownActive && (
                <Typography variant="body1" component="span">
                  Resend SMS in {Math.floor(remainingTime / 60)} minutes {remainingTime % 60} seconds
                </Typography>
              )}
            </Grid>
          </Grid>
      </Box>
            </Grid>
          </Grid>
      </Paper>
      </Box>

      <Box>
      <Paper>
        <Box style={{textAlign:'center', color:'white'}}>
        <Grid container spacing={2}>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={4} style={{marginRight:'5px'}}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="default"
                className={classes.submit}
                component={RouterLink}
                to="login"
                >
                Back
                </Button>
            </Grid>
            <Grid item xs={4} style={{marginRight:'5px'}}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                color="primary"
                className={classes.submit}
                >
                Continue
                </Button>
            </Grid>
            </Grid>
        </Box>
        </Paper>
      </Box>
      
      </Box>
    </div>
    </div>
  );
};

export default OtpComponent;



