import { Button } from "@material-ui/core";
import React from 'react';
import Swal from 'sweetalert2';
// import { useGetPermissionInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import FilterTableComponent from './FilterTableComponent';
import axios from "../../../utils/axios";
import { useTranslation } from "react-i18next";



const PermissionTypes = () => {
  const { t } = useTranslation();
  // const {  data:permissionTypes} = useGetPermissionInfoQuery();
  // const [data,setData] = useState({
  //   ref:'',
  //   tradeCode: '',
   
  // });
//   const [title, setTitle] = useState('')

//   const changeHandler = e => {
//     setTitle( prevValues => {
//         return { ...prevValues,[e.target.name]: e.target.value}
//         console.log(title)
//      })
    
// }


  return (
    <div><h3>{t('PermissionTypes.permissionTypes')}</h3>
      {/* <PerTypesTable /> */}
      
      <FilterTableComponent/>
    </div>
  )
}

export default PermissionTypes