import { Grid, TextField, Button } from "@material-ui/core";
import { default as React, useContext, useState } from "react";
import ReduceContext from "./ReduceContext";
import useStyles from "./useStyle";

export  const RequiredDocuments = () => {
  //const [file, setFile] = useState();

  // same for all cases
  const [photoOfInvestorsNID, setPhotoOfInvestorsNID] = useState('');
  const [photoOfBOFolio, setPhotoOfBOFolio] = useState('');
  const [photoOfAdditionalFile, setPhotoOfAdditionalFile] = useState('');
  const [userWantsAnotherField, setUserWantsAnotherField] = useState(false);

  //Authorized Person
  const [photoOfAuthorizationLetter, setPhotoOfAuthorizationLetter ] = useState('');
  const [photoOfAuthorizedPersonNID, setPhotoOfAuthorizedPersonNID] = useState('');

  //power of attorney
  const [photoOfPowerAttorneyLetter, setPhotoOfPowerAttorneyLetter ] = useState('');
  const [photoOfPowerAttorneyPersonNID, setPhotoOfPowerAttorneyPersonNID] = useState('');

  //successor/nominee
  const [photoOfDeathCertificate, setPhotoOfDeathCertificate] = useState('');
  const [photoOfCourtSuccessionCertificate, setPhotoOfCourtSuccessionCertificate] = useState('');
  const [photoOfInheritanceLetter, setPhotoOfInheritanceLetter ] = useState('');
  const [photoOfSuccessorsNID, setPhotoOfSuccessorsNID] = useState('');
  


  
  const classes = useStyles()
  const {data, setData} = useContext(ReduceContext);

  function checkFileType(file) {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
    if(allowedFileTypes.includes(file.type)){
      return true;
    } else{
      alert( " Plese check your upload file type. Only allow (PNG/JPG/JPEG/PDF)")
      return false
    }
  }

  let requiredContent ='' ;
  if(data?.claimType === 'Self'){
    requiredContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}> 
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="nid-file"
              label="Investors NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File"
              fullWidth
              focused
              required={!data.nid_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, nid_file: e.target.files[0]})
                  setPhotoOfInvestorsNID(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
              <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfInvestorsNID} /> 
              </div>
                {data.nid_file.length === 0 ? null: data.nid_file.name}
            </div>
          
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="boid-file"
              label="Bo/Folio File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Bo/Folio File"
              fullWidth
              focused
              required={!data.folio_bo_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, folio_bo_file: e.target.files[0]})
                  setPhotoOfBOFolio(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, folio_bo_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
              <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfBOFolio} /> 
              </div>
                {data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name}
            </div>
        
          
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }

  if(data?.claimType === 'Successor'){
    requiredContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="nid-file"
              label="Investors NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File"
              fullWidth
              focused
              required={!data.nid_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, nid_file: e.target.files[0]})
                  setPhotoOfInvestorsNID(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
              <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfInvestorsNID} /> 
              </div>
                {data.nid_file.length === 0 ? null: data.nid_file.name}
            </div> 
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="death-file"
              label="Death Certificate File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Death Certificate "
              fullWidth
              focused
              required={!data.death_certificate_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, death_certificate_file: e.target.files[0]})
                  setPhotoOfDeathCertificate (URL.createObjectURL(e.target.files[0]));

                }else{
                  e.target.value = null;
                  setData({...data, death_certificate_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
                <img style={{height: "135px", weight: "135px"}} src={ photoOfDeathCertificate } /> 
              </div>
                 {data.death_certificate_file.length === 0 ? null: data.death_certificate_file.name}
            </div>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="nid-file"
              label="Successor NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Successor NID File for "
              fullWidth
              focused
              required={!data.successor_nid_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, successor_nid_file: e.target.files[0]})
                  setPhotoOfSuccessorsNID(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, successor_nid_file: []});
                 
                
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
              <img style={{height: "135px", weight: "135px"}} src={ photoOfSuccessorsNID } /> 
            </div>
                 {data.successor_nid_file.length === 0 ? null: data.successor_nid_file.name}
            </div>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="inheritance-letter"
              label="Inheritance letter (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Inheritance letter File"
              fullWidth
              focused
              required={!data.inheritance_certificate_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, inheritance_certificate_file: e.target.files[0]})
                  setPhotoOfInheritanceLetter (URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, inheritance_certificate_file: []});
                  
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfInheritanceLetter} /> 
              </div>
                 {data.inheritance_certificate_file.length === 0 ? null: data.inheritance_certificate_file.name}
            </div>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="court-letter"
              label="Court Certificate (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Court Certificate File"
              fullWidth
              focused
              required={!data.court_certificate_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, court_certificate_file: e.target.files[0]})
                  setPhotoOfCourtSuccessionCertificate(URL.createObjectURL(e.target.files[0]));
                
                }else{
                  e.target.value = null;
                  setData({...data, court_certificate_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
              <img style={{height: "135px", weight: "135px"}} src={photoOfCourtSuccessionCertificate} /> 
            </div>
                 {data.court_certificate_file.length === 0 ? null: data.court_certificate_file.name}
            </div>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="boid-file"
              label="Bo/Folio File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Bo/Folio File"
              fullWidth
              focused
              required={!data.folio_bo_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, folio_bo_file: e.target.files[0]})
                  setPhotoOfBOFolio(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, folio_bo_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
              <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfBOFolio} /> 
              </div>
                 {data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name}
            </div>
        
          
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }
//console.log(photoOfBOFolio.type)
  if(data?.claimType === 'Authorized'){
    requiredContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}> 
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="nid-file"
              label="Investors NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File"
              fullWidth
              focused
              required={!data.nid_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, nid_file: e.target.files[0]})
                  setPhotoOfInvestorsNID(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfInvestorsNID} /> 
              </div>
                 {data.nid_file.length === 0 ? null: data.nid_file.name}
            </div>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="nid-file"
              label="Authorized NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Authorized NID File for "
              fullWidth
              focused
              required={!data.poa_nid_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, poa_nid_file: e.target.files[0]})
                  setPhotoOfAuthorizedPersonNID(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, poa_nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
              <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfAuthorizedPersonNID} /> 
              </div>
                 {data.poa_nid_file.length === 0 ? null: data.poa_nid_file.name}
            </div>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="authorized-letter"
              label="Authorized Letter File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Authorized Letter File"
              fullWidth
              focused
              required={!data.poa_letter_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, poa_letter_file: e.target.files[0]})
                  setPhotoOfAuthorizationLetter(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, poa_letter_file: []});
                 
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfAuthorizationLetter} /> 
              </div>
                 {data.poa_letter_file.length === 0 ? null: data.poa_letter_file.name}
            </div>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="boid-file"
              label="Bo/Folio File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Bo/Folio File"
              fullWidth
              focused
              required={!data.folio_bo_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, folio_bo_file: e.target.files[0]})
                  setPhotoOfBOFolio (URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, folio_bo_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfBOFolio} /> 
              </div>
                 {data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name}
            </div>
        
          
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }

  if(data?.claimType === 'PowerOfAttorney'){
    requiredContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="nid-file"
              label="Investors NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File"
              fullWidth
              focused
              required={!data.nid_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, nid_file: e.target.files[0]})
                  setPhotoOfInvestorsNID(URL.createObjectURL(e.target.files[0]));
                 // console.log(e.target.files);
                }else{
                  e.target.value = null;
                  setData({...data, nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfInvestorsNID} /> 
              </div>
                 {data.nid_file.length === 0 ? null: data.nid_file.name}
            </div> 
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="nid-file"
              label="NID File of POA (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File for "
              fullWidth
              focused
              required={!data.poa_nid_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, poa_nid_file: e.target.files[0]})
                  setPhotoOfPowerAttorneyPersonNID(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, poa_nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfPowerAttorneyPersonNID} /> 
              </div>

                 {data.poa_nid_file.length === 0 ? null: data.poa_nid_file.name}
            </div>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="poa-letter"
              label="POA Letter (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your POA Letter File"
              fullWidth
              focused
              required={!data.poa_letter_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, poa_letter_file: e.target.files[0]})
                  setPhotoOfPowerAttorneyLetter(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, poa_letter_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfPowerAttorneyLetter} /> 
              </div>
                 {data.poa_letter_file.length === 0 ? null: data.poa_letter_file.name}
            </div>
      <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.root,
          asterisk: classes.requiredAsterisk,
        },
      }}
              id="boid-file"
              label="Bo/Folio File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Bo/Folio File"
              fullWidth
              focused
              required={!data.folio_bo_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, folio_bo_file: e.target.files[0]})
                  setPhotoOfBOFolio (URL.createObjectURL(e.target.files[0]));

                }else{
                  e.target.value = null;
                  setData({...data, folio_bo_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>       
              <div>
                <img style={{height: "135px", weight: "135px"}} src={photoOfBOFolio} /> 
              </div>
                 {data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name}
            </div>
            
          
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }
 

 
    return (
      <>
      
            {requiredContent}  
            
            {/* <Button
           
            variant="contained"
              color="secondary"
              backgroundColor="green"
              onClick={()=>setUserWantsAnotherField(true)}>
                Add Another Field
            </Button> */}
            
            <div > <br/></div>
           
            {/* {userWantsAnotherField && <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                InputLabelProps={{
                  classes: {
                    outlined: classes.root,
                    asterisk: classes.requiredAsterisk,
                  },
                }}
                        id="additional-file"
                        label="Other supportive document (IF ANY) (JPG or PNG or JPEG or PDF)"
                        variant="filled"
                        // placeholder="Upload"
                        helperText="if you wish to upload additional document, you can add here "
                        fullWidth
                        focused
                        // required={!data.nid_file}
                        margin="normal"
                        type="file"
                        onChange={(e) =>{
                          if(checkFileType(e.target.files[0])){
                            setData({...data, additional_file: e.target.files[0]})
                            setPhotoOfAdditionalFile(URL.createObjectURL(e.target.files[0]));
                          // console.log(e.target.files);
                          }else{
                            e.target.value = null;
                            setData({...data, additional_file: []});
                          }
                        } }
                      /> 
                      
                      <div style={{marginBottom: "10px"}}>
                      <div>
                          <img style={{height: "135px", weight: "135px"}} src={photoOfAdditionalFile} /> 
                        </div>
                          {data.additional_file.length === 0 ? null: data.additional_file.name}
                      </div>
              </Grid>
            </Grid>} */}

      </>
    );
  };