import { Button, TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import axios from "../../../../utils/axios";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    
 
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(10, 20, 15),
  },
}));

export default function Create({ open,data, control, getData}) {
  const classes = useStyles();
 
  const { t } = useTranslation();

  const [title, setTitle] = useState('')

  const changeHandler = e => {
    setTitle( prevValues => {
        return { ...prevValues,[e.target.name]: e.target.value}
        console.log(title)
     })
    
}

  const submitHandler = (e) => {
          e.preventDefault();
          console.log('addHandler');
          
          axios.post('/permission-types',title     
          ).then((res)=>{
            getData();
            console.log(title);
            Swal.fire({
              icon: 'success',
              title: 'Permission Type added successfully',
              showConfirmButton: false,
              confirmButtonText:<i class="fa fa-thumbs-up">Great!</i> ,
              timer: 1000
            }) 
        
          }).catch((err)=>{
              console.log(err);
          });
          ;
          console.log(`Added successfully`);
          
          control();
          

         
          
    
   
  };

  return (
    <div>
    
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={control}
        disableEscapeKeyDown
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h1 >{ t('PermissionTypes.newPermissionTypeAdd')}</h1>
            <div>
                
                <TextField 
                    helperText="This is required "
                    required
                    id="newPermissionType"
                    name="title"
                    label="Input Permission Type"
                    fullWidth
                    autoComplete="id"
                    variant="outlined"
                   onChange={changeHandler}
        
                />

<Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                 variant="contained"
                 color="primary"
                 type="submit"
                 size="large"
                 onClick={submitHandler}
                 disabled={title.length<3}
                 
               >
                 { t('Issuer.submitButton')}
               </Button>

                 <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                 variant="contained"
                 color="primary"
                 size="large"
                 onClick={control}
               >
                  { t('Issuer.cancelButton')}
               </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
