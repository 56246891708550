import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import FilterTableComponent from "./FilterTableComponent";


export default function CompanyStatus(){
 
    const { t } = useTranslation();
   

    return(
        <>
            <PageTitle title={ "Details Report on Issuer Company's Dividend Transfer"}  />
            <FilterTableComponent />
        </>
    )
}