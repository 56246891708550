import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from "react-i18next";
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { useGetIssuerInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import axios from "../../../utils/axios";
// import Select from 'react-select'

const options = [{ tradecode: "No Data Available", name: "" },
// { tradecode: "No Data Available", name: "" },
];


function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize,setNumberOfpage,params,issuerId }) {

    const {  data:issuer} = useGetIssuerInfoQuery();
    const { t } = useTranslation();
    const [statusValue,setStatusValue]= useState("")
    const [newData, setNewData] = useState([]);

    useEffect(()=>
  {axios.get(`/issuer-year-format/${issuerId.issuerId}`, paramsData)
  .then((res)=>{
    setNewData(res.data.payload)
  })
  .catch((err) =>{
    alert(err.response.data.message)
  })
},[issuerId.issuerId])

  // console.log(newData)

    const newData2 = newData?newData:options
    var sortedNewData = newData2
    if (newData2) {
      sortedNewData = newData2.slice().sort((a, b) => {
        if (a.tradecode < b.tradecode) {
          return -1;
        }
      });
     
    }

    const defaultColumn = React.useMemo(
        () => ({
            Filter: "",
        }),
        []
    )

    const issuerData = issuer?.payload
    //console.log(issuerData);
    const issuerData2 = issuerData;
    //console.log(issuerData2);
    var sortedIssuerData = issuerData
    if (issuerData2) {
     sortedIssuerData = issuerData2.slice().sort((a, b) => {
        if (a.tradecode < b.tradecode) {
          return -1;
        }
      });
     
    }
    
//console.log(sortedIssuerData);
const ITEM_HEIGHT = 100;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 250,
            //height: 100,
            overflowY: 'scroll',
            overflowX: 'scroll'
          },
        },
      };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const nextPageHandler = () =>{        
        if(numberOfPage > currentPage){
            setCurrentPage(++currentPage);      
        }
    }

    const prevPageHandler = () =>{        
        if(currentPage > 1){
            setCurrentPage(--currentPage);
        }
    }
    
    const handleFirstPage = () =>{
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }

    const handleLastPage = () =>{
        if(currentPage !== numberOfPage){
            setCurrentPage(numberOfPage)
        }
    }

    const goToPageHandler = (e) =>{
        getDataSync(e,showPageSize, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue)
    }

    

    const pSize= [5, 10, 20, 30, 40, 50];

    const [fullName, setFullName] = useState("")
    const [folioNumberValue, setFolioNumberValue] = useState("")
    const [boIDValue, setBoIDValue] = useState("")
    const [netDividendValue, setNetDividendValue] = useState("")
    const [dateValue, setDateValue] = useState("")
    const [issuerValue, setIssuerValue] = useState("")
    const [totalCashValue, setTotalCashValue] = useState("")
    const [totalFcValue, setTotalFcValue] = useState("")
   // const [totalStockValue, setStockValue] = useState("")
    const [remarksValue, setRemarksValue] = useState("")
    const [emptyValue, setEmptyValue] = useState("")


    const [shareUpper, setShareUpper] = useState("")
    const [shareLower, setShareLower] = useState("")

    const [netDividendUpper, setNetDividendUpper] = useState()
    const [netDividendLower, setNetDividendLower] = useState()

    const totalStockValue = shareLower && shareUpper ? [shareLower, shareUpper] : null;
    const totalNetDividendValue = netDividendLower && netDividendUpper ? [netDividendLower, netDividendUpper] : null;


   
    //const totalStockValue = console.log(JSON.stringify(totalStockValuei));
    //const shareArray = [shareLower,shareUpper];
    // const convertedArray = shareArray.JSON
   //console.log(JSON.stringify(totalStockValue));

    const paramsData = {
        page: currentPage,
        take: showPageSize,
        fullName: fullName,
        boID: boIDValue,
        folioNumber: folioNumberValue,
        netDividend: totalNetDividendValue,
        status:statusValue,
        nofshare: totalStockValue,
        yearDividend: dateValue,
        issuerId: issuerValue,
    }
    //console.log(paramsData)


    const searchResethandler = () =>{
        setFullName("");
        setIssuerValue("")
        setBoIDValue("")
        setFolioNumberValue("")
        setShareLower("")
        setShareUpper("")
        setStatusValue("")
        //setNetDividendValue("") 
        setNetDividendUpper("") 
        setNetDividendLower("")
    }
    
 
   
    useEffect(()=>{
        setCurrentPage(1)
    },[])
    return (
        
        <div>
            <Grid container spacing={1}>
          </Grid>
           
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <ul className="pagination">
                <li className="page-item" onClick={handleFirstPage} disabled={!canPreviousPage}>
                    <a className="page-link">First</a>
                </li>
                <li className="page-item" onClick={prevPageHandler}
                 disabled={!canPreviousPage}
                 >
                    <a className="page-link">{'<'}</a>
                </li>
                <li className="page-item" onClick={nextPageHandler}
                 disabled={!canNextPage}
                 >
                    <a className="page-link">{'>'}</a>
                </li>
                <li className="page-item" onClick={handleLastPage} disabled={!canNextPage}>
                    <a className="page-link">Last</a>
                </li>
                <li>
                    <a className="page-link">
                        Page{' '}
                        <strong>
                            {currentPage} of {numberOfPage}
                        </strong>{' '}
                    </a>
                </li>
                <li>
                    <a className="page-link">
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                goToPageHandler(e.target.value)
                            }}
                            style={{ width: '100px', height: '20px' }}
                        />
                    </a>
                </li>{' '}
                <select
                    className="form-control"
                    value={showPageSize}
                    onChange={e => {
                        setShowPageSize(Number(e.target.value))
                    }}
                    style={{ width: '120px', height: '38px' }}
                >
                    {pSize.map(pageSize => (
                        <option key={pageSize} value={pageSize} >
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </ul>
  
        </div>
    )
}

export default Table;