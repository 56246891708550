import {
  Button, CircularProgress, TextField, Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AuthHeader from "../../services/AuthHeader";
import { changedPassword } from "../../services/AuthService";
import axiosInstance from "../../utils/axios";
import { getUserId } from "../../utils/strings/userString";


export default function ProfileEdit(){

  const user = JSON.parse(localStorage.getItem('user-name'))
  //console.log(user);
  const history = useHistory();
        
   
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState(false);
    var [success, setSuccess] = useState(false);
    var [userType, setUserType] = useState('');
    const userId = getUserId();
    const [userDetails,setUserDetails] = useState({
      username: '',
      fullName: '',
      phoneNumber: '',
      email: '',
      countryCode:'BD'
    })

    useEffect(()=>{
      axiosInstance.get(`users/${userId}`)
      .then((res)=>{
        if(res?.data?.success === true){
          setUserDetails({...setUserDetails,
            fullName:res?.data?.payload?.fullName,
            email:res?.data?.payload?.email,
            phoneNumber:res?.data?.payload?.phoneNumber
           })
           setUserType(res?.data?.payload?.userTypes[0])
        }
      })
      .catch((err)=>{
        console.log("There was an error occured!")
      })
    },[]);

    const updateProfileHandler =()=>{
      axiosInstance.put(`users/${userId}`,userDetails)
      .then((res)=>{
        if(res?.data?.success === true){
            history.push('/app/profile')
        }
      })
      .catch((err)=>{
        console.log("There was an error occured!")
      })
    }

    
    var [currentPassword, setCurrentPassword] = useState("");
    var [newPassword, setNewPassword] = useState("");
    var [confirmPassword, setConfirmPassword] = useState("");
    const PasswordChanged = (currentPassword,confirmPassword,setIsLoading,setError)=>{
        const userName = AuthHeader();
       const passwordDetails = {
          "userName" : userName.userName,
          "oldPassword" : currentPassword,
          "newPassword" : confirmPassword,
        }

        changedPassword(passwordDetails ).then((response) => {
      if(response.success){
        
        setError(response.msg)
        setSuccess('')
      }
      if(!response.success){
        
        setError(response.msg)
        setSuccess('')
      }
      if(response.isPasswordChanged){
        setError('');
        setSuccess(response.msg);
        setConfirmPassword("");
        setCurrentPassword("");
        setNewPassword('')
      }
        }).catch((err) =>{
          setError('Internal Server Error!')
        setSuccess('')
        })
    }
    return(
        <div style={{minHeight:"100%"}}>
            <div >

        
<React.Fragment>
  <Typography variant="h2" >
    Profile Update
  </Typography>

   
    <Typography style={{color:'green', textAlign:'left',marginTop:'20px'}}>
      {success}
    </Typography>
    <Typography style={{color:'red', textAlign:'left',marginTop:'20px'}}>
      {error}
    </Typography>
  
  
    <TextField
  id="fullName"
  label="Full Name"
  variant="outlined"
  type="text"
  fullWidth
  required
  value={userDetails.fullName}
  margin="normal"
  onChange={(e) => setUserDetails({...userDetails, fullName: e.target.value})}
/>
    <TextField
  id="phone"
  label="Phone Number"
  variant="outlined"
  type="number"
  fullWidth
  required
  disabled
  value={userDetails.phoneNumber}
  margin="normal"
  // onChange={(e) => setUserDetails({...userDetails, phoneNumber: e.target.value})}
/>
  
    <TextField
  id="mobileNumber"
  label="Email"
  variant="outlined"
  type="email"
  fullWidth
  required
  value={userDetails.email}
  margin="normal"
  onChange={(e) => setUserDetails({...userDetails, email: e.target.value})}
/>
    {/* <TextField
      id="nId"
      label="National ID"
      variant="outlined"
      placeholder="Enter Your Current Password"
      fullWidth
      required
      value={userDetails.nid}
      margin="normal"
      // onChange={(e) => setData({...data, fullName: e.target.value})}
      onChange={e => setConfirmPassword(e.target.value)}
    />
    <TextField
  id="gender"
  label="Gender"
  variant="outlined"
  placeholder="Enter Your Current Password"
  fullWidth
  required
  value={userDetails.gender}
  margin="normal"
  // onChange={(e) => setData({...data, fullName: e.target.value})}
  onChange={e => setConfirmPassword(e.target.value)}
/>
 
    <TextField
  id="dob"
  label="Date of Birth"
  variant="outlined"
  placeholder="Enter Your Current Password"
  fullWidth
  required
  value={userDetails.dob}
  margin="normal"
  // onChange={(e) => setData({...data, fullName: e.target.value})}
  onChange={e => setConfirmPassword(e.target.value)}
/> */}


 
  <div >
  <Link to="/app/profile" > <Button variant="contained" color="primary"size="large">   Back</Button></Link> &nbsp;&nbsp;&nbsp;
    {isLoading ? (
      <CircularProgress size={26}  />
    ) : (
      <Button
        disabled={
            userDetails.fullName <= 4 ||
            userDetails.email <= 5 ||
            userDetails.phoneNumber <= 10 
        }
        onClick={() =>
          updateProfileHandler(
            currentPassword,
            confirmPassword,
            setIsLoading,
            setError,
          )
        }
        variant="contained"
        color="primary"
        size="large"
      >
        Profile Update
      </Button>
      
    )}
 
  </div>
  
</React.Fragment>
</div>
        </div>
    )
}