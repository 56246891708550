import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from "../../utils/axios";

const ViewBankInfo = () => {
  const [bankData, setBankData] = useState({})
  const [totalResponse, setTotalResponse] = useState()

        const userId = localStorage.getItem("user-id");
        const strId = JSON.parse(userId);
        const user = localStorage.getItem('user-name')
        // console.log(user);
        const userFormatted = JSON.parse(user);
      
      
      function getBankInfo () {
        axios.get(`bank-informations`)
          .then((res) => {
            if(res.data.payload){
            setTotalResponse(res.data.total)
            setBankData(res.data.payload[0])
            }
           
                
          })
          .catch((err) =>{
            console.log(err)
          })
    }
    
    
    useEffect(()=>{
    getBankInfo();
    },[totalResponse]);


    const [userDetails,setUserDetails] = useState({
        u_name: userFormatted.fullName,
        fullName: userFormatted.fullName,
        mobile: userFormatted.phoneNumber,
        email: userFormatted.email,
        bankName: '',
        accountName: '',
        branchName: '',
        routingNo: '',
        swiftCode: '',
    })

    const { t } = useTranslation();
    if (totalResponse === 0){
      
      return(
        <>
          <h2>No Data Added Yet. Click the Button Below to Add</h2>
          <Link to="/app/bank-informations" >
          <Button variant="contained" color="primary"size="large">  Add Bank Details</Button></Link>
        </>
      )
     
    }
  else if(totalResponse === 1){
  return (
    <div>
    <h2 style={{textAlign: "center"}}>{ t('BankInfo.View')}</h2>
    
    <Table className='center' style={{width:"50%", marginLeft: "auto", marginRight: "auto"}}>
  <TableHead>

  </TableHead>
  <TableBody >
    <TableRow>
      <TableCell style={{border:'1px solid black'}}>User Name</TableCell>
      <TableCell style={{border:'1px solid black'}}>{userDetails.fullName}</TableCell>
    </TableRow>

    <TableRow>
      <TableCell style={{border:'1px solid black'}}>Bank Name</TableCell>
      <TableCell style={{border:'1px solid black'}}>{bankData.bankName}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{border:'1px solid black'}}>Account Name</TableCell>
      <TableCell style={{border:'1px solid black'}}>{bankData.accountName}</TableCell>
    </TableRow>
   
    <TableRow>
      <TableCell style={{border:'1px solid black'}}>Account Number</TableCell>
      <TableCell style={{border:'1px solid black'}}>{bankData.accountNo}</TableCell>
    </TableRow>

    <TableRow>
      <TableCell style={{border:'1px solid black'}}>Branch Name</TableCell>
      <TableCell style={{border:'1px solid black'}}>{bankData.branchName}</TableCell>
    </TableRow>

    <TableRow>
      <TableCell style={{border:'1px solid black'}}>Routing Number</TableCell>
      <TableCell style={{border:'1px solid black'}}>{bankData.routingNo}</TableCell>
    </TableRow>

    <TableRow>
      <TableCell style={{border:'1px solid black'}}>Swift Code</TableCell>
      <TableCell style={{border:'1px solid black'}}>{bankData.swiftCode}</TableCell>
    </TableRow>
  </TableBody>
</Table> <br/>
<Link to="/app/bank-informations" >
<Button variant="contained" color="primary"size="large">   Profile Edit</Button></Link>
</div>
  
  
  )
  }
  if((totalResponse === 0)) 
  <h2>No data available</h2>
 }

export default ViewBankInfo