import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/Wrappers/Wrappers";
import FilterTableComponent from "./FilterTableComponent";

export default function ListInvestorsInformationComponent(){
    const { t } = useTranslation();

    return(
        <div>
            <PageTitle title={ t('investorsInformation.list.pageTitle')} button={<Link to="/app/inward-dividend/list"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('investorsInformation.list.listLink')}
    </Button></Link>} />

            <FilterTableComponent />
        </div>
    )
}