import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useContext } from "react";
import { MyTextField } from './MyTextField';
import ReduceContext from "./ReduceContext";

export const ReviewInformation = () => {
  const {data, setData} = useContext(ReduceContext);

  let dividendContent ='' ;
  
  if(data?.investorsdividendType === 'CashDividend' || data?.investorsdividendType === 'PublicSubscriptionMoney' || data?.investorsdividendType === 'FCCashDividend' || data?.investorsdividendType === 'FCPublicSubscriptionMoney'){
    dividendContent = <div>
      <Grid style={{padding:'20px'}} container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}> 
              
          <MyTextField label="Dividend Year" value={data.yearDividend} /> <br />
          <MyTextField label="Gross Dividend" value={data.grossDividend} /> <br />
          <MyTextField label="TDS" value={data.tds} /> <br />
          <MyTextField label="Net Dividend" value={data.netDividend} /> <br />
          <MyTextField label="Cheque Leaf (JPG or PNG or JPEG or PDF)" value={data?.cheque_leaf_file.length === 0 ? null: data?.cheque_leaf_file.name} /> <br />
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}>
          <MyTextField label="Bank Acount Name" value={data.Actitle} /> <br /> 
          <MyTextField label="Account Number" value={data.AccountNumber} /> <br />
          <MyTextField label="Bank Name" value={data.BankName} /> <br />
          <MyTextField label="Branch Name" value={data.BranchName} /> <br />
          <MyTextField label="Routing Number" value={data.RoutingNumber} /> <br />
            
          </Grid>
          </Grid>
    </div>
  }

  if(data?.investorsdividendType === 'StockBonusShare' || data?.investorsdividendType === 'StockRightShare' ){
    dividendContent = <div>
      <Grid container spacing={4}>
        <Grid item lg={6} md={6} sm={12} xs={12}>  
          <MyTextField label="Account Title" value={data.Actitle} /> <br />    
          <MyTextField label="Dividend Year" value={data.yearDividend} /> <br />
          <MyTextField label="No of Share" value={data.nofshare} /> <br />
          </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>   
          <MyTextField label="BO ID" value={data.boID} /> <br /> 
          <MyTextField label="TREC Holder" value={data.trecHolderName} /> <br />
          <MyTextField label="DP ID" value={data.dpID} /> <br />
            <MyTextField label="CDBL Share Transfer File (JPG or PNG or JPEG or PDF)" value={data.cdbl_share_transfer_file.length === 0 ? null: data.cdbl_share_transfer_file.name} /> <br />
          </Grid>
          </Grid>
    </div>
  }

  let requiredContent ='' ;
  if(data?.claimType === 'Self'){
    requiredContent = <div>
      <Grid style={{padding:'20px'}} container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}> 
            <MyTextField label="Investors NID File (JPG or PNG or JPEG or PDF)" value={data.nid_file.length === 0 ? null: data.nid_file.name} />
          <MyTextField label="Bo/Folio File (JPG or PNG or JPEG or PDF)" value={data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name} />
          
          {/* <MyTextField label="Additional File (JPG or PNG or JPEG or PDF)" value={data.additional_file.length === 0 ? null: data.additional_file.name} /> */}
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }

  if(data?.claimType === 'Successor'){
    requiredContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
            <MyTextField label="Investors NID File (JPG or PNG or JPEG or PDF)" value={data.nid_file.length === 0 ? null: data.nid_file.name} />
            <MyTextField label="Death Certificate File (JPG or PNG or JPEG or PDF)" value={data.death_certificate_file.length === 0 ? null: data.death_certificate_file.name} />
            <MyTextField label="Successor NID File (JPG or PNG or JPEG or PDF)" value={data.successor_nid_file.length === 0 ? null: data.successor_nid_file.name} />
            <MyTextField label="Inheritance letter (JPG or PNG or JPEG or PDF)" value={data.inheritance_certificate_file.length === 0 ? null: data.inheritance_certificate_file.name} />
            <MyTextField label="Court Certificate (JPG or PNG or JPEG or PDF)" value={data.court_certificate_file.length === 0 ? null: data.court_certificate_file.name} />
            <MyTextField label="Bo/Folio File (JPG or PNG or JPEG or PDF)" value={data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name} />
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }

  if(data?.claimType === 'Authorized'){
    requiredContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}> 
            <MyTextField label="Investors NID File (JPG or PNG or JPEG or PDF)" value={data.nid_file.length === 0 ? null: data.nid_file.name} />
            <MyTextField label="Authorized NID File (JPG or PNG or JPEG or PDF)" value={data.poa_nid_file.length === 0 ? null: data.poa_nid_file.name} />
            <MyTextField label="Authorized Letter File (JPG or PNG or JPEG or PDF)" value={data.poa_letter_file.length === 0 ? null: data.poa_letter_file.name} />
            <MyTextField label="Bo/Folio File (JPG or PNG or JPEG or PDF)" value={data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name} />
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }

  if(data?.claimType === 'PowerOfAttorney'){
    requiredContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
            <MyTextField label="Investors NID File (JPG or PNG or JPEG or PDF)" value={data.nid_file.length === 0 ? null: data.nid_file.name} />
            <MyTextField label="NID File of POA (JPG or PNG or JPEG or PDF)" value={data.poa_nid_file.length === 0 ? null: data.poa_nid_file.name} />
            <MyTextField label="POA Letter (JPG or PNG or JPEG or PDF)" value={data.poa_letter_file.length === 0 ? null: data.poa_letter_file.name} />
            <MyTextField label="Bo/Folio File (JPG or PNG or JPEG or PDF)" value={data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name} />
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }
 
    return (
      <Grid style={{padding:'35px'}}>
    <br /><br />
      <Typography variant='h1' align="center" style={{ backgroundColor: '#96B6C5', paddingBottom: '20px' }}>Intrductory Information</Typography>
            <Box bgcolor="#fff">
            <Grid style={{padding:'20px'}} container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}> 
            <MyTextField label="Company Name" value={data.tradeCode} /> <br />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}> 
            <MyTextField label="Claimant's Residency" value={data.residency} />
           
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}> 
            <MyTextField label="BO Type" value={data.boType} />
            </Grid>
           
            </Grid>
            </Box>
<Box>
    <Typography variant='h1' align="center" style={{ backgroundColor: '#96B6C5', paddingBottom: '20px' }}>Applicant's Information</Typography>
    </Box>
    <Box bgcolor="#fff">
<Grid style={{padding:'20px'}} container spacing={4}>
  <Grid item lg={6} md={6} sm={12} xs={12}>
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Claimant Type</InputLabel>
  
        <Select
        required
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={data.claimType}
        onChange={(e) => setData({...data, claimType: e.target.value})}
        disabled
        >
          <MenuItem value={"Self"}>Self</MenuItem>
          <MenuItem value={"Authorized"}>Authorized Person</MenuItem>
          <MenuItem value={"PowerOfAttorney"}>Power of Attorney</MenuItem>
          <MenuItem value={"Successor"}>Successor/Nominee (of deceased person)</MenuItem>
        </Select>
    </FormControl>
<h4 style={{marginTop:"15px"}}> </h4>
    <MyTextField label="Full Name" value={data.fullName} />
    <MyTextField label="Folio Number" value={data.folioNumber} />
    <MyTextField label="BO ID" value={data.boID} />
    <MyTextField label="Share Holding" value={data.shareholding} />
    <MyTextField label="Warrant Number" value={data.warrantNumber} />
              </Grid>
              
 <Grid item lg={6} md={6} sm={12} xs={12}>

       <FormControl fullWidth>
   <InputLabel id="demo-simple-select-label2">Dividend Type </InputLabel>
           <Select
                labelId="demo-simple-select-label2"
                id="demo-simple-select"
                value={data.investorsdividendType}
                disabled
                // onChange={(e) => setData({...data, investorsdividendType: e.target.value})}
              >
                <MenuItem value={"CashDividend"}>Cash Dividend</MenuItem>
                <MenuItem value={"PublicSubscriptionMoney"}>Public Subscription Money</MenuItem>
                <MenuItem value={"FCCashDividend"}>FC Cash Dividend</MenuItem>
                <MenuItem value={"FCPublicSubscriptionMoney"}>FC Public Subscription Money</MenuItem>
                <MenuItem value={"StockBonusShare"}>Stock Bonus Share</MenuItem>
                <MenuItem value={"StockRightShare"}>Stock Right Share</MenuItem>
              </Select>
            </FormControl>

            <h4 style={{marginTop:"15px"}}> </h4>

    <MyTextField label="Father Name" value={data.fathersName} />
    <MyTextField label="Mother Name" value={data.mothersName} />
    <MyTextField label="Phone Number" value={data.phoneNumber} />
    <MyTextField label="National Id" value={data.nid} />
    <MyTextField label="Date Of Birth" value={data.dob} />

   
        </Grid>
   <Grid item lg={12} md={12} sm={12} xs={12}>
    <MyTextField label="Address" value={data.address} />
        </Grid>            
  </Grid>
<h2> </h2>

          </Box>
          <Typography variant='h1' align="center" style={{ backgroundColor: '#96B6C5', paddingBottom: '20px' }}>Dividend/Payment Information</Typography>
          <Box bgcolor="#fff">
          {dividendContent}
          </Box>

          <Typography variant='h1' align="center" style={{ backgroundColor: '#96B6C5', paddingBottom: '20px' }}>Required Documents</Typography>
          <Box bgcolor="#fff">
          {requiredContent}
          
          {/* to be added */}
          </Box>
          <Box>
         
          </Box>
      </Grid>
    );
  };