import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import Widget from "../../components/Widget/Widget";
import useStyles from "../../../src/pages/dashboard/styles";
 

export default function GridItem({data, name, colors, url}){
  var classes = useStyles();
    return(
      <Grid  item  xl={3} lg={3} md={6} sm={12} >
       <Widget 
       title={name}
       url={url}
       upperTitle
       bodyClass={classes.fullHeightBody}
       className={classes.card}
     >
       <div className={classes.visitsNumberContainer}>
         <Grid container item alignItems={"center"}>
           <Grid item xs={12}>
         <Typography  size="xl" weight="medium" noWrap style={{ color:colors, fontWeight: "bold", fontSize: "30px" }}>
           {data}
         </Typography>
           </Grid>
         </Grid>
       </div>
      
     </Widget>
    </Grid>
    )
}