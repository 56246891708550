import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'bn'];

const options = {

  order: [ 'navigator', 'htmlTag', 'path', 'subdomain'],

  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], 

  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  htmlTag: document.documentElement,

  checkWhitelist: true
}

i18n
  .use(Backend) 
  .use(LanguageDetector)
  .use(initReactI18next) 

  .init({
    fallbackLng, 
    debug: false,
    whitelist: availableLanguages,
    detection: options,

    interpolation: {
      escapeValue: false
    },
  });

export default i18n;