import { Button, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axios";



export const EditForm = () => {

  const [isActiveButton, setIsActiveButton] = useState(false);
  const user = localStorage.getItem('user-name')
  const userFormatted = JSON.parse(user);
  const userType = userFormatted.userTypes[0]
  
  console.log(userType);
    const [data, setData] = useState({
        success: '',
        error: '',      
        investorinformationid: "",
        issuerid: "",
        inwarddividendid: "",
        yearDividend: "",
        fullName: "",
        fathersName: "",
        mothersName: "",
        nid:"",
        dob:"2023-02-20",
        warrantNumber: "",
        folioNumber: "",
        phoneNumber: "",
        shareholding: "",
        address: "",
        remarks: "",
        investorsdividendType: "",
        claimType: "",
        grossDividend: "",
        tds: "",
        netDividend: "",
        Actitle: "",
        BankName: " ",
        BranchName: " ",
        AccountNumber: "",
        RoutingNumber: "",
        cheque_leaf_file: "",
        nofshare: "",
        boID: "",
        trecHolderName: "",
        dpID: "",
        cdbl_share_transfer_file: "",
        nid_file: [],
        folio_bo_file: "",
        poa_nid_file: "",
        poa_letter_file: "",
        death_certificate_file: "",
        successor_letter_file: "",
        inheritance_certificate_file: "",
        court_certificate_file: "",
        successor_nid_file: "",
        issuer_approval_status: "",
        issuer_approval_comments: "",
        cmsf_comments: "" 
      });

    const params = useParams();
    const history = useHistory()
    useEffect(()=>{
        // axiosInstance.get(`claim-settlement/${params.id}`)
        axiosInstance.get(`claim-application/${params.id}`)
        .then((res) =>{
            if(res?.data?.payload){
                setData({
                    ...data,
                    claimType: res?.data?.payload.claimType,
                    investorsdividendType: res?.data?.payload.investorsdividendType,
                    Actitle: res?.data?.payload.Actitle,
                  });

            }
        })
        .catch((err) =>{
          alert("There was an error occured!")
        })
    },[])

    //console.log(params.id);

    function checkFileType(file) {
      const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
      if(allowedFileTypes.includes(file.type)){
        return true;
      } else{
        alert( " Plese check your upload file type. Only allow (PNG/JPG/JPEG/PDF)")
        return false
      }
    }


  let dividendContent ='' ;
  if(data?.claimType === 'Self'){
    dividendContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}> 
      <TextField
              id="nid-file"
              label="Investors NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, nid_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.nid_file.length === 0 ? null: data.nid_file.name}
            </div>
      <TextField
              id="boid-file"
              label="Bo/Folio File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Bo/Folio File"
              fullWidth
              required
              focused
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, folio_bo_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, folio_bo_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name}
            </div>
        
          
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }

  if(data?.claimType === 'Successor'){
    dividendContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
      <TextField
              id="nid-file"
              label="Investors NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File"
              fullWidth
              required
              focused
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, nid_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.nid_file.length === 0 ? null: data.nid_file.name}
            </div> 
      <TextField
              id="death-file"
              label="Death Certificate File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Death Certificate "
              fullWidth
              required
              focused
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, death_certificate_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, death_certificate_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.death_certificate_file.length === 0 ? null: data.death_certificate_file.name}
            </div>
      <TextField
              id="nid-file"
              label="Successor NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Successor NID File for "
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, successor_nid_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, successor_nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.successor_nid_file.length === 0 ? null: data.successor_nid_file.name}
            </div>
      <TextField
              id="inheritance-letter"
              label="Inheritance letter (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Inheritance letter File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, inheritance_certificate_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, inheritance_certificate_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.inheritance_certificate_file.length === 0 ? null: data.inheritance_certificate_file.name}
            </div>
      <TextField
              id="court-letter"
              label="Court Certificate (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Court Certificate File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, court_certificate_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, court_certificate_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.court_certificate_file.length === 0 ? null: data.court_certificate_file.name}
            </div>
      <TextField
              id="boid-file"
              label="Bo/Folio File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Bo/Folio File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, folio_bo_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, folio_bo_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name}
            </div>
        
          
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }
  if(data?.claimType === 'Authorized'){
    dividendContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}> 
      <TextField
              id="nid-file"
              label="Investors NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, nid_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.nid_file.length === 0 ? null: data.nid_file.name}
            </div>
      <TextField
              id="nid-file"
              label="Authorized NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Authorized NID File for "
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, poa_nid_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, poa_nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.poa_nid_file.length === 0 ? null: data.poa_nid_file.name}
            </div>
      <TextField
              id="authorized-letter"
              label="Authorized Letter File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Authorized Letter File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, nid_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.poa_letter_file.length === 0 ? null: data.poa_letter_file.name}
            </div>
      <TextField
              id="boid-file"
              label="Bo/Folio File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Bo/Folio File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, folio_bo_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, folio_bo_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
            {data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name}
            </div>
        
          
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }
  if(data?.claimType === 'PowerOfAttorney'){
    dividendContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
      <TextField
              id="nid-file"
              label="Investors NID File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, nid_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.nid_file.length === 0 ? null: data.nid_file.name}
            </div> 
      <TextField
              id="nid-file"
              label="NID File of POA (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your NID File for "
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, poa_nid_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, poa_nid_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.poa_nid_file.length === 0 ? null: data.poa_nid_file.name}
            </div>
      <TextField
              id="poa-letter"
              label="POA Letter (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your POA Letter File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, poa_letter_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, poa_letter_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.poa_letter_file.length === 0 ? null: data.poa_letter_file.name}
            </div>
      <TextField
              id="boid-file"
              label="Bo/Folio File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Bo/Folio File"
              fullWidth
              focused
              required
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, folio_bo_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, folio_bo_file: []});
                }
              } }
            /> 
            
            <div style={{marginBottom: "10px"}}>
                 {data.folio_bo_file.length === 0 ? null: data.folio_bo_file.name}
            </div>
        
          
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}></Grid>
          </Grid>
    </div>
  }
 
  const submitHandler =(e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append('Actitle',data.Actitle);
    formData.append('cdbl_share_transfer_file',data.cdbl_share_transfer_file);
    formData.append('nid_file',data.nid_file);
    formData.append('folio_bo_file',data.folio_bo_file);
    formData.append('poa_nid_file',data.poa_nid_file);
    formData.append('poa_letter_file',data.poa_letter_file);
    formData.append('death_certificate_file',data.death_certificate_file);
    formData.append('successor_letter_file',data.successor_letter_file);
    formData.append('inheritance_certificate_file',data.inheritance_certificate_file);
    formData.append('court_certificate_file',data.court_certificate_file);
    formData.append('successor_nid_file',data.successor_nid_file);
    formData.append('successor_letter_file',data.successor_letter_file);

    // axiosInstance.put(`claim-settlement/${params.id}`, formData)
    axiosInstance.put(`claim-application/${params.id}`, formData)
    .then((res)=>{
      setIsActiveButton(true);
      if(res?.data?.success){
        alert("Successfully updated!")
        if(userType === 'Super_Admin' || userType === 'CMSF_Admin' )history.push("/app/claim-settlement/claim/cmsf/list")
        if(userType === 'Issuer')history.push("/app/claim-settlement/claim/issuer/list")
        if(userType === 'Investor')history.push("/app/claim-settlement/claim/investors/list")
      }
    })
    .catch((err) =>{
      setIsActiveButton(false);
      alert("There was an error occred!")
    })

  }
    return (
      <>
      <form onSubmit={submitHandler}>

      {dividendContent}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        size="large"
        disabled={isActiveButton}
      >
        Submit
      </Button>
      </form>
            

      </>
    );
  };