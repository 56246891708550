import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';

function AgreeDisagreePopup(props) {
  const { open, onClose } = props;
  const [agree, setAgree] = useState(false);

  const handleClose = () => {
    onClose(false);
  };

  const handleAgree = () => {
    setAgree(true);
    onClose(true);
  };

  const handleDisagree = () => {
    setAgree(false);
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Agree or Disagree?</DialogTitle>
      <DialogContent>
        <p>Do you agree with the terms and conditions?</p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="medium" onClick={handleAgree} color="primary">
          Agree
        </Button>
        <Button variant="contained" size="medium" onClick={handleDisagree} color="secondary">
          Disagree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AgreeDisagreePopup;
