import React from 'react';
import './Footer.css';
import BsecLogo from './logo/BSEC.png';
import BaplcLogo from './logo/baplc.jpg';
import BbLogo from './logo/bb.png';
import BicmLogo from './logo/bicm.jpg';
import CcblLogo from './logo/ccbl.jpg';
import CdblLogo from './logo/cdbl.png';
import CseLogo from './logo/cse.png';
import DseLogo from './logo/dse.png';
import MofLogo from './logo/mof.jpg';
import NbrLogo from './logo/nbr.jpg';

function Footer() {
  return (
    <div style={{ }}>
      <p>
        <img src={MofLogo} alt="Logo 1" style={{ margin: '0 10px' }} />
        <img src={BsecLogo} alt="Logo 2" style={{ margin: '0 10px' }} />
        <img src={BbLogo} alt="Logo 3" style={{ margin: '0 10px' }} />
        <img src={DseLogo} alt="Logo 4" style={{ margin: '0 10px' }} />
        <img src={CseLogo} alt="Logo 5" style={{ margin: '0 10px' }} />
        <img src={BaplcLogo} alt="Logo 6" style={{ margin: '0 10px' }} />
        <img src={CdblLogo} alt="Logo 7" style={{ margin: '0 10px' }} />
        <img src={CcblLogo} alt="Logo 8" style={{ margin: '0 10px' }} />
        <img src={BicmLogo} alt="Logo 9" style={{ margin: '0 10px' }} />
        <img src={NbrLogo} alt="Logo 10" style={{ margin: '0 10px' }} />
      </p>
    </div>
  );
}

export default Footer;
