import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/Wrappers/Wrappers";
import { EditForm } from "./EditForm";


export default function EditLodgedClaimComponent(){
    const { t } = useTranslation();

    return(
        <div>
            
            <PageTitle title={ t('claimSettlement.editTableTitle')} button={<Link to="/app/claim-settlement/claim/investors/list"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('claimSettlement.claimList')}
    </Button></Link>} />

           <EditForm />
        </div>
    )
}