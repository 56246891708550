import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Container } from '@material-ui/core';
// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";

// context
import { useUserState } from "../context/UserContext";
import ForgetPasswordOtpVerify from "../pages/login/ForgetPasswordOtpVerify";
import RegistrationComponent from "../pages/login/RegistrationComponent";
import OtpPage from "../pages/login/otp/OtpPage";
import Success from "../pages/success/Success";
import ForgetPasswordComponent from './../pages/login/ForgetPasswordComponent';
import LoginComponent from './../pages/login/LoginComponent';
import OtpComponent from './../pages/login/OtpComponent';
import { makeStyles } from '@material-ui/core/styles';



export default function App() {
  // global
  var { isAuthenticated } = useUserState();
 

  return (
    <>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} />
        
        <PrivateRoute path="/app" component={Layout} />

        <PublicRoute exact path="/login" component={LoginComponent} />
        <PublicRoute exact path="/registration" component={RegistrationComponent} />
        <PublicRoute exact path="/forget-password" component={ForgetPasswordComponent} />
        <PublicRoute exact path="/otp-confirmation" component={OtpComponent} />
        <PublicRoute exact path="/forget-password-verify" component={ForgetPasswordOtpVerify} />
        <PublicRoute exact path="/login/otp/:id" component={OtpPage} />
        <PublicRoute path="/message" component={Success} />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
    </>
  );

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
