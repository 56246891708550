import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { default as axios } from '../../../utils/axios';
import './style.css';

const TimelineItem = ({ data }) => {

	const params = useParams();
	const handleEditClick = (id) => {
	  alert("Edit is disable by Developer "+id);
	};

	return (
	  <div className="timeline-item">
		<div className="timeline-item-content">
		  {/* <span className="tag" style={{ background: data.category.color }}> */}
		  <span className="tag" style={{ background: `${data.title == "Claim Initiated" ? "blue" : data.title == "Claim Received" ? "orange": (data.title == "OMC Approved" || data.title == "BOG Approved" || data.title == "Claim Approved" || data.title == "Claim settled" )? "green" : data.title == "Claim Canceled" ? "red" : data.title == "Canceled by CMSF" ? "red": data.title == "Claim Scrutinized" ? "orange" : "pink"}` }}>
			{data.title} 
		  </span>
		  <time>{new Date(data.createdAt).toLocaleDateString("en-GB")}</time>
		  <p>{data.comments}</p>
		  <span style={{ fontSize: "10px" }}>Responsibility: {data?.user?.fullName}</span>
		  {/* <span onClick={() => handleEditClick(data.id)}>Edit</span> */}
		  <span className="circle" />
		</div>
	  </div>
	);
  };

const Timeline = () =>{
	
	const params = useParams();
	const [payload, setPayload] = useState([]);
	const [userData, setUserData] = useState([]);
	const bodyData = {
		claimid: params.id
	}
	useEffect(()=>{
		// axios.post(`claim-timeline/list`,bodyData)
		axios.post(`claim-application-timeline/list-by-claim`,bodyData)
		.then((res) =>{
			if(res?.data?.success){
				setPayload(res.data?.payload);
			}
		})
		.catch((err) =>{
			alert("There was an error ocurred!")
		})

		// axios.post(`claim-timeline/user/${params.id}`)
		// .then((res) =>{
		//   setUserData(res.data)
		// })
		// .catch((err) => {
		// })

	},[])
	

	return(<>		
	{payload.length > 0 && (
				<div className="timeline-container">
					{payload.map((data, idx) => (
						<TimelineItem data={data} user={userData} key={idx} />
					))}
					
				</div>
			)
		}
		</>
	)
	
}	
  export default Timeline;