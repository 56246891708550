import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import ReduceContext from "./ReduceContext";


export const PersonalInformation = () => {

  const {data, setData} = useContext(ReduceContext);
 
    return (
      <>
            <TextField
              id="full-name"
              label="Full Name"
              variant="outlined"
              placeholder="Enter Your Full Name"
              fullWidth
              required
              value={data.fullName}
              margin="normal"
              onChange={(e) => setData({...data, fullName: e.target.value})}
            />
   
            <TextField
              id="email-address*"
              label="Email Address"
              variant="outlined"
              placeholder="Enter Your Email Address"
              fullWidth
              required
              type="email"
              margin="normal"
              value={data.emailAddress}
              onChange={(e) => setData({...data, emailAddress: e.target.value})}
            />

            <TextField
              id="phone-number"
              label="Phone Number"
              variant="outlined"
              placeholder="Enter Your Phone Number"
              type="number" 
              required             
              fullWidth
              margin="normal"
              value={data.phoneNumber}
              onChange={(e) => setData({...data, phoneNumber: e.target.value})}
            />

            <TextField
              id="nid"
              label="National Id"
              variant="outlined"
              placeholder="Enter Your National Id"
              required
              type="number"
              fullWidth
              margin="normal"
              value={data.nId}
              onChange={(e) => setData({...data, nId: e.target.value})}
            />

            <TextField
              id="date-of-birth"
              label="Date Of Birth"
              type="date"
              required
              variant="outlined"
            //   placeholder="Enter DateOfBirth"
              fullWidth
              margin="normal"
              value={data.dateOfBirth}
              onChange={(e) => setData({...data, dateOfBirth: e.target.value})}
            />


            <FormControl>
                  <FormLabel required id="demo-row-radio-buttons-group-label" >Gender</FormLabel>
                  <RadioGroup
                    row aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group">
                    <FormControlLabel value="Female" checked={ data.gender == "Female"} onChange={(e) => setData({...data, gender: e.target.value})} control={<Radio />} label="Female" />
                    <FormControlLabel value="Male" checked={ data.gender == "Male"} onChange={(e) => setData({...data, gender: e.target.value})} control={<Radio />} label="Male" />
                    <FormControlLabel value="Other" checked={ data.gender == "Other"} onChange={(e) => setData({...data, gender: e.target.value})} control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>

    <h2> </h2>

      </>
    );
  };

  