import React from 'react'
import FilterTableComponent from './FilterTableComponent'
import { Switch } from '@material-ui/core';
import { useState } from 'react';
import { FormControl, FormControlLabel } from '@material-ui/core';

const UserPermission = () => {
  const [state, setState] = useState(false)

  function handleSwitchChange (e) {
    setState(e.target.checked);
    console.log(e.target.checked)
    // Add actions here for when the switch is triggered
  };

  var text;

  if (state) {
    text = 'on';
  } else {
    text = 'off';
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  
  return (
    <div>
      <h2 style={{marginBottom:'30px'}}>User Permissions</h2>
     {/* <div>
    
      {text}
      <FormControl>
          <FormControlLabel
           
            control={<Switch
              checked={state}
              onChange={handleSwitchChange}
              color="primary"
            />}
            
           
          />
          </FormControl> 
    </div> */}
        <FilterTableComponent/>
    </div>
  )
}

export default UserPermission