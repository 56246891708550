import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import FilterTableComponent from "./FilterTableComponent";


export default function ReportOfLedger(){
 
    const { t } = useTranslation();
   
// jijfidjf
    return(
        <>
            <PageTitle title={ "Ledger wise Investor Report"}  />
            <FilterTableComponent />
        </>
    )
}