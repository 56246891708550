// import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "@material-ui/core";
import { ViewListOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../utils/axios";
import Table from './Table';

function FilterTableComponent() {
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };

    const paramsData ={
        page: currentPage,
        take: showPageSize,
    }

    useEffect(() =>{
        axios.post(`investor-informations/list`, paramsData)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    },[])

    
    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.post('investor-informations/list', paramsData)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
        })
        .catch((err) =>{
            alert("There was a error occured!")
        })
    }
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'All Investor Table',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'autoId',
                    },
                    {
                        Header: 'Full Name',
                        accessor: 'fullName'
                    },
                    {
                        Header: 'BO Id',
                        accessor: 'boID'
                    },
                    {
                        Header: 'Folio Id',
                        accessor: 'folioNumber'
                    }, 
                    {
                        Header: 'Dividend Type',
                        accessor: 'investorsdividendType',
                        Cell: ({row}) => <>
                        {row.values.investorsdividendType === 'CashDividend' ? (
                            'Cash Dividend'
                          ) : row.values.investorsdividendType === 'FCCashDividend' ? 
                          (
                             `FC Cash Dividend`

                          ) : row.values.investorsdividendType === 'StockRightShare' ? (
                             `Stock Right Share`

                          ) : row.values.investorsdividendType === 'StockBonusShare' ? (
                              `Stock Bonus Share`
                          ) : row.values.investorsdividendType === 'PublicSubscriptionMoney'? (
                              `Public Subscription Money`

                         ) : row.values.investorsdividendType === 'FCPublicSubscriptionMoney' ? (
                              `FC Public Subscription Money`

                          )  : (
                             <></>
                         )}</>
                    },
                    {
                        Header: 'Net Dividend',
                        accessor: 'netDividend'
                    },
                    {
                        Header: 'No of Share',
                        accessor: 'nofshare'
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        Cell:({ value }) => {
                            return <>
                            {value === 1 ? "Approved" : value === 0 ? "Not Approved" : value === 4 ? "Declined" :value === 2 ? "Claim Lodged" :value === 3 ? "Settled" :value === 5 ? "CMSF Declined" :''}</>;
                          },
                    },
                    {
                        Header: 'Action',
                        Cell: ({row}) => <>
                        <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/investor-informations/views/${row.original.id}`}> <Button
                         style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='View' variant="contained" color="primary" 
                        >
                                <ViewListOutlined />
                            </Button></Link>
                        </>
                    }
                    
                   
                ],
            },
        ],
        []
    )

    return (
        <div>
            <Table 
            getDataSync={getDataSync} 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            />
           
        </div>
    )
}

export default FilterTableComponent;