import React from 'react';
import { Link } from "react-router-dom";

const pageofUauth = () => {
  return (
    <>
    <Link to="/404"></Link>
    </>
  )
}

export default pageofUauth