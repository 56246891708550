import { Grid, Paper } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

  
  // styles
  import useStyles from "./styles";
  
  // components
import { useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import axios from "../../utils/axios";
import { getUserId } from "../../utils/strings/userString";
import AreaCharts from "./charts/AreaCharts";
import BarchartStock from "./charts/BarchartStock";
import GridItem from "./GridItem";


const userID = getUserId();
  

  
  export default function DashboardManagement(props) {
    const { t } = useTranslation();
    var classes = useStyles();
    var theme = useTheme();
    const [loadingInvestors, setLoadingInvestors] = useState(true);
    const [totalFileUpload, setTotalFileUpload] = useState("")
    const [totalInvestos, setTotalInvestors] = useState("")
    const [issuer, setIssuer] = useState("")

    const [realData, setRealData] = useState([])
    const [realDataCash, setRealDataCash] = useState("")
    const [realDataStock, setRealDataStock] = useState("")
    const [realDataFC, setRealDataFC] = useState("")
    const [allInvestors, setAllInvestors] = useState("")

    const [areaChartData, setAreChartData] = useState("")
    const [barChartData, setBarChartData] = useState("")
    const [matches, setMatches] = useState(
      window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(()=>{
      window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches( e.matches ));
      axios.get('dashboard/management/card')
          .then((res) =>{
              if(res.data.success){
                //setRealData(res?.data?.payload?.totalInvetorsAndIssuers)
                setRealData(res?.data?.payload)
                //console.log(realData)
                setRealDataCash(res?.data?.payload[0])
                console.log(realDataCash)
                setRealDataStock(res?.data?.payload[2])
                console.log(realDataStock)
                setRealDataFC(res?.data?.payload[1])
                setAllInvestors(res?.data?.payload[3])
                //console.log(realDataFC)
                
              }
          })
   
          axios.get(`/dashboard/management/chart/cash`)
              .then((res) =>{
                  if(res.data.success){
                    setAreChartData(res?.data?.payload?.chart)
                    setBarChartData(res?.data?.payload?.chart)
                  }
              })
              .catch((err) =>{
              })
  
    },[]);


    const data = [
      
      {
        "name": "May",
        "uv": 1890,
        "pv": 4800
      },
      {
        "name": "Jun",
        "uv": 2390,
        "pv": 3800
      },
      {
        "name": "Jul",
        "uv": 3490,
        "pv": 4300
      },{
        "name": "Aug",
        "uv": 4000,
        "pv": 2400
      },
      {
        "name": "Sep",
        "uv": 3000,
        "pv": 1398
      },
      {
        "name": "Oct",
        "uv": 2000,
        "pv": 9800
 
      },
      
    ]
    
    return (
      <>
        <PageTitle title={t('Dashboard.title2')}  />
          <Grid style={{marginTop:"30px"}}><h4>{t('Dividends.Cash')}</h4></Grid>
            
                <Grid container spacing={2} >
                  <>
                  <GridItem name="Compliant Issuers" data={realDataCash?realDataCash.issuersubmitted : 'Loading'} url={'/app/inward-dividend/list'}/>
                  <GridItem name="Submitted Investors" data={allInvestors?allInvestors.totalcashinvestors : 'Loading'}  url={'/app/investor-informations'} />
                  <GridItem name="Claims Submitted" data={(realDataCash?realDataCash.tatalClaims:'Loading')} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  
                  <GridItem name="Received by Issuer" data={realDataCash?realDataCash.receivedIssuer:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem name="Approved by Issuer" data={realDataCash?realDataCash.approvedByIssuer:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem name="Declined by Issuer" data={realDataCash?realDataCash.declinedByIssuer:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  
                  <GridItem name="Received by CMSF" data={realDataCash?realDataCash.receivedByCmsf:0} url={'/app/inward-dividend/list'}/> 
                  <GridItem name="Scrutinized by CMSF" data={realDataCash?realDataCash.scrutinizedByCmsf:'Loading'} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem name="Declined by CMSF" data={realDataCash?realDataCash.declinedByCmsf:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  
                  
                  <GridItem name=" Approved by OMC" data={realDataCash?realDataCash.approvedByOmc:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem 
                  // name=" Approved by BOG" 
                  name="Approved by Chairman, BOG"
                  data={realDataCash?realDataCash.approvedByBog:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem name="Claims Settled" data={realDataCash?realDataCash.settledByCmsf:'Loading'} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  
                  {/* <GridItem name="Claim Unattended by Issuer" data={realDataCash?realDataCash.lodgedClaim:0} url={'/app/claim-settlement/claim/cmsf/list'}/> */}
                  {/* <GridItem name="Claims Queried" data={realDataCash?(Math.abs(realDataCash.approvedByIssuer - realDataCash.declinedByCmsf)):'Loading'} url={'/app/inward-dividend/list'} />  */}
                  <h3 style={{margin:"40px", marginLeft:"500px",  marginBottom:"20px", color:"black"}}>{t('Project.CMSF claims settlement statistics')}</h3>
                  </>
                  </Grid>
          
              

{/*------------------------------------- CASH GRAPH GRID ---------------------------------------------*/}
          {/* <Card style={{backgroundColor:'#white'}}> */}
            {/* <Grid >
            <Grid >
              {areaChartData?
              <BarchartStock data={barChartData}/> : <BarchartStock data={data}/>}
            </Grid>
            <Grid container spacing={2}>
              {areaChartData?
              <><AreaCharts data={areaChartData} /></> 
              :
              <><AreaCharts data={data} /> </>}
            </Grid>
            </Grid> */}
            <Grid container spacing={3} >
              <Paper  variant="outlined"  style={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '7px',
                      width: matches ?'48%':'90%',
                       borderRadius: 20, 
                       borderColor: '#a4de6c',
                       borderBottomWidth: '10px',
                       
                    }}>
                <Grid >
                <BarchartStock data={areaChartData}/>
                </Grid>
              </Paper>

              <Paper  variant="outlined" square={true} style={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '7px',
                      width: matches ?'48%':'90%',
                       borderRadius: 20, 
                       borderColor: '#82ca9d',
                       borderBottomWidth: '10px',
                      
                  }}>
                <Grid>
                  <AreaCharts data={areaChartData}/>
                  {/* <RadialBarChart/> */}
                </Grid>
              </Paper>
           </Grid>
              {/* </Card> */}
          
         

{/* -----------------------------------------------STOCK ----------------------------------------------- */}
      
  <Grid style={{marginTop:"80px"}}><h4>{t('Dividends.Stock')}</h4></Grid>
              <>
                <Grid container spacing={3} >
                  <GridItem name="Issuer Companies" data={realDataStock?realDataStock.issuersubmitted:'Loading'} url={'/app/inward-dividend/list'}/>
                  <GridItem name="Total Investors" data={(allInvestors?allInvestors.totalstockinvestors: 'Loading')} url={'/app/investor-informations'}/>
                  <GridItem name="Claims Submitted" data={realDataStock?realDataStock.tatalClaims:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem name="Received by Issuer" data={realDataStock?realDataStock.receivedIssuer:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem name="Verified by Issuer" data={realDataStock?realDataStock.approvedByIssuer:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem name="Declined by Issuer" data={realDataStock?realDataStock.declinedByIssuer:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  
                  <GridItem name="Received by CMSF" data={realDataStock?realDataStock.receivedByCmsf:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem name="Declined by CMSF" data={realDataStock?realDataStock.declinedByCmsf:0} url={'/app/claim-settlement/claim/cmsf/list'}/>

                  <GridItem name=" Approved by OMC" data={realDataStock?realDataStock.approvedByOmc:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem 
                  // name=" Approved by BOG" 
                  name=" Approved by Chairman, BOG" 
                  data={realDataStock?realDataStock.approvedByBog:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                  <GridItem name="Claims Settled" data={realDataStock?realDataStock.settledByCmsf:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                 
                  <GridItem name="Claim Unattended by Issuer" data={realDataStock?realDataStock.lodgedClaim:0} url={'/app/claim-settlement/claim/cmsf/list'}/>
                 
                  {/* <GridItem name="Claims Queried" data={Math.abs(realDataStock.approvedByIssuer - realDataCash.declinedByCmsf)} url={'/app/claim-settlement/claim/cmsf/list'}/>  */}
                </Grid>
              </> 
           
{/*------------------------------------------ STCK GRAPH GRID ---------------------------------------------*/}    
               {/* <Card style={{backgroundColor:'white'}}>
              
              to be opened the following in future
                
                <Grid container spacing={2} style={{marginTop:'50px'}}>
                  <Paper style={{marginTop:"100px", marginBottom: "50px"}}>
                  <LineComparison/>
                  </Paper>
                </Grid>
              
              </Card>  */}
            
            {/* <Grid>
                    <Grid container spacing={2}>
                      <Paper style={{marginTop:"35px"}}>
                      <CustomDataLabelsStock/>
                      </Paper>
                    </Grid>
                    <Box>
                      <Grid container spacing={4}>
                        <AreaCharts/>
                        <BarchartStock/>
                      </Grid>
                    </Box>
            </Grid> */}
      </>
    );
  }
