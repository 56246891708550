import { Button, TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import axios from "../../../../utils/axios";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'right',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    height: "250px",
    width:"500px",
    padding: theme.spacing(2, 2, 2),
    //overflow: 'scroll',
  },
}));

export default function Create({ open,data, control,getData}) {
  const classes = useStyles();
 
  const { t } = useTranslation();

  const [title, setTitle] = useState('')
  const [data2, setData2] = useState('')

  const changeHandler = e => {
    console.log(e.target.value)
    setTitle( prevValues => {
        return { ...prevValues,[e.target.name]: e.target.value}
        
     })
    
}

useEffect(()=>{
 
},[data2]) 

  const submitHandler = (e) => {
          e.preventDefault();
          axios.post('/roles',title     
          ).then((res)=>{
            setData2(res)
            Swal.fire({
              icon: 'success',
              title: 'Role added successfully',
              showConfirmButton: false,
              confirmButtonText:<i class="fa fa-thumbs-up">Great!</i> ,
              timer: 1000
            }) 
            console.log(title);
            getData();

        
          }).catch((err,res)=>{
              console.log(err);
               console.log(res);
              Swal.fire({
                icon: 'error',
                //title: err.response.data.errorMessages,
                title: err.request.statusText,
                showConfirmButton: false,
                confirmButtonText:<i class="fa fa-thumbs-up">Great!</i> ,
                timer: 3000
              }) 
          });
          ;
         
          
         control();
          
         setTitle('')
   
  };

  return (
    <div>
    
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={control}
        disableEscapeKeyDown
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h1 >{ t('Roles.newRoleAdd')}</h1>
            <div>
                
                <TextField 
                    helperText="*Required Field"
                    required
                    id="newRole"
                    name="title"
                    label="Input role title"
                    fullWidth
                    autoComplete="id"
                    variant="outlined"
                   onChange={changeHandler}
        
                />

<Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                 variant="contained"
                 color="primary"
                 type="submit"
                 size="large"
                 onClick={submitHandler}
                disabled={title===''}
                 
               >
                 { t('Issuer.submitButton')}
               </Button>

                 <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                 variant="contained"
                 color="primary"
                 size="large"
                 onClick={control}
               >
                  { t('Issuer.cancelButton')}
               </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
