import ProfileView from "./ProfileView";


export default function Profile() {
    return (
        <div style={{minHeight:"100%"}}>
            <ProfileView />
           
        </div>
    )
}