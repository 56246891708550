import { Button, Grid } from '@material-ui/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ResponsiveContainer } from 'recharts';
import Golden50 from '../../../reports/ClaimReport/50y_logo.jpg';
import ReportStyle from '../../../reports/ClaimReport/Report.module.css';
import styles from '../../../reports/ClaimReport/Table.module.css';
import CmsfLogo from '../../../reports/ClaimReport/logo.png';
import Mujib100 from '../../../reports/ClaimReport/mujib_100_logo.jpg';

// eifie

const UiOfLedgerReport = ({ data ,inwardDividendType,grandTotal,divTypeFromResponse}) => {

  
  const params = useParams();
  const pdfRef = useRef();
  const pdfRef2 = useRef();




  // const generatePdf = () => {

  //   const pdf = new jsPDF('p', 'mm', 'a4');
  
  //   html2canvas(pdfRef.current, {
  //     scale:2,
  //     useCORS: true,
  //   }).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     pdf.addImage(imgData, 'JPEG', 10, 20, 170, 257); 
  //     pdf.save('Claim_Report.pdf');
  //   });
  // };
  const generatePdf = () => {

    const pdf = new jsPDF('p', 'mm', 'a4'); // Create a new PDF document
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();


  html2canvas(pdfRef.current, {
        scale:2,
        useCORS: true,
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        // pdf.addImage(imgData, 'JPEG', 10, 20, 170, 257); 
        // pdf.save('Claim_Report.pdf');
        const imgWidth = canvas.width;
  const imgHeight = canvas.height;
  const aspectRatio = imgHeight / imgWidth;
  const pdfAspectRatio = pdfHeight / pdfWidth;
  const scaleFactor = pdfWidth / imgWidth;
  let remainingHeight = imgHeight;
  let position = 0;
  while (remainingHeight > 0) {
    // Calculate the current height of the content to be added to the current page
    const currentHeight = Math.min(remainingHeight, pdfHeight / scaleFactor);

    // Add the current portion of the content to the PDF
    pdf.addImage(
      imgData,
      'PNG',
      0,
      position,
      pdfWidth,
      pdfWidth * aspectRatio * (currentHeight / imgHeight),
      undefined,
      'FAST'
    );

    // Reduce the remaining height
    remainingHeight -= currentHeight;
    position = -((imgHeight - remainingHeight) / scaleFactor);

    // Add a new page if there is still remaining content
    // if (remainingHeight > 0) {
    //   pdf.addPage();
    // }
  }
  pdf.save('Ledger_Report.pdf');
  
      });
  };


  

  return (
    <ResponsiveContainer width="85%">
    <Grid ref={pdfRef2} className={ReportStyle.form}> 
    {/* Page- 1 */}
    </Grid>

    <Grid ref={pdfRef} className={ReportStyle.form}>

      <Grid className={ReportStyle.headerLogo}>
          <div className={ReportStyle.CmsfLogo}>
        {/* <img className={ReportStyle.Golden50} src={Golden50} alt='CMSF Logo' /> */}
          </div>
          <div className={ReportStyle.CmsfLogo}>
        <img className={ReportStyle.CmsfLogo} src={CmsfLogo} alt='CMSF Logo' />
          </div>
          <div className={ReportStyle.Mujib100}>
        {/* <img className={ReportStyle.Mujib100} src={Mujib100} alt='CMSF Logo' /> */}
          </div>

      </Grid>
      <Grid className={ReportStyle.address}>
        <span>Dhaka Chamber Building(3rd Floor), 65-66 Motijheel C/A, Dhaka-1000</span> <br />
        <span>Tel: +880241052430, Email: claim@cmsfbd.org</span>
      </Grid>

      <h1><strong>Investor-wise Ledger Report</strong></h1>
      
      
      
    
      <h5 className={ReportStyle.GategoryName}> Investor wise Ledger Report</h5>
      <h5>The {divTypeFromResponse==='Cash'? 'Cash Dividend':'Stock Dividend'} status of the Investor is as follows:</h5>
     
      <Grid className={ReportStyle.GategoryName2}>
      <h1></h1>
      <div>
        <table>
          <thead>
            <tr>
            <th className={styles.td} colSpan={3}>Sl</th>
              <th className={styles.td} colSpan={3}>Ledger No</th>
              <th className={styles.td} colSpan={3}>Ledger Name</th>
              <th className={styles.td} colSpan={3}>BO Id</th>
              <th className={styles.td} colSpan={3}>Folio</th>
              <th className={styles.td} colSpan={3}>Transaction Date</th>
              <th className={styles.td} colSpan={3}>Cr </th>
              <th className={styles.td} colSpan={3}>Dr </th>
              {/* <th className={styles.td} colSpan={3}>{inwardDividendType==='Cash'? 'Balance ':'Balance' } </th> */}
              <th className={styles.td} colSpan={3}>Balance</th>
              


          
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} >
                <td className={styles.td} colSpan={3}>{index+1}</td>
               <td className={styles.td} colSpan={3}>{item.ldgacnot!==null?item.ldgacnot:0}</td>
               <td className={styles.td} colSpan={3}>{item.ldgacnot!==null?item.investorname:0}</td>
               <td className={styles.td} colSpan={3} >{item.boid}</td>
               <td className={styles.td} colSpan={3} >{item.folio}</td>
               <td className={styles.td} colSpan={3} >{item.tdate}</td>
               <td className={styles.td} colSpan={3} >{item.cr}</td>
               <td className={styles.td} colSpan={3} >{item.dr}</td>
               <td className={styles.td} colSpan={3} >{item.bal}</td>
 
              </tr>
            ))}
            {/* <tr>
           
            <td className={styles.td} colSpan={6} style={{fontSize:'1.3rem', textAlign:'Center',fontWeight:'bold'}}>Total</td>
            <td className={styles.td} colSpan={3} style={{fontSize:'1rem', fontWeight:'bold'}}>{(grandTotal.nst).toFixed(2)}</td>
            <td className={styles.td} colSpan={3} style={{fontSize:'1rem', fontWeight:'bold'}}>{(grandTotal.sett).toFixed(2)}</td>
            <td className={styles.td} colSpan={3} style={{fontSize:'1rem', fontWeight:'bold'}}>{(grandTotal.outs).toFixed(2)}</td>
           
          </tr> */}
          </tbody>
        </table>
      </div>
    </Grid>
    
      {/* <h5 className={ReportStyle.GategoryName}>Claimant Information</h5>
    
    <h1></h1>
    <h1></h1> */}

    {/* <h5 className={ReportStyle.GategoryName}>Dividend Information</h5> */}
    
    <br />
    <br />


    {/* <h5 className={ReportStyle.GategoryName}>Payment Information</h5>
    <div>
      
    </div> */}

      <br />
     

      <Grid className={styles.fileListContainer}>
      
      
    </Grid>

      <Grid className={ReportStyle.msgDiv}>
        <span className={ReportStyle.msg}><i>This is a system generated report.</i></span>
      </Grid>

    </Grid>
    
     <Button 
     style={{ margin: '10px', marginTop: '20px' , backgroundColor: '#45CFDD'}}  
     variant="contained" 
     color="default" 
     onClick={generatePdf}>Download Report</Button>
    </ResponsiveContainer>
  );
};

export default UiOfLedgerReport;
