import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button } from "../../components/Wrappers/Wrappers";
import FilterTableComponent from "./FilterTableComponent";

export default function ClaimListInvestors(){
    const { t } = useTranslation();
    const userName = localStorage.getItem('user-specific-name');
  const strUserName = JSON.parse(userName);

    return(
        <div>
            <PageTitle title={ t('claimListInvestors.title') + ''  + strUserName} button={<Link to="/app/search-dividend"> 
            {/* <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('claimSettlement.sideBarMainTitle')}
    </Button> */}
    </Link>} />
    
            <FilterTableComponent />
        </div>
    )
}