import { Button, FormControl, Grid, InputLabel, MenuItem, Select,OutlinedInput } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { useGetIssuerInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import axios from "../../../utils/axios";
import {ResponsiveContainer } from 'recharts';
import './test.css'



const options = [{ tradecode: "No Data Available", name: "" },
// { tradecode: "No Data Available", name: "" },
];


function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize,setNumberOfpage }) {
    const { t } = useTranslation();
    const [newData, setNewData] = useState([]);
    const [key, setKey] = useState(Date.now());
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
      )

    useEffect(()=>
  {axios.get('./issuer/get-info')
  .then((res)=>{
    setNewData(res.data.payload)
  })
  .catch((err) =>{
    alert(err.response.data.message)
  })
},[])

  // console.log(newData)

    const newData2 = newData?newData:options
    var sortedNewData = newData2
    // if (newData2) {
    //   sortedNewData = newData2.slice().sort((a, b) => {
    //     if (a.tradecode < b.tradecode) {
    //       return -1;
    //     }
    //   });
     
    // }


    const defaultColumn = React.useMemo(
        () => ({
            Filter: "",
        }),
        []
    )
    // const {  data:issuer} = useGetIssuerInfoQuery();
    // const issuerData = issuer?.payload
    //console.log(issuerData);
    // const issuerData2 = issuerData;
    //console.log(issuerData2);
    // var sortedIssuerData = issuerData
    // if (issuerData2) {
    //  sortedIssuerData = issuerData2.slice().sort((a, b) => {
    //     if (a.tradecode < b.tradecode) {
    //       return -1;
    //     }
    //   });
     
    // }
    
//console.log(sortedIssuerData);

   // console.log(sortedIssuerData);
        //const sortedIssuerData =  issuerData.sort((a, b) => {return a.autoId - b.autoId })
    
    const userFormatted = JSON.parse(localStorage.getItem('user-name'));
    const userType = userFormatted.userTypes[0]
  
    const ITEM_HEIGHT = 100;
    const ITEM_PADDING_TOP = 8;
    
    const MenuProps = {
        PaperProps: {
          style: {
            //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 350,
            // height:50,
          
           
          },
        },
      };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const nextPageHandler = () =>{        
        if(numberOfPage > currentPage){
            setCurrentPage(currentPage+1);      
        }
    }

    const prevPageHandler = () =>{        
        if(currentPage > 1){
            setCurrentPage(currentPage-1);
        }
    }
    
    const handleFirstPage = () =>{
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }

    const handleLastPage = () =>{
        if(currentPage !== numberOfPage){
            setCurrentPage(numberOfPage);
        }
    }

    const pSize= [5, 10, 20, 30, 40, 50];

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [issuerValue, setIssuerValue] = useState("")
   // const [totalCashValue, setTotalCashValue] = useState("")
    //const [totalFcValue, setTotalFcValue] = useState("")
    //const [totalStockValue, setStockValue] = useState("")
    const [remarksValue, setRemarksValue] = useState("")

    const [totalCashLower, setTotalCashLower] = useState("")
    const [totalCashUpper, setTotalCashUpper] = useState("")

    const [totalFCLower, setTotalFCLower] = useState("")
    const [totalFCUpper, setTotalFCUpper] = useState("")

    const [totalStockLower, setTotalStockLower] = useState("")
    const [totalStockUpper, setTotalStockUpper] = useState("")

    const dateValue = startDate && endDate ? [startDate, endDate] : null;
    const totalCashValue = totalCashLower && totalCashUpper ? [totalCashLower, totalCashUpper] : null;
    const totalFcValue = totalFCLower && totalFCUpper ? [totalFCLower, totalFCUpper] : null;
    const totalStockValue = totalStockLower && totalStockUpper ? [totalStockLower, totalStockUpper] : null;

    const [statusValue,setStatusValue]= useState("")
    const paramsData = {
        page: currentPage,
        take: showPageSize,
        issuerid: issuerValue,
        status:statusValue,
        total_cash: totalCashValue,
        total_fc: totalFcValue,
        total_stock: totalStockValue,
        remarks: remarksValue,
        date: dateValue ,
    }

    const searchResethandler = () =>{
        setStartDate(null);
        setEndDate(null);
        setKey(Date.now());
        //setTotalCashValue("");
        setTotalCashLower("")
        setTotalCashUpper("")
        //setTotalFcValue("");
        setTotalFCLower("")
        setTotalFCUpper("")
       // setStockValue("")
       setTotalStockLower("")
       setTotalStockUpper("")
        setRemarksValue("")
        setStatusValue("")
        setIssuerValue("");
        
    }
    
    
    const goToPageHandler = (e) =>{
        if( e === 0) {   
        }
        else if(e < 0){
        }
        else if( e => 1){
            setCurrentPage(e);
        }else{
            setCurrentPage(1); 
        }
    }

    useEffect(()=>{
            axios.post(`inward-dividend/list`,paramsData)
        .then((res) =>{
            setDataStore(res.data.payload)
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));

    },[remarksValue,startDate,endDate, issuerValue, totalCashLower, totalCashUpper, totalFCLower, totalFCUpper, totalStockLower, totalStockUpper,statusValue, setDataStore,setCurrentPage,setNumberOfpage,showPageSize,currentPage])

//console.log(paramsData);

useEffect(()=>{
    setCurrentPage(1)
},[issuerValue,startDate,endDate,totalCashLower,totalCashUpper,totalFCLower,totalFCUpper,totalStockLower,totalStockUpper,statusValue])

    return (
        <div>
            <Grid container spacing={1}>
            { userType !== 'Issuer' ?
                <>
          <Grid item lg={3} md={3} sm={3} xs={12}>
          
                <FormControl fullWidth >
                
                    {/* <InputLabel id="issuer-id" >Select Issuer</InputLabel> */}
                    
                    {/* <Select 
                        required
                        labelId="issuer-id"
                        id="demo-simple-select"
                        value={issuerValue}
                        label="Select Issuer"
                        onChange={(e) => setIssuerValue(e.target.value)}
                             
                        MenuProps={MenuProps}  
                    >   
                        {sortedIssuerData?.map((i)=><MenuItem value={i.id} key={i.id}>({i.tradecode}){i.name}</MenuItem>)}
                    </Select> */}

            <Autocomplete
             key={key}
                //  freeSolo
                //   value={issuerValue}
                // value={issuerValue=== '' ? {label: ''} : {issuerValue}}
                label="Select Issuer"
                // onChange={handleIssuerIDandTradecode}
                blurOnSelect={true}
                onChange={(event, newValue) => {
                    setIssuerValue( newValue.id);
                //   console.log(issuerValue)
                }}
                disableClearable
                // options={top100Films}
                // getOptionLabel={(option) => "(" + option.title + ") " +option.year}
                options={sortedNewData?sortedNewData:newData?newData:options}
                getOptionLabel={(option) => option.name +" (" + option.tradecode + ") " }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Issuer Name"
                    placeholder={(option) => (issuerValue === "" ? "choose country" : `${option.name} (${option.tradecode})`)}
                    // margin="normal"
                    // variant="outlined"
                    // InputProps={{ ...params.InputProps, type: 'search' }}
          />
                )}
            />


                    
                </FormControl> 
            </Grid>
        </>:""}
                        

<Grid item lg={2} md={2} sm={2} xs={12}>
          
          <FormControl fullWidth >
          
                  <InputLabel id="issuer-id" >Search by dividend status</InputLabel>
                    <Select 
                      labelId="status"
                      id="status"
                      value={statusValue}
                      label="Select status"
                      onChange={(e) => setStatusValue(e.target.value)}  
                      >
                      <MenuItem value='0' key='Initiated'>Initiated</MenuItem>
                      <MenuItem value='1' key='Received by Issuer'>Verified by Issuer</MenuItem>
                      <MenuItem value='2' key='Approved by Issuer'>Approved by CMSF</MenuItem>
                      <MenuItem value='4' key='Received by CMSF'>Declined by CMSF</MenuItem>
                
                  </Select>
                  </FormControl> 
                  </Grid>
          
          <Grid item lg={2} md={2} sm={2} xs={12}>
          <span>
            Start Date:{' '}
            <input
                className="form-control"
                value={startDate || ""}
                type="date"
                onChange={e => {
                    setStartDate(e.target.value);
                }}
                placeholder={` search...`}
            />
        </span>
          <span>
            End Date:{' '}
            <input
                className="form-control"
                value={endDate || ""}
                onChange={e => {
                   // if()
                    setEndDate(e.target.value);
                }}
                placeholder={` search...`}
                type="date"
            />
        </span>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
          <span>
            Total Cash:
            <input
                className="form-control"
                type='number'
                value={totalCashLower || ""}
                onChange={e => {
                    setTotalCashLower(e.target.value);
                    //setTotalCashValue(e.target.value);
                }}
                placeholder={` from...`}
            />
        </span>
          <span>
            <input style={{marginTop: '5px'}}
                className="form-control"
                type='number'
                value={totalCashUpper || ""}
                onChange={e => {
                    setTotalCashUpper(e.target.value);
                    //setTotalCashValue(e.target.value);
                }}
                placeholder={` to...`}
            />
        </span>
          </Grid>

          <Grid item lg={1} md={1} sm={1} xs={12}>
          <span>
            Total FC:{' '}
            <input
                className="form-control"
                type='number'
                value={totalFCLower || ""}
                onChange={e => {
                    setTotalFCLower(e.target.value)
                    //setTotalFcValue(e.target.value);
                }}
                placeholder={`from...`}
            />
        </span>
        <span>
            {' '}
            <input style={{marginTop: '5px'}}
                className="form-control"
                type='number'
                value={totalFCUpper || ""}
                onChange={e => {
                    setTotalFCUpper(e.target.value)
                    //setTotalFcValue(e.target.value);
                }}
                placeholder={`to...`}
            />
        </span>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
            <span>
                Total Stock:{' '}
                <input
                    className="form-control"
                    type='number'
                    value={totalStockLower || ""}
                    onChange={e => {
                        setTotalStockLower(e.target.value)
                        //setStockValue(e.target.value);
                    }}
                    placeholder={` from...`}
                />
            </span>
            <span>
              {' '}
            <input style={{marginTop: '5px'}}
                className="form-control"
                type='number'
                value={totalStockUpper || ""}
                onChange={e => {
                    setTotalStockUpper(e.target.value)
                   // setStockValue(e.target.value);
                }}
                placeholder={` to...`}
            />
        </span>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={12}>
          {/* <span>
            Remarks:{' '}
            <input
                className="form-control"
                value={remarksValue || ""}
                onChange={e => {
                    setRemarksValue(e.target.value);
                }}
                placeholder={` search...`}
            />
        </span> */}
          <span>
          <Button
           variant="contained"
                 color="primary"
                 size="medium"
                 style={{margin:"20px"}}
                 onClick={searchResethandler}
                  >{ t('common.reset')}</Button>
        </span>
          </Grid>
          </Grid>
          <Grid container style={{ overflowX: 'auto'}}>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
  {rows.map((row, i) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        {row.cells.map(cell => {
          return (
            <td 
            style={{wordWrap: 'break-word',
            whiteSpace: 'none',
            maxWidth: matches?'13rem':'25rem'}} 
                {...cell.getCellProps()} data-label={cell.column.render('Header')}>
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  })}
</tbody>
        
      </table>
            </Grid>
            <ul className="pagination">
                <li className="page-item" onClick={handleFirstPage} disabled={!canPreviousPage}>
                    <span style={{cursor: "grab"}} className="page-link">First</span>
                </li>
                <li className="page-item" onClick={prevPageHandler}
                 disabled={!canPreviousPage}
                 >
                    <span style={{cursor: "grab"}} className="page-link">{'<'}</span>
                </li>
                <li className="page-item" onClick={nextPageHandler}
                 disabled={!canNextPage}
                 >
                    <span style={{cursor: "grab"}} className="page-link">{'>'}</span>
                </li>
                <li className="page-item" onClick={handleLastPage} disabled={!canNextPage}>
                    <span style={{cursor: "grab"}} className="page-link">Last</span>
                </li>
                <li>
                    <a className="page-link">
                        Page{' '}
                        <strong>
                            {currentPage} of {numberOfPage}
                        </strong>{' '}
                    </a>
                </li>
                <li>
                    <a className="page-link">
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                goToPageHandler(e.target.value)
                            }}
                            style={{ width: '100px', height: '20px' }}
                        />
                    </a>
                </li>{' '}
                <select
                    className="form-control"
                    value={showPageSize}
                    onChange={e => {
                        setShowPageSize(Number(e.target.value))
                    }}
                    style={{ width: '120px', height: '38px' }}
                >
                    {pSize.map(pageSize => (
                        <option key={pageSize} value={pageSize} >
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </ul>
  
        </div>
    )
}

export default Table;