import DoneAllIcon from '@mui/icons-material/DoneAll';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Autocomplete, Box, Button, MenuItem, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en-gb';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import LoaderBar from "../../components/LoaderBar/LoaderBar";
import PageTitle from "../../components/PageTitle/PageTitle";
import axios from '../../utils/axios';
// export type Person = {
//   id: string;
//   firstName: string;
//   middleName: string;
//   lastName: string;
//   address?: string;
//   city?: string;
//   state?: string;
//   country?: string;
// };

// export const data = [
//   {
//     id: '1',
//     firstName: 'Dylan',
//     middleName: 'Sprouse',
//     lastName: 'Murray',
//     address: '261 Erdman Ford',
//     city: 'East Daphne',
//     state: 'Kentucky',
//     country: 'United States',
//   },
//   {
//     id: '2',
//     firstName: 'Raquel',
//     middleName: 'Hakeem',
//     lastName: 'Kohler',
//     address: '769 Dominic Grove',
//     city: 'Vancouver',
//     state: 'British Columbia',
//     country: 'Canada',
//   },
//   {
//     id: '3',
//     firstName: 'Ervin',
//     middleName: 'Kris',
//     lastName: 'Reinger',
//   },
//   {
//     id: '4',
//     firstName: 'Brittany',
//     middleName: 'Kathryn',
//     lastName: 'McCullough',
//     address: '722 Emie Stream',
//     city: 'Lincoln',
//     state: 'Nebraska',
//     country: 'United States',
//   },
//   {
//     id: '5',
//     firstName: 'Branson',
//     middleName: 'John',
//     lastName: 'Frami',
//   },
// ];

const ReadyToSettled = () => {

  const initialValue = {
    p_id:0,
    p_settlementStatus:0,
    bankName:"",
    bandId:"",
    bankAccountNo:"",
    p_settlementNo:"",
    p_settlementDate:"",
    p_remarks:"",
    p_updatedBy: "",
    p_dp_id:0,
    p_stock_unit_price:0.0
  
  }
  
  let settlementstatus = [{id:1,label:"Ready to settle"},{id:2,label:"Settled"}]
  
let claimtypelist =[{id:1,label:"Self"},{id:2,label:"Authorized Person/Power of Attorney"},{id:3,label:"Successor/Nominee"}] 
  const [settlementStatus,setSettlementStatus] = useState(0);
  const [bankList, setBankList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [selectedBank,setSelectedBank] = useState("");
  const [data,setData] = useState([]);
  const [dividendType,setDividendType] = useState([]);
  const { register, control,setValue } = useForm({});
  const [submitValue,setSubmitValue] = useState(initialValue);
const [branch,setBranch] = useState({});
const [show,setShow] = useState(false);
const [dpList,setDPList] = useState([]);
const [selectedHouse,setSelectedHouse] = useState(null);
const [ExcelData, setExcelData] = useState([]);
const [isLoading,setIsLoading] = useState(false);

// const initialValue = {
//   p_id:0,
//   p_settlementStatus:0,
//   bankName:"",
//   bandId:"",
//   bankAccountNo:"",
//   p_settlementNo:"",
//   p_settlementDate:"",
//   p_remarks:"",
//   p_updatedBy: "",

// }


const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});
const handleExportData = table => {
  console.log(table);
  const csv = generateCsv(csvConfig)(ExcelData);
  download(csvConfig)(csv);
};


  const handleSubmit = (id) =>{
// console.log(submitValue)
    if(submitValue.p_settlementStatus==2 && submitValue.p_settlementDate!="")
    {
      if(submitValue.bankName!=="")
      {
        if(submitValue.bandId!=="" && submitValue.bankAccountNo!=="")
        {
          axios.put('/settlement',submitValue)
          .then((res)=>{
            if(res.data.statusCode==200)
            {
              setSelectedBank("");
              setSubmitValue(initialValue);
              setBranch({});
              setSettlementStatus(0);
              setValue('p_bank_id',null);
              setValue('p_bankName',null);
              Swal.fire({
                icon: 'success',
                title: 'Dividend has been settled successfully',
                showConfirmButton: false,
                timer: 3000
              })
              // data.filter(item => item.settlement_id !== id);
              setData(data.filter(item => item.settlement_id !== id))
              // getReadyToSettledList();
            }
          })
          .catch((err)=>{
            console.log(err);
          })
        }
        else{
          alert("Please select Branch Name and Provide Account No");
        }
      }
      else{
        axios.put('/settlement',submitValue)
          .then((res)=>{
            if(res.data.statusCode==200)
              {
                setSelectedBank("");
                setSubmitValue(initialValue);
                setBranch({});
                setSettlementStatus(0);
                Swal.fire({
                  icon: 'success',
                  title: 'Dividend has been settled successfully',
                  showConfirmButton: false,
                  timer: 3000
                })
                setData(data.filter(item => item.settlement_id !== id))
              }
          })
          .catch((err)=>{
            console.log(err);
          })
      }
      
    }
    else{
      alert("Please Change the Settlement Status and Provide Settlement Date");
    }
    
  }
  
 

  const getBankList = async () =>
    {
      await axios.get('/bank/get-all-bank-names')
      .then((res)=>{
        // console.log(res.data.payload);
        setBankList(res.data.payload);
      } )
      .catch((err)=>console.log(err));
    }
    
    const getReadyToSettledList = async () =>{
      setIsLoading(true);
      try
      {
        const dividenTypeApiHit = await axios.get('/inward-dividend/get-dividend-types')  
        let dividenTypeApiRes = dividenTypeApiHit.data.payload; 
        const response = await axios.get('/settlement/get-by-settlement-status/1')
           let res = response.data.payload;
        // console.log(response);
        let modifiedData =[]
        res.map(item =>{
          const rowWithFetchedId = dividenTypeApiRes.find(row => row.id === item.investor_dividend_type_id);
          const labelToShow = rowWithFetchedId ? rowWithFetchedId.investorDividendType+ "("+rowWithFetchedId.dividendType+")"  : item.investor_dividend_type_id;
          const cashorstock = rowWithFetchedId ? rowWithFetchedId.dividendType  : item.investor_dividend_type_id;
          const claimTypeText = claimtypelist.find(row=>row.id===item.claimtype)?.label ;
          const YearList = item.years.join(", ");
          delete item.years;
          modifiedData.push({...item,investorDividendType:labelToShow,yearlist:YearList,dividendType:cashorstock,claimTypeText:claimTypeText})
        })
        setData(modifiedData);
        setIsLoading(false);
        setDividendType(dividenTypeApiRes);
        console.log(modifiedData)
        
      }
      catch(error)
      {
        setIsLoading(false);
        console.error('Error fetching ready to settled list:', error.response?.data?.message || error.message);
      }
      
    }
 

  // useEffect(() => {
  //   fetchData();
  // }, [dividendType]); 
  
  // const getDividendTypes =  () =>{
  //   try{
  //     const response = axios.get('/inward-dividend/get-dividend-types')
  //     let res = response.data.payload;
  //     setDividendType(res);
  //   }
  //   catch(e){
  //     console.log("error fetching dividend types",e.response?.data?.message || e.message)
  //   }
    
  // }

  const getBranchList = async (bank) =>
    {
      let object = {bankName:`${bank}`}
      await axios.post('/bank/get-all-banks-by-name',object)
      .then((res)=>{
        // console.log(res.data.payload);
        setBranchList(res.data.payload);
      } )
      .catch((err)=>console.log(err));
    }
    const getdplist = () =>{
        axios.get('/bank/get-all-dp-names')
        .then((res)=>{
          setDPList(res.data.payload);
        })
        .catch((err)=>console.log(err));
      }
  
let accounts = [{id:1,label:"Joint Account"},{id:2,label:"Individual Account"}] ;
  
        // const handleShowData = async () => {
        //   setShow(!show);
        //   fetchData();
        // }
      const handleChangeSettlementStatus = (event) =>{
        setSettlementStatus(event.target.value);
        setSubmitValue(prevState => ({
          ...prevState,
          p_settlementStatus:event.target.value
        }));
      }


      console.log(data);
  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: 'bo_id',
        header: 'BOID',
      },
      {
        accessorKey: 'folio_number',
        header: 'Folio Number',
      },
      {
        accessorKey: 'name',
        header: 'Investor Name',
      },
      {
        accessorKey: 'issuer_name',
        header: 'Issuer',
        size: 50,
      },
      
      {
        accessorKey: 'investorDividendType',
        header: 'Investor Dividend Type',
        // Cell: ({ cell }) => {
        //   const id = cell.getValue(); // Get the raw id value
        //   const rowWithFetchedId = dividendType.find(row => row.id === id);
        //   const labelToShow = rowWithFetchedId ? rowWithFetchedId.investorDividendType+ "("+rowWithFetchedId.dividendType+")"  : id;
        //   return labelToShow // Map to label or show 'Unknown' if not found
        // },
      },
      {
        accessorKey: 'total_amount',
        header: 'Amount',
      },
    ],
    [],
    //end
  );

  const table = useMaterialReactTable({
    columns,
    data,
    // enableExpandAll: false, //disable expand all button
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={()=>handleExportData(table)}
          startIcon={<FileDownloadIcon />}
          disabled={table.getPrePaginationRowModel().rows.length === 0}
        >
          Export All Data
        </Button>
        
      </Box>
    ),
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {table.setExpanded({ [row.id]: !row.getIsExpanded() })
      setSubmitValue(prevState => ({
        ...prevState,
        p_id:row.original?.settlement_id,
        p_settlementStatus:row.original?.settlementstatus,
        p_settlementNo:row.original?.settlementno,
        bankName:row.original?.bankName || "",
        p_remarks:row.original?.remarks,
        bankAccountNo:row.original?.bankaccountno || null,
        bandId:row.original?.bankid || null,
        p_dp_id:row.original.dp_id || 0,
        p_stock_unit_price: row.original.stockunitprice || 0.0

      }))
      setSelectedBank(row.original?.bankname || "");
      setBranch({});
    }, //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    //conditionally render detail panel
    renderDetailPanel: ({ row }) =>
      
      row.original.name ? (
        <>
        <Box
          sx={{
            display: 'grid',
            gap: 2, // Adds spacing between grid items
            gridTemplateColumns: {
             xs: '1fr', // 1 column on extra-small screens
             sm: '1fr', // 2 columns on small screens and up
             md: '1fr 1fr', // 2 columns on medium screens and up
            },
            width: '100%',
            margin: '0 auto', // Centers the form on the screen
            overflow:"visible",
            textAlign:"center",
            padding:"100px"
          }}
        >
          <TextField  style={{margin:"10px"}} disabled  label="Issuer Name"  defaultValue={row.original.issuer_name}/> 
          <TextField  style={{margin:"5px"}} disabled label="BOID" defaultValue={row.original.bo_id}/> 
          <TextField  style={{margin:"5px"}} disabled label="Folio No" defaultValue={row.original.folio_number}/>
          <TextField  style={{margin:"5px"}} disabled label="Name" defaultValue={row.original.name}/> 
          <TextField  style={{margin:"5px"}} disabled label="NID" defaultValue={row.original.nid}/> 
          <TextField  style={{margin:"5px"}} disabled label="Passport" defaultValue={row.original.passport}/> 
          <TextField  style={{margin:"5px"}} disabled label="Investor Dividend Type" defaultValue={row.original.investorDividendType}/> 
          <TextField  style={{margin:"5px"}} disabled label="Claim Type" defaultValue={row.original.claimTypeText}/>
          {
            row.original.claimtype == 2 || row.original.claimtype == 3
            ?
            <>
            <TextField  style={{margin:"5px"}} disabled label="Claimant Name" defaultValue={row.original.claimantname}/>
            <TextField  style={{margin:"5px"}} disabled label="Claimant NID" defaultValue={row.original.claimantnid}/>
            </>
            :
            null
          }
          
          <TextField  style={{margin:"5px"}} disabled label="Account Type" defaultValue={accounts.find(item=>item.id===row.original.account_type)?.label}/>
          <TextField  style={{margin:"5px"}} disabled label="Second Investor's Name" defaultValue={row.original.shareholder_name}/>
          <TextField  style={{margin:"5px"}} disabled label="Second Investor's NID" defaultValue={row.original.sholdernid}/>
          <TextField  style={{margin:"5px"}} disabled label="Second Investor's Passport" defaultValue={row.original.sholderpassport}/>
          <TextField 
                    select
                    focused
                    required
                    name="p_settlementstatus"
                    defaultValue={row.original.settlementstatus || 1}
                    // {...register("p_settlementstatus", { required: true })}
                    label="Select Settlement Status"
                    variant="outlined"
                    className="input-field"
                    
                    onChange={handleChangeSettlementStatus}
                    style={{ margin: "5px" }}
                  >
                    {settlementstatus.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField>
              <TextField  style={{margin:"5px"}} disabled label="Application Date" defaultValue={row.original.application_date}/>
              <TextField  style={{margin:"5px"}} disabled label="Claim Recieved Date" defaultValue={row.original.receive_date}/>
              

              {
                row.original.dividendType == "Cash"
                ?
                <>
                {
                row.original.bankname == "" || row.original.bankname==null ? 
                
                <Controller
              
                name="p_bankName"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={bankList}
                    style={{ margin: "5px"}}
                    getOptionLabel={(option) => `${option.bankName}`}
                    renderInput={(params) => (
                      <TextField 
                      focused
                      placeholder="Select Bank"
                        {...params}
                        label="Select Bank"
                        variant="outlined"
                        
                      />
                    )}
                    onChange={(_, data) => {
                      if(data)
                      {
                        field.onChange(data.bankName); 
                        setSelectedBank(data.bankName);
                          setSubmitValue(prevState => ({
                            ...prevState,
                            bankName:data.bankName
                          }));
                        getBranchList(data.bankName);
                      }
                      else{
                        field.onChange(null);
                      }

                      
                    }} // Update the form value
                  />
                )}
              />
            :
            <TextField  style={{margin:"5px"}} disabled label="Bank Name" defaultValue={row.original.bankname}/>
                
            }
              {
                row.original.branchname=="" || row.original.branchname==null ?
                
              <Controller
                name="p_bank_id"
                control={control}
                
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={branchList}
                    
                    style={{ margin: "5px"}}
                    defaultValue={row.original.bankid || null}
                    getOptionLabel={(option) => `${option.branchName} (routingNo:${option.routingNo})`}
                    renderInput={(params) => (
                      <TextField 
                        {...params}
                        label="Select Branch"
                        variant="outlined"
                        required={selectedBank !== ""}
                        placeholder="Select Branch"
                        focused
                      />
                    )}
                    onChange={(_, data) => {
                      if(data)
                      {
                        field.onChange(data.id);
                        setBranch(data); 
                        setSubmitValue(prevState => ({
                          ...prevState,
                          bandId:data.id
                        }))
                      }
                      else{
                        field.onChange(null);
                      }

                      
                    }} 
                  />
                )}
              />
              :
                            <TextField  style={{margin:"5px"}} disabled label="Branch Name" defaultValue={row.original.branchname}/>
              
            }
              {
                row.original.routingno=="" || row.original.routingno==null ?
                
                <TextField  style={{margin:"5px"}} label="Routing No"
              // required={selectedBank !== ""}
              focused
              readonly
              value={branch ? branch.routingNo:""}
              defaultValue={row.original.routingno || null}
              />
            :
            <TextField  style={{margin:"5px"}} disabled label="Routing No" defaultValue={row.original.routingno}/>
                
            }
              {
                row.original.bankaccountno=="" || row.original.bankaccountno==null ?
               
                
                <TextField focused style={{margin:"5px"}} label="Bank Account No"
              required={selectedBank !== ""}
              defaultValue={row.original.bankaccountno || null}
              onChange={(event)=>{
                setSubmitValue(prevState => ({
                  ...prevState,
                  bankAccountNo:event.target.value
                }))
              }}
              />
            :
            <TextField  style={{margin:"5px"}} disabled label="Bank Account No" defaultValue={row.original.bankaccountno}/>
            }
                </>
                :
                row.original.dividendType == "Stock"
                ?
                <>
                     {
                row.original.dpname=="" || row.original.dpname==null ?
                
                <Controller
                name="p_dp_id"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    {...register("p_dp_id")}
                    options={dpList}
                    getOptionLabel={(option) => `${option.dpName} (${option.dpId})`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select DP House"
                        variant="outlined"
                        fullWidth
                        focused
                        style={{ margin: "5px 0", width: "100%" }}
                      />
                    )}
                    onChange={(_, data) => {
                      console.log(data)
                      if(data)
                      {
                        field.onChange(data.id); 
                        setSelectedHouse(data.id);
                        setSubmitValue(prevState => ({
                          ...prevState,
                          p_dp_id:data.id
                        }));
                      }
                      else{
                        field.onChange(null);
                        setSelectedHouse(0);
                      }
                      
                    }} // Update the form value
                  />
                )}
              />
              :
                            <TextField  style={{margin:"5px"}} disabled label="DP House" defaultValue={row.original.dpname || ""}/>
              
            }
              {
                row.original.stockunitprice=="" || row.original.stockunitprice==null ?
                
                <TextField name="p_stock_unit_price"
                  // required={selectedHouse >0 || selectedHouse < 0  ? true : false}
                  focused 
                  fullWidth variant="outlined" 
                  label="Stock Unit Price" 
                  style={{ margin: "5px", width: "100%" }}
                  onChange={(e)=>{
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_stock_unit_price:e.target.value
                    }));
                  }}
                {...register("p_stock_unit_price")}
                />

            :
            <TextField  style={{margin:"5px"}} disabled label="Stock Unit Price" defaultValue={row.original.stockunitprice}/>
                
            }
                </>
                :
                null
              }




              <TextField  style={{margin:"5px"}}
              focused
              type="number"
              defaultValue={row.original.settlementno || null}
                onChange={(e)=>{
                  setSubmitValue(prevState => ({
                    ...prevState,
                    p_settlementNo:e.target.value
                  }))
                }}
              label="Settlement Serial" />
              <Controller
              name="p_settlementdate"
              control={control}
              focused
              render={({ field: { onChange, value } })  => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'} focused>
                  <DatePicker sx={{margin:"5px"}}
                  focused
                  required= {settlementStatus==2 ? true : false}
                  label="Settlement Date"
                  {...register("p_settlementdate", { required: settlementStatus==2 ? true : false })}
                    onChange={(date) => {
                      date = date ? date.format("YYYY/MM/DD") : "";
                      setSubmitValue(prevState => ({
                      ...prevState,
                      p_settlementDate: date
                    }))
                    onChange(date);
                  
                  }
                    
                    
                      }
                    renderInput={(params) => (
                      <TextField 
                        fullWidth
                        {...params}
                        focused
                        label="Settlement Date"
                        variant="outlined"
                        style={{ margin: "5px 0" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
              
              <TextField  style={{margin:"5px"}} disabled label="Total Amount" defaultValue={row.original.total_amount}/>
              <span></span>
              <TextField  style={{margin:"5px"}} disabled label="Years" variant="outlined" multiline rows={5} defaultValue={row.original.yearlist}/>
              <TextField  style={{margin:"5px"}} label="Remarks" focused
                onChange={(e)=>{
                  setSubmitValue(prevState => ({
                    ...prevState,
                    p_remarks:e.target.value
                  }))
                }}
              variant="outlined" multiline rows={5} defaultValue={row.original.remarks}/>
        </Box>
              <Box  display="flex" justifyContent="center" mt={2} sx={{ width: '100%' }}>
                <Button  color="primary" onClick={()=>handleSubmit(row.original.settlement_id)} variant="contained" endIcon={<DoneAllIcon />}>UPDATE DIVIDEND RECORD </Button>
              </Box>
        </>
      ) : null,
  });

const getExcelData = () => {
  axios.get('/settlement/get-by-settlement-status-for-excel/1')
  .then((res)=>{
    let response = res.data.payload;
    setExcelData(response);
  })
  .catch((e)=>{
    console.log(e);
  })
}

  useEffect(() => {
    
    getReadyToSettledList();
    getExcelData();
    getBankList();
   getdplist();
  
  
  }, [])

  return(
        <>
          <PageTitle title={"Settle Status Update Dashboard"}></PageTitle>
          {/* <Box>
            <Button variant="contained" color="primary" onClick={handleShowData}> {show ? "Hide Data" : "See Data"}</Button>
          </Box> */}
          
          {
             isLoading
             ?
            <LoaderBar/>
            :
            <MaterialReactTable table={table} />
          }

          
        </>);
};

export default ReadyToSettled;