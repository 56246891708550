import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useContext } from "react";
import ReduceContext from "./ReduceContext";


export const ReviewInformation = () => {
  const {data} = useContext(ReduceContext);

    return (
      <>
      <Paper>
        <Table>
          <TableHead>

          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>{data.fullName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email Address</TableCell>
              <TableCell>{data.emailAddress}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone Number</TableCell>
              <TableCell>{data.phoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>National ID</TableCell>
              <TableCell>{data.nId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>{data.gender}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date of Birth</TableCell>
              <TableCell>{data.dateOfBirth}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Claim Type</TableCell>
              <TableCell>{data.claimType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Claimant Type</TableCell>
              <TableCell>{data.claimantType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BO ID</TableCell>
              <TableCell>{data.boId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Issuer Company Name</TableCell>
              <TableCell>{data.issuerCompanyName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dividend Year</TableCell>
              <TableCell>{data.dividendYear}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>{data.amount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account Name</TableCell>
              <TableCell>{data.accountHolderName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account Number</TableCell>
              <TableCell>{data.accountNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bank Name</TableCell>
              <TableCell>{data.bankName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Branch Name</TableCell>
              <TableCell>{data.branchName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Routing Number</TableCell>
              <TableCell>{data.routingNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <h1 style={{marginBottom: "50px"}}> </h1>
      </>
    );
  };