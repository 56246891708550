import { Box, Container, CssBaseline, Paper } from "@material-ui/core";
import LodgeClaim from "./LogdgeClaim";


export default function lodgeClaim(){
    return(
        <div>
         
      <CssBaseline />
      <Container component={Box} p={4}>
        <Paper component={Box} p={3}>
          <LodgeClaim />
        </Paper>
      </Container>
  
        </div>
    )
}