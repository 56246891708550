import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from 'react';
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";



const UpdateBankInformation = ({data}) => {
  const history = useHistory();
  const userId = localStorage.getItem("user-id");
  const strId = JSON.parse(userId);
  

 const  {bankName, accountName,accountNo,branchName,routingNo,swiftCode} = data;

 const bankDataId = data.id

 const { t } = useTranslation();

const changeHandler = e => {
  setAllValues( prevValues => {
  return { ...prevValues,[e.target.name]: e.target.value}
  
})
}
console.log(bankDataId)

const [allValues, setAllValues] = useState({
  bankName: data.bankName,
  accountName:data.accountName,
  accountNo: data.accountNo,
  branchName : data.branchName,
  routingNo:data.routingNo,
  swiftCode:swiftCode
  //user: strId
  
});


const submitHandler = (e) =>{ 
  e.preventDefault();

  
  axios.put(`/bank-informations/${bankDataId}`,allValues
  ).then((res)=>{



  }).catch((err)=>{
      console.log(err);
  });
  ;

  Swal.fire({
    icon: 'success',
    title: 'Bank Details have been saved',
    showConfirmButton: false,
    timer: 1500
  })
    history.push('/app/view/bank-informations');
  
}

  return (
    <div>
      <form onSubmit={submitHandler}>
   
        <h2>{ t('BankInfo.Title')}</h2>
        <Grid container spacing={3}> 
         
              <Grid item lg={12} md={12} sm={12} xs={12}> 
                <TextField 
                        required
                        id="bankName"
                        name="bankName"
                        label="bankName"
                        defaultValue={bankName}
                        fullWidth
                        autoComplete="title"
                        variant="outlined"
                        onChange={changeHandler}
                        
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}> 
                <TextField 
                        required
                        id="accountName"
                        name="accountName"
                        label="Account Name"
                        defaultValue={accountName}
                        fullWidth
                        //autoComplete="title"
                        variant="outlined"
                        onChange={changeHandler}
                        
                  />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}> 
                <TextField 
                        required
                        id="accountNo"
                        name="accountNo"
                        label="Account Number"
                        defaultValue={accountNo}
                        fullWidth
                        autoComplete="title"
                        variant="outlined"
                        onChange={changeHandler}
                  />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}> 
                <TextField 
                        required
                        id="branchName"
                        name="branchName"
                        label="Branch Name"
                        fullWidth
                        defaultValue={branchName}
                        autoComplete="title"
                        variant="outlined"
                        onChange={changeHandler}
                  />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}> 
                <TextField 
                        required
                        id="routingNo"
                        name="routingNo"
                        label="Routing Number"
                        fullWidth
                        defaultValue={routingNo}
                        autoComplete="title"
                        variant="outlined"
                        onChange={changeHandler}
                  />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}> 
                <TextField 
                        required
                        id="swiftCode"
                        name="swiftCode"
                        label="Swift Code"
                        fullWidth
                        defaultValue={swiftCode}
                        autoComplete="title"
                        variant="outlined"
                        onChange={changeHandler}
                  />
              </Grid>
              
              <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="large"
                            onSubmit={submitHandler}
                          >
                          { t('BankInfo.submitButton')}
                  </Button>
                  
                  
             
        </Grid>
        </form>
    </div>
  )
}

export default UpdateBankInformation;