// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../utils/axios";
import Table from './Table';

function FilterTableComponent(issuerId) {
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    

    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };

    const paramsData ={
        page: currentPage,
        take: showPageSize,
    }

    useEffect(() =>{
         {issuerId.issuerId!== ''&& axios.get(`/issuer-year-format/${issuerId.issuerId}`, paramsData)
        
        // axios.get(`/issuer-year-format/b1b21f2e-d6ea-48a8-b70b-fef51802be5a`, paramsData)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })}
    },[issuerId.issuerId])
// console.log(typeof(issuerId))
// console.log(issuerId.issuerId)
    
    const getDataSync = (page,take) =>{
        axios.get(`/issuer-year-format/${issuerId.issuerId}`, paramsData)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
        })
        .catch((err) =>{
            alert("There was a error occured!")
        })
    }
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Already entered',
                columns: [
                    
                    {
                        Header: 'Issuer',
                        accessor: 'issuername'
                    }, 
                    ,
                    {
                        Header: 'Year',
                        accessor: 'year'
                    },  
                    {
                        Header: 'Created By',
                        accessor: 'createdby'
                    }, 
                ],
            },
        ],
        []
    )

    return (
        <div>
            <Table 
            getDataSync={getDataSync} 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            issuerId={issuerId}
            />
           
        </div>
    )
}

export default FilterTableComponent;