// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import { Button } from '@material-ui/core';
import {  ViewListOutlined } from "@material-ui/icons";
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import PageTitle from "../../../components/PageTitle/PageTitle";
import axios from "../../../utils/axios";
import Table from './Table';
import FileViewInModal from "./FileViewInModal";

function FilterTableComponent() {
    const { t } = useTranslation();
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    const [opened, setOpened] = useState(false);
    const [modalData, setModalData] = useState('');
    const userId = localStorage.getItem("user-id");

    const paramsData = {
        
      'userId':userId,
      'issuerId':'',
      'filename':''
  
  }
  
      const controlModal = (e) => {
          setOpened((prevState) => !prevState);
          setModalData(e);
      };


      const fileDownloadHandler = (e) =>{
        axios({
          url: `inward-dividend/get-inward-dividend-file/${params.id}`, 
          method: 'POST',
          data: {
            userId: userId,
            //issuerId: '',
            filename:e
          },
          responseType: 'blob', // important
      })
      .then((response) =>{
        //setLoader(false)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // window.open(url, 'utf-8');
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${e}`);
        document.body.appendChild(link);
        link.click();
       // let tab = window.open();
        //tab.location.href = link;
        //window.open([response.data]);
        //link.remove();
      })
      .catch((error) =>{
        //setLoader(false);
        //setError(error.message);
        alert("There was an error ocurred!")
      })
    }
    // console.log(params.id)
    const getData = () =>{
        axios.get(`/investor-informations/inward-dividend-file-info/${params.id}?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            // if (res.data.payload.userTypes)
            setDataStore(res.data.payload)
            console.log('use');
            setCurrentPage(1);

            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
            .catch((err) =>{
            })
    }

    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.get(`/investor-informations/inward-dividend-file-info/${params.id}?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
            console.log('after');
        })
            .catch((err) =>{
                alert("There was a error occured!")
            })
    }

const testHandler = (id) =>{
    console.log(id);
}

   
    const columns = React.useMemo(
        () => [
           {
                Header: 'Particular',
                columns: [
                    // {
                    //     Header: 'ID',
                    //     accessor: 'createdAt',
                    // },
                    {
                        Header: 'File Name',
                        accessor: 'filename'
                    },
                    {
                        Header: 'Dividend Type',
                        accessor: 'investorsdividendType',
                        Cell: ({row}) => <>
                        {row.values.investorsdividendType === 'CashDividend' ? (
                            'Cash Dividend'
                          ) : row.values.investorsdividendType === 'FCCashDividend' ? 
                          (
                             `FC Cash Dividend`

                          ) : row.values.investorsdividendType === 'StockRightShare' ? (
                             `Stock Right Share`

                          ) : row.values.investorsdividendType === 'StockBonusShare' ? (
                              `Stock Bonus Share`
                          ) : row.values.investorsdividendType === 'PublicSubscriptionMoney'? (
                              `Public Subscription Money`

                         ) : row.values.investorsdividendType === 'FCPublicSubscriptionMoney' ? (
                              `FC Public Subscription Money`

                          )  : (
                             <></>
                         )}</>
                    },
                    {
                        Header: 'Date of Upload',
                        accessor: 'createdAt',
                        Cell: ({ value }) => {
                          const date = new Date(value);
                          return <>{date.toLocaleDateString("en-GB")}</>;
                        },
                    },
                    {
                      Header: 'Actions',
                      accessor: 'id',
                      Cell: ({row}) => <>
                      {/* the first button (enlarged view of the entry) comes default with the list */}
                      
                      {/* <Button
                       style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} size="small" title='View' variant="contained" color="primary" 
                       onClick={controlModal}
                      > <ViewListOutlined /></Button> */}

                      <Button
                       style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} size="small" title='Download' variant="contained" color="primary" 
                       onClick={()=>fileDownloadHandler(row.original.filename)}
                      > <GetAppIcon /></Button>
                    
                      </>
                  },
                ],
            },
           
        ],
        []
    )

    return (
        <>
         <PageTitle title={ "Details of Uploaded Excel Files"} />
            <div>

                    <Table 
                        getDataSync={getDataSync} 
                        showPageSize={showPageSize} 
                        setShowPageSize={setShowPageSize} 
                        setNumberOfpage={setNumberOfpage} 
                        numberOfPage={numberOfPage} 
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage} 
                        setDataStore={setDataStore} 
                        columns={columns} 
                        data={dataStore} 
                        params={params}
                    />
                     <FileViewInModal open={opened} data={modalData} control={controlModal} />
            </div>
               
        </>
    )
}

export default FilterTableComponent;