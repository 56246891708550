import { TextField } from '@material-ui/core';

export const MyTextField = ({ label, value }) => {
  return (
    <TextField
      fullWidth
      multiline
      id={label.toLowerCase().replace(' ', '-')}
      label={label}
      variant="filled"
      placeholder={`Enter ${label}`}
      margin="normal"
      value={value}
    />
  );
}