import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table';
import Swal from "sweetalert2";
import { useGetIssuerInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import UiOfCompanySummaryReport from './UiOfCompanySummaryReport';
import axios from "../../../utils/axios";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

// Initial options for Autocomplete
const options = [{ tradecode: "No Data Available", name: "" }];

function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize, setNumberOfpage, params }) {
  const { data: issuer } = useGetIssuerInfoQuery();
  const { t } = useTranslation();
  const [inwardDividendType, setInwardDividendType] = useState("");
  const [newData, setNewData] = useState([]); // for autocomplete
  const [filteredData, setFilteredData] = useState([]);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [issuerValue, setIssuerValue] = useState(null);
  const [key, setKey] = useState(Date.now());
  const [reset, setReset] = useState(false);
  const [grandTotalResults, setGrandTotalResults] = useState([]);
  const [divTypeFromResponse, setDivTypeFromResponse] = useState("");

  // Fetch issuer info data on component mount
  useEffect(() => {
    axios.get('./issuer/get-info')
      .then((res) => {
        setNewData([{ id: 'All', name: 'All', tradecode: 'All Companies' }, ...res.data.payload]);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  }, []);

  const sortedNewData = newData.length ? newData : options;

  // Prepare data for Table using react-table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: React.useMemo(() => ({ Filter: "" }), []),
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );

  // Handlers for navigating through pages
  const nextPageHandler = () => {        
    if (numberOfPage > currentPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {        
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== numberOfPage) {
      setCurrentPage(numberOfPage);
    }
  };

  // Search and Reset handlers
  const searchResethandler = () => {
    setIssuerValue(null);
    setInwardDividendType("");
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setKey(Date.now());
    setReset(true);
    setFilteredData([]);
  };

  const searchHandler = () => {
    // Set "all" as default if no date is selected, otherwise format the selected date
    const fromdate = selectedFromDate ? selectedFromDate.format("YYYY-MM-DD") : "All";
    const todate = selectedToDate ? selectedToDate.format("YYYY-MM-DD") : "All";

    const paramsData = {
      issuerid: issuerValue,
      inwardDividendType: inwardDividendType,
      take: 20,
      fromdate: fromdate,
      todate: todate,
    };

    axios.post(`investor-informations/issuer-wise-summary`, paramsData)
      .then((res) => {
        setFilteredData(res.data.payload.result);

        // console.log(res.data.payload.result);

        setGrandTotalResults(res.data.payload.grandTotal);
        setDivTypeFromResponse(res.data.payload.inwardDividendType);

        if (res.data.payload.result.length === 0) {
          Swal.fire({
            title: "Oops..",
            text: "No Data found with the Given Information!",
            icon: "warning",
          });
        }

        if (res.data?.page) {
          setNumberOfpage(Math.ceil(res.data?.total / showPageSize));
        }
      })
      .catch(() => {
        Swal.fire("Something went wrong!");
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* Autocomplete for selecting Issuer Name */}
        <Grid item lg={3} md={3} sm={2} xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              key={key}
              onChange={(event, newValue) => {
                setIssuerValue(newValue.id);
              }}
              disableClearable
              options={sortedNewData}
              getOptionLabel={(option) => `${option.name} (${option.tradecode})`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Issuer Name"
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Select for Inward Dividend Type */}
        <Grid item lg={2} md={2} sm={2} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="issuer-id">Inward Dividend Type</InputLabel>
            <Select
              required
              labelId="status"
              id="status"
              value={inwardDividendType}
              label="Select status"
              onChange={(e) => setInwardDividendType(e.target.value)}
            >
              <MenuItem value='Cash' key='CashDividend'>Cash</MenuItem>
              <MenuItem value='Stock' key='StockRightShare'>Stock</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Date Picker for From Date */}
        <Grid item lg={2} md={2} sm={2} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              value={selectedFromDate}
              onChange={(newValue) => setSelectedFromDate(newValue)}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        {/* Date Picker for To Date */}
        <Grid item lg={2} md={2} sm={2} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              value={selectedToDate}
              onChange={(newValue) => setSelectedToDate(newValue)}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        {/* Search and Reset Buttons */}
        <Grid item lg={1} md={2} sm={2} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            style={{ margin: "10px", marginTop: "20px" }}
            disabled={!issuerValue || !inwardDividendType}
            onClick={searchHandler}
          >
            Search
          </Button>
        </Grid>

        <Grid item lg={1} md={2} sm={2} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            style={{ margin: "10px", marginTop: "20px" }}
            onClick={searchResethandler}
          >
            {t('common.reset')}
          </Button>
        </Grid>
      </Grid>

      {/* Table Section */}
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Conditional rendering based on filteredData availability */}
    
   

      {filteredData && filteredData.length > 0 && (
        <UiOfCompanySummaryReport
          grandTotal={grandTotalResults}
          data={filteredData}
          inwardDividendType={divTypeFromResponse}
        />
      )}
    </>
  );
}

export default Table;
