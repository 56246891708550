import { Button } from '@material-ui/core';
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import axios from "../../../../utils/axios";
import Table from './Table';
import { Link, useHistory } from "react-router-dom";

function FilterTableComponent() {
    const { t } = useTranslation();
    
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    
    const paramsData ={
        page: currentPage,
        take: showPageSize,
    }



    const confirmHandler = (idd) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/roles/${idd}`,{
                }).then((res)=>{
                    getData();
                });
                    console.log(`${idd}  deleted successfully`)
              Swal.fire(
                'Deleted!',
                'Selected Role has been deleted.',
                'success'
              )
            }
          })
    }

    const forcePasswordChangeHandler = (identifierValue) => {
         
        const forcePasswordChange = {
            "countryCode": "BD",
            "identifier": identifierValue,
            "password": "123456"
        };

         Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, reset password!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`/auth/force-password-change`,forcePasswordChange     
                ).then((res)=>{
                  console.log(forcePasswordChange);
                }).catch((err)=>{
                    console.log(err);
                });
              Swal.fire(
                'Password has been Reset!',
                '',
                'success'
              )
            }
          })
        
          
       };

    const getData = () =>{
        axios.get(`users/by-user-type/issuer`, paramsData)
        .then((res) =>{
            // if (res.data.payload.userTypes)
            setDataStore(res.data.payload)
            
            setCurrentPage(1);

            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
            .catch((err) =>{
            })
    }

    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.get(`users/by-user-type/issuer?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
            
        })
            .catch((err) =>{
                alert("There was a error occured!")
            })
    }
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Particular',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'autoId',
                    },
                    {
                        Header: 'Name',
                        accessor: 'fullName'
                    },
                    {
                        Header: 'Type',
                        accessor: 'userTypes'
                    },
                    {
                        Header: 'Email',
                        accessor: 'email'
                    },
                    
                    {
                        Header: 'Contact Number',
                        accessor: 'phoneNumber'
                    },
                    {
                        Header: 'Actions',
                        //accessor: 'title',
                        Cell: ({row}) =>
                      
                            <span>

                                {/* <Button 
                                    style={{ margin: '5px', backgroundColor: 'purple'}}   
                                    variant="contained" 
                                    color="primary" 
                                    onClick={()=>console.log(row.original)}
                                >
                                    View                         
                                </Button> */}


                                {/* <Button
                                    style={{ margin: '5px', backgroundColor: 'blue'}}  
                                    variant="contained" 
                                    color="primary" 
                                    onClick={()=>console.log(row.original)}
                                >
                                    Edit                          
                                </Button> */}

<Button 
                                    style={{ margin: '5px', backgroundColor: 'red'}}   
                                    variant="contained" 
                                    color="primary" 
                                    onClick={()=>forcePasswordChangeHandler(row.original.phoneNumber)}
                                        
                                >
                                    Reset Password                          
                                </Button>
        
                                {/* <Button 
                                    style={{ margin: '5px', backgroundColor: 'red'}}   
                                    variant="contained" 
                                    color="primary" 
                                    onClick={()=>confirmHandler(row.original.id)}
                                >
                                    Delete                          
                                </Button> */}

                               

 
                            
                            </span>
                            
                    },
    
                ],
            },
           
        ],
        []
    )

    return (
        <>
        <PageTitle title={ t('UserM.listOfCreateIssuerTypeUser')} button={<Link to="/app/user-issuer-create"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('Issuer.goToNewAdd')}
    </Button></Link>}  />
            <div>
                

                    <Table 
                        getDataSync={getDataSync} 
                        showPageSize={showPageSize} 
                        setShowPageSize={setShowPageSize} 
                        setNumberOfpage={setNumberOfpage} 
                        numberOfPage={numberOfPage} 
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage} 
                        setDataStore={setDataStore} 
                        columns={columns} 
                        data={dataStore} 
                        params={params}
                    />
            </div>
               
        </>
    )
}

export default FilterTableComponent;