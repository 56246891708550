import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/Wrappers/Wrappers";
import { ViewLodgedClaim } from "./ViewLodgedClaim";


export default function ViewLodgedClaimComponent(){
    const { t } = useTranslation();

    return(
        <div>
            
            <PageTitle title={ t('claimSettlement.viewTableTitle')} button={<Link to="/app/lodged-claim/list"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('claimSettlement.claimList')}
    </Button></Link>} />
    <ViewLodgedClaim />
        </div>
    )
}