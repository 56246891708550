import { Grid } from "@material-ui/core";
import React from 'react';
import { Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
  
const BarchartStock = (props) => {
  
// Sample data
const data = [
    {
      "name": "Jan",
      "claims received": 14,
      "claims settled": 2
    },
    {
      "name": "Feb",
      "claims received": 13,
      "claims settled": 12
    },
    {
      "name": "Mar",
      "claims received": 16,
      "claims settled": 4
    },
    {
      "name": "Apr",
      "claims received": 22,
      "claims settled": 2
    },
    {
      "name": "May",
      "claims received": 7,
      "claims settled": 4
    },
   
  ]
  
  
return (
<ResponsiveContainer width="95%" height={400}>
{/* <Grid item  xl={6} lg={6} md={6} sm={12} > */}

    <BarChart width={900} height={450} spacing ={2} data={props.data} margin={{ top: 60, right: 20,  bottom: 20 , left: 30}}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="monthyear">
        <Label value="Month" offset={-10} position="insideBottomRight" />
            <Label value="Cash claim settlement" offset={340} position="insideBottomRight" />
        </XAxis>
        <YAxis label={{ value: 'Settled Amount (in Lac)', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalclaim" name="Lodged" fill="#5063de" />
        <Bar 
        // label={{ position:"insideBottomRight"  }} 
        dataKey="settled" name="Settled" fill="#4bca63" />
    </BarChart>
    {/* </Grid> */}
</ResponsiveContainer>

);
}

export default BarchartStock;