import { Button, Checkbox, FormControl, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { useAddInwardDividendMutation, useGetIssuerInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import axios from "../../../utils/axios";
import useStyles from "./../../../utils/validations/useStyles";

const options = [{ tradecode: "No Data Available", name: "" },
// { tradecode: "No Data Available", name: "" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
      // height:150,
      // overflowY: 'scroll',
      // overflowX: 'scroll'
    },
  },
};




function 
Form(props){
  const classes = useStyles();
  const params = useParams();

  const user = localStorage.getItem('user-name')
  const userFormatted = JSON.parse(user);
  const userType = userFormatted.userTypes[0]
  const [newData, setNewData] = useState([]);
  const [thisCompanysYearType, setThisCompanysYearType] = useState([]);
  const [yearShow, setYearShow] = useState([]);
  const [yearTypeAvailable, setYearTypeAvailable] = useState('');

  const [data,setData] = useState({
    ref:'',
    tradeCode: '',
    date: '',
    dividendType:'',  
    totalCash: '',
    cash_9_1: '',
    cash_9_4: '',
    totalShare: '',
    stock_9_3: '',
    stock_9_2: '',
    startYear: '',
    totalFc:'',
    fc_9_1:'',
    fc_9_4:'',
    endYear: '',
    remarks: '',
    dividendYear:[],
    decisionType:'',
    images: '',
    // localcurrencybank_id:'',
    // foreigncurrencybank_id:'',
    // boaccount_id: '',
  });
// console.log(data)
  const handleChange = (event,newValue) => {
    
    // console.log(newValue.key)
  
    setYearShow(
      // On autofill we get a stringified value.
      // typeof value === 'string' ? event.target.value.split(',') : value,
      event.target.value
    );
    // console.log(yearShow)
    setData({...data, dividendYear: event.target.value})
   

  };
 

  const issuerId = localStorage.getItem('issuer-id')
  //const issuerId2 = issuerId.replace(/['"]+/g, '')

  const issuerName = localStorage.getItem('issuer-name')
 // const issuerName2 = issuerName.replace(/['"]+/g, '')

  const issuerTradeCode = localStorage.getItem('issuer-tradecode')
  //const issuerTradeCode2 = issuerTradeCode.replace(/['"]+/g, '')
  //const issuerId2 = issuerId.replace(/['"]+/g, '')

  // console.log(issuerId);
  // console.log(issuerName);
  // console.log(issuerTradeCode);
  // console.log(issuerId);
 
let cashArray = []
let stockArray = []
let fcArray = []

useEffect(()=>{
    axios.get('./issuer/get-info')
    .then((res)=>{
      setNewData(res.data.payload)
    })
    .catch((err) =>{
      alert(err.response.data.message)
    })
// console.log(data.tradeCode)
   if (data.tradeCode!== '') {axios.get(`/issuer-year-format/${data.tradeCode}`)
    .then((res)=>{
      setThisCompanysYearType(res.data.payload)
      if(res?.data?.payload?.length === 0){
        setYearTypeAvailable('false')
        setData({...data, dividendYear: []})
    }
      else {
        setYearTypeAvailable('true')
        setData({...data, dividendYear: []})
    }
    })
    .catch((err) =>{
      alert(err.response.data.message)
    })}
},[data.tradeCode])

  //  console.log(data.tradeCode)
  //  console.log(yearTypeAvailable)
  //  console.log(yearShow)

    const newData2 = newData?newData:options
    var sortedNewData = newData2
    // if (newData2) {
    //   sortedNewData = newData2.slice().sort((a, b) => {
    //     if (a.tradecode < b.tradecode) {
    //       return -1;
    //     }
    //   });
     
    // }

  const { t } = useTranslation();
    const [sortedData,setSortedData] = useState("");
    const [addInwardDividend, { isSuccess,isLoading, isError}] = useAddInwardDividendMutation();
    const {  data:issuer , isError:isErrorGetIssuer, isLoading:isLoadGetIssuer, isSuccess:isSuccessGetIssuer} = useGetIssuerInfoQuery();
    const history = useHistory();
    let currentDate = new Date().toJSON().slice(0, 10);

    const issuerData = issuer?.payload
    //console.log(issuerData);
    const issuerData2 = issuerData;
    //console.log(issuerData2);
    var sortedIssuerData = issuerData
    if (issuerData2) {
     sortedIssuerData = issuerData2.slice().sort((a, b) => {
        if (a.tradecode < b.tradecode) {
          return -1;
        }
      });
     
    }
    //console.log(sortedIssuerData);

  

    const [loading, setLoading] = useState(false);
    const [dataStore, setDataStore] = useState([]);
   
    const [allAccounts, setAllAccounts] = useState([]);
    const [localcurrencybank_id, setLocalCurrencyBankId] = useState({});
    const [foreigncurrencybank_id, setForeigncurrencybankId] = useState({});
    const [boaccount_id, setBoaccountId] = useState({});
    const [newArray, setNewArray] = useState([]);

    function checkFileType(file) {
      const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
      if(allowedFileTypes.includes(file.type)){
        return true;
      } else{
        alert( " Plese check your upload file type. Only allow (PNG/JPG/JPEG/PDF)")
        return false
      }
    }
    
    const submitHandler = (e) =>{
      //console.log("Auto click to submit")
        e.preventDefault();
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are about to submit the form. Do you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, submit it!', 
          cancelButtonText: 'No, cancel it',
        }).then((result) => {
          if (result.isConfirmed) {
// console.log(data.dividendYear)
        const formData = new FormData();
          formData.append('images',data.images);
          formData.append('issuerid',data.tradeCode);
          formData.append('ref',data.ref);
          formData.append('date',data.date);
          formData.append('dividendType',data.decisionType);
          formData.append('year',JSON.stringify(data.dividendYear));
          formData.append('remarks',data.remarks);
          if(data.decisionType == 'Cash' || data.decisionType == 'All'){
            formData.append('localcurrencybank_id',data.localcurrencybank_id);
            formData.append('total_cash',data.totalCash || 0);
            formData.append('cash_9_1',data.cash_9_1 || 0);
            formData.append('cash_9_4',data.cash_9_4 || 0);
          }
          
          if(data.decisionType == 'FC' || data.decisionType == 'All'){
          formData.append('foreigncurrencybank_id',data.foreigncurrencybank_id);
          formData.append('total_fc',data.totalFc || 0);
          formData.append('fc_9_1',data.fc_9_1 || 0);
          formData.append('fc_9_4',data.fc_9_4 || 0);
          }
         
          if(data.decisionType == 'Stock' || data.decisionType == 'All'){
          formData.append('boaccount_id',data.boaccount_id);
          formData.append('total_stock',data.totalShare || 0);
          formData.append('stock_9_2',data.stock_9_2 || 0);
          formData.append('stock_9_3',data.stock_9_3 || 0);
          }
          
            // addInwardDividend(formData);
          axios.post(`inward-dividend`,formData)
          .then((res) =>{
            if(res?.data?.success){
              Swal.fire({
                icon: 'success',
                text: 'Inward Dividend has been successfully saved.',
              }).then(() => {
                history.push('/app/inward-dividend/list');
              });
            }
          })
          .catch((err) =>{
            Swal.fire("There was an error ocurred!")
          })
          
        }
      });
    }
    const checkData = (issuer?.payload)


const boID = (item) => {setBoaccountId(item)};

    useEffect(() => {
      axios.get(`/bank-bo/list`)
        .then((res) =>{
          // console.log(res?.data?.payload?.type);
          // res?.data?.payload.forEach((item, index)=>{
          //   if(item.type === 'Cash'){setLocalCurrencyBankId(item)}
          //   else if(item.type === 'FC'){setForeigncurrencybankId(item)}
          //   else if(item.type === 'Stock'){setBoaccountId(item)}
          //   else if(item.type === 'All'){console.log(item)}
          // })
          setAllAccounts(res?.data?.payload)
          setLocalCurrencyBankId(res?.data?.payload)
          setForeigncurrencybankId(res?.data?.payload)
          setNewArray(res?.data?.payload)
          setBoaccountId(res?.data?.payload)
          
        })
        .catch((err) =>{
        })
      axios.post(`inward-dividend/list`)
        .then((res) =>{
            setDataStore(res?.data?.payload)
        })
        .catch((err) =>{
        })
      // if (isSuccess) {
      //   Swal.fire({
      //     icon: 'success',
      //     text: 'Inward Dividend has been successfully saved.',
      //   }).then(() => {
      //     history.push('/app/inward-dividend/list');
      //   });
      // }
      // if (isError) {
      //   Swal.fire({
      //     icon: 'error',
      //     text: 'There was an error occurred!',
      //   });
      // }
      if(isSuccessGetIssuer){
        setSortedData(issuer?.payload);
      }
    }, [isSuccess, isError]);
    
    //console.log(dataStore);
    //console.log(allAccounts);
   
    // console.log(newArray);
    // console.log(cashArray);
    // console.log(stockArray);
    // console.log(fcArray);

    
    newArray?.forEach((element, index, array) => {
      //console.log(index); // 0, 1, 2
      //console.log(element); // 100, 200, 300
      if(element.type == 'Cash' && element.status == 1) cashArray.push(element)
      if(element.type == 'Stock' && element.status == 1) stockArray.push(element)
      if(element.type == 'FC' && element.status == 1) fcArray.push(element)
      
      
      //console.log(array); // same myArray object 3 times
  });
   
          // setBoaccountId(res?.data?.payload)
          // setLocalCurrencyBankId(res?.data?.payload)
          // setForeigncurrencybankId(res?.data?.payload)

    let dividendContent ='' ;
    if(data.decisionType === 'Cash'){
      dividendContent = <div>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={12} xs={12}> 
            <FormControl fullWidth >
                <InputLabel id="localcurrencybank_id" >Select Bank Name (currency in BDT)</InputLabel>
                    <Select style={{marginBottom: '25px'}}
                        required
                        labelId="localcurrencybank_id"
                        id="demo-simple-select"
                        value={data.localcurrencybank_id}
                        label="localcurrencybank_id"
                        onChange={(e) => setData({...data, localcurrencybank_id: e.target.value})}
                        MenuProps={MenuProps}  
                        >
                          {cashArray?.map((i)=><MenuItem value={i.id} key={i.id}>{i.companyName}</MenuItem>)}
                    </Select>  
            </FormControl> <br />
        
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              
               fullWidth
              id="cashDividend"
              label="Cash Dividend"
              variant="outlined"
              placeholder="Enter Cash Dividend"
              required
              margin="normal"
              value={data.cash_9_1}
              onChange={(e) => setData({...data, cash_9_1: e.target.value})}
            /> <br />
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="ipoAmount"
              label="IPO"
              variant="outlined"
              placeholder="Enter IPO"
              required
              margin="normal"
              value={data.cash_9_4}
              onChange={(e) => setData({...data, cash_9_4: e.target.value})}
            /> <br />
             <TextField
             InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
          fullWidth
              id="totalCash"
              label="Total Cash"
              variant="outlined"
              placeholder="Enter Total Cash"
              required
              helperText="'Total Cash' value must be the sum of 'Cash Dividend' and 'IPO'"
              margin="normal"
              value={data.totalCash}
              onChange={(e) => setData({...data, totalCash: e.target.value})}
            /> <br />
            
          </Grid>
        </Grid>
      </div>
    } 
    if(data.decisionType === 'FC'){
      dividendContent = <div>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={12} xs={12}> 
          <FormControl fullWidth>
                <InputLabel id="foreigncurrencybank_id" >Select Bank Name (currency in Dollar)</InputLabel>
                    <Select style={{marginBottom: '25px'}}
                        required
                        labelId="foreigncurrencybank_id"
                        id="demo-simple-select"
                        value={data.foreigncurrencybank_id}
                        label="foreigncurrencybank_id"
                        onChange={(e) => setData({...data, foreigncurrencybank_id: e.target.value})}
                        MenuProps={MenuProps}  
                        >
                          {fcArray?.map((i)=><MenuItem value={i.id} key={i.id}>{i.companyName}</MenuItem>)}
                    </Select>  
          </FormControl> <br />
         
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="cashDividend"
              label="FC Cash Dividend"
              variant="outlined"
              placeholder="Enter FC Cash Dividend"
              required
              margin="normal"
              value={data.fc_9_1}
              onChange={(e) => setData({...data, fc_9_1: e.target.value})}
            /> <br />
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="ipoAmount"
              label="FC IPO"
              variant="outlined"
              placeholder="Enter FC IPO"
              required
              margin="normal"
              value={data.fc_9_4}
              onChange={(e) => setData({...data, fc_9_4: e.target.value})}
            /> <br />
            <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
          fullWidth
              id="totalFC"
              label="Total FC"
              variant="outlined"
              placeholder="Enter Total FC"
              required
              helperText="'Total FC' value must be the sum of 'FC Cash Dividend' and 'FC IPO'"
              margin="normal"
              value={data.totalFc}
              onChange={(e) => setData({...data, totalFc: e.target.value})}
            /> <br />
          </Grid>
        </Grid>
      </div>
    }
    if(data.decisionType === 'Stock'){
      dividendContent = <div>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={12} xs={12}> 
            <FormControl fullWidth>
                <InputLabel id="boaccount_id" >Select BO Account</InputLabel>
                    <Select style={{marginBottom: '25px'}}
                        required
                        labelId="boaccount_id"
                        id="demo-simple-select"
                        value={data.boaccount_id}
                        label="Select BO Account"
                        onChange={(e) => setData({...data, boaccount_id: e.target.value})}
                        MenuProps={MenuProps}  
                        >
                          { stockArray?.map((i)=><MenuItem value={i.id} key={i.id}>{i.companyName}</MenuItem>)}
                    </Select>  
            </FormControl> <br />

              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="bonusShare"
              label="Bonus Share"
              variant="outlined"
              placeholder="Enter Bonus Share"
              required
              margin="normal"
              value={data.stock_9_2}
              onChange={(e) => setData({...data, stock_9_2: e.target.value})}
            /> <br />
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
               fullWidth
              id="rightShare"
              label="Right Share"
              variant="outlined"
              placeholder="Enter Right Share"
              required
              margin="normal"
              value={data.stock_9_3}
              onChange={(e) => setData({...data, stock_9_3: e.target.value})}
            /> <br />
            <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
          fullWidth
              id="totalShare"
              label="Total Share"
              variant="outlined"
              placeholder="Enter Total Share"
              required
              helperText="'Total Share' value must be the sum of 'Bonus Share' and 'Right Share'"
              margin="normal"
              value={data.totalShare}
              onChange={(e) => setData({...data, totalShare: e.target.value})}
            /> <br />
          </Grid>
        </Grid>
      </div>
    }
    if(data.decisionType === 'All'){
      dividendContent = <div>
        <Grid container spacing={4}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormControl fullWidth >
                <InputLabel id="localcurrencybank_id" >Select Bank Name (currency in BDT)</InputLabel>
                    <Select style={{marginBottom: '25px'}}
                        required
                        labelId="localcurrencybank_id"
                        id="demo-simple-select"
                        value={data.localcurrencybank_id}
                        label="localcurrencybank_id"
                        onChange={(e) => setData({...data, localcurrencybank_id: e.target.value})}
                        MenuProps={MenuProps}  
                        >
                          {cashArray?.map((i)=><MenuItem value={i.id} key={i.id}>{i.companyName}</MenuItem>)}
                    </Select>  
            </FormControl> <br />
              <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
                fullWidth
                id="cashDividend"
                label="Cash Dividend"
                variant="outlined"
                placeholder="Enter Cash Dividend"
                required
                margin="normal"
                value={data.cash_9_1}
                onChange={(e) => setData({...data, cash_9_1: e.target.value})}
              /> <br />
                <TextField
                {...props}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.requiredAsterisk,
                    outlined: classes.root,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="ipoAmount"
                label="IPO"
                variant="outlined"
                placeholder="Enter IPO"
                required
                margin="normal"
                value={data.cash_9_4}
                onChange={(e) => setData({...data, cash_9_4: e.target.value})}
              /> <br />
              <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                classes: {
                  outlined: classes.root,
                },
              }}
                fullWidth
                id="totalCash"
                label="Total Cash"
                variant="outlined"
                placeholder="Enter Total Cash"
                required
                helperText="'Total Cash' value must be the sum of 'Cash Dividend' and 'IPO'"
                margin="normal"
                value={data.totalCash}
                onChange={(e) => setData({...data, totalCash: e.target.value})}
              /> <br />
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormControl fullWidth>
                  <InputLabel id="foreigncurrencybank_id" >Select Bank Name (currency in Dollar)</InputLabel>
                      <Select style={{marginBottom: '25px'}}
                          required
                          labelId="foreigncurrencybank_id"
                          id="demo-simple-select"
                          value={data.foreigncurrencybank_id}
                          label="foreigncurrencybank_id"
                          // SelectProps={{
                          //   sorted: true,
                          // }}
                          onChange={(e) => setData({...data, foreigncurrencybank_id: e.target.value})}
                          MenuProps={MenuProps}  
                          >
                            {fcArray?.map((i)=><MenuItem value={i.id} key={i.id}>{i.companyName}</MenuItem>)}
                      </Select>  
              </FormControl> <br />
            <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
                fullWidth
                id="cashDividend"
                label="FC Cash Dividend"
                variant="outlined"
                placeholder="Enter FC Cash Dividend"
                required
                margin="normal"
                value={data.fc_9_1}
                onChange={(e) => setData({...data, fc_9_1: e.target.value})}
              /> <br />
                <TextField
                {...props}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.requiredAsterisk,
                    outlined: classes.root,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="ipoAmount"
                label="FC IPO"
                variant="outlined"
                placeholder="Enter FC IPO"
                required
                margin="normal"
                value={data.fc_9_4}
                onChange={(e) => setData({...data, fc_9_4: e.target.value})}
              /> <br />
              <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                classes: {
                  outlined: classes.root,
                },
              }}
                fullWidth
                id="totalFC"
                label="Total FC"
                variant="outlined"
                placeholder="Enter Total FC"
                required
                helperText="'Total FC' value must be the sum of 'FC Cash Dividend' and 'FC IPO'"
                margin="normal"
                value={data.totalFc}
                onChange={(e) => setData({...data, totalFc: e.target.value})}
              /> <br />
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
          <FormControl fullWidth>
                  <InputLabel id="boaccount_id" >Select BO Account</InputLabel>
                      <Select style={{marginBottom: '25px'}}
                          required
                          labelId="boaccount_id"
                          id="demo-simple-select"
                          value={data.boaccount_id}
                          label="Select Issuer"
                          // SelectProps={{
                          //   sorted: true,
                          // }}
                          onChange={(e) => setData({...data, boaccount_id: e.target.value})}
                          MenuProps={MenuProps}  
                          >
                            { stockArray?.map((i)=><MenuItem value={i.id} key={i.id}>{i.companyName}</MenuItem>)}
                      </Select>  
          </FormControl> <br />
            <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                outlined: classes.root,
              },
            }}
                fullWidth
                id="bonusShare"
                label="Bonus Share"
                variant="outlined"
                placeholder="Enter Bonus Share"
                required
                margin="normal"
                value={data.stock_9_2}
                onChange={(e) => setData({...data, stock_9_2: e.target.value})}
              /> <br />
                <TextField
                {...props}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.requiredAsterisk,
                    outlined: classes.root,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="rightShare"
                label="Right Share"
                variant="outlined"
                placeholder="Enter Right Share"
                required
                margin="normal"
                value={data.stock_9_3}
                onChange={(e) => setData({...data, stock_9_3: e.target.value})}
              /> <br />
              <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                classes: {
                  outlined: classes.root,
                },
              }}
                fullWidth
                id="totalShare"
                label="Total Share"
                variant="outlined"
                placeholder="Enter Total Share"
                required
                helperText="'Total Share' value must be the sum of 'Bonus Share' and 'Right Share'"
                margin="normal"
                value={data.totalShare}
                onChange={(e) => setData({...data, totalShare: e.target.value})}
              /> <br />
            </Grid>
          
        </Grid>  
      </div>
    }

const [photoOfTransaction, setPhotoOfTransaction] = useState('');

  
//  console.log(dataStore.issuer.name);


//console.log( boaccount_id);

// console.log(data)

    return (
        <div >

        <form onSubmit={submitHandler}>  
          <Grid container spacing={4}>
            
              <Grid item lg={4} md={4} sm={12} xs={12}>  {/* SELECT ISSUER FIELD */}
                <FormControl fullWidth >
                  
                    {userType === 'Issuer'?
                      <>
                      <InputLabel id="issuer-id" >Select Issuer Name</InputLabel>
                        <Select 
                          required
                          labelId="issuer-id"
                          id="demo-simple-select"
                          value={data.tradeCode}
                          label="Select Issuer"
                          // SelectProps={{
                          //   sorted: true,
                          // }}
                          onChange={(e) => setData({...data, tradeCode: e.target.value})}
                          MenuProps={MenuProps}  
                        >   
                        <MenuItem value={issuerId} key={issuerId}>{issuerName}</MenuItem>
                        </Select>  
                      </>:  
            <>
            {/* <Select 
            required
            labelId="issuer-id"
            id="demo-simple-select"
            value={data.tradeCode}
            label="Select Issuer"
            // SelectProps={{
            //   sorted: true,
            // }}
            onChange={(e) => {setData({...data, tradeCode: e.target.value});console.log(data)}}
            MenuProps={MenuProps}  
            >
              {sortedIssuerData?.map((i)=><MenuItem value={i.id} key={i.id}>({i.tradecode}) {i.name}</MenuItem>)}
            {issuer?.payload.map((i)=><MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>)}
        </Select> */}

          <Autocomplete        
                value={data.name}
                label="Select Issuer"
                onChange={(event, newValue) => {
                   setData({...data, tradeCode: newValue.id});
                  // console.log(data.tradeCode)
                }}
                disableClearable
                options={sortedNewData?sortedNewData:newData?newData:options}
                getOptionLabel={(option) => option.name +" (" + option.tradecode + ") " }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Select Issuer Name"
                    // margin="normal"
                    // variant="outlined"
                    // InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
                />
                </>     }        
                </FormControl>
                
                  <h4 style={{marginTop:"10px"}}> </h4> 
              </Grid>
              
             { yearTypeAvailable=== 'true' &&<Grid item lg={3} md={3} sm={12} xs={12}>  {/* DIVIDEND YEAR */}
              
                  {/* <div>
                          <FormControl sx={{ m: 1, width: 300 }} fullWidth>
                          <InputLabel id="demo-multiple-checkbox-label">Dividend Year</InputLabel>
                          <Select
                          required
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              value={personName}
                              onChange={handleChange}
                              input={<OutlinedInput label="dividendYear" />}
                              renderValue={(selected) => selected.join(', ')}
                              MenuProps={MenuProps}
                              >
                              {dividendYear.map((name) => (
                              <MenuItem key={name} value={name}>
                                  <Checkbox checked={personName.indexOf(name) > -1} />
                                  <ListItemText primary={name} />
                              </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                      </div> */}
                  {/* <TextField
                      {...props}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        max: `${currentDate}`
                      }}
                      fullWidth
                        id="date-year"
                        label="Dividend Year"
                        helperText="Type the dividend year as per your company"
                        variant="outlined"
                        required
                        type='text'
                        multiline
                      //  rows={2}
                        margin="normal"
                        value={data.dividendYear}
                        onChange={(e) => {setData({...data, dividendYear: e.target.value});console.log(data)}}
                    /> */}

                <FormControl fullWidth >
                  <InputLabel id="issuer-id" >Dividend Year</InputLabel>
                    <Select style={{marginTop:'18px'}}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={data.dividendYear}
                       onChange={(e,newValue)=>handleChange(e,newValue)}
                      
                      // options={thisCompanysYearType?thisCompanysYearType:''}
                      renderValue={(selected) => selected.map(item => item.year).join(', ')}
                      MenuProps={MenuProps}
                      // onChange={(e) => {setData({...data, tradeCode: e.target.value});console.log(data)}}
                      // MenuProps={MenuProps}  
                      >
                        {/* {thisCompanysYearType?.map((i)=><MenuItem value={i.id} key={i.id}>{i.year}</MenuItem>)} */}
                         {thisCompanysYearType.map((i) => (
                            <MenuItem key={i.id} value={i}>
                              <Checkbox checked={yearShow.indexOf(i) > -1} />
                              <ListItemText primary={i.year} />
                            </MenuItem>
                          ))}
                    </Select>
                </FormControl>
              </Grid>} 

              {yearTypeAvailable=== 'false' && 
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <h6>Company's year type is not defined yet. <br/>Please define it First.</h6>
                <a href="/app/issuer/yearDefine">Go to Year Define</a></Grid>}

              


              <Grid item lg={4} md={4} sm={12} xs={12}>  {/* Ref ID */}         
              <TextField
              {...props}
              InputLabelProps={{
                classes: {
                  asterisk: classes.requiredAsterisk,
                  outlined: classes.root,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
              }}
              fullWidth
                id="ref"
                label="Ref"
                variant="outlined"
                placeholder="Enter the Ref ID"
                helperText="If Ref is not maintained by your company then type 'Not Available'"
                required
                margin="normal"
                value={data.ref}
                onChange={(e) => setData({...data, ref: e.target.value})}
              /> 
                
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>  {/* Dividend Type*/}
                  <FormControl fullWidth >
                  <InputLabel id="issuer-id" >Dividend Type</InputLabel>
                  <Select
                  required 
                      labelId="issuer-id"
                      id="demo-simple-select"
                      value={data.decisionType}
                      label="Select Issuer"
                      onChange={(e) => setData({...data, decisionType: e.target.value})}
                      >
                  <MenuItem value={"Cash"} >Cash</MenuItem>
                  <MenuItem value={"FC"} >FC</MenuItem>
                  <MenuItem value={"Stock"} >Stock</MenuItem>
                  <MenuItem value={"All"} >All</MenuItem>
                  </Select>
                  </FormControl>
                  <h4 style={{marginTop:"1px"}}> </h4>
              </Grid>  

              <Grid item lg={4} md={4} sm={12} xs={12}>   {/* payment date*/}
            
            <TextField
            {...props}
            InputLabelProps={{
              classes: {
                asterisk: classes.requiredAsterisk,
                outlined: classes.root,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              ),
            }}
            inputProps={{
              max: `${currentDate}`
            }}
            fullWidth
              id="date-submit"
              label="Payment Date"
              variant="outlined"
              required
              type='date'
              margin="normal"
              value={data.date}
              onChange={(e) => setData({...data, date: e.target.value})}
            /><br />
              </Grid>  

                 
          </Grid>

             {dividendContent}
            
          <Grid container spacing={2}>
           <Grid item lg={6} md={6} sm={12} xs={12}>
             <TextField
             {...props}
             InputLabelProps={{
               classes: {
                 asterisk: classes.requiredAsterisk,
                 outlined: classes.root,
               },
             }}
             InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                 </InputAdornment>
               ),
             }}
              id="file"
              label="Transaction (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Enter Your Transaction File"
              required
              helperText="This requires the forwarding letter send by the company to CMSF"
              focused
              fullWidth
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, images: e.target.files[0]})
                  setPhotoOfTransaction (URL.createObjectURL(e.target.files[0]));

                }else{
                  e.target.value = null;
                  setData({...data, images: []});
                }
              } }
            />
            </Grid>

            <div style={{marginBottom: "10px"}}>
                 {data.images.length === 0 ? null : data.images.name}
            </div>
           <Grid item lg={6} md={6} sm={12} xs={12}>
             <TextField
             {...props}
             InputLabelProps={{
               classes: {
                 asterisk: classes.requiredAsterisk,
                 outlined: classes.root,
               },
             }}
             InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                 </InputAdornment>
               ),
             }}
             
              id="remarks"
              label="Remarks"
              variant="outlined"
              placeholder="Enter Remarks"
              //multiline
              //rows={2}
              fullWidth
              margin="normal"
              value={data.remarks}
              onChange={(e) => setData({...data, remarks: e.target.value})}
            />
            </Grid>
            </Grid>
            <br />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={
            
                Number(data.totalCash)  !== Number(data.cash_9_1) + Number(data.cash_9_4) ||
                Number(data.totalFc)  !== Number(data.fc_9_1) + Number(data.fc_9_4) ||
                Number(data.totalShare)  !== Number(data.stock_9_2) + Number(data.stock_9_3) ||
                loading ||
                yearTypeAvailable=== 'false'
              }
            >
              { t('common.submit')}
            </Button>
                </form>                        
        </div>
    )
}
export default Form;