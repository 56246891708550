import {
  Button, FormControl, Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField, Typography,
} from "@material-ui/core";
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import PageTitle from '../../components/PageTitle/PageTitle';
import axios from './../../utils/axios';
import LeaveApplicationTable from './LeaveApplicationTable';

const LeaveApplication = () => {
  const token = localStorage.getItem('bearer-token');
  const decoded = jwtDecode(token);
  // console.log(decoded);

  const [employee, setEmployee] = useState("");
  const [formValues, setFormValues] = useState({
    empId: '',
    leaveType: '',
    fromDate: '',
    toDate: '',
    totalDays: 0,
    remarks: '',
  });

  const [errors, setErrors] = useState({});
  const [leaveApplications, setLeaveApplications] = useState([]);
 

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/users/${decoded.id}`);
        if (response.data && response.data.payload) {
          const empId = response.data.payload.empId;
          const fullName = response.data.payload.fullName;
          getLeave(empId);
          setEmployee(`${empId} : ${fullName}`);
          setFormValues(prev => ({ ...prev, empId: empId }));
        } else {
          console.error('No payload in the response');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [decoded.id]);

  // Ensure getLeave function is defined here
  const getLeave = async (empId) => {
    try {
      const res =  await  axios.get(`/employee/get-leave?empId=${empId}`);
      const sortedDatabyApplyDate = res.data.payload
      .sort((a, b) => new Date(b.entry_date) - new Date(a.entry_date));

      // setLeaveApplications(res.data.payload); // Initialize leaveApplications
      setLeaveApplications(sortedDatabyApplyDate);
    } 
    catch (error) 
    {
      console.error('Error fetching leave data:', error);
    }
  }

  useEffect(() => {
    const fetchLeaveData = async () => {
      const empId = formValues.empId; // Fetch the employee ID
        getLeave(empId); // Call the function to fetch leave applications
    };

    fetchLeaveData();
  }, [formValues.empId]);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; // Today's date in YYYY-MM-DD format
  
    // Initialize error state and totalDays for consistency
    let errors = {};
    let totalDays = formValues.totalDays;

    // Calculate total days based on the selected dates
    const calculateTotalDays = (fromDate, toDate) => {
        if (fromDate && toDate) {
            const startDate = new Date(fromDate);
            const endDate = new Date(toDate);
            if (endDate >= startDate) {
                const timeDifference = endDate - startDate;
                return Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1; // Calculate total days
            }
        }
        return 0; // Return 0 if the dates are not valid
    };

    // Check leave type and set validation accordingly
    if (formValues.leaveType === 'Early Departure') {
        // Set both dates to today and validate for "Early Departure"
        if (formValues.fromDate !== today || formValues.toDate !== today) {
            setFormValues(prevValues => ({
                ...prevValues,
                fromDate: today,
                toDate: today
            }));
        }
        errors = { fromDate: '', toDate: '' };
        totalDays = 1;
    } 
    else if (formValues.leaveType === 'On Duty') {
        // Ensure "On Duty" is set to a future date and both dates match
        if (formValues.fromDate <= today) {
            errors.fromDate = 'On Duty must be set to a future date only';
            totalDays = 1;
        } else {
            // Set toDate to match fromDate for "On Duty"
            setFormValues(prevValues => ({
                ...prevValues,
                toDate: formValues.fromDate
            }));
            errors = { fromDate: '', toDate: '' };
            totalDays = 1;
        }
    } 
    else if (formValues.leaveType === 'Sick leave') {
      if (new Date(formValues.toDate) < new Date(formValues.fromDate)) {
          errors.toDate = 'To Date should be greater than or equal to From Date';
          totalDays = 0;
      } else {
          errors.toDate = ''; // No error for toDate
          totalDays = calculateTotalDays(formValues.fromDate, formValues.toDate);
      }
      errors.fromDate = ''; // No error for fromDate
  }
  
    else {
        // Validation for other leave types
        if (formValues.fromDate && formValues.fromDate < today) {
            errors.fromDate = 'From Date cannot be in the past for this leave type';
        } else {
            errors.fromDate = '';
        }

        if (formValues.fromDate && formValues.toDate) {
            const startDate = new Date(formValues.fromDate);
            const endDate = new Date(formValues.toDate);
            if (endDate >= startDate) {
                totalDays = calculateTotalDays(formValues.fromDate, formValues.toDate);
            } else {
                errors.toDate = 'To Date must be greater than From Date';
                totalDays = 0;
            }
        }
    }

    // Update form values and errors
    setFormValues(prevValues => ({
        ...prevValues,
        totalDays
    }));
    setErrors(errors);

}, [formValues.leaveType, formValues.fromDate, formValues.toDate]);

  
  


  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    });

    setErrors({
      ...errors,
      [e.target.name]: ''
    });
  };

  const hasErrors = Object.values(errors).some(error => error);

  const handleSave = async () => {
    let validationErrors = {};

    // Perform validation checks
    if (!formValues.empId) validationErrors.empId = 'Please enter Employee ID';
    if (!formValues.leaveType) validationErrors.leaveType = 'Please select a Leave Type';
    if (!formValues.fromDate) validationErrors.fromDate = 'Please select From Date';
    if (!formValues.toDate) validationErrors.toDate = 'Please select To Date';
    if (!formValues.remarks) validationErrors.remarks = 'Please input remarks';

    // If there are validation errors, update the state and return early
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Prevent data posting
    }

    const leaveTypeMapping = {
      'Casual leave': 1,
      'Sick leave': 2,
      'Earned leave': 3,
      'Maternity leave': 4,
      'Early Departure' : 5,
      'On Duty' : 6,
      'Paternity Leave' : 7
    };

    const mappedLeaveType = leaveTypeMapping[formValues.leaveType];

    // Prepare the payload with leaveType as number, entryBy as 10, and include remarks
    const applicationData = {
      empId: formValues.empId,
      leaveType: mappedLeaveType,
      fromDate: formValues.fromDate,
      toDate: formValues.toDate,
      totalDays: formValues.totalDays,
      entryBy: decoded.id,  // Default entryBy value
      remarks: formValues.remarks // Include remarks field
    };

    try {
      if (window.confirm('Are you sure you want to save the data?')) {
        const res = await axios.post('/employee/create-leave', applicationData);
        Swal.fire({
          title: `${res.data.payload.status}!`,
          text: res.data.payload.message,
          icon: res.data.payload.status === "success" ? 'success' : 'warning',
          confirmButtonText: 'OK'
        }).then(async (result) => {
          // Re-fetch leave applications after SweetAlert is closed
          if (result.isConfirmed) {
            await getLeave(formValues.empId); // Call the function to fetch updated leave applications
          }
        });

        // Reset form values
        setFormValues({
          empId: '',
          leaveType: '',
          fromDate: '',
          toDate: '',
          totalDays: 0,
          remarks: '',
        });
      }
    } catch (error) {
      console.error('Failed to submit leave application:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to submit leave application. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  // Edit application
  const handleEdit = (application) => {
    setLeaveApplications(application);
  };

  return (
    <>
      <PageTitle title={"Leave Application Dashboard"} />
      <Grid container spacing={3}>
        <Grid item lg={12} md={6} sm={12} xs={12}>
          <Grid container spacing={3}>
            {/* Form Fields */}
            <Grid item lg={6}>
              <FormControl fullWidth>
                <TextField
                  label="Emp-ID"
                  variant="outlined"
                  name="empId"
                  value={employee} // Display empId: empName
                  onChange={(e) => {
                    const empIdValue = e.target.value.split(":")[0]; // Extract empId when value changes
                    handleChange({ target: { name: 'empId', value: empIdValue } });
                  }}
                  required
                  error={!!errors.empId}
                />
                {errors.empId && (
                  <Typography color="error" variant="body2">
                    {errors.empId}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item lg={6}>
              <FormControl fullWidth>
                <InputLabel id="leave-type-label">Leave Type</InputLabel>
                <Select
                  labelId="leave-type-label"
                  variant="outlined"
                  name="leaveType"
                  value={formValues.leaveType}
                  onChange={handleChange}
                  required
                  error={!!errors.leaveType}
                >
                  <MenuItem value="Casual leave">Casual leave</MenuItem>
                  <MenuItem value="Sick leave">Sick leave</MenuItem>
                  <MenuItem value="Earned leave">Earned leave</MenuItem>
                  <MenuItem value="Maternity leave">Maternity leave</MenuItem>
                  <MenuItem value="Early Departure">Early Departure</MenuItem>
                  <MenuItem value="On Duty">On Duty</MenuItem>
                  <MenuItem value="Paternity Leave">Paternity Leave</MenuItem>
                </Select>
                {errors.leaveType && (
                  <Typography color="error" variant="body2">
                    {errors.leaveType}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item lg={6}>
              <FormControl fullWidth>
                <TextField
                  margin="normal"
                  label="From Date"
                  type="date"
                  variant="outlined"
                  name="fromDate"
                  InputLabelProps={{ shrink: true }}
                  // inputProps={{
                  //   min: new Date().toISOString().split('T')[0], // Set the minimum date to today
                  // }}
                  value={formValues.fromDate}
                  onChange={handleChange}
                  required
                  error={!!errors.fromDate}
                />
                {errors.fromDate && (
                  <Typography color="error" variant="body2">
                    {errors.fromDate}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item lg={6}>
              <FormControl fullWidth>
                <TextField
                  margin="normal"
                  label="To Date"
                  type="date"
                  variant="outlined"
                  name="toDate"
                  InputLabelProps={{ shrink: true }}
                  // inputProps={{
                  //   min: new Date().toISOString().split('T')[0], // Set the minimum date to today
                  // }}
                  value={formValues.toDate}
                  onChange={handleChange}
                  required
                  error={!!errors.toDate}
                />
                {errors.toDate && (
                  <Typography color="error" variant="body2">
                    {errors.toDate}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item lg={6}>
              <FormControl fullWidth>
                <TextField
                  label="Total Days"
                  variant="outlined"
                  name="totalDays"
                  value={formValues.totalDays}
                  InputProps={{ readOnly: true }}
                />
              </FormControl>
            </Grid>

            <Grid item lg={6}>
              <FormControl fullWidth>
                <TextField
                  label="Remarks"
                  variant="outlined"
                  name="remarks"
                  value={formValues.remarks}
                  onChange={handleChange}
                  required
                  error={!!errors.remarks}
                />
                {errors.remarks && (
                  <Typography color="error" variant="body2">
                    {errors.remarks}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item lg={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={hasErrors}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* Leave Application Table */}
        <Grid item lg={12} md={6} sm={12} xs={12}>
          <LeaveApplicationTable 
            leavedata={leaveApplications} // Use leaveApplications state for dynamic updates
            onEdit={handleEdit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LeaveApplication;
