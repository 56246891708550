// import {
//     ArrowBack as ArrowBackIcon,
//     HelpOutline as FAQIcon,
//     FindInPage,
//     Home as HomeIcon, LibraryBooks as LibraryIcon, QuestionAnswer as SupportIcon
// } from "@material-ui/icons";
// import GroupIcon from '@material-ui/icons/Group';
// import GroupAddIcon from '@material-ui/icons/GroupAdd';
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import LocationCityIcon from '@material-ui/icons/LocationCity';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
// import AddBoxIcon from '@mui/icons-material/AddBox';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import AnnouncementIcon from '@mui/icons-material/Announcement';
// import AppsIcon from '@mui/icons-material/Apps';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import {
    FcAddDatabase,
    FcApproval, FcAutomatic, FcBusinessman, FcConferenceCall, FcCurrencyExchange,
    FcDataSheet, FcDebt, FcDecision, FcDepartment, FcHome, FcLibrary, FcList, FcVoicePresentation
} from "react-icons/fc";
export default {
    FcApproval,
    FcAutomatic,
    FcBusinessman,
    FcConferenceCall,
    FcCurrencyExchange,
    FcDataSheet,
    FcDebt,
    FcDecision,
    FcDepartment,
    FcHome,
    FcLibrary,
    FcList,
    FcVoicePresentation,
    FcAddDatabase

};