import axios from "axios";

function getUserFromLocalStorage() {
  const storage = window.localStorage;
  if (!storage) {
    return null;
  }
  const userJson = storage.getItem("bearer-token");
  if (!userJson) {
    return null;
  }
  return JSON.parse(userJson);
}

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v1/`,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getUserFromLocalStorage();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

// axiosInstance.interceptors.response.use(response => {
//   return response;
// }, error => {
//  if (error.response.status === 401) {
//   localStorage.clear()
//  }
//  return error;
// });

export default axiosInstance;