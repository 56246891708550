import { Button, Grid } from '@material-ui/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ResponsiveContainer } from 'recharts';
import ReportStyle from '../../reports/ClaimReport/Report.module.css';
import styles from '../../reports/ClaimReport/Table.module.css';
import CmsfLogo from '../../reports/ClaimReport/logo.png';


const UiOfAttendanceReport = ({ data,stateValus,reportType}) => {

  
  const params = useParams();
  const pdfRef = useRef();
  const pdfRef2 = useRef();

// const nameOfCompany1 = data[0].issuername;
// if(data.length > 1) {var nameOfCompany2 = data[1].issuername}


  // const generatePdf = () => {

  //   const pdf = new jsPDF('p', 'mm', 'a4');
  
  //   html2canvas(pdfRef.current, {
  //     scale:2,
  //     useCORS: true,
  //   }).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     pdf.addImage(imgData, 'JPEG', 10, 20, 170, 257); 
  //     pdf.save('Claim_Report.pdf');
  //   });
  // };

  const contentRef = useRef();

  const generatePdf = () => {
    const pdf = new jsPDF('p', 'mm', 'a4'); // Create a new PDF document
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    html2canvas(pdfRef.current, {
      scale: 2,
      useCORS: true,
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const aspectRatio = imgHeight / imgWidth;
      const scaleFactor = pdfWidth / imgWidth;
      let remainingHeight = imgHeight;
      let position = 0;

      while (remainingHeight > 0) {
        const currentHeight = Math.min(remainingHeight, pdfHeight / scaleFactor);

        pdf.addImage(
          imgData,
          'PNG',
          0,
          position,
          pdfWidth,
          pdfWidth * aspectRatio * (currentHeight / imgHeight),
          undefined,
          'FAST'
        );

        remainingHeight -= currentHeight;
        position = -((imgHeight - remainingHeight) / scaleFactor);

        if (remainingHeight > 0) {
          pdf.addPage();
          position = 0;
        }
      }

      pdf.save('Employee_Attendance_Report.pdf');
    });
  };

  const generatePdf2 = async () => {
    const content = pdfRef.current;

    const pdf = new jsPDF('p', 'mm', 'a4');
    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL('image/png');

    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // pdf.save(`Attendance_Report_${stateValus.empId}.pdf`);
    pdf.save(`Attendance_Report_Report.pdf`);
  };

  var late = 0
  // let startDate = new Date(stateValus.fromDate.split('-').reverse().join('-'))
  // let endDate = new Date(stateValus.toDate.split('-').reverse().join('-'))
  let startDate = new Date(stateValus.fromDate)
  let endDate = new Date(stateValus.toDate)
 
   const timeDifference = endDate - startDate;
   const dayDifference = (timeDifference / (1000 * 3600 * 24))+1;
  // console.log( endDate +' <--> '+startDate )
  // console.log(dayDifference)

  const lateCount = data.filter((item) => item.remarks === 'Late').length;
  const holidayCount = data.filter((item) => item.remarks === 'holiday').length;
  const casualLeaveCount = data.filter((item) => item.remarks === 'Casual Leave').length;
  const sickLeaveCount = data.filter((item) => item.remarks === 'Sick Leave').length;
  const leaveCount = casualLeaveCount + sickLeaveCount;
  const weekendCount = data.filter((item) => item.remarks === 'weekend').length;
  const salaryDeductCount = data.filter((item) => item.remarks === 'Absent').length + Math.floor(lateCount/3);
  const absentCount = data.filter((item) => item.remarks === 'Absent').length;

  return (
    <>
      <ResponsiveContainer style={{ width: '80%', maxWidth: '80%', padding: '10px' }}>
          
        <Grid ref={pdfRef2} className={ReportStyle.form}> 

        </Grid>

        <Grid ref={pdfRef} className={ReportStyle.form}>

        <Grid className={ReportStyle.headerLogo}>
                  <div className={ReportStyle.CmsfLogo}>
                {/* <img className={ReportStyle.Golden50} src={Golden50} alt='CMSF Logo' /> */}
                  </div>
                  <div className={ReportStyle.CmsfLogo}>
                <img className={ReportStyle.CmsfLogo} src={CmsfLogo} alt='CMSF Logo' />
                  </div>
                  <div className={ReportStyle.Mujib100}>
                {/* <img className={ReportStyle.Mujib100} src={Mujib100} alt='CMSF Logo' /> */}
                  </div>

              </Grid>

        <Grid className={ReportStyle.address} style={{ textAlign: 'center', fontSize: '1rem', margin: '10px 0' }}>
            <span>Dhaka Chamber Building(3rd Floor), 65-66 Motijheel C/A, Dhaka-1000</span><br />
            <span>Tel: +880241052430, Email: claim@cmsfbd.org</span>
        </Grid>

          <h4 style={{ textAlign: 'center', fontSize: '2rem' }}><strong>Attendance Report</strong></h4>
          <h6 style={{ textAlign: 'center'}}><strong>From:</strong> {stateValus.fromDate} <strong>To:</strong> {stateValus.toDate}</h6>
          <br></br>
          {reportType==='Individual'?<h6 style={{ textAlign: 'center'}}><strong>Days:</strong> {dayDifference}<strong> <strong> Leave:</strong> {leaveCount} Holidays:</strong> {holidayCount}<strong> Weekend:</strong> {weekendCount} <strong> Absent:</strong> {absentCount} </h6>:''}
          {reportType==='Individual'?<h6 style={{ textAlign: 'center'}}>  <strong> Penalty:</strong> {lateCount} <strong>Salary Deduct:</strong> {salaryDeductCount}<strong> Salary Days:</strong> {dayDifference-salaryDeductCount}</h6>:''}
          <br></br>
          <Grid className={ReportStyle.GategoryName2} style={{ overflowX: 'auto' ,}}>
            <table style={{ width: '90%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th className={styles.td} colSpan={1}>Sl</th>
                  <th className={styles.td} colSpan={3}>Name</th>
                  <th className={styles.td} colSpan={2}>Designation</th>
                  <th className={styles.td} colSpan={2}>Date</th>
                  <th className={styles.td} colSpan={2}>Log In Time</th>
                  <th className={styles.td} colSpan={2}>Log Out Time</th>
                  <th className={styles.td} colSpan={2}>Remarks</th>
                  
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} >
                    <td className={styles.td} colSpan={1}>{index + 1}</td>
                    <td className={styles.td} colSpan={3}>{item.emp_name}</td>
                    <td className={styles.td} colSpan={2}>{item.desig}</td>
                    <td className={styles.td} colSpan={2}>{item.attdate}</td>
                    {/* <td className={styles.td} colSpan={3}>{
                      (() => {
                        const date = new Date(item.punch_date);
                        return date.toLocaleDateString("en-GB");
                      })()}</td> */}

                    <td className={styles.td} colSpan={2}>{item.intime}</td>
                    {/* <td className={styles.td} colSpan={3}>{
                      (() => {
                        const date = new Date(item.check_in_time);
                        return date.toLocaleTimeString("en-GB");
                      })()}</td> */}

                    <td className={styles.td} colSpan={2}>{item.outtime}</td>
                    {/* <td className={styles.td} colSpan={3}>{
                      (() => {
                        const date = new Date(item.check_out_time);
                        return date.toLocaleTimeString("en-GB");
                      })()}</td> */}

                       {/* <td className={styles.td} style={{ backgroundColor: item.remarks === 'Late' ? '#FF7F7F' : item.remarks === 'weekend' ? 'yellow' : '' }}colSpan={3}>
                        {item.remarks}
                        </td> */}
                        <td className={styles.td} colSpan={3}>
                        {item.remarks}
                        </td>
                  </tr>
                ))}
                {/* <tr>
                  <td className={styles.td} colSpan={6} style={{ fontSize: '1.3rem', textAlign: 'center', fontWeight: 'bold' }}>Total</td>
                  <td className={styles.td} colSpan={3} style={{ fontSize: '1rem', fontWeight: 'bold' }}>{grandTotal.nst.toFixed(2)}</td>
                  <td className={styles.td} colSpan={3} style={{ fontSize: '1rem', fontWeight: 'bold' }}>{grandTotal.sett.toFixed(2)}</td>
                  <td className={styles.td} colSpan={3} style={{ fontSize: '1rem', fontWeight: 'bold' }}>{grandTotal.outs.toFixed(2)}</td>
                </tr> */}
              </tbody>
            </table>
          </Grid>

          {/* <Grid className={ReportStyle.msgDiv}>
            <span className={ReportStyle.msg}><i>This is a system-generated report.</i></span>
          </Grid> */}
        </Grid>

        <Button 
          style={{ margin: '10px', marginTop: '20px', backgroundColor: '#45CFDD' }}  
          variant="contained" 
          color="default" 
          onClick={generatePdf2}
        >
          Download Attendance Report
        </Button>

      </ResponsiveContainer>
    </>
  );
};

export default UiOfAttendanceReport;
