// import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Grid } from "@material-ui/core";
import { ViewListOutlined } from "@material-ui/icons";
import React, { useEffect, useState ,useRef} from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../utils/axios";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Table from './Table';
import { makeStyles } from '@material-ui/core/styles';
import UiOfReport from "../../../reports/ClaimReport/InvestorReport/UiOfReport";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


function FilterTableComponent() {
    const pdfRef = useRef();
  const pdfRef2 = useRef();
    const classes = useStyles();
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    const [inwardDividendType, setInwardDividendType] = useState('');

    const handleInwardDividendStateChange = (newState) => {
        setInwardDividendType(newState);
    };
    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };

    const 
    paramsData ={
        page: currentPage,
        take: showPageSize,
    }

    // useEffect(() =>{
    //     axios.post(`investor-informations/issuer-wise-inward-report`, paramsData)
    //     .then((res) =>{
    //         setDataStore(res.data.payload)
           
    //         setCurrentPage(1);
    //         if(res.data?.page){
    //             setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
    //         }
    //     })
    //     .catch((err) =>{
    //     })
    // },[])

  
    
    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.post('investor-informations/issuer-wise-investors', paramsData)
        .then((res) =>{
            setDataStore(res.data.payload.result)
            setCurrentPage(page);
            setShowPageSize(take)
            
        })
        .catch((err) =>{
            alert("There was a error occured!")
        })
    }
    const columns = React.useMemo(
        () => [
            {
                Header: 'Investors of the selected Company',
                columns: [
                    {
                        Header: 'Investor name',
                        accessor: 'sn'
                    },
                   
                    {
                        Header: 'BO ID',
                        accessor: 'boid'
                    },
                    {
                        Header: 'Folio',
                        accessor: 'foid'
                    },
                    
                    // {
                    //     Header: 'Dividend Type',
                    //     accessor: 'investors_dividend_type',
                    //     Cell: ({row}) => <>
                    //     {row.values.investors_dividend_type === 'CashDividend' ? (
                    //         'Cash Dividend'
                    //       ) : row.values.investors_dividend_type === 'FCCashDividend' ? 
                    //       (
                    //          `FC Cash Dividend`

                    //       ) : row.values.investors_dividend_type === 'StockRightShare' ? (
                    //          `Stock Right Share`

                    //       ) : row.values.investors_dividend_type === 'StockBonusShare' ? (
                    //           `Stock Bonus Share`
                    //       ) : row.values.investors_dividend_type === 'PublicSubscriptionMoney'? (
                    //           `Public Subscription Money`

                    //      ) : row.values.investors_dividend_type === 'FCPublicSubscriptionMoney' ? (
                    //           `FC Public Subscription Money`

                    //       )  : row.values.investors_dividend_type === 'CashDividend' ? (
                    //         `Cash Dividend`
                    //     ) : row.values.investors_dividend_type === 'FCCashDividend' ? (
                    //         `FC Cash Dividend`
                    //     ) : row.values.investors_dividend_type === 'FractionDividend' ? (
                    //         `Fraction Dividend`
                    //     ) : row.values.investors_dividend_type === 'DividendOnDividend' ? (
                    //         `Dividend On Dividend`
                    //     )
                    //     :(
                    //          <></>
                    //      )}</>
                    // },
                     {
                        Header: 'Cash Div Year',
                        accessor: 'dyear'
                    },
                    {
                        Header: 'Cash',
                        accessor: 'cashamt',
                        Cell: ({row}) => <>
                            {row.values.cashamt.toFixed(2)}
                            {/* {row.values.netamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                        </>
                    },
                    {
                        Header: 'Fraction',
                        accessor: 'fracamt',
                        Cell: ({row}) => <>
                            {row.values.fracamt.toFixed(2)}
                            {/* {row.values.netamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                        </>
                    },
                    {
                        Header: 'IPO',
                        accessor: 'ipoamt',
                        Cell: ({row}) => <>
                            {row.values.ipoamt.toFixed(2)}
                            {/* {row.values.netamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                        </>
                    },
                    {
                        Header: 'Net Cash',
                        accessor: 'netamt',
                        Cell: ({row}) => <>
                            {row.values.netamt.toFixed(2)}
                            {/* {row.values.netamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                        </>
                    },
                     {
                        Header: 'Stock Div Year',
                        accessor: 'dyearstock'
                    },
                    {
                        Header: 'Bonus Share',
                        accessor: 'bshare'
                    },
                    {
                        Header: 'Right Share',
                        accessor: 'rightshare'
                    },
                    {
                        Header: 'Div On Div',
                        accessor: 'dshare'
                    },
                    
                    {
                        Header: 'Net Share',
                        accessor: 'netshare',
                        // Cell: ({row}) => <>
                        // {row.values.netshare === null && (
                        //     '0'
                        //   )}</>
                    },
                ],
              
            },
        ],
        []
    )
    const columnsCash = React.useMemo(
        () => [
            {
                Header: 'Investors of the selected Company',
                columns: [
                    {
                        Header: 'Investor name',
                        accessor: 'sn'
                    },
                   
                    {
                        Header: 'BO ID',
                        accessor: 'boid'
                    },
                    {
                        Header: 'Folio',
                        accessor: 'foid'
                    },
                    
                    {
                        Header: 'Dividend Year',
                        accessor: 'dyear'
                    },
                    {
                        Header: 'Cash',
                        accessor: 'cashamt',
                        Cell: ({row}) => <>
                            {row.values.cashamt.toFixed(2)}
                            {/* {row.values.netamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                        </>
                    },
                    {
                        Header: 'Fraction',
                        accessor: 'fracamt',
                        Cell: ({row}) => <>
                            {row.values.fracamt.toFixed(2)}
                            {/* {row.values.netamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                        </>
                    },
                    {
                        Header: 'IPO',
                        accessor: 'ipoamt',
                        Cell: ({row}) => <>
                            {row.values.ipoamt.toFixed(2)}
                            {/* {row.values.netamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                        </>
                    },
                    {
                        Header: 'Net Cash',
                        accessor: 'netamt',
                        Cell: ({row}) => <>
                            {row.values.netamt.toFixed(2)}
                            {/* {row.values.netamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                        </>
                    },
                ],
              
            },
        ],
        []
    )
    const columnsStock = React.useMemo(
        () => [
            {
                Header: 'Investors of the selected Company',
                columns: [
                    {
                        Header: 'Investor name',
                        accessor: 'sn'
                    },
                   
                    {
                        Header: 'BO ID',
                        accessor: 'boid'
                    },
                    {
                        Header: 'Folio',
                        accessor: 'foid'
                    },
                     {
                        Header: 'Dividend Year',
                        accessor: 'dyearstock'
                    },
                    {
                        Header: 'Bonus Share',
                        accessor: 'bshare'
                    },
                    {
                        Header: 'Right Share',
                        accessor: 'rightshare'
                    },
                    {
                        Header: 'Div On Div',
                        accessor: 'dshare'
                    },
                    
                    {
                        Header: 'Net Share',
                        accessor: 'netshare',
                        // Cell: ({row}) => <>
                        // {row.values.netshare === null && (
                        //     '0'
                        //   )}</>
                    },
                ],
              
            },
        ],
        []
    )
    
console.log(inwardDividendType)
   
    return (
        <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={11} sm={12} md={12} lg={12} xl={12}>
                <Table 
                getDataSync={getDataSync} 
                showPageSize={showPageSize} 
                setShowPageSize={setShowPageSize} 
                setNumberOfpage={setNumberOfpage} 
                numberOfPage={numberOfPage} 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage} 
                setDataStore={setDataStore} 
                columns={inwardDividendType==='Cash'?columnsCash:inwardDividendType==='Stock'?columnsStock:columns}
                //  columns={[]}
                data={dataStore} 
                params={params}
                handleInwardDividendStateChange={handleInwardDividendStateChange}
                inwardDividendType={inwardDividendType}
            />
             </Grid>
        </Grid>
             
           
        </div>
    )
}

export default FilterTableComponent;