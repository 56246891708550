import { TextField } from "@material-ui/core";
import { default as React, useContext } from "react";
import ReduceContext from "./ReduceContext";


export  const BankInformation = () => {

  const {data, setData} = useContext(ReduceContext);

    return (
      <>
            <TextField
              id="accountHolderName"
              label="Account Name"
              required
              variant="outlined"
              placeholder="Enter Account Name"
              fullWidth
              margin="normal"
              value={data.accountHolderName}
              onChange={(e) => setData({...data, accountHolderName: e.target.value})}
            
            />
       
            <TextField
              id="accountNumber"
              label="Account Number"
              variant="outlined"
              placeholder="Enter Account Number"
              fullWidth
              type="number"
              required
              margin="normal"
              value={data.accountNumber}
              onChange={(e) => setData({...data, accountNumber: e.target.value})}
             
            />
        
            <TextField
              id="bankName"
              label="Bank Name"
              variant="outlined"
              placeholder="Enter Bank Name"
              fullWidth
              required
              margin="normal"
              value={data.bankName}
              onChange={(e) => setData({...data, bankName: e.target.value})}
           
            />
        
            <TextField
              id="branchName"
              label="Branch Name"
              variant="outlined"
              placeholder="Enter Branch Name"
              fullWidth
              required
              margin="normal"
              value={data.branchName}
              onChange={(e) => setData({...data, branchName: e.target.value})}
         
            />
       
            <TextField
              id="rountingNumber"
              label="Rounting Number"
              variant="outlined"
              placeholder="Enter Rounting Number"
              fullWidth
              required
              type="number"
              margin="normal"
              value={data.routingNumber}
              onChange={(e) => setData({...data, routingNumber: e.target.value})}
          
            />

            <TextField
              id="chequePageFile"
              label="Bank Cheque Leaf File"
              variant="outlined"
              placeholder="Enter Your chequePageFile"
              fullWidth
              focused
              margin="normal"
              type="file"
              onChange={(e) => setData({...data, chequePageFile: e.target.files[0]})}
            />

            <div style={{marginBottom: "10px"}}>
                 {data.chequePageFile.length === 0 ? null : data.chequePageFile.name}
            </div>

      </>
    );
  };