import {
  AppBar, IconButton, Menu,
  MenuItem, Toolbar
} from "@material-ui/core";
import {
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon, Menu as MenuIcon,
  VpnKey
} from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import CmsfLogo from "./cmsf.jpg";
// styles
import useStyles from "./styles";

// components
import Localization from "../../utils/strings/Localization";
import { Typography } from "../Wrappers";

// context
import {
  toggleSidebar, useLayoutDispatch, useLayoutState
} from "../../context/LayoutContext";
import { signOut, useUserDispatch } from "../../context/UserContext";


const notifications = [
  { id: 0,
     color: "info",
     type: "info",
      message: "INSAF is for doing justice to the esteemed investors by protecting their rights of receiving their dues." },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "It is inspired by the Bangla word ‘Insaf’ which means justice and fairness.Insaf symbolizes the organization and its motto. The IN stands for Investors and SAF for StAbilization Fund representing CMSF, as CMSF protects Investor rights and does justice to them",
  },  
];

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  const { t } = useTranslation();
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  var [userHasBankInfo, setUserHasBankInfo] = useState(false);
  const userName = localStorage.getItem('user-specific-name');
  const strUserName = JSON.parse(userName);
  const userEmail = localStorage.getItem('user-email');
  const strUserEmail = JSON.parse(userEmail);
  const userId = localStorage.getItem('user-id');

  // const getData = () =>{
  // axios.get(`bank-informations/${userId}`)
  //       .then((response) =>{
  // localStorage.setItem("isActive", JSON.stringify(response?.payload?.isActive=true))
  // });
  //       }
  // const token = localStorage.getItem("bearer-token");
  // console.log(localStorage);
  // let decoded="";
  // if(token)
  // decoded = jwtDecode(token);
  // const expirationTime = (decoded.exp * 1000) - 60000;
 
  const user = localStorage.getItem('user-name')
  const userFormatted = JSON.parse(user);
  const activeness = localStorage.getItem('isActive');

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>

          <img style={{height:"50px"}} src={CmsfLogo} alt="CMSF-Logo" /> 
          <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          {t('Project.name')}
          
        </IconButton>
        </Typography>
        <div className={classes.grow} />
        <Localization />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {/* {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))} */}
          
          <MenuItem>
          <span style={{margin:"20px"}}>
INSAF is a software developed by CMSF for ensuring justice to the esteemed investors by<br /> protecting their rights of receiving their due dividends. It is inspired by the Bangla<br /> word ‘Insaf’ which means justice and fairness.INSAF symbolizes the organization and its motto. <br />The IN stands for Investors and SAF stands for StAbilization Fund representing CMSF, <br />as CMSF protects Investor rights and does justice to them.
            </span>
          </MenuItem>
        </Menu>
        
        {userFormatted?.fullName}
        <br></br>
        {userFormatted?.userTypes}

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        > 
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {strUserName}
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="primary"
              // href="https://cmsfbd.org"
            >
              
              {strUserEmail}
              {activeness}
            </Typography>
          </div>
         
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
           
           <AccountIcon className={classes.profileMenuIcon} /> 
            <Link to="/app/profile" style={{ textDecoration: 'none' }} ><span style={{textDecorationLine: "none"}}> Profile</span></Link>
          </MenuItem>


          {/* <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
            <AccountIcon className={classes.profileMenuIcon} /> 
              <Link to="/app/view/bank-informations" style={{ textDecoration: 'none' }} ><span style={{textDecorationLine: "none"}}>Bank Details</span></Link>
          </MenuItem> */}
          
          <MenuItem
            
          >
            
            <VpnKey className={classes.profileMenuIcon} />
                  <Link to="/app/password-change" style={{ textDecoration: 'none' }}><span style={{textDecorationLine: "none"}}> Password Change</span></Link>
            
          </MenuItem>
          {/* <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Messages
          </MenuItem> */}
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
