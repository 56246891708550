import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import axios from '../../utils/axios'; // Adjust path to your axios setup
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';

const LeaveApprovalDashboard = () => {
  const token = localStorage.getItem('bearer-token');
  const decoded = jwtDecode(token);

  const [leaveData, setLeaveData] = useState([]);
  const leaveTypes = {
    1: 'Casual Leave',
    2: 'Sick Leave',
    3: 'Earned Leave',
    4: 'Maternity Leave',
    5: 'Early Departure',
    6: 'On Duty',
    7: 'Paternity Leave'
  };

  // State for status, leave type, and employee name filters
  const [leaveStatus, setLeaveStatus] = useState('Pending');
  const [leaveType, setLeaveType] = useState('All');
  const [empName, setEmpName] = useState('All');
  const [filteredData, setFilteredData] = useState([]);
  const [employeeNames, setEmployeeNames] = useState([]); // For dropdown options

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/users/${decoded.id}`);
        if (response.data && response.data.payload) {
          const empId = response.data.payload.empId;
          // Fetch leave data based on the user
          fetchData();
        } else {
          console.error('No payload in the response');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [decoded.id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/employee/leave-approval-list?loginid=${decoded.id}&flagview=HR&empid=All`);
      
      if (response.data.success) {
        
       
        response.data.payload.forEach(item => {
          item.hr_status = item.hr_status === null ? 0 : parseInt(item.hr_status);
        });

        // Filter data to include only items with n1_status = "1"
        const n1ApprovalData = response.data.payload
        .filter(item => item.n1_status === "1")
        .sort((a, b) => new Date(b.entry_date) - new Date(a.entry_date)); // Sort in descending order


        // Set leave data and unique employee names in state
        setLeaveData(n1ApprovalData);
        setEmployeeNames([...new Set(response.data.payload.map(item => item.emp_name))]);

        // Apply default filter settings with n1ApprovalData as initial data set
        filterData('Pending', 'All', 'All', n1ApprovalData); 
      }
    } catch (error) {
      console.error('Error fetching leave data:', error);
    }
  };

    useEffect(() => {
    fetchData();
  }, []);

  // Function to filter data based on leave status, leave type, and emp_name
  const filterData = (status, type, emp, data) => {
    let filtered = data;

    // Filter by leave status
    if (status === 'Pending') {
      filtered = filtered.filter(item => item.hr_status === null || item.hr_status === 0);
    } else if (status === 'Approved') {
      filtered = filtered.filter(item => item.hr_status === 1);
    } else if (status === 'Rejected') {
      filtered = filtered.filter(item => item.hr_status === 2);
    }

    // Filter by leave type
    if (type !== 'All') {
      const leaveTypeId = Object.keys(leaveTypes).find(key => leaveTypes[key] === type);
      filtered = filtered.filter(item => item.leave_type == leaveTypeId);
    }

    // Filter by employee name
    if (emp !== 'All') {
      filtered = filtered.filter(item => item.emp_name === emp);
    }

    setFilteredData(filtered);
  };

  // Handle status change from the dropdown
  const handleStatusChange = (event) => {
    const status = event.target.value;
    setLeaveStatus(status);
    filterData(status, leaveType, empName, leaveData); // Update filtered data
  };

  // Handle leave type change from the dropdown
  const handleLeaveTypeChange = (event) => {
    const type = event.target.value;
    setLeaveType(type);
    filterData(leaveStatus, type, empName, leaveData); // Update filtered data
  };

  // Handle employee name change from the dropdown
  const handleEmpNameChange = (event) => {
    const emp = event.target.value;
    setEmpName(emp);
    filterData(leaveStatus, leaveType, emp, leaveData); // Update filtered data
  };

  // Define the columns for Material React Table
  const columns = [
    {
      accessorKey: 'serialNumber',
      header: 'SL',
      size: 50,
      Cell: ({ row }) => row.index + 1,
    },
    // { accessorKey: 'emp_id', header: 'ID', size: 50 },
    {
      accessorKey: 'emp_name',
      header: 'EmpId &Full Name',
      size: 50,
    },
    {
      accessorKey: 'from_date',
      header: 'From Date',
      size: 50,
      Cell: ({ cell }) => {
        const dateValue = cell.getValue();
        return dateValue 
          ? new Date(dateValue).toLocaleString('en-US', { 
      
              month: 'short', 
              day: '2-digit', 
              year: 'numeric' 
            }) 
          : 'No date available';
      },
    },
  
    {
      accessorKey: 'to_date',
      header: 'To Date',
      size: 50,
      Cell: ({ cell }) => {
        const dateValue = cell.getValue();
        return dateValue 
          ? new Date(dateValue).toLocaleString('en-US', { 
      
              month: 'short', 
              day: '2-digit', 
              year: 'numeric' 
            }) 
          : 'No date available';
      },
    },
    { accessorKey: 'total_days', header: 'Total Days', size: 50 },
    {
      accessorKey: 'leave_type',
      header: 'Leave Type',
      size: 50,
      Cell: ({ cell }) => leaveTypes[cell.getValue()] || 'Unknown',
    },
    { accessorKey: 'remarks', header: 'Remarks', size: 50 },
    {
      accessorKey: 'entry_date',
      header: 'Application time & Date',
      size: 50,
      Cell: ({ cell }) => {
        const dateValue = cell.getValue();
        return dateValue 
          ? new Date(dateValue).toLocaleString('en-US', { 
      
            month: 'short', 
            day: '2-digit', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: true 
            }) 
          : 'No date available';
      },
    },
    {
      accessorKey: 'n1_entry_date',
      header: 'N1 Approval Date',
      size: 50,
      Cell: ({ cell }) => {
        const dateValue = cell.getValue();
        return dateValue 
          ? new Date(dateValue).toLocaleString('en-US', { 
      
            month: 'short', 
            day: '2-digit', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: true 
            }) 
          : 'No date available';
      },
    },
    { accessorKey: 'n1_remarks', header: 'N1 Remarks', size: 50 },

    {
    accessorKey: 'n1_status',
    header: 'N1 Status',
    size: 50,
    Cell: ({ cell }) => {
      const status = cell.getValue();
      let statusText = 'Unknown';
      let backgroundColor = '#f0f0f0'; // Default background
      let color = '#333'; // Default text color
  
      if (status === null || status === "0") {
        statusText = 'Pending';
        backgroundColor = '#d4d6b6'; // Yellow for Pending
        color = '#333'; // Dark text for readability
      } else if (status === "1") {
        statusText = 'Approved';
        backgroundColor = '#75a832'; // Green for Approved
        color = '#fff'; // White text for contrast
      } else if (status === "2") {
        statusText = 'Rejected';
        backgroundColor = '#f44336'; // Red for Rejected
        color = '#fff'; // White text for contrast
      }
  
      return (
        <div
          style={{
            backgroundColor,
            color,
            padding: '1px',
            borderRadius: '3px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {statusText}
        </div>
      );
    },
  }, 
 
  ...(leaveStatus === 'Approved' || leaveStatus === 'Rejected'
    ? [{
        accessorKey: 'hr_entry_date',
        header: 'HR Status Date',
        size: 50,
        Cell: ({ cell }) => {
          const dateValue = cell.getValue();
          return dateValue 
            ? new Date(dateValue).toLocaleString('en-US', { 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true, 
                month: 'short', 
                day: '2-digit', 
                year: 'numeric' 
              })
            : 'Not approved yet';
        },
      }]
    : []),
  
      // Conditionally render the 'N1 Remarks' column only if leaveStatus is "Approved" or "Rejected"
  ...(leaveStatus === 'Approved' || leaveStatus === 'Rejected'
    ? [{
      accessorKey: 'hr_remarks',
      header: 'HR Remarks',
      size: 50,
    }]
   : []),

   ...(leaveStatus === 'Approved' || leaveStatus === 'Rejected'
    ? [{
      accessorKey: 'hr_entry_by',
      header: 'HR Entry by',
      size: 50,
    }]
   : []),

   {
    accessorKey: 'hr_status', // New column for updating status
    header: 'HR Status',
    size: 50,
    Cell: ({ row }) => {
      console.log(row);
      const [statusValue, setStatusValue] = useState(row.original.hr_status);
      const [remarks, setRemarks] = useState('');

      useEffect(() => {
        setStatusValue(row.original.hr_status);
      }, [row.original.hr_status]);

      const handleStatusChange = (event) => {
        setStatusValue(event.target.value);
      };

      const handleRemarksChange = (event) => {
        setRemarks(event.target.value);
      };

      const handleSave = async () => {
        const id = row.original.id; // Assuming id is the unique identifier
        console.log(id);
     debugger
        try {
          const response = await axios.put(`/employee/approve-declined-by-hr/${id}`, 
            { 
              status: statusValue, 
              remarks 
            });
      
          if (response.data.success) {
            // Show SweetAlert2 success message
            Swal.fire({
              title: 'Success',
              text: response.data.payload.fn_approve_declined_by_hr_leave_application,
              icon: 'success',
               confirmButtonText: 'OK'
              // timer: 3000
            })
          //   setTimeout(function(){
          //     window.location.reload();
          // }, 3000);
         
     
        filterData(leaveStatus, leaveType, empName, leaveData);
        setStatusValue(row.original.hr_status); // Clear the MenuItem selection
        setRemarks('');     // Clear the remarks field
        fetchData(); // Fetch data again to reflect changes
          
          }
           else {
            console.error('Failed to update status:', response.data.message);
          }
        } catch (error) {
          console.error('Error updating status:', error);
          // Show SweetAlert2 error message
          Swal.fire({
            title: 'Error!',
            text: 'There was an error updating the status.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      };
      

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Select
            value={statusValue}
            onChange={handleStatusChange}
            style={{
              borderRadius: "4px",
              padding: "2px",
              backgroundColor: statusValue === 0 ? '#754a04' : statusValue === 1 ? '#5cb85c' : '#d9534f',
              color: 'white',
            }}
          >
            <MenuItem disabled={leaveStatus === "Approved"|| leaveStatus === "Rejected"} value={0}>Pending</MenuItem>
            <MenuItem value={1}>Approved</MenuItem>
            <MenuItem disabled={leaveStatus === "Approved"} value={2}>Rejected</MenuItem>
          </Select>
          <input 
          
         
            type="text" 
            placeholder="Remarks"
            value={remarks}
            onChange={handleRemarksChange} 
            style={{ marginLeft: '8px', padding: '4px', borderRadius: '4px', border: '1px solid #ccc' , display: leaveStatus === "Pending" || leaveStatus === "Rejected" ?  'block' : 'none' }}
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSave}
            style={{ marginLeft: '8px', padding: '4px', borderRadius: '4px', border: '1px solid #ccc' , display: leaveStatus === "Pending" || leaveStatus === "Rejected" ?  'block' : 'none' }}
          >
            Save
          </Button>
        </Box>
      );
    },
  },
    
  ];

  return (
    <>
      <PageTitle title={"Leave Approval Dashboard (HR)"} />
      <Grid container spacing={3}>
        {/* Leave Status Dropdown */}
        <Grid item lg={4}>
          <FormControl fullWidth>
            <InputLabel id="emp-name-label">Employee Name</InputLabel>
            <Select
              labelId="emp-name-label"
              value={empName}
              onChange={handleEmpNameChange}
              label="Employee Name"
            >
              <MenuItem value="All">All</MenuItem>
              {employeeNames.map((name) => (
                <MenuItem key={name} value={name}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4}>
          <FormControl fullWidth>
            <InputLabel id="leave-status-label">Leave Status</InputLabel>
            <Select
              labelId="leave-status-label"
              value={leaveStatus}
              onChange={handleStatusChange}
              label="Leave Status"
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Leave Type Dropdown */}
        <Grid item lg={4}>
          <FormControl fullWidth>
            <InputLabel id="leave-type-label">Leave Type</InputLabel>
            <Select
              labelId="leave-type-label"
              value={leaveType}
              onChange={handleLeaveTypeChange}
              label="Leave Type"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Casual Leave">Casual Leave</MenuItem>
              <MenuItem value="Sick Leave">Sick Leave</MenuItem>
              <MenuItem value="Earned Leave">Earned Leave</MenuItem>
              <MenuItem value="Maternity Leave">Maternity Leave</MenuItem>
              <MenuItem value="Early Departure">Early Departure</MenuItem>
              <MenuItem value="On Duty">On Duty</MenuItem>
              <MenuItem value="Paternity Leave">Paternity Leave</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Employee Name Dropdown */}
   

        {/* Table to display filtered data */}
        <Grid item xs={12}>
          <MaterialReactTable
            columns={columns}
            data={filteredData}
            enableEditing={false}
            enableSorting
            initialState={{
              density: 'compact',
              pagination: {
                pageSize: 5, // Sets default rows per page
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                  border: '0.5px solid #e0e0e0',  
               
              },
          }}
          muiTableHeadCellProps={{
              sx: {
                  border: '0.5px solid #e0e0e0', 
              },
          }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LeaveApprovalDashboard;
