import 'dayjs/locale/en-gb';
import { mkConfig } from 'export-to-csv'; //or use your library of choice here
import { default as React } from 'react';
import PageTitle from "../../../components/PageTitle/PageTitle";
import FilterTableComponent from "./FilterTableComponent";





const ReportType =[
    {id: 1,category: "Summary"},
    {id: 2,category: "Details"},
  ]
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });





  

const DataUploadedByOfficers = () => {
    

  return (
    <React.Fragment > 
    <PageTitle title={ "Settlement Data Uploaded by Employees"}  />
        {/* <Grid container spacing={3}  style={{justifyContent:"center"}}> */}
           
       <FilterTableComponent/>
        
        {/* </Grid> */}
    </React.Fragment>
  )
}

export default DataUploadedByOfficers;