import React, { useState, useEffect } from 'react';
import { Button, FormControl, Grid, TextField, Typography } from "@material-ui/core";
import PageTitle from '../../components/PageTitle/PageTitle';
import useStyles from '../../utils/validations/useStyles';
import Swal from 'sweetalert2';
import { MaterialReactTable } from 'material-react-table';
import axios from '../../utils/axios'; // Import your axios instance

const HolidayDeclare = () => {
    const classes = useStyles();

    const [formValues, setFormValues] = useState({
        date: '',
        remarks: ''
    });

    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [holidays, setHolidays] = useState([]);

    // Get the current year
    const currentYear = new Date().getFullYear();

    // Fetch holidays from API
    const fetchHolidays = async () => {
        try {
            const response = await axios.get('/employee/get-holyday');
            if (response.data && response.data.payload) {
                const holidaysData = response.data.payload
                    .filter(holiday => new Date(holiday.holydaydate).getFullYear() === currentYear)
                    .map(holiday => ({
                        holydaydate: holiday.holydaydate,
                        name: holiday.name,
                        entrydate:holiday.entrydate,
                        entryby:holiday.entryby
                    }));
                setHolidays(holidaysData);
            }
        } catch (error) {
            console.error('Error fetching holidays:', error);
            setMessage('Failed to fetch holidays. Please try again later.');
        }
    };

    useEffect(() => {
        fetchHolidays();
    }, []);

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            [e.target.name]: ''
        }));
    };

    const handleSave = async () => {
        let validationErrors = {};
    
        // Get today's date without the time component
        const today = new Date().setHours(0, 0, 0, 0);
        const selectedDate = new Date(formValues.date).setHours(0, 0, 0, 0);
    
        if (!formValues.date) {
            validationErrors.date = 'Please select the date';
        } else if (selectedDate < today) {
            validationErrors.date = 'You cannot select a previous date';
        }
    
        if (!formValues.remarks) {
            validationErrors.remarks = 'Please input remarks';
        }
    
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
    
        const newHoliday = {
            hDays: formValues.date,
            hRemarks: formValues.remarks,
        };
    
        try {
            const res = await axios.post('/employee/create-holyday', newHoliday);
            Swal.fire({
                title: res.data.payload.message === "New record created successfully." ? 'Success' : 'Warning',
                text: res.data.payload.message,
                icon: res.data.payload.message === "New record created successfully." ? 'success' : 'warning',
                confirmButtonText: 'OK'
            });
    
            // Re-fetch holidays after saving
            const response = await axios.get('/employee/get-holyday');
            if (response.data && response.data.payload) {
                const holidaysData = response.data.payload.filter(holiday => {
                    const holidayYear = new Date(holiday.holydaydate).getFullYear();
                    return holidayYear === currentYear;
                }).map(holiday => ({
                    holydaydate: holiday.holydaydate,
                    name: holiday.name,
                }));
                setHolidays(holidaysData);
            }
    
            // Reset form values
            setFormValues({ date: '', remarks: '' });
        } catch (error) {
            console.error('Error saving holiday:', error);
            setMessage('Failed to declare holiday. Please try again later.');
        }
    };
    
    const columns = [
        {
            accessorKey: 'serialNumber',
            header: 'SL',
            size: 50,
            Cell: ({ row }) => row.index + 1,
        },
        {
            accessorKey: 'holydaydate',
            header: 'Holiday Date',
          
            Cell: ({ cell }) => {
                const dateValue = cell.getValue();
                return dateValue 
                  ? new Date(dateValue).toLocaleString('en-US', { 
                      month: 'short', 
                      day: '2-digit', 
                      year: 'numeric', 
                    
                    }) 
                  : 'Not yet';
              },
        },
        {
            accessorKey: 'name',
            header: 'Remarks',
        },

        {
            accessorKey:'entryby',
            header:'Entry By'

        },
        {
            accessorKey: 'entrydate',
            header: 'Entry time & Date',
            Cell: ({ cell }) => {
                const dateValue = cell.getValue();
                return dateValue 
                  ? new Date(dateValue).toLocaleString('en-US', { 
                      month: 'short', 
                      day: '2-digit', 
                      year: 'numeric', 
                      hour: '2-digit', 
                      minute: '2-digit', 
                      hour12: true 
                    }) 
                  : 'No date available';
              },
        }

    ];

    return (
        <>
            <PageTitle title={"Holiday Declare"} />
            <Grid container spacing={3} style={{ justifyContent: "center" }}>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Date"
                            type="date"
                            variant="outlined"
                            name="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: new Date().toISOString().split('T')[0],
                            }}
                            value={formValues.date}
                            onChange={handleChange}
                            required
                            error={!!errors.date}
                        />
                        {errors.date && (
                            <Typography color="error" variant="body2">
                                {errors.date}
                            </Typography>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={6} md={3} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Remarks"
                            variant="outlined"
                            name="remarks"
                            value={formValues.remarks}
                            onChange={handleChange}
                            required
                            error={!!errors.remarks}
                        />
                        {errors.remarks && (
                            <Typography color="error" variant="body2">
                                {errors.remarks}
                            </Typography>
                        )}
                    </FormControl>
                </Grid>

                <Grid item lg={4} md={3} sm={12} xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ marginTop: "15px" }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Grid>

                {message && (
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "20px" }}>
                        <Typography color="error" variant="h6">
                            {message}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            {/* Table Container */}
            <Grid container style={{ marginTop: '20px', width: '100%' }}>
                <Grid item xs={12}>
                    <MaterialReactTable

                        columns={columns}
                        data={holidays}
                        enableColumnOrdering
                        enableEditing={false}
                        enableSorting
                        positionToolbarAlertBanner="bottom"
                     
                        initialState={{
                            pagination: {
                                pageSize: 5,
                            },
                        }}
                        style={{ width: '100%' }}
                        muiTableBodyCellProps={{
                            sx: {
                                border: '0.5px solid #e0e0e0',  
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                border: '0.5px solid #e0e0e0', 
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default HolidayDeclare;
