import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import { getUserId } from '../../utils/strings/userString';

export default function ProfileView() {   

    const [userDetails,setUserDetails] = useState({
        u_name: "",
        fullName: "",
        phoneNumber: "",
        email: "",
        dob: '',
        gender: '',
        address: '',
        userType: '',
    })

    const userId = getUserId();

    useEffect(()=>{
      axiosInstance.get(`users/${userId}`)
      .then((res)=>{
        
        if(res?.data?.success === true){
          setUserDetails({...setUserDetails,
            fullName:res?.data?.payload?.fullName,
            email:res?.data?.payload?.email,
            phoneNumber:res?.data?.payload?.phoneNumber,
            userType:res?.data?.payload?.userTypes[0],
           })
        }
      })
      .catch((err)=>{
        console.log("There was an error occured!")
      })
    },[])



    return(
        <div>
            <h2 style={{textAlign: "center"}}>Profile Details</h2>
            <Table className='center' style={{width:"50%", marginLeft: "auto", marginRight: "auto"}}>
          <TableHead>

          </TableHead>
          <TableBody >
            <TableRow>
              <TableCell style={{border:'1px solid black'}}>Full Name</TableCell>
              <TableCell style={{border:'1px solid black'}}>{userDetails.fullName}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell style={{border:'1px solid black'}}>Email Address</TableCell>
              <TableCell style={{border:'1px solid black'}}>{userDetails.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{border:'1px solid black'}}>Phone Number</TableCell>
              <TableCell style={{border:'1px solid black'}}>{userDetails.phoneNumber}</TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell style={{border:'1px solid black'}}>National ID</TableCell>
              <TableCell style={{border:'1px solid black'}}>{userDetails.nid}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{border:'1px solid black'}}>Gender</TableCell>
              <TableCell style={{border:'1px solid black'}}>{userDetails.gender}</TableCell>
            </TableRow>
           
            <TableRow>
              <TableCell style={{border:'1px solid black'}}>Date of Birth</TableCell>
              <TableCell style={{border:'1px solid black'}}>{userDetails.dob}</TableCell>
            </TableRow> */}

            {/* <TableRow>
              <TableCell style={{border:'1px solid black'}}>User Type</TableCell>
              <TableCell style={{border:'1px solid black'}}>{userDetails.userType}</TableCell>
            </TableRow> */}
          </TableBody>
        </Table> <br/>
        <Link to="/app/profile/update" >
        <Button variant="contained" color="primary"size="large">   Profile Edit</Button></Link>
        </div>
    )
}