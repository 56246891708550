import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { Button } from "../../../../components/Wrappers/Wrappers";
import DataTable from "./DataTable";

export default function ViewInvestor(){
    const { t } = useTranslation();

    return(
        <div>
            
            <PageTitle title={ t('inwardDividend.investorsDetails.view.pageTitle')} 
            // button={
            // <Link to="/app/inward-dividend/list"> 
            //     <Button
            //         variant="contained"
            //         size="medium"
            //         color="primary"
            //     >
            //         { t('inwardDividend.investorsDetails.view.listLink')}
            //     </Button>
            // </Link>}
             />
    <DataTable />
        </div>
    )
}