import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import Swal from "sweetalert2";
import { useGetIssuerInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import UiOfCompanyStatusReport from "./UiOfCompanyStatusReport";
import axios from "../../../utils/axios";
// import Select from 'react-select'

const options = [{ tradecode: "No Data Available", name: "" },
// { tradecode: "No Data Available", name: "" },
];


function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize,setNumberOfpage,params }) {

    const {  data:issuer} = useGetIssuerInfoQuery();
    const { t } = useTranslation();
    const [statusValue,setStatusValue]= useState("")
    const [statusValue2,setStatusValue2]= useState("")
    const [newData, setNewData] = useState([]); // for autocomplete
    const [filteredData, setFilteredData] = useState([]);

    const cashDividend = [
        {value:'CashDividend', key:'Cash Dividend'},
        {value:'PublicSubscriptionMoney', key:'Public Subscription Money'},
        {value:'FractionDividend', key:'Fraction Dividend'},
        {value:'All', key:'All'}
    ];

    const stockDividend = [
        {value:'StockRightShare' ,key:'Stock Right Share'},
        {value:'StockBonusShare' ,key:'Stock Bonus Share'},
        {value:'DividendOnDividend' ,key:'Dividend On Dividend'},
        {value:'All', key:'All'}
    ];

    const allDividend = [
        {value:'CashDividend', key:'Cash Dividend'},
        {value:'PublicSubscriptionMoney', key:'Public Subscription Money'},
        {value:'FractionDividend', key:'Fraction Dividend'},
        {value:'StockRightShare' ,key:'Stock Right Share'},
        {value:'StockBonusShare' ,key:'Stock Bonus Share'},
        {value:'DividendOnDividend' ,key:'Dividend On Dividend'},
        {value:'FCCashDividend', key:'FC Cash Dividend'},
        {value:'FCPublicSubscriptionMoney' ,key:'FC PublicSubscription Money'},
        {value:'All', key:'All'}
    ];

    useEffect(()=>
  {axios.get('./issuer/get-info')
  .then((res)=>{
    setNewData(res.data.payload)
  })
  .catch((err) =>{
    alert(err.response.data.message)
  })
},[])

  // console.log(newData)

    const newData2 = newData?newData:options
    var sortedNewData = newData2
    // if (newData2) {
    //   sortedNewData = newData2.slice().sort((a, b) => {
    //     if (a.tradecode < b.tradecode) {
    //       return -1;
    //     }
    //   });
     
    // }

    const defaultColumn = React.useMemo(
        () => ({
            Filter: "",
        }),
        []
    )

    const issuerData = issuer?.payload
    //console.log(issuerData);
    const issuerData2 = issuerData;
    //console.log(issuerData2);
    var sortedIssuerData = issuerData
    if (issuerData2) {
     sortedIssuerData = issuerData2.slice().sort((a, b) => {
        if (a.tradecode < b.tradecode) {
          return -1;
        }
      });
     
    }
    
//console.log(sortedIssuerData);
const ITEM_HEIGHT = 100;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 250,
            // height: 250,
            overflowY: 'scroll',
            overflowX: 'scroll'
          },
        },
      };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const nextPageHandler = () =>{        
        if(numberOfPage > currentPage){
            setCurrentPage(++currentPage);      
        }
    }

    const prevPageHandler = () =>{        
        if(currentPage > 1){
            setCurrentPage(--currentPage);
        }
    }
    
    const handleFirstPage = () =>{
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }

    const handleLastPage = () =>{
        if(currentPage !== numberOfPage){
            setCurrentPage(numberOfPage)
        }
    }

    const goToPageHandler = (e) =>{
        getDataSync(e,showPageSize, remarksValue,dateValue, issuerValue)
    }

    

    const pSize= [5, 10, 20, 30, 40, 50];

    const [fullName, setFullName] = useState("")
    const [folioNumberValue, setFolioNumberValue] = useState("")
    const [boIDValue, setBoIDValue] = useState("")
   
    const [dateValue, setDateValue] = useState([])
    const [issuerValue, setIssuerValue] = useState(null)
    const [key, setKey] = useState(Date.now());
   
   // const [totalStockValue, setStockValue] = useState("")
    const [remarksValue, setRemarksValue] = useState("")
    const [reset, setReset] = useState("false")
    const [yearTypeAvailable, setYearTypeAvailable] = useState('');
    const [thisCompanysYearType, setThisCompanysYearType] = useState([]);
    const [yearShow, setYearShow] = useState([]);
    const [checkValue, setCheckValue] = useState([])
    const [date, setDate] = useState('')
    const [grandTotalResults,setGrandTotalResults] = useState([])
    

    

   
    //const totalStockValue = console.log(JSON.stringify(totalStockValuei));
    //const shareArray = [shareLower,shareUpper];
    // const convertedArray = shareArray.JSON
   //console.log(JSON.stringify(totalStockValue));

   let customedValue= ""
  
    const paramsData = {
        issuerid: issuerValue,
        boID: boIDValue,
        folioNumber: folioNumberValue,
        inwardDividendType:statusValue,
        investorsdividendType:statusValue2,
        // yearDividend: checkValue.toString(),
        yearDividend: date,
        page: currentPage,
        take: showPageSize,
         
    }
    const paramsData2 = {
        issuerid: issuerValue,
        boID: boIDValue,
        folioNumber: folioNumberValue,
        inwardDividendType:statusValue,
        investorsdividendType:statusValue2,
        // yearDividend: checkValue.toString(),
        yearDividend: date,
        take: 500,  
    }

    const handleChange = (event,newValue) => {
    
        // console.log(newValue.key)
        // console.log(newValue.props.value.year)
      
        // setYearShow(
          // On autofill we get a stringified value.
        //    typeof value === 'string' ? event.target.value.split(',') : value,
        //   event.target.value
        // );

        //  customedValue = event.target.value.map(item => item.year).join(',');

        const value = event.target.value;
        if (value.some(item => item.id === 'all')) {
          setYearShow([{ id: 'all', year: 'All' }]);
          customedValue="All"
        } else {
          setYearShow(value);
          customedValue = event.target.value.map(item => item.year).join(',');
        }

       
        setDate(customedValue)

        // setData({...data, dividendYear: event.target.value})
         setDateValue((event.target.value))
        //  console.log((event.target.value.year))
         checkValue.push(newValue.props.value.year)
        //  console.log(checkValue)
        
    
      };

console.log(paramsData)
    const searchResethandler = () =>{
        setFullName("");
        // setDateValue([])
        setIssuerValue(null)
        setKey(Date.now());
        // setCheckValue([])
        setBoIDValue("")
        setFolioNumberValue("")
        setStatusValue("")
        setStatusValue2("")
        setReset(true)
        setFilteredData([])
        setDateValue([])
        setDate('')
    }
    
 
    // useEffect(()=>{
    //         axios.post(`investor-informations/issuer-wise-inward-report`, paramsData)
    //     .then((res) =>{
    //         setDataStore(res.data.payload)

    //         if(res.data?.page){
    //             setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
                
    //         }
    //     })
    //     .catch((err) =>{
    //     })
    //     axios.post(`investor-informations/issuer-wise-inward-report`,paramsData2)
    //     .then((res) =>{
    //         //  setFilteredData(res.data.payload)
    //         // console.log(res.data.payload)
            
            
    //     })
    //     .catch((err) =>{
    //     })
            
    // },[  setDataStore,setCurrentPage,setNumberOfpage,showPageSize,currentPage])



    const searchHandler = () => {
        setReset(false)
        if ((issuerValue!=='')) {
            axios.post(`investor-informations/issuer-wise-inward-report`, paramsData)
            .then((res) =>{
                setDataStore(res.data.payload.result)
                // Swal.fire({
                //     title: "...",
                //     html: <UiOfReport />, //How to write this line?
                //     confirmButtonText: "Check",
                //     preConfirm: () => {
                //         //api request
                //     },
                //   });
                
                setCheckValue([])
                
                if(res.data.payload.result.length===0)
                Swal.fire({
                    title: "Oops..",
                    text: "No Data found with the Given Information!",
                    icon: "warning"
                  })
                ;
                else console.log("no data")
                if(res.data?.page){
                    setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
                    
                }
            })
            .catch((err) =>{
            })
            axios.post(`investor-informations/issuer-wise-inward-report`,paramsData2)
            .then((res) =>{
                  setFilteredData(res.data.payload.result)
                  setGrandTotalResults(res.data.payload.grandTotal)
                  
                // console.log(res.data.payload)
            })
            .catch((err) =>{
            })
    }
    else{
        Swal.fire("Select atleast Company Name,and BO ID or Folio ")
    }
    }

    useEffect(()=>{
        setCurrentPage(1)
    },[setDataStore,issuerValue])


    return (  
        <>
        <Grid container spacing={2}>
       
            <Grid item lg={3} md={3} sm={2} xs={12}>
             <FormControl fullWidth >
              <InputLabel id="issuer-id" ></InputLabel>
                <Autocomplete
                 key={key} // Use the unique key here
                    //   value={issuerValue}
                    label="Select Issuer"
                    onChange={(event, newValue) => {
                        setIssuerValue( newValue.id);
                        axios.get(`/issuer-year-format/${newValue.id}`)
                            .then((res)=>{
                                setThisCompanysYearType([{ id: 'all', year: 'All' },...res.data.payload])
                                if(res?.data?.payload?.length === 0) 
                                    {
                                        setYearTypeAvailable('false')
                                        setCheckValue([])
                                        setDateValue([])
                                        console.log("hi")
                                    }
                                else {
                                    setYearTypeAvailable('true')
                                    setCheckValue([])
                                    setDateValue([])
                                    console.log("buye")
                                }     
                                })
                            .catch((err) =>{
                                alert(err.response.data.message)
                            })
                    }}
                    disableClearable
                    // options={top100Films}
                    // getOptionLabel={(option) => "(" + option.title + ") " +option.year}
                    options={sortedNewData?sortedNewData:newData?newData:options}
                    getOptionLabel={(option) => "(" + option.tradecode + ") " +option.name}
                   
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Issuer Name"
                        // margin="normal"
                        // variant="outlined"
                        // InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                    )}
                />
            </FormControl>
            </Grid>
   
            {<Grid item lg={3} md={3} sm={12} xs={12}>
                <FormControl fullWidth >
                  <InputLabel id="issuer-id" >Dividend Year</InputLabel>
                    <Select style={{marginTop:'18px'}}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      rows={2}
                      multiple
                      value={dateValue}
                      onChange={(e,newValue)=>handleChange(e,newValue)}
                    //   renderValue={(selected) => selected.map(item => item.year).join(', ')}
                    renderValue={(selected) => 
                        selected.some(item => item.id === 'all') 
                          ? 'All' 
                          : selected.map(item => item.year).join(', ')
                      }
                      MenuProps={MenuProps}
                      >
                         {yearTypeAvailable=== 'true' && thisCompanysYearType.map((i) => (
                            <MenuItem key={i.id} value={i}>
                                {/* <Checkbox checked={yearShow.indexOf(i) > -1} /> */}
                                <Checkbox checked={yearShow.some(selected => selected.id === i.id)} />
                              <ListItemText primary={i.year} />
                            </MenuItem>
                          ))}
                          {yearTypeAvailable=== 'false' && 
                            <MenuItem key="Year Type not defined yet" value="Year Type not defined yet">
                            Year Type not defined yet</MenuItem>
                          }
                
                    </Select>
                </FormControl>
            </Grid>} 

            <Grid item lg={2} md={2} sm={2} xs={12}> 
                <FormControl fullWidth >
                    <InputLabel id="issuer-id" >Inward Dividend Type</InputLabel>
                        <Select 
                        required
                        labelId="status"
                        id="status"
                        value={statusValue}
                        label="Select status"
                        onChange={(e) => setStatusValue(e.target.value)}  
                        >
                            <MenuItem value='Cash' key='CashDividend'>Cash</MenuItem>
                            <MenuItem value='Stock' key='StockRightShare'>Stock</MenuItem>
                            <MenuItem value='All' key='FCCashDividend'>All</MenuItem>
                        </Select>
                </FormControl> 
            </Grid>

            <Grid item lg={2} md={2} sm={2} xs={12}> 
                <FormControl fullWidth >
                    <InputLabel id="issuer-id" >Investor Dividend Type</InputLabel>
                        <Select 
                        required
                        labelId="status"
                        id="status"
                        value={statusValue2}
                        label="Select status"
                        onChange={(e) => setStatusValue2(e.target.value)}  
                        >
                            {statusValue==='Cash' && cashDividend?.map((i)=><MenuItem value={i.value} key={i.key}>{i.key}</MenuItem>)} 
                            {statusValue==='Stock' && stockDividend?.map((i)=><MenuItem value={i.value} key={i.key}>{i.key}</MenuItem>)} 
                            {statusValue==='All' && allDividend?.map((i)=><MenuItem value={i.value} key={i.key}>{i.key}</MenuItem>)} 
                        </Select>
                </FormControl> 
            </Grid>

            <Grid item lg={1} md={2} sm={2} xs={12}>
                <span> 
                <Button
                variant="contained"
                        color="primary"
                        size="medium"
                        style={{margin:"10px", marginTop:"20px"}}
                        disabled={ issuerValue===""|| statusValue==="" || statusValue2==="" || checkValue===""}
                        onClick={searchHandler}
                        >Search
                </Button>
                </span>
            </Grid>

            <Grid item lg={1} md={2} sm={2} xs={12}>
                <span>
                <Button
                variant="contained"
                        color="primary"
                        size="medium"
                        style={{margin:"10px", marginTop:"20px"}}
                        onClick={searchResethandler}
                        >{ t('common.reset')}
                        </Button>
                        
                </span>
            </Grid>
        </Grid>
           
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {/* <ul className="pagination">
                <li className="page-item" onClick={handleFirstPage} disabled={!canPreviousPage}>
                    <a className="page-link">First</a>
                </li>
                <li className="page-item" onClick={prevPageHandler}
                 disabled={!canPreviousPage}
                 >
                    <a className="page-link">{'<'}</a>
                </li>
                <li className="page-item" onClick={nextPageHandler}
                 disabled={!canNextPage}
                 >
                    <a className="page-link">{'>'}</a>
                </li>
                <li className="page-item" onClick={handleLastPage} disabled={!canNextPage}>
                    <a className="page-link">Last</a>
                </li>
                <li>
                    <a className="page-link">
                        Page{' '}
                        <strong>
                            {currentPage} of {numberOfPage}
                        </strong>{' '}
                    </a>
                </li>
                <li>
                    <a className="page-link">
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                goToPageHandler(e.target.value)
                            }}
                            style={{ width: '100px', height: '20px' }}
                        />
                    </a>
                </li>{' '}
                <select
                    className="form-control"
                    value={showPageSize}
                    onChange={e => {
                        setShowPageSize(Number(e.target.value))
                    }}
                    style={{ width: '120px', height: '38px' }}
                >
                    {pSize.map(pageSize => (
                        <option key={pageSize} value={pageSize} >
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </ul> */}
           
            {filteredData.length!==0 && <Grid style={{marginTop:'80px'}}><UiOfCompanyStatusReport data={filteredData} grandTotal={grandTotalResults}/></Grid>}
  
        </>
    )
}

export default Table;