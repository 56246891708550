import React from 'react';
import FilterTableComponent from './FilterTableComponent';
// import RoleTable from './RoleTable'

const Role = () => {
    

  return (
    <div>
     
        {/* <RoleTable/> */}
        <FilterTableComponent/>
    </div>
  )
}

export default Role