import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/Wrappers/Wrappers";
import FilterTableComponent from "./FilterTableComponent";
import InvestorViewTable from "./InvestorViewTable";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "../../../utils/axios";

export default function ListInvestorsDetailsComponent(){
    const { t } = useTranslation();
    const [testState, setTestState] = useState(true);
    const [data, setData] = useState("")
    const params = useParams();
    const [approveButton, setApproveButton] = useState()

    useEffect(() => {

        axios.get(`inward-dividend/${params?.id}`)
        .then((res) =>{
            if(res.data.success){
                setData(res.data.payload)
                setApproveButton(res.data.payload.approveButton)
                
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })  

        }, [testState]);

    return(
        <div>
            
            <PageTitle title={ t('inwardDividend.investorsDetails.pageTitle')} button={<Link to="/app/inward-dividend/list"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('inwardDividend.investorsDetails.listLink')}
    </Button></Link>} />
            <InvestorViewTable setTestState={setTestState} testState={testState}  />
            <FilterTableComponent setTestState={setTestState} testState={testState} />
        </div>
    )
}