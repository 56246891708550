import {
  Button, CircularProgress, Fade, Grid,
  TextField, Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo

// context
import { useUserDispatch, verifyOtpToLogin } from "./../../../context/UserContext";

function OtpPage (props) {
  const [optionOTP, setOptionOTP] = useState(true);
  var classes = useStyles();
  const params = useParams();

  // global
  var userDispatch = useUserDispatch();
  
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [activeTabId, setActiveTabId] = useState(0);
  var [phoneNumber, setPhoneNumber] = useState(params.id);
  var [isLoading, setIsLoading] = useState(false);
  var [errorLogin, setErrorLogin] = useState(false);


  const [ otp, setOtp] = useState("");
  // var [password, setPassword] = useState("");
  // var [confirmPassword, setConfirmPassword] = useState("");

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
                <React.Fragment>
                    <Typography variant="h3" className={classes.greeting}>
                      Check your phone for the OTP
                    </Typography>
                    <Fade in={errorLogin}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {errorLogin}
                </Typography>
              </Fade>

                    <Fade in={error}>
                      <Typography color="secondary" className={classes.errorMessage}>
                        Something is wrong with your phone number
                      </Typography>
                    </Fade> 
                  <label>OTP*</label>
                  <TextField
                    id="otp-identifier"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={otp}
                    onChange={e => setOtp(e.target.value)}
                    margin="none"
                    placeholder="Enter OTP"
                    type="text"
                    required
                    fullWidth
                  />
                
                  <div className={classes.formButtons}>
                    {isLoading ? (
                      <CircularProgress size={26} className={classes.loginLoader} />
                    ) : (
                      <Button
                        disabled={
                          otp.length !== 4 
                        }
                        onClick={() =>verifyOtpToLogin(
                          setIsLoading, 
                          setErrorLogin,
                          otp,
                          phoneNumber,
                          props.history, 
                          userDispatch                           
                          )
                        }
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Login
                      </Button>
                    )}
                    <Button
                      color="primary"
                      size="large"
                      className={classes.forgetButton}
                      component={Link}
                      to="/login"
                    >
                      Back to Login
                    </Button>
                  </div>
                </React.Fragment>
          
          
        </div>
        <Typography color="primary" className={classes.copyright}>
        © 2021-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://cmsfbd.org" rel="noopener noreferrer" target="_blank">CMSF</a>,  All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(OtpPage);
