import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import FilterTableComponent from './FilterTableComponent';

const ListOfIssuers = () => {
  const { t } = useTranslation();
  return (
    <div>
       <PageTitle title={ t('Issuer.sideBarListTitle')} button={<Link to="/app/issuer/new-add"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('Issuer.goToNewAdd')}
    </Button></Link>}  />
        <FilterTableComponent/>
    </div>
  )
}

export default ListOfIssuers