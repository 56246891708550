import { Button, Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableRow from '@material-ui/core/TableRow';
import { default as React, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { default as axios, default as axiosInstance } from "../../../utils/axios";
import Timeline from './Timeline';
//import { Button } from 'bootstrap';






export const ViewLodgedClaim = () => {

  const [photo, setPhoto] = useState('');

  const [isActiveButton, setIsActiveButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');
  const userId = localStorage.getItem("user-id");
  const issuerId = localStorage.getItem("issuer-id");

    const paramsData = {
      
        'userId':userId,
        'issuerId':'',
        'filename':''
    
    }

    const [data, setData] = useState({
        success: '',
        error: '',      
        investorinformationid: "",
        issuerid: "",
        issuerName: "",
        inwarddividendid: "",
        yearDividend: "",
        fullName: "",
        fathersName: "",
        mothersName: "",
        nid:"",
        dob:"",
        warrantNumber: "",
        folioNumber: "",
        phoneNumber: "",
        shareholding: "",
        address: "",
        remarks: "",
        investorsdividendType: "",
        claimType: "",
        grossDividend: "",
        tds: "",
        netDividend: "",
        Actitle: "",
        BankName: " ",
        BranchName: " ",
        AccountNumber: "",
        RoutingNumber: "",
        cheque_leaf_file: "",
        nofshare: "",
        boID: "",
        trecHolderName: "",
        dpID: "",
        cdbl_share_transfer_file: "",
        nid_file: [],
        folio_bo_file: "",
        poa_nid_file: "",
        poa_letter_file: "",
        death_certificate_file: "",
        successor_letter_file: "",
        inheritance_certificate_file: "",
        court_certificate_file: "",
        successor_nid_file: "",
        issuer_approval_status: "",
        issuer_approval_comments: "",
        cmsf_comments: "" 
      });
      console.log(data?.nid_file)
const token = localStorage.getItem('token');

    const summaryDownloadHandler = () => {
      history.push(`/app/claim-settlement/claim/report/${params.id}`)
        //   //console.log('hi');
        //  // setLoader(true)
        //  // axios.get(`claim-settlement/applicaion/${params.id}`)
        //   axios({
        //     url: `claim-settlement/applicaion/${params.id}`, //your url
        //     method: 'GET',
        //     responseType: 'blob', // important
        // })
        // .then((response) =>{
        //   //setLoader(false)
        //   const url = window.URL.createObjectURL(new Blob([response.data]));
        //   const link = document.createElement('a');
        //   link.href = url;
        //   link.setAttribute('download', 'claim_summary.PDF');
        //   document.body.appendChild(link);
        //   link.click();
        //   //window.open(url);
        //   //link.remove();
        // })
        // .catch((error) =>{
        //   //setLoader(false);
        //   setError(error.message);
        //   alert("There was an error ocurred!")
        // })
    };

    const fileDownloadHandler = (e) =>{
      console.log(e);
      axios({
        url: `claim-settlement/get-claim-file/${params.id}`, 
        method: 'POST',
        data: {
          userId: userId,
          //issuerId: '',
          filename:e
        },
        responseType: 'blob', // important
    })
    .then((response) =>{
      //setLoader(false)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${e.slice(14)}`);
      document.body.appendChild(link);
      link.click();
     // let tab = window.open();
      //tab.location.href = link;
      //window.open([response.data]);
      //link.remove();
    })
    .catch((error) =>{
      //setLoader(false);
      //setError(error.message);
      alert("There was an error ocurred!")
    })
  }

//     const summaryDownloadHandler = () => {
//       console.log('hi');
//       setLoader(true)
//       axios.get(`claim-settlement/applicaion/${params.id}`)
//       .then(response => {
//         response.blob().then(blob => {
//       setLoader(false)
//       const url = window.URL.createObjectURL(blob);
//       var link = document.createElement('a');
//       link.href = url;
//      // link.setAttribute('download', 'response.PDF');
//       //document.body.appendChild(link);
//       link.download = 'etin_report.pdf'
//       link.click();
//     })
//     })
//     .catch((error) =>{
//       //setLoader(false);
//       setError(error.message);
//       alert("There was an error ocurred!")
//     })
// };


    const params = useParams();
    const history = useHistory()
    useEffect(()=>{
        // axiosInstance.get(`claim-settlement/${params.id}`)
        axiosInstance.get(`claim-application/${params.id}`)
        .then((res) =>{
            if(res?.data?.payload){
                
                setData({
                    ...data,
                    claimType: res?.data?.payload.claimType,
                    investorsdividendType: res?.data?.payload.investorsdividendType,
                    Actitle: res?.data?.payload.Actitle,
                    issuerName: res?.data?.payload?.issuer?.name,
                    fullName: res?.data?.payload?.fullName,
                    fathersName: res?.data?.payload?.fathersName,
                    mothersName: res?.data?.payload?.mothersName,
                    boID: res?.data?.payload?.boID,
                    warrantNumber: res?.data?.payload?.warrantNumber,
                    address: res?.data?.payload?.address,
                    nid: res?.data?.payload?.nid, 
                    dob: res?.data?.payload?.dob,
                    phoneNumber: res?.data?.payload?.phoneNumber,
                    folioNumber: res?.data?.payload?.folioNumber,
                    shareholding: res?.data?.payload?.shareholding,
                    yearDividend: res?.data?.payload?.yearDividend,
                    grossDividend: res?.data?.payload?.grossDividend,
                    tds: res?.data?.payload?.tds,
                    netDividend: res?.data?.payload?.netDividend,
                    AccountNumber: res?.data?.payload?.AccountNumber,
                    BankName: res?.data?.payload?.BankName,
                    BranchName: res?.data?.payload?.BranchName,
                    RoutingNumber: res?.data?.payload?.RoutingNumber,
                    cheque_leaf_file: res?.data?.payload?.cheque_leaf_file,
                    trecHolderName: res?.data?.payload?.trecHolderName,
                    nofshare: res?.data?.payload?.nofshare,
                    dpID: res?.data?.payload?.dpID,
                    nid_file: res?.data?.payload?.nid_file.slice(-1)[0],
                    folio_bo_file: res?.data?.payload?.folio_bo_file.slice(-1)[0],
                    poa_nid_file: res?.data?.payload?.poa_nid_file.slice(-1)[0],
                    poa_letter_file: res?.data?.payload?.poa_letter_file.slice(-1)[0],
                    death_certificate_file: res?.data?.payload?.death_certificate_file.slice(-1)[0],
                    successor_nid_file: res?.data?.payload?.successor_nid_file.slice(-1)[0],
                    successor_letter_file: res?.data?.payload?.successor_letter_file.slice(-1)[0],
                    inheritance_certificate_file: res?.data?.payload?.inheritance_certificate_file.slice(-1)[0],
                    court_certificate_file: res?.data?.payload?.court_certificate_file.slice(-1)[0],
                  });
            }
        })
        .catch((err) =>{
          alert("There was an error occured!")
        })
    },[]);

    
    let dividendContent ='' ;
  if(data?.claimType === 'Self'){
    dividendContent = <>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
    </>
  }
console.log(data.nid_file)
  if(data?.claimType === 'Successor'){
    dividendContent = <>
            <TableRow>
              <TableCell>Death Certificate File</TableCell>
              {/* <TableCell> {data.death_certificate_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.death_certificate_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Successor NID File</TableCell>
              {/* <TableCell> {data.successor_nid_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.successor_nid_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Inheritance letter File</TableCell>
              {/* <TableCell> {data.inheritance_certificate_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.inheritance_certificate_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Court Certificate File</TableCell>
              {/* <TableCell> {data.court_certificate_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.court_certificate_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
    </>
  }
  if(data?.claimType === 'Authorized'){
    dividendContent = <>
            <TableRow>
              <TableCell>Authorized NID File</TableCell>
              {/* <TableCell>{data.poa_nid_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.poa_nid_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Authorized Letter File</TableCell>
              {/* <TableCell>{data.poa_letter_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.poa_nid_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
    </>
  }
  if(data?.claimType === 'PowerOfAttorney'){
    dividendContent = <>
            {/* <TableRow>
              <TableCell>Dividend Type</TableCell>
              <TableCell>{data.nid_file}</TableCell>
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.nid_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow> */}
            
            <TableRow>
              <TableCell>NID File of POA</TableCell>
              {/* <TableCell>{data.poa_nid_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.poa_nid_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>POA Letter</TableCell>
              {/* <TableCell>{data.poa_letter_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.poa_letter_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bo/Folio File</TableCell>
              {/* <TableCell>{data.folio_bo_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.folio_bo_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
    </>
  }

  let investorTypeContent ='' ;
  
  if(data?.investorsdividendType === 'CashDividend' || data?.investorsdividendType === 'PublicSubscriptionMoney' || data?.investorsdividendType === 'FCCashDividend' || data?.investorsdividendType === 'FCPublicSubscriptionMoney'){
    investorTypeContent = <>
            <TableRow>
              <TableCell>Account Title</TableCell>
              <TableCell>{data.Actitle}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dividend Year</TableCell>
              <TableCell>{data.yearDividend}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gross Dividend</TableCell>
              <TableCell>{data.grossDividend}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TDS</TableCell>
              <TableCell>{data.tds}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Net Dividend</TableCell>
              <TableCell>{data.netDividend}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account Number</TableCell>
              <TableCell>{data.AccountNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bank Name</TableCell>
              <TableCell>{data.BankName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Branch Name</TableCell>
              <TableCell>{data.BranchName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Routing Number</TableCell>
              <TableCell>{data.RoutingNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cheque Leaf File</TableCell>
              {/* <TableCell>{data?.cheque_leaf_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data?.cheque_leaf_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow>
    </>
  }

  if(data?.investorsdividendType === 'StockBonusShare' || data?.investorsdividendType === 'StockRightShare' ){
    investorTypeContent = <>
            <TableRow>
              <TableCell>Account Title</TableCell>
              <TableCell>{data.Actitle}</TableCell>
            </TableRow> 
            <TableRow>
              <TableCell>Dividend Year</TableCell>
              <TableCell>{data.yearDividend}</TableCell>
            </TableRow> 
            <TableRow>
              <TableCell>No of Share</TableCell>
              <TableCell>{data.nofshare}</TableCell>
            </TableRow> 
            <TableRow>
              <TableCell>TREC Holder</TableCell>
              <TableCell>{data.trecHolderName}</TableCell>
            </TableRow> 
            <TableRow>
              <TableCell>DP ID</TableCell>
              <TableCell>{data.dpID}</TableCell>
            </TableRow> 
            <TableRow>
              <TableCell>CDBL Share Transfer File </TableCell>
              {/* <TableCell>{data.cdbl_share_transfer_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.cdbl_share_transfer_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow> 
    </>
  }

  return (
      <>
      <>
   
      <Grid container spacing={4}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
        <Paper>
        <Typography style={{ backgroundColor: '#E1D9D7'}}>Claim Summary</Typography>
        <Table>
          <TableHead>
  
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Claimant Type</TableCell>
              <TableCell>{data.claimType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dividend Type</TableCell>
              <TableCell>{data.investorsdividendType==='StockRightShare'? 'Stock Right Share' 
                          :data.investorsdividendType==='CashDividend'? 'Cash Dividend' 
                          :data.investorsdividendType==='FCCashDividend'? 'FC Cash Dividend'
                          :data.investorsdividendType==='PublicSubscriptionMoney' ? 'Public Subscription Money' 
                          :data.investorsdividendType==='FCPublicSubscriptionMoney'? 'FC Public Subscription Money'
                          :data.investorsdividendType==='StockBonusShare'? 'Stock Bonus Share' 
                          : 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Issuer Name</TableCell>
              <TableCell>{data.issuerName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>{data.fullName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Father Name</TableCell>
              <TableCell>{data.fathersName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mother Name</TableCell>
              <TableCell>{data.mothersName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>BO ID</TableCell>
              <TableCell>{data.boID}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Folio Number</TableCell>
              <TableCell>{data.folioNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Warrant Number</TableCell>
              <TableCell>{data.warrantNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>National ID</TableCell>
              <TableCell>{data.nid}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date of Birth</TableCell>
              <TableCell>{data.dob}</TableCell>
            </TableRow>
      
            <TableRow>
              <TableCell>Phone Number</TableCell>
              <TableCell>{data.phoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Share Holding</TableCell>
              <TableCell>{data.shareholding}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>{data.address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper> 
      <Paper>
          <Typography style={{ backgroundColor: '#E1D9D7'}}>Dividend/Payment Information</Typography>
        <Table>
          <TableHead>
          </TableHead>
          <TableBody>
            {investorTypeContent}
          <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            
          </TableBody>
        </Table>
      </Paper>
      <Paper>
          <Typography style={{ backgroundColor: '#E1D9D7'}}>Required Documents</Typography>
        <Table>
          <TableHead>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Investors NID File</TableCell>
              {/* <TableCell>{ data.nid_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.nid_file}`)}><GetAppIcon /></Button></TableCell>
              <div>
                <img style={{height: "205px", weight: "200px"}} src={ photo } /> 
              </div>

            </TableRow>
            <TableRow>
              <TableCell>Bo/Folio File</TableCell>
              {/* <TableCell>{data.folio_bo_file}</TableCell> */}
              <TableCell><Button  style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} variant='contained' color='primary' 
              onClick={()=>fileDownloadHandler(`${data.folio_bo_file}`)}><GetAppIcon /></Button></TableCell>
            </TableRow> 
            {dividendContent}         
          </TableBody>
        </Table>
      </Paper>
      <Button 
        style={{ margin: '5px', marginTop: '15px' , backgroundColor: '#45CFDD'}}  
        variant="contained" 
        color="default" 
        onClick={summaryDownloadHandler}
          >
           {loader? 'Generating' : 'Generate Claim Report'}
            {/* Download a Copy */}
        </Button>
      {error!=='' && <h6 fontColor='red'>{error}</h6>}
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography style={{ backgroundColor: '#E1D9D7'}}>Timeline of Lodged Claim</Typography>
        <Timeline />
          </Grid>
        </Grid>
      <h1 style={{marginBottom: "20px"}}> </h1>
      </>
      </>
    );
  };