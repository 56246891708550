import { Button, Grid, Paper, Typography } from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo

export default function Success() {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant="h1"
          color="primary"
          
        >
          Congratulations!
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          Your Registration is Successful!
        </Typography>
        <Typography
          variant="h6"
          color="text"
          colorBrightness="secondary"
          className={classnames(classes.textRow, classes.safetyText)}
        >
          Temporary Password Has Been Sent To Your E-mail. Please Use That User ID & Password To Log In
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/home"
          size="large"
          className={classes.backButton}
        >
          Back to Home
        </Button>
      </Paper>
    </Grid>
  );
}
