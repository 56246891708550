import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Link,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  IconButton,
  Typography,
  TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Email, Lock, Payment, Person, Phone} from '@material-ui/icons';
import CakeIcon from '@material-ui/icons/Cake';
import React, { useEffect, useState } from 'react';
import { registrationUser } from '../../context/UserContext';
import Logo from './AuthBackground';
import Notification from './Notification';
import CmsfLogo from './cmsf.jpg'; // Replace with the path to your logo image

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'relative',
      padding: theme.spacing(4),
      maxWidth: 600,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    logo: {
      position: 'absolute',
      top: theme.spacing(3),
      left: theme.spacing(3),
      width: 180,
      height: 80,
    },
  }));
  
  const RegistrationComponent = (props) => {
    const classes = useStyles();
    const [isFormValid, setIsFormValid] = useState(false);
    const [fullName, setFullName] = useState("");
    const [fullNameError, setFullNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [nid, setNid] = useState("");
    const [nidError, setNidError] = useState("");
    const [dob, setDob] = useState("");
    const [dobError, setDobError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [gender, setGender] = useState('');
    const [genderError, setGenderError] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const successMessage = "You've successfully registered!";
    const [isSuccess, setIsSuccess] =useState(false)
    const [isErrorMsg, setIsErrorMsg] =useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
   

    useEffect(() => {
      validateForm();
    }, [ fullName, email, phoneNumber, nid, dob, password, confirmPassword, gender]);

    const validateForm = () => {
      const isFullNameValid = validateFullName(fullName);
      const isEmailValid = validateEmail(email);
      const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
      const isNIDValid = validateNID(nid);
      const isDOBValid = validateDOB(dob);
      const isPasswordValid = validatePassword(password);
      const isConfirmPasswordValid = validateConfirmPassword(confirmPassword);
      const isGenderValid = validateGender(gender);
      setIsFormValid(
        isFullNameValid &&
        isEmailValid &&
        isPhoneNumberValid &&
        isNIDValid &&
        isDOBValid &&
        isPasswordValid &&
        isConfirmPasswordValid &&
        isGenderValid
      );
    };

    const handleFormSubmit = (e) => {
      e.preventDefault();
      if (isFormValid) {
        registrationUser(email,
          fullName,
          phoneNumber,
          password,
          nid,
          dob,
          gender,
          setIsLoading,
          setIsError,
          props.history,
          setIsSuccess,
          setIsErrorMsg) 
      }
      if(isSuccess){
        setFullName('');
        setEmail('');
        setPhoneNumber('');
        setNid('');
        setDob('');
        setPassword('');
        setConfirmPassword('');
        setGender('');
      }
    };
    useEffect(() => {
      if (isSuccess === true) {
        setFullName('');
        setEmail('');
        setPhoneNumber('');
        setNid('');
        setDob('');
        setPassword('');
        setConfirmPassword('');
        setGender('');
      }
    }, [isSuccess]);
   
    const handleClickShowPassword = () => {setShowPassword((prevState) => !prevState)};
    const handleClickShowConfirmPassword = () => {setShowConfirmPassword((prevState) => !prevState)};
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const handleMouseDownConfirmPassword = (event) => {
      event.preventDefault();
    };
    const validateFullName = (value) => {
      if (value.trim().length < 1) {
        setFullNameError("");
        return false;
      }else if(value.trim().length < 3){
        setFullNameError("Must be enter your name.");
        return false;
      } else {
        setFullNameError("");
        return true;
      }
    };
    
    const validateEmail = (value) => {
      const emailRegex = /^\S+@\S+\.[a-zA-Z]{2,4}$/;
      if (email === '') {
        setEmailError("");
        return false;
      }else if(!emailRegex.test(value)){
        setEmailError("Invalid Email Address");
        return false;
      } else {
        setEmailError("");
        return true;
      }
    };
    
    const validatePhoneNumber = (value) => {
      if (phoneNumber === '') {
        setPhoneNumberError("");
        return false;
      }else if(value.length !== 11 || isNaN(value)){
        setPhoneNumberError("Phone Number must be 11-digits");
        return false;
      } else {
        setPhoneNumberError("");
        return true;
      }
    };
    

  const validateNID = (value) => {
    const nidRegex = /^(?:\d{10}|\d{13}|\d{17})$/;

    if (nid === '') {
      setNidError("");
      return false;
    }else if (!nidRegex.test(value)) {
      setNidError("NID must be a 10, 13, or 17-digit number");
      return false;
    }else {
      setNidError("");
      return true;
    }
  };

  const validateDOB = (value) => {
    if(value){

    }else if(dob === ''){
      setDobError("");
      return false;
    }else{
      setDobError("Must be select date of birth.");
      return false;
    }
    const today = new Date();
    const selectedDate = new Date(value);

    if (selectedDate > today || selectedDate === "") {
      setDobError("Date of Birth cannot be a future date");
      return false;
    } else {
      setDobError("");
      return true;
    }
  };

  const validatePassword = (value) => {
    const passwordRegex = /^((?=.*[0-9])(?=.*[A-Z])(?=.*\W)).{6,32}$/;
    if (password === '') {
      setPasswordError("");
      return false;
    }
    if(!passwordRegex.test(value)){
      setPasswordError("Password should be at least 6 characters with at least one number, one special character and one upper case character");
      return false;
    } 
    else {
      setPasswordError("");
      return true;
    }
  };

  const validateConfirmPassword = (value) => {
    if (confirmPassword === '') {
      setConfirmPasswordError("");
      return false;
    }else if (value !== password) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    } else {
      setConfirmPasswordError("");
      return true;
    }
  };

  const validateGender = (value) => {
    if (gender === '') {
      setGenderError('');
      return false;
    }else if (!value) {
      setGenderError('Please select a gender');
      return false;
    } else {
      setGenderError('');
      return true;
    }
  };
  

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
    validateFullName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    validatePhoneNumber(e.target.value);
  };

  const handleNIDChange = (e) => {
    setNid(e.target.value);
    validateNID(e.target.value);
  };

  const handleDOBChange = (e) => {
    setDob(e.target.value);
    validateDOB(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validateConfirmPassword(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
    validateGender(e.target.value);
  };
  
  const handleClose = () => {
    setIsSuccess(false);
    setIsError(false);
  };

  
    return (
      <div>
        <Box>
        <Notification message={successMessage} open={isSuccess} handleClose={handleClose} color='green' />
        <Notification message={isErrorMsg} open={isError} handleClose={handleClose} color='red' />
        </Box>
      <div >
        <Box className={classes.logo}>
          <Logo />
        </Box>
  
        <div className={classes.root}>
          <img src={CmsfLogo} alt="Logo" className={classes.logo} />
          <Box style={{transform:'scale(0.8)'}}>
          <Box style={{marginTop:'50px'}}>
            <Paper>
              <Box style={{ textAlign: 'center', backgroundColor: 'blue', color: 'white' }}>
                <Typography variant="h2" component="h1">
                  Welcome to CMSF
                </Typography>
              </Box>
            </Paper>
          </Box>
          <Box>
            <Paper>
              <Box style={{ textAlign: 'center', color: 'white', paddingTop: '20px' }}>
                <Typography style={{ color: 'black' }} variant="h5" component="h1" gutterBottom>
                  Create a new account
                </Typography>
                <Typography style={{ color: 'black', fontSize: '18px' }} variant="body1" component="p" gutterBottom>
                  It's quick and easy.
                </Typography>
              </Box>
            </Paper>
          </Box>
  
          <Box >
            <Paper className={classes.paper}>
     <form onSubmit={handleFormSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OutlinedInput
            fullWidth
            id="fullname"
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={handleFullNameChange}
            startAdornment={
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            }
          />
          {fullNameError && (
            <Typography variant="caption" color="error">
              {fullNameError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <OutlinedInput
            fullWidth
            id="email"
            value={email}
            onChange={handleEmailChange}
            type="email"
            placeholder="Email Address"
            startAdornment={
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            }
          />
          {emailError && (
            <Typography variant="caption" color="error">
              {emailError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <OutlinedInput
            fullWidth
            type="number"
            id="phone"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            startAdornment={
              <InputAdornment position="start">
                <Phone />
              </InputAdornment>
            }
            placeholder="Phone Number"
          />
          {phoneNumberError && (
            <Typography variant="caption" color="error">
              {phoneNumberError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <OutlinedInput
            fullWidth
            id="nid"
            type="text"
            value={nid}
            onChange={handleNIDChange}
            startAdornment={
              <InputAdornment position="start">
                <Payment />
              </InputAdornment>
            }
            placeholder="NID"
          />
          {nidError && (
            <Typography variant="caption" color="error">
              {nidError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant='outlined'
            fullWidth
            id="dob"
            type="date"
            placeholder="Date of Birth"
            label="Date of Birth"
            value={dob}
            onChange={handleDOBChange}
            InputLabelProps={{
              shrink: true,
            }}
            startAdornment={
              <InputAdornment position="start">
                <CakeIcon />
              </InputAdornment>
              
            }
          />
          {dobError && (
            <Typography variant="caption" color="error">
              {dobError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <OutlinedInput
            fullWidth
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
            startAdornment={
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
              
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {passwordError && (
            <Typography variant="caption" color="error">
              {passwordError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <OutlinedInput
            fullWidth
            id="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            startAdornment={
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {confirmPasswordError && (
            <Typography variant="caption" color="error">
              {confirmPasswordError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
        <FormControl component="fieldset" error={!!genderError}>
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup row value={gender} onChange={handleGenderChange}>
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
          {genderError && (
            <Typography variant="caption" color="error">
              {genderError}
            </Typography>
          )}
        </FormControl>
      </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!isFormValid}
      >
        Register
      </Button>
      <Grid container justify="flex-end">
        <Grid item>
          <Link href="login" variant="body2">
            Already have an account? Login
          </Link>
        </Grid>
      </Grid>
    </form>
            </Paper>
          </Box>
          </Box>
        </div>
        
      </div>
      <Box style={{height:'30px',width:'100%',marginTop:'100px', backgroundColor:'gray', }}>
  

      </Box></div>
    );
  };
  
  export default RegistrationComponent;
  