import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import PageTitle from '../../../components/PageTitle/PageTitle';
import axios from '../../../utils/axios';
import useStyles from "./../../../utils/validations/useStyles";
const MenuProps = {
    PaperProps: {
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
        // height:150,
        // overflowY: 'scroll',
        // overflowX: 'scroll'
      },
    },
  };

const options = [{ tradecode: "No Data Available", name: "" },
// { tradecode: "No Data Available", name: "" },
];

const CreateLedger = () => {
    const classes = useStyles();
    let dividendContent ='' ;
    let dividendContent2 ='' ;
    const [glName, setGlName] = useState("")
    const [employeeList, setEmployeeList] = useState([])
    const [issuerList, setIssuerList] = useState([])
    const [bankList, setBankList] = useState([])
    const [branchList, setBranchList] = useState([]);
    const [dpList,setDPList] = useState([]);

    const [employeeName, setEmployeeName] = useState({})
    const [bankName, setbankName] = useState({})
    const [branchName, setBranchName] = useState({})
    const [nameofDp, setNameofDp] = useState({})
    const [nameofIssuer, setNameofIssuer] = useState({})
    const [ledgerName, setLedgerName] = useState("")
    const getUserId = localStorage.getItem("user-id")
   
    const [allValues,setAllValues] =useState({
        group_ledger:"",
        ledger_type:"",
        employee_id:"",
        empId:"",
        bank:"",
        bankId:"",
        branch:"",
        routing:"",
        dp:"",
        ledger_no:"",
        ledger_name:"",
        remarks:"",
        entryby:getUserId,
        dpId: "",
        issuer: "",
        issuerId: "",
    })

    useEffect(()=>{
        axios.get('/employee?flagview=1')
        .then((res)=>{
            setEmployeeList(res.data.payload)
        })
        .catch((err) =>{
          alert(err.response.data.message)
        })
        axios.get('/issuer/get-info')
        .then((res)=>{
            setIssuerList(res.data.payload)
        })
        .catch((err) =>{
          alert(err.response.data.message)
        })
        axios.get('/bank/get-all-bank-names')
        .then((res)=>{
            setBankList(res.data.payload);
        } )
        .catch((err)=>console.log(err));
        
        axios.get('/bank/get-all-dp-names')
        .then((res)=>{
            setDPList(res.data.payload);
        })
        .catch((err)=>console.log(err));
    },[])

    useEffect(()=>{
        if (allValues.ledger_type === 'EMP') {
              setbankName({})
              setBranchName({})
              setNameofDp({})
              setAllValues((prevValues) => ({
                  ...prevValues,
                  bank: "",
                  branch: "",
                  routing: "",
                  dp: "",
             
              }));
            setLedgerName("")
          }
          else if (allValues.ledger_type === 'BANK') {
              setEmployeeName({})
              setNameofDp({})
              setAllValues((prevValues) => ({
                  ...prevValues,
                  employee_id: "",
                  dp: ""
            }));
            setLedgerName("")
          }
          else if (allValues.ledger_type === 'DP') {
               setEmployeeName({})
               setbankName({})
               setBranchName({})
            setAllValues((prevValues) => ({
              ...prevValues,
              bank: "",
              branch: "",
              routing: "",
              employee_id:""
            }));
            setLedgerName("")
          }
    },[allValues.ledger_type])

    // const resetHandler = () =>{
    //   setAllValues({
    //     group_ledger:"",
    //     ledger_type:"",
    //     employee_id:"",
    //     bank:"",
    //     branch:"",
    //     routing:"",
    //     dp:"",
    //     ledger_no:"",
    //     ledger_name:"",
    // })
    // }

    const resetHandler = () =>{
      setAllValues({
        group_ledger:"",
        ledger_type:"",
        employee_id:"",
        empId:"",
        bank:"",
        bankId:"",
        branch:"",
        routing:"",
        dp:"",
        ledger_no:"",
        ledger_name:"",
        remarks:"",
        entryby:getUserId,
        dpId: "",
        issuer: "",
        issuerId: "",
    });
      setEmployeeName({})
      setbankName({})
      setBranchName({})
      setNameofDp({})   
      setNameofIssuer({}) 
      setLedgerName("")
    }

    const getBranchList = (bank) =>{
        let object = {bankName:`${bank}`}
        axios.post('/bank/get-all-banks-by-name',object)
        .then((res)=>{
        // console.log(res.data.payload);
            // setTimeout(function(){
            setBranchList(res.data.payload);
            // }, 3000);
        
        } )
        .catch((err)=>{
        console.log(err)
        });
    }
   
        console.log(allValues);

        const submitHandler = () => {

          // if(allValues?.group_ledger === 'BWB'){
          //   setAllValues( prevValues => {
          //     return { ...prevValues,ledger_type: 'BANK'}
          //  })
          // }

          // else if(allValues?.group_ledger === 'ISSUER'){
          //   setAllValues( prevValues => {
          //     return { ...prevValues,ledger_type: 'ISSUER'}
          //  })
          // }
          // else if(allValues?.group_ledger === 'INVESTOR'){
          //   setAllValues( prevValues => {
          //     return { ...prevValues,ledger_type: 'INVESTOR'}
          //  })
          // }
          // else if(allValues?.group_ledger === 'FDR'){
          //   setAllValues( prevValues => {
          //     return { ...prevValues,ledger_type: 'FDR'}
          //  })
          // }
          // else if(allValues?.group_ledger === 'IAC'){
          //   setAllValues( prevValues => {
          //     return { ...prevValues,ledger_type: 'IAC'}
          //  })
          // }
  
  
          let ldgAccTypeRef;
          if (allValues.ledger_type === "BANK") {
            ldgAccTypeRef = allValues.bankId;
          }  if (allValues.ledger_type === "EMP") {
            ldgAccTypeRef = allValues.empId;
          }  if (allValues.ledger_type === "DP") {
            ldgAccTypeRef = allValues.dpId;
          }
            if (allValues.ledger_type === "ISSUER") {
            ldgAccTypeRef = allValues.issuerId;
          }
          if (allValues.ledger_type === "BWB"||allValues.ledger_type === "FDR") {
            ldgAccTypeRef = allValues.bankId;
          }
          if (allValues.ledger_type === "IAC") {
            ldgAccTypeRef = "IAC";
          }
           

          const postData = {
            group_ledger: allValues.group_ledger,
            ledger_type: allValues.ledger_type,
            ldgAccTypeRef: ldgAccTypeRef, 
            entryby: allValues.entryby,
            ldgaccname: allValues.ledger_name,
            remarks: allValues.remarks
          };

          console.log(postData);

          if (!allValues.group_ledger) {
            Swal.fire('Please select Group Ledger');
            return;
          }
        
          if (allValues.group_ledger === 'LGIV_CRD' && !allValues.ledger_type) {
            Swal.fire('Please select Ledger Type');
            return;
          }

          if (((allValues.group_ledger === 'LGIV_CRD' && allValues.ledger_type === 'BANK') || allValues.group_ledger === 'BWB' || allValues.group_ledger === 'FDR') && !allValues.routing) {
            Swal.fire('Please select Bank Name and Branch Name');
            return;
          }

          if (allValues.group_ledger === 'ISSUER'  && !allValues.ledger_name && !ledgerName) {
            Swal.fire('Please select Issuer Name');
            return;
          }

          if (!ledgerName || !allValues.ledger_name ) {
            Swal.fire(`Please select Ledger Name`);
            return;
          }
 
          if (!allValues.remarks) {
            Swal.fire('Please enter your Remarks');
            return;
          }

          // if (!allValues.group_ledger || !allValues.ledger_type || !allValues.ledger_name || !ldgAccTypeRef || !allValues.remarks) {
          //   Swal.fire('Please POVIDE ALL DATA');
          //   return;
          // }

          axios.post('/bank-ldfix/create-ldgaccno',postData)
          .then((res)=>{           

            if(res?.data?.statusCode === 200){
              let LedgerNumber = res.data.payload[0].fn_get_new_ldgacno_glwise
              console.log(LedgerNumber)
              Swal.fire({
                icon:'success',
                text:`Ledger created successfully. Your Ledger Number is: ${LedgerNumber}`
              });
            } 
          
            })
            .catch((err)=>{
              const errorMessage = err.response?.data?.message || 'Error creating ledger'; 
              // Swal.fire(errorMessage);
              Swal.fire('Information Mismatch Found. Please Try Again')
            });  resetHandler()
        }

    const changeHandler = e => {
        if( allValues.group_ledger === 'INVESTOR' ){
            setAllValues({
              group_ledger:e.target.value,
              ledger_type:"",
              employee_id:"",
              empId:"",
              bank:"",
              bankId:"",
              branch:"",
              routing:"",
              dp:"",
              ledger_no:"",
              ledger_name:"",
              remarks:"",
              entryby:getUserId,
              dpId: "",
              issuer: "",
              issuerId: "",
          })
        }
        else{
            setAllValues( prevValues => {
            return { ...prevValues,[e.target.name]: e.target.value}
           
         })
        }
    }
    const changeHandlerLedgerName = (e) => {
      setLedgerName(e.target.value); // Update ledgerName state when the value changes
    };

    const changeHandler_GL = e => {
          setEmployeeName({})
          setbankName({})
          setBranchName({})
          setNameofDp({})   
          setNameofIssuer({}) 
          setLedgerName("")
          setBranchList([])
          {setAllValues( prevValues => {
            return { ...prevValues,routing: "",ledger_name:""} 
         })}
          if(e.target.value==='INVESTOR' || e.target.value==='IAC'){setAllValues( prevValues => {
            return { ...prevValues,ledger_type: e.target.value} 
         })}
          setAllValues( prevValues => {
          return { ...prevValues,[e.target.name]: e.target.value} 
       })
      
  }


    if(allValues.group_ledger === 'LGIV_CRD'){
      dividendContent = <Grid style={{marginLeft:'30px'}} container spacing={4}>
         <Grid item lg={3} md={3} sm={12} xs={12}> 
                    <FormControl fullWidth >
                        <InputLabel id="ledger_type" >Select Ledger Type</InputLabel>
                            <Select style={{marginBottom: '25px'}}
                                required
                                labelId="ledger_type"
                                name='ledger_type'
                                id="demo-simple-select"
                                value={allValues.ledger_type}
                                label="ledger_type"
                                onChange={changeHandler}
                                >
                                <MenuItem value='EMP' key='EMP'>Employee</MenuItem>
                                <MenuItem value='BANK' key='BANK'>Bank</MenuItem>
                                <MenuItem value='DP' key='DP'>DP</MenuItem>
                            </Select>  
                    </FormControl> <br />   
                </Grid>
      </Grid>
       if(allValues.ledger_type === 'EMP'){
        dividendContent2 =  <Grid style={{marginLeft:'30px'}} container spacing={4}>
        <Grid item lg={3} md={3} sm={12} xs={12}> 
                   <FormControl fullWidth >
                           <Autocomplete 
                            value={employeeName}                            
                            onChange={(event, newValue) => { 
                             
                              setEmployeeName(newValue)
                            setAllValues({...allValues, employee_id: newValue.emp_name,ledger_name:newValue.emp_name,empId: newValue?.empidr,
                                // ,bank:"",branch:"",routing:"",dp:""
                            });setLedgerName(newValue.emp_name)
                            }}
                            disableClearable
                            options={employeeList?employeeList:options}
                            getOptionLabel={(option) => option.emp_name  }
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                 label="Select Employee Name"
                                // margin="normal"
                                // variant="outlined"
                                // InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                /> 
                   </FormControl> <br />   
               </Grid>
     </Grid>
       }
       else if(allValues.ledger_type === 'BANK'){
       
        dividendContent2 = <Grid style={{marginLeft:'30px'}} container spacing={4}>
        <Grid item lg={3} md={3} sm={12} xs={12}> 
                   <FormControl fullWidth > 
                       <Autocomplete   
                            value={bankName}                   
                            disableClearable
                            options={bankList?bankList:options}
                            getOptionLabel={(option) => option.bankName  }
                            onChange={(event, newValue) => {
                                console.log(newValue)
                                setbankName(newValue)
                                setAllValues({...allValues, bank: newValue.bankName,ledger_name:newValue.bankName
                                    // ,employee_id:"",dp:""
                                });setLedgerName(newValue.bankName)
                                getBranchList(newValue.bankName)}}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label="Select Bank Name"
                                // margin="normal"
                                // variant="outlined"
                                // InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                        /><br />   
                        <Autocomplete   
                           value={branchName}
                            disableClearable
                            options={branchList?branchList:options}
                            getOptionLabel={(option) => option.branchName}
                            onChange={(event, newValue) => {
                              setBranchName(newValue)
                                setAllValues({...allValues, branch:newValue.branchName ,routing:newValue.routingNo,bankId: newValue.id
                                });
                                }}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label="Select Branch Name"  
                                // margin="normal"
                                // variant="outlined"
                                // InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                />
                   </FormControl> <br />   
                  
                    <TextField
                     fullWidth
                     InputLabelProps={{
                       classes: {
                         asterisk: classes.requiredAsterisk,
                         outlined: classes.root,
                       },
                     }}
                     InputProps={{
                       startAdornment: (
                         <InputAdornment position="start">
                         </InputAdornment>
                       ),
                     }}
                        fullWidth
                        // disabled
                        id="routing"
                        label="Routing"
                        variant="outlined"
                        required
                        type='number'
                        margin="normal"
                        value={allValues.routing}
                        // onChange={changeHandler}
                    /><br />
                   
                    
                 
               </Grid>
     </Grid>
       }
       else if(allValues.ledger_type === 'DP'){
        dividendContent2 = <Grid style={{marginLeft:'30px'}} container spacing={4}>
        <Grid item lg={3} md={3} sm={12} xs={12}> 
                   <FormControl fullWidth >
                           <Autocomplete  
                            value={nameofDp}      
                            onChange={(event, newValue) => {
                                console.log(newValue)
                                setNameofDp(newValue)
                            setAllValues({...allValues, dp: newValue.dpName,ledger_name:newValue.dpName,dpId: newValue.id,
                                // ,employee_id:"",bank:"",branch:"",routing:""
                            });setLedgerName(newValue.dpName)
                            }}
                            disableClearable
                            options={dpList?dpList:options}
                            getOptionLabel={(option) => option.dpName}
                            renderInput={(params) => (
                            <TextField
                            fullWidth
                                {...params}
                                required
                                 label="Select DP Name"
                            />
                            )}
                /> 
                   </FormControl> <br />   
               </Grid>
     </Grid>
        
       }
     } 

    if(allValues.group_ledger === 'BWB'){

        dividendContent2 = <Grid style={{marginLeft:'30px'}} container spacing={4}>
        <Grid item lg={3} md={3} sm={12} xs={12}> 
                   <FormControl fullWidth > 
                       <Autocomplete   
                            value={bankName}                   
                            disableClearable
                            options={bankList?bankList:options}
                            getOptionLabel={(option) => option.bankName  }
                            onChange={(event, newValue) => {
                                console.log(newValue)
                                setbankName(newValue)
                                setAllValues({...allValues, bank: newValue.bankName,ledger_name:newValue.bankName,ledger_type:'BWB'
                                    // ,employee_id:"",dp:""
                                });setLedgerName(newValue.bankName)
                                getBranchList(newValue.bankName)}}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label="Select Bank Name"
                                // margin="normal"
                                // variant="outlined"
                                // InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                        /><br />   
                        <Autocomplete   
                           value={branchName}
                            disableClearable
                            options={branchList?branchList:options}
                            getOptionLabel={(option) => option.branchName}
                            onChange={(event, newValue) => {
                              setBranchName(newValue)
                                setAllValues({...allValues, branch:newValue.branchName ,routing:newValue.routingNo,bankId: newValue.id
                                });
                                }}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label="Select Branch Name"  
                                // margin="normal"
                                // variant="outlined"
                                // InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                />
                   </FormControl> <br />   
                  
                    <TextField
                     fullWidth
                     InputLabelProps={{
                       classes: {
                         asterisk: classes.requiredAsterisk,
                         outlined: classes.root,
                       },
                     }}
                     InputProps={{
                       startAdornment: (
                         <InputAdornment position="start">
                         </InputAdornment>
                       ),
                     }}
                        fullWidth
                        // disabled
                        id="routing"
                        label="Routing"
                        variant="outlined"
                        required
                        type='number'
                        margin="normal"
                        value={allValues.routing}
                        // onChange={changeHandler}
                    /><br />
                   
                    
                 
               </Grid>
     </Grid>
       } 

       if(allValues.group_ledger === 'FDR'){

        dividendContent2 = <Grid style={{marginLeft:'30px'}} container spacing={4}>
        <Grid item lg={3} md={3} sm={12} xs={12}> 
                   <FormControl fullWidth > 
                       <Autocomplete   
                            value={bankName}                   
                            disableClearable
                            options={bankList?bankList:options}
                            getOptionLabel={(option) => option.bankName  }
                            onChange={(event, newValue) => {
                                console.log(newValue)
                                setbankName(newValue)
                                setAllValues({...allValues, bank: newValue.bankName,ledger_name:newValue.bankName,ledger_type:'FDR'
                                    // ,employee_id:"",dp:""
                                });setLedgerName(newValue.bankName)
                                getBranchList(newValue.bankName)}}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label="Select Bank Name"
                                // margin="normal"
                                // variant="outlined"
                                // InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                        /><br />   
                        <Autocomplete   
                           value={branchName}
                            disableClearable
                            options={branchList?branchList:options}
                            getOptionLabel={(option) => option.branchName}
                            onChange={(event, newValue) => {
                              setBranchName(newValue)
                                setAllValues({...allValues, branch:newValue.branchName ,routing:newValue.routingNo,bankId: newValue.id
                                });
                                }}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label="Select Branch Name"  
                                // margin="normal"
                                // variant="outlined"
                                // InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                            )}
                />
                   </FormControl> <br />   
                  
                    <TextField
                     fullWidth
                     InputLabelProps={{
                       classes: {
                         asterisk: classes.requiredAsterisk,
                         outlined: classes.root,
                       },
                     }}
                     InputProps={{
                       startAdornment: (
                         <InputAdornment position="start">
                         </InputAdornment>
                       ),
                     }}
                        fullWidth
                        // disabled
                        id="routing"
                        label="Routing"
                        variant="outlined"
                        required
                        type='number'
                        margin="normal"
                        value={allValues.routing}
                    /><br />
                   
                    
                 
               </Grid>
     </Grid>
       } 

       if(allValues.group_ledger === 'ISSUER'){
         dividendContent = <div>
          <Grid style={{marginLeft:'30px'}} container spacing={4}>
        <Grid item lg={3} md={3} sm={12} xs={12}> 
                   <FormControl fullWidth >
                           <Autocomplete  
                            value={nameofIssuer}      
                            onChange={(event, newValue) => {
                                console.log(newValue)
                                setNameofIssuer(newValue)
                            setAllValues({...allValues, issuer: newValue.name,ledger_name:newValue.name,issuerId: newValue.id,ledger_type:'ISSUER'
                                // ,employee_id:"",bank:"",branch:"",routing:""
                            });setLedgerName(newValue.name)
                            }}
                            disableClearable
                            options={issuerList?issuerList:options}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                            <TextField
                            fullWidth
                                {...params}
                                required
                                 label="Select Issuer Company"
                            />
                            )}
                /> 
                   </FormControl> <br />   
               </Grid>
     </Grid>
         </div>
       } 
       

       if(allValues.group_ledger === 'INVESTOR'){
        
       } 
        
  return (
    <>
        <PageTitle title={"Create Ledger"}></PageTitle>
            <Grid style={{marginLeft:'5px'}} container spacing={4}>
                <Grid item lg={3} md={3} sm={12} xs={12}> 
                    <FormControl fullWidth >
                        <InputLabel id="group_ledger" >Select Group Ledger </InputLabel>
                            <Select style={{marginBottom: '25px'}}
                                required
                                labelId="group_ledger"
                                name='group_ledger'
                                id="demo-simple-select"
                                value={allValues.group_ledger}
                                label="group_ledger"
                                 onChange={changeHandler_GL}
                                
                                >
                                <MenuItem value='LGIV_CRD' key='LGIV_CRD'>Loan_Given_Crd</MenuItem>
                                <MenuItem value='BWB' key='BWB'>Balance With Bank</MenuItem>
                                <MenuItem value='ISSUER' key='ISSUER'>Issuer Account</MenuItem>
                                {/* <MenuItem value='INVESTOR' key='INVESTOR'>Investor Account</MenuItem> */}
                                <MenuItem value='IAC' key='IAC'>Income of Account</MenuItem>
                                <MenuItem value='FDR' key='FDR'>FDR</MenuItem>
                                
                            </Select>  
                    </FormControl>   
                </Grid>
 
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField
                    fullWidth
                     InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                    required
                    label="Ledger Name"
                    name='ledger_name'
                    // value={allValues.ledger_name}
                    value={ledgerName}
                    // margin="normal"
                     variant="outlined"
                     onChange={changeHandlerLedgerName}
                    // InputProps={{ ...params.InputProps, type: 'search' }}
                    
                />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField
                    fullWidth
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.requiredAsterisk,
                        outlined: classes.root,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                        
                        required
                        label="Remarks"
                        name='remarks'
                        value={allValues.remarks}
                        onChange={(e)=>{setAllValues( prevValues => {
                          return { ...prevValues,remarks: e.target.value}
                       })}}
                        // margin="normal"
                        variant="outlined"
                        // InputProps={{ ...params.InputProps, type: 'search' }}
                       
                    />
                </Grid>
            </Grid>
            {dividendContent}
            {dividendContent2}
            <Button
            
            style={{margin:'20px'}}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            position="right"
            onClick={submitHandler}
            >
              Submit
            </Button>
            
            {/* <Button

            style={{margin:'20px'}}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            position="right"
            onchange={resetHandler}
            >
              Reset
            </Button> */}
            
    </>
  )
}

export default CreateLedger