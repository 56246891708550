import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import axios from "../../../../utils/axios";
import Swal from 'sweetalert2'
import { Link } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const Sectors = [
  
//     {id: 1,sector: "Bank"},
//     {id: 2,sector: "Cement"},
//     {id: 3,sector: "Ceramics Sector"},
//     {id: 4,sector: "Corporate Bond"},
//     {id: 5,sector: "Debenture"},
//     {id: 6,sector: "Engineering"},
//     {id: 7,sector: "Financial Institutions"},
//     {id: 8,sector: "Food & Allied"},
//     {id: 9,sector: "Fuel & Power"},
//     {id: 10,sector: "G-SEC (T.Bond)"},
//     {id: 11,sector: "Insurance"},
//     {id: 12,sector: "IT Sector"},
//     {id: 13,sector: "Jute"},
//     {id: 14,sector: "Miscellaneous"},
//     {id: 15,sector: "Mutual Funds"},
//     {id: 16,sector: "Paper & Printing"},
//     {id: 17,sector: "Pharmaceuticals & Chemicals"},
//     {id: 18,sector: "Services & Real Estate"},
//     {id: 19,sector: "Tannery Industries"},
//     {id: 20,sector: "Telecommunication"},
//     {id: 21,sector: "Textile"},
//     {id: 22,sector: "Travel & Leisure"}
  
// ];

const RoleType =[
  {id: 2, roleType: "Admin_CMSF", label: "CMSF Admin"},
  {id: 3, roleType: "Super_Admin", label: "Super Admin"},
]

const CmsfUserCreate = () => {
  const history = useHistory();
  const [allValues, setAllValues] = useState({
    countryCode: 'BD',
    identifier:'',
    firstName: '',
    lastName : '',
    email:'',
    nid:'',
    password: '',
    avatar: '',
    roleType: ''
    
});


const { t } = useTranslation();

const changeHandler = e => {
    setAllValues( prevValues => {
    return { ...prevValues,[e.target.name]: e.target.value}
    
 })
 }
  
  const submitHandler = (e) =>{ 
        e.preventDefault();
        
        axios.post('auth/register-user',allValues     
        ).then((res)=>{
          
          Swal.fire({
            icon: 'success',
            title: 'CMSF Admin user added successfully',
            timer: 3000
          })
          history.push('/app/user-cmsf');
        }).catch((err)=>{
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.message,
          })
      });
   }

   const navigateToList = () => {
       history.push('/app/user-cmsf');
   }

return(
  <>
  
      <form onSubmit={submitHandler}>
        
        <Grid>
        {/* <PageTitle title={ t('Issuer.addPageTitle')} button={<Link to="/app/issuer/list"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('Issuer.goToList')}
    </Button></Link>}  /> */}
              {/* <h1 >{ t('Issuer.addPageTitle')} </h1> */}
              </Grid>
              <h2>{ t('UserM.formOfCreateCMSFTypeUser')}</h2>

              <Grid container spacing={5}>   
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl fullWidth >
                       <InputLabel  id="issuer-id" >Select Category</InputLabel>
                          <Select 
                              required
                              id="roleType"
                              name="roleType"
                              label="Role Type"
                              defaultValue = ""
                              variant="standard"
                              onChange={changeHandler}
                                  >
                                {RoleType.map((i)=><MenuItem value={i.roleType} key={i.id}>{i.label}</MenuItem>)}
                      </Select>
                      </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField 
                            required
                            id="firstName"
                            name="firstName"
                            label="firstName"
                            //defaultValue=""
                            fullWidth
                            variant="outlined"
                            label="First Name"
                            onChange={changeHandler}
                            />         
                  </Grid>

               <Grid item lg={6} md={6} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={changeHandler}
                />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField 
                            required
                            id="identifier"
                            type="number"
                            name="identifier"
                            label="Phone Number"
                            helperText={
                              // may provide condition
                              'Phone Number must be 11-digits'}
                            fullWidth
                            autoComplete="id"
                            variant="outlined"
                            onChange={changeHandler}
                  />
                  </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="email"
                      name="email"
                      label="E-mail"
                      type="email"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={changeHandler}
                />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="password"
                      name="password"
                      label="Password"
                      helperText={'Password should be at least 6 characters with at least one number, one special character and one upper case character'}
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={changeHandler}
                />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="nid"
                      name="nid"
                      label="NID"
                      helperText={
                          'NID must be a 10, 13, or 17-digit number '}
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={changeHandler}
                />
                </Grid>

               

                {/* <Grid item lg={6} md={6} sm={12} xs={12}> 
                  
                  <TextField 
                      
                      id="avatar"
                      name="avatar"
                      label="Avatar"
                      //type="avatar"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={changeHandler}
                />
                </Grid> */}
  </Grid>
     
                  <Button style={{ allignRight: 'true' , margin: '20px'}}
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="medium"
                            disabled={!(allValues.password.length >= 6) || !(allValues.nid.length === 10 || allValues.nid.length ===13 || allValues.nid.length === 17) || !(allValues.identifier.length === 11)}
                            onSubmit={submitHandler}
                          >
                          { t('Issuer.submitButton')}
                  </Button>

                  {<Link to="/app/user-cmsf"><Button style={{ margin: '20px'}}
                            variant="contained"
                            color="primary"
                            size="medium"
                            
                         >
                          { t('Issuer.cancelButton')}
                  </Button></Link>}
                  
                
            
      </form>
              
      </>
  
)
    
}

export default CmsfUserCreate