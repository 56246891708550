import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListModalBody from "../list/ListModalBody";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    
 
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(10, 20, 15),
  },
}));

export default function ListModal({ open,data, control, getUserData}) {
  const classes = useStyles();
 
  const { t } = useTranslation();

  return (
    <div>
        
      {/* <button type="button" onClick={control}>
        react-transition-group
      </button> */}
    
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={control}
        disableEscapeKeyDown
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h1 >{ t('Issuer.addPageTitle')}</h1>
            
            {/* <p id="transition-modal-description">checking clsname</p> */}
            <div>
              <ListModalBody modalData={data} handleClose ={control} getUserData={getUserData}/>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
