import { Button } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import axios from "../../../utils/axios";


export default function IssuerDetail(){
  const { t } = useTranslation();
    const [isLoading, setIsLoding] =useState(true)
    const [isSuccess, setIsSuccess] =useState(false)
    const [data, setData] = useState("")

    const params = useParams();
   

    useEffect(() => {

        axios.get(`issuer/${params.id}`)
        .then((res) =>{
            if(res.data.success){
                setIsSuccess(true)
                setIsLoding(false)
                setData(res.data.payload)
                // console.log(data)
                // console.log(res.data.payload)
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
      }, [params.id]);


      let content = "";
      content = <div> <Paper variant="outlined"  style={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        margin: '7px',
        width: '70%',
         borderRadius: 20, 
         borderColor: '#96B6C5',
         borderBottomWidth: '10px',
         
      }}>
      <Table>
        <TableHead>

        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{data.autoId}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Active</TableCell>
            <TableCell>{data.isActive}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>Created By</TableCell>
            <TableCell>{data.createdBy}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>Updated By</TableCell>
            <TableCell>{data.updatedBy}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>Deleted By</TableCell>
            <TableCell>{
            data.deletedBy}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell>{data.createdAt}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>Updated At</TableCell>
            <TableCell>{data.updatedAt}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>Issuer Name</TableCell>
            <TableCell>{data.name}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>No. of Shares</TableCell>
            <TableCell>{data.noofshare}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Company Category</TableCell>
            <TableCell>{data.category}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Sector</TableCell>
            <TableCell>{data.sector}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Debut Date</TableCell>
            <TableCell>{data.debutdate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Company Secratary</TableCell>
            <TableCell>{data.company_secretary}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mobile</TableCell>
            <TableCell>{data.mobile}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Official Phone</TableCell>
            <TableCell>{data.officialphone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>E-mail</TableCell>
            <TableCell>{data.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Official E-mail</TableCell>
            <TableCell>{data.officialemail}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{data.address}</TableCell>
          </TableRow>
          
         
         
        </TableBody>
      </Table>
    </Paper>

    <h1 style={{marginBottom: "50px"}}> </h1></div>;


    return(
        <div>
        <PageTitle title={data.name} button={<Link to="/app/issuer/list"> <Button
   variant="contained"
   size="medium"
   color="primary"
 >
     { t('Issuer.goToList')}
 </Button></Link>}  />
 {isSuccess && content}

     </div>
    )
}