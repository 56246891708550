import { Button,Grid } from '@material-ui/core';
import { CallReceived, CancelRounded, DeleteForeverTwoTone, EditTwoTone, ListAltOutlined, VerifiedUser } from "@material-ui/icons";
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../utils/axios";
import Table from './Table';

function FilterTableComponent() {
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };

    useEffect(() =>{
        // axios.post(`claim-settlement/claim-list-by-investors`)
        axios.post(`claim-application/claim-list-by-investors`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    },[])

    const receivedHandler = ()=> {};

    const columns = React.useMemo(
        () => [
            {
                Header: 'My Claims',
                columns: [
                    {
                        Header: 'Sl',
                        accessor: 'autoId',
                    },
                    {
                        Header: 'Claim ID',
                        accessor: 'claimToken',
                    },
                    {
                        Header: 'Claim Date',
                        accessor: 'createdAt',
                        Cell: ({ value }) => {
                            const date = new Date(value);
                            return <>{date.toLocaleDateString("en-GB")}</>;
                          },
                    },
                    {
                        Header: 'Full Name',
                        accessor: 'fullName'
                    },
                    {
                        Header: 'Dividend Year',
                        accessor: 'yearDividend'
                    },
                    {
                        Header: 'Claim Amount',
                        accessor: 'netDividend'
                    },
                    {
                        Header: 'Dividend Type',
                        accessor: 'investorsdividendType',
                        Cell: ({row}) => <>
                        {row.values.investorsdividendType === 'CashDividend' ? (
                            'Cash Dividend'
                          ) : row.values.investorsdividendType === 'FCCashDividend' ? 
                          (
                             `FC Cash Dividend`

                          ) : row.values.investorsdividendType === 'StockRightShare' ? (
                             `Stock Right Share`

                          ) : row.values.investorsdividendType === 'StockBonusShare' ? (
                              `Stock Bonus Share`
                          ) : row.values.investorsdividendType === 'PublicSubscriptionMoney'? (
                              `Public Subscription Money`

                         ) : row.values.investorsdividendType === 'FCPublicSubscriptionMoney' ? (
                              `FC Public Subscription Money`

                          )  : (
                             <></>
                         )}</>
                    },
                    {
                        Header: 'Claim Type',
                        accessor: 'claimType'
                    },
  
                    {
                        Header: 'BO Id',
                        accessor: 'boID'
                    },
                    {
                        Header: 'Folio Number',
                        accessor: 'folioNumber'
                    },
                    // {
                    //     Header: 'Warrant Number',
                    //     accessor: 'warrantNumber'
                    // },

                    {
                        Header: 'Current Status',
                        accessor: 'status',
                        Cell: ({row}) => <>
                        {row.values.status === 0 ? (
                               'Initiated by Investor'
                             ) : row.values.status === 1 ? 
                             (
                                `Received by Issuer`

                             ) : row.values.status === 2 ? (
                                `Approved by Issuer`

                             ) : row.values.status === 3 ? (
                                 `Cancelled by Issuer`
                             ) : row.values.status === 4 ? (
                                 `Received by CMSF`

                            ) : row.values.status === 5 ? (
                                 `Scrutinized by CMSF`

                             ) : row.values.status === 6 ? (
                                 `Approved by OMC`

                             ) : row.values.status === 7 ? (
                                //  `Approved by BOG`
                                `Approved by Chairman, BOG`
                                
                             ) : row.values.status === 8 ? (
                                 `Claim Cancelled by CMSF`

                             ) : row.values.status === 9 ? (
                                 ` Claim Setteled by CMSF`
                            ) : (
                                <> </>
                            )}

                        </>
                    },
                    {
                        Header: 'Action(s)',
                        accessor: '',
                        Cell: ({row}) => <>
                         <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/claim-settlement/claim/investors/view/${row.original.id}`}> 
                            <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='View the Lodged Claim' variant="contained" color="primary" ><ListAltOutlined  /> </Button></Link>
                        {row.values.status === 0 ? 
                        (
                        // <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Receive by Issuer' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><CallReceived  /></Button> 
                        <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/lodged-claim/edit/${row.original.id}`}> 
                            <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Edit the Claim' variant="contained" color="primary" ><EditTwoTone  /> </Button></Link>
                        ) 
                         : ( <> </>)}
                        
                       
                        
                    
                      
                        </>
                    },
                ],
            },
        ],
        []
    )
    return (
        <>
            <Grid>
            <Table 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            />
            </Grid>
        </>
    )
}

export default FilterTableComponent;