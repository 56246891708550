import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
// import { Radio,RadioGroup, FormLabel} from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import PageTitle from "../../../components/PageTitle/PageTitle";
import axios from "../../../utils/axios";
import useStyles from "./../../../utils/validations/useStyles";
import FilterTableComponent from "./FilterTableComponent";




const options = [{ tradecode: "No Data Available", name: "" },
// { tradecode: "No Data Available", name: "" },
];

const DefineDivYearForm = () => {
  const [initialStartDate, setInitialStartDate] = useState('');
  const [initialEndtDate, setinitialEndtDate] = useState('');
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const [key,setKey] = useState(0);


 
  const user = localStorage.getItem('user-name')
  const userFormatted = JSON.parse(user);
  const userType = userFormatted.userTypes[0]
  const issuerId = localStorage.getItem('issuer-id')
  const issuerName = localStorage.getItem('issuer-name')

  const classes = useStyles();
  let currentDate = new Date().toJSON().slice(0, 10);
  const currentYear = new Date(currentDate).getFullYear();
  const years = Array.from(new Array(100), (val, index) => currentYear + 1 - index);
  console.log(years)
  const [newData, setNewData] = useState([]);
  var year=''
const [endyearSelection,setEndYearSelection] = useState([]);
  const [data,setData] = useState({
    issuer:'',
    year:'',
    status:1
   
  });


  useEffect(()=>{
    axios.get('./issuer/get-info')
    .then((res)=>{
      setNewData(res.data.payload)
    })
    .catch((err) =>{
      alert(err.response.data.message)
    })

},[])
    const newData2 = newData?newData:''
    var sortedNewData = newData2
    // if (newData2) {
    //   sortedNewData = newData2.slice().sort((a, b) => {
    //     if (a.tradecode < b.tradecode) {
    //       return -1;
    //     }
    //   });
     
    // }
   

    const submitHandler = (e) =>{
      e.preventDefault();
     setKey(prevKey => prevKey + 1);
      // console.log("send response")
      if (startYear !== '' && endYear !== '')
      {
        if(startYear===endYear){
          year=startYear  
        axios.post('/issuer-year-format',{...data,year:`${startYear}`})
        .then((res)=>{
          // console.log(res)
          Swal.fire({
            icon: 'success',
            title: 'Year has been saved',
            showConfirmButton: false,
            timer: 1500
          })
         
        })
        .catch((err) =>{
          Swal.fire({ title: err.response.data.message }) 
        })
       
      }
      else{
           year=`${startYear}-${endYear}`
          
        // setData({...data, year:`${startYear}-${endYear}`})
        axios.post('/issuer-year-format',{...data,year:`${startYear}-${endYear}`})
        .then((res)=>{
          // console.log(res)
          Swal.fire({
            icon: 'success',
            title: 'Year has been saved',
            showConfirmButton: false,
            timer: 1500
          })
  
        })
        .catch((err) =>{
          Swal.fire({ title: err.response.data.message }) 
        })
       
      }
      }
      else
      {
        Swal.fire({
          icon: 'warning',
          title: 'Select both start year and end year',
          showConfirmButton: true,
        })
      }
      
      // console.log(data)
    }
   

    const handleStartDateChange = (event) => {
      console.log(event);
      const inputDate = event.target.value;
      // setInitialStartDate(inputDate);
      // const extractedYear = getYearFromDate(inputDate);
      setStartYear(inputDate);
      setEndYearSelection([inputDate, (parseInt(inputDate) + 1).toString()])
    };
console.log(endyearSelection)
    const handleEndDateChange = (event) => {
      const inputDate = event.target.value;
      setEndYear(event.target.value)
      console.log(inputDate);
    //   const extractedYear = getYearFromDate(inputDate);
    //   if (extractedYear >= startYear) {
    //     setEndYear(extractedYear);
    // } else {
    //   setEndYear('')
    //   Swal.fire("End date cannot be earlier than start date.");
    // }
      
    };

    const getYearFromDate = (dateString) => {
      if (dateString) {
        const [year] = dateString.split('-');
        return year;
      }
      return '';
    };
// console.log(startYear)
// console.log(endYear)
// console.log(data)

  return (
    <>
      <form onSubmit={submitHandler}>  
      <PageTitle title="Please Define Your Company's Year Format"></PageTitle>
      {/* <Grid container spacing={4} width="65%"> */}
            <Grid item lg={6} md={6} sm={12} xs={12} > 
            <FormControl fullWidth >
              {userType === 'Issuer'?
                      <>
                       <InputLabel id="issuer-id" >Select Company Name</InputLabel>
                        <Select 
                          required
                          labelId="issuer-id"
                          id="demo-simple-select"
                          value={data.tradeCode}
                          label="Select Issuer"
                          onChange={(e) => setData({...data, issuer: e.target.value})} 
                        >   
                        <MenuItem value={issuerId} key={issuerId}>{issuerName}</MenuItem>
                        </Select>  
                      </>: 
                <>
                <Autocomplete        
                value={data.name}
                label="Select Issuer"
                onChange={(event, newValue) => {
                setData({...data, issuer: newValue.id});
                          // console.log(data.tradeCode)
                        }}
                disableClearable
                options={sortedNewData?sortedNewData:newData?newData:options}
                getOptionLabel={(option) => option.name + " (" + option.tradecode + ") " }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Select Company Name"
                    // margin="normal"
                    // variant="outlined"
                    // InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              /></>
              }</FormControl>
            </Grid>

            <Grid item  item lg={4} md={4} sm={12} xs={12} style={{marginTop:'50px'}}><h5>Select your dividend calculation:</h5></Grid> 
            
            <Grid item lg={4} md={4} sm={12} xs={12} style={{marginLeft:'50px'}}>


            <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Start Year</InputLabel>
        <Select
          required
          value={startYear}
          label="Start Year"
          onChange={handleStartDateChange}
          disabled={data.issuer !== '' ? false : true}
        >
          {years.map((year,index)=>{
            return(
              <MenuItem value={year} index={index}>{year}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

              {/* <TextField       
                InputLabelProps={{
                  classes: {
                    asterisk: classes.requiredAsterisk,
                    outlined: classes.root,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  max: `${currentDate}`
                }}
                
                fullWidth
                  id="date-submit"
                  label="Start Date"
                  variant="outlined"
                  required
                  type='date'
                  margin="normal"
                  value={data.date}
                  onChange={handleStartDateChange}
                  //  onChange={(e) => setData({...data, startDate: e.target.value})}
                /> */}
            </Grid>  
            <Grid item lg={4} md={4} sm={12} xs={12} style={{marginLeft:'50px'}}>  
                

      <FormControl fullWidth>
        <InputLabel >End Year</InputLabel>
        <Select
          value={endYear}
          label="End Year"
          required
          onChange={handleEndDateChange}
          disabled={startYear ? false : true}
        >
          {endyearSelection.map(year=>{
            return(
              <MenuItem value={year}>{year}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

                  {/* <TextField 
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        // min: `${startYear.toJSON().slice(0, 10)}`
                       
                      }}
                      
                      fullWidth
                        id="date-submit"
                        label="End Date"
                        variant="outlined"
                        required
                        type='date'
                        margin="normal"
                        value={data.date}
                        onChange={handleEndDateChange}
                        //  onChange={(e) => setData({...data, endDate: e.target.value})}
                  /> */}
            </Grid> 
<p>Your Company's Year Format is: { startYear === endYear ? `${startYear}` : `${startYear}-${endYear}`}</p>

    {/* <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        required
      >
        <FormControlLabel value="2012" color="success"control={<Radio />}  label="2012"
         onChange={(e) => setData({...data, dateType: e.target.value})} />
        <FormControlLabel value="2012-13" control={<Radio />} label="2012-13" 
         onChange={(e) => setData({...data, dateType: e.target.value})}/>
        <FormControlLabel value="other" control={<Radio />} label="Other" />
      </RadioGroup>
    </FormControl> */}

{/* </Grid> */}
<Grid item style={{marginTop:'20px'}}><Button
    variant="contained"
    color="primary"
    type="submit"
    size="large"
    >
      Save
    </Button></Grid>
    </form> 
    
   
     <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop:'50px'}}><FilterTableComponent key={key} issuerId={data.issuer}/></Grid>   
     </>
  );
};

export default DefineDivYearForm;