import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { default as axios, default as axiosInstance } from '../../../utils/axios';
import './style.css';


const timelineData = [
	{
		text: "Lodged Claim by Claimant",
		date: "March 18 2019",
		category: {
			tag: "Lodged",
			color: "#FFDB14"
		},
		link: {
			url: "#",
			text: "Check it out here"
		}
	},
	{
		text: "Update Claim by Claimant",
		date: "1. February 25 2019",
		category: {
			tag: "Update",
			color: "#FFDB14"
		},
		link: {
			url: "#",
			text: "Check it out here"
		}
	},
	{
		text: "Received Claim by Issuer",
		date: "1. February 25 2019",
		category: {
			tag: "Received",
			color: "#FFDB14"
		},
		link: {
			url: "#",
			text: "Check it out here"
		}
	},
	{
		text: "Query by Issuer",
		date: "2. March 04 2019",
		category: {
			tag: "Query",
			color: "#e17b77"
		},
		link: {
			url: "#",
			text: "Check it out here"
		}
	},
	{
		text: "Received Claim by CMSF",
		date: "3 March 07 2019",
		category: {
			tag: "Received",
			color: "#1DA1F2"
		},
		link: {
			url: "#",
			text: "Check it out here"
		}
	},
	{
		text: "Scrutinized by ICSD, CMSF",
		date: "4. March 18 2019",
		category: {
			tag: "Scrutinized",
			color: "#018f69"
		},
		link: {
			url:
				"#",
			text: "Check it out here"
		}
	},
	{
		text: "Approved by ICSD, CMSF",
		date: "5. April 05 2019",
		category: {
			tag: "Approved",
			color: "#018f69"
		},
		link: {
			url: "#",
			text: "Check it out here"
		}
	},
	{
		text: "Approved by OMC, CMSF",
		date: "6. April 05 2019",
		category: {
			tag: "Approved",
			color: "#018f69"
		},
		link: {
			url: "#",
			text: "Check it out here"
		}
	},
	{
		text: "Lodged Claim settled",
		date: "7. April 05 2019",
		category: {
			tag: "Settled",
			color: "#018f69"
		},
		link: {
			url: "#",
			text: "Check it out here"
		}
	},
];

const TimelineItem = ({ data, user }) => {

	const params = useParams();
	const handleEditClick = (id) => {
	  alert("Edit is disable by Developer "+id);
	};

	return (
	  <div className="timeline-item">
		<div className="timeline-item-content">
		  {/* <span className="tag" style={{ background: data.category.color }}> */}
		  <span className="tag" style={{ background: `${data.title == "Claim Initiated" ? "blue" : data.title == "Claim Received" ? "orange": "green" }` }}>
			{data.title} 
		  </span>
		  <time>{new Date(data.createdAt).toLocaleDateString("en-GB")}</time>
		  <p>{data.comments}</p>
		  <span style={{ fontSize: "10px" }}>Responsibility: {data?.user?.fullName}</span>
		  <span onClick={() => handleEditClick(data.id)}>Edit</span>
		  <span className="circle" />
		</div>
	  </div>
	);
  };

const Timeline = () =>{
	
	const params = useParams();
	const [payload, setPayload] = useState([]);
	const [userData, setUserData] = useState([]);
	const bodyData = {
		claimid: params.id
	}
	useEffect(()=>{
		axios.post(`claim-timeline/list`,bodyData)
		.then((res) =>{
			if(res?.data?.success){
				setPayload(res.data?.payload);
			}
		})
		.catch((err) =>{
			alert("There was an error ocurred!")
		})

		axiosInstance.post(`claim-timeline/user/${params.id}`)
		.then((res) =>{
		  setUserData(res.data)
		})
		.catch((err) => {
		})

	},[])
	

	return(<>		
	{payload.length > 0 && (
				<div className="timeline-container">
					{payload.map((data, idx) => (
						<TimelineItem data={data} user={userData} key={idx} />
					))}
					
				</div>
			)
		}
		</>
	)
	
}	
  export default Timeline;