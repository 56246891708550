import { Button } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
    flexDirection: 'column',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    height: '500px',
    width: '600px',
    padding: theme.spacing(10, 10, 10),
    justifyContent: 'justified',
    textAlign:'justify',
    overflow: "scroll"

  },
}));

export default function PermissionModal({ open,data, control2, scrutinizedByCMSF}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoding] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);

  const history = useHistory();

  //for claim type : self
  const [isCheckedInvestorsNID, setIsCheckedInvestorsNID] = useState(false);
  const [isCheckedBOFolio, setIsCheckedBOFolio] = useState(false);
  const [isCheckedInvestorsChequeLeaf, setIsCheckedInvestorsChequeLeaf] = useState(false);
  const [documentData, setDocumentData] = useState({
    InvestorsNID:'',
    BOFolio:'',
    InvestorsChequeLeaf:'',
    AuthorizationPOALetter:'',
    AuthorizedPOAPersonNID:'',
    AuthPOAChequeLeaf:'',
    DeathCertificate:'',
    InheritanceLetter:'',
    SuccNomineeNID:'',
    CourtCertificate:'',
    SuccNomineeChequeLeaf:'',
  });
console.log(documentData)

  const submitHandler = (e) => {
    e.preventDefault();
    // axios.put(`claim-settlement/claim-scrutinized-by-cmsf/${data}`)
    axios.put(`claim-application/claim-scrutinized-by-cmsf/${data}`)
    .then((res) =>{
     control2();
     Swal.fire({
      title: "This claim has been  Scrutinized by CMSF",
      icon: "success"
    });
    }
    
    )
    .catch((err) =>{
        alert("There was an error occured!")
        
    })
  
};
  const handleCheckboxInvestorsNID = (event) => {
    setIsCheckedInvestorsNID(event.target.checked);
    console.log(event.target.checked);
    if(event.target.checked)
    setDocumentData({...documentData, InvestorsNID:event.target.checked });
    if(!event.target.checked)
    setDocumentData({...documentData, InvestorsNID:'' });
  };

  const handleCheckboxBOFolio = (event) => {
    setIsCheckedBOFolio(event.target.checked);
    if(event.target.checked)
    setDocumentData({...documentData, BOFolio:event.target.checked });
    if(!event.target.checked)
    setDocumentData({...documentData, BOFolio:'' });
  };

  const handleCheckboxInvestorsChequeLeaf = (event) => {
    setIsCheckedInvestorsChequeLeaf(event.target.checked);
    if(event.target.checked)
    setDocumentData({...documentData, InvestorsChequeLeaf:event.target.checked });
    if(!event.target.checked)
    setDocumentData({...documentData, InvestorsChequeLeaf:'' });
  };

  //for claim type Authorized or POA
  const [isCheckedAuthorizationPOALetter, setIsCheckedAuthorizationPOALetter] = useState(false);
  const [isCheckedAuthorizedPOAPersonNID, setAuthorizedPOAPersonNID] = useState(false);
  const [isCheckedAuthPOAChequeLeaf, setIsCheckedAuthPOAChequeLeaf] = useState(false);

  const handleCheckboxAuthorizationPOALetter = (event) => {
    setIsCheckedAuthorizationPOALetter(event.target.checked);

    if(event.target.checked)
    setDocumentData({...documentData, AuthorizationPOALetter:event.target.checked });
    if(!event.target.checked)
    setDocumentData({...documentData, AuthorizationPOALetter:'' });
  };

  const handleCheckboxAuthorizedPOAPersonNID = (event) => {
    setAuthorizedPOAPersonNID(event.target.checked);
  
    if(event.target.checked)
    setDocumentData({...documentData, AuthorizedPOAPersonNID:event.target.checked });
    if(!event.target.checked)
    setDocumentData({...documentData, AuthorizedPOAPersonNID:'' });
    
  };

  const handleCheckboxAuthPOAChequeLeaf = (event) => {
    setIsCheckedAuthPOAChequeLeaf(event.target.checked);
    
    if(event.target.checked)
    setDocumentData({...documentData, AuthPOAChequeLeaf:event.target.checked });
    if(!event.target.checked)
    setDocumentData({...documentData, AuthPOAChequeLeaf:'' });
  };
 
   //for claim type Power of Successor Nominee
    const [isCheckedDeathCertificate, setIsCheckedDeathCertificate] = useState(false);
    const [isCheckedInheritanceLetter, setIsCheckedInheritanceLetter] = useState(false);
    const [isCheckedSuccNomineeNID, setIsCheckedSuccNomineeNID] = useState(false);
    const [isCheckedCourtCertificate, setIsCheckedCourtCertificate] = useState(false);
    const [isCheckedSuccNomineeChequeLeaf, setIsCheckedSuccNomineeChequeLeaf] = useState(false);

   const handleCheckboxDeathCertificate = (event) => {
    setIsCheckedDeathCertificate(event.target.checked);
   
    if(event.target.checked)
    setDocumentData({...documentData, DeathCertificate:event.target.checked });
    if(!event.target.checked)
    setDocumentData({...documentData, DeathCertificate:'' });
  };

  const handleCheckboxInheritanceLetter = (event) => {
    setIsCheckedInheritanceLetter(event.target.checked);
  
    if(event.target.checked)
    setDocumentData({...documentData, InheritanceLetter:event.target.checked });
    if(!event.target.checked)
    setDocumentData({...documentData, InheritanceLetter:'' });
  };

  const handleCheckboxSuccNomineeNID = (event) => {
    setIsCheckedSuccNomineeNID(event.target.checked);
  
    if(event.target.checked)
    setDocumentData({...documentData, SuccNomineeNID:event.target.checked });
    if(!event.target.checked)
    setDocumentData({...documentData, SuccNomineeNID:'' });
  };

  const handleCheckboxCourtCertificate = (event) => {
      setIsCheckedCourtCertificate(event.target.checked);

      if(event.target.checked)
      setDocumentData({...documentData, CourtCertificate:event.target.checked });
      if(!event.target.checked)
      setDocumentData({...documentData, CourtCertificate:'' });
    };

    const handleCheckboxSuccNomineeChequeLeaf = (event) => {
        setIsCheckedSuccNomineeChequeLeaf(event.target.checked);
       
        if(event.target.checked)
        setDocumentData({...documentData, SuccNomineeChequeLeaf:event.target.checked });
        if(!event.target.checked)
        setDocumentData({...documentData, SuccNomineeChequeLeaf:'' });
      };

   


  let content = ""
  content =   <div>
                <div>
                <h5><b>Please check the documents you have scrutized</b></h5>
                  <h6><b>Claim Type: Self</b></h6>
                  <div>
                    <label>
                      <input 
                        type="checkbox"
                        checked={isCheckedInvestorsNID}
                        onChange={handleCheckboxInvestorsNID}
                      /> &nbsp;
                      Investors NID (common for all cases)
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={isCheckedBOFolio}
                        onChange={handleCheckboxBOFolio}
                      /> &nbsp;
                      Investors BO/Folio (common for all cases)
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={isCheckedInvestorsChequeLeaf}
                        onChange={handleCheckboxInvestorsChequeLeaf}
                      /> &nbsp;
                      Investors Cheque Leaf
                    </label>
                  </div>
                  &nbsp;
                </div>

          <div>   
            <h6><b>Claim Type: Authorized Person / Power of Attorney (POA)</b></h6>
              <div>
                <label>
                  <input 
                    type="checkbox"
                    checked={isCheckedAuthorizationPOALetter}
                    onChange={handleCheckboxAuthorizationPOALetter}
                  /> &nbsp;
                  Authorization / POA Letter 
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={isCheckedAuthorizedPOAPersonNID}
                    onChange={handleCheckboxAuthorizedPOAPersonNID}
                  /> &nbsp;
                  Authorized / POA  Persons NID
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={isCheckedAuthPOAChequeLeaf}
                    onChange={handleCheckboxAuthPOAChequeLeaf}
                  /> &nbsp;
                  Authorized / POA  Persons Cheque Leaf
                </label>
              </div>
          </div>
          &nbsp;
            <div>
              <h6><b>Claim Type: Successor / Nominee</b></h6>
                <div>
                  <label>
                    <input 
                      type="checkbox"
                      checked={isCheckedDeathCertificate}
                      onChange={handleCheckboxDeathCertificate}
                    /> &nbsp;
                    Death Certificate
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={isCheckedInheritanceLetter}
                      onChange={handleCheckboxInheritanceLetter}
                    /> &nbsp;
                    Inheritance Letter
                  </label>
              </div>
            <div>
              <label>
                <input
                    type="checkbox"
                    checked={isCheckedCourtCertificate}
                    onChange={handleCheckboxCourtCertificate}
                  /> &nbsp;
                  Court Certificate

              </label>
            </div>
            <div>
                <label>
                  <input
                    type="checkbox"
                    checked={isCheckedSuccNomineeNID}
                    onChange={handleCheckboxSuccNomineeNID}
                  /> &nbsp;
                  Successor / Nominee NID
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isCheckedSuccNomineeChequeLeaf}
                  onChange={handleCheckboxSuccNomineeChequeLeaf}
                /> &nbsp;
                Successor / Nominee Cheque Leaf
              </label>
            </div>
          </div>
  
    
    </div>
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
           
          <div className={classes.paper}>
          {/* <img style={{height:"50px", textAlign:'center',}} src={CmsfLogo} alt="CMSF-Logo" /> */}
            
          {content}
            
       <form onSubmit={submitHandler}>

                <h4 style={{marginTop:"15px"}}> </h4>
              
                <Button style={{float:"left", margin:'5px'}}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                
                    disabled={!((isCheckedInvestorsNID && isCheckedBOFolio && isCheckedInvestorsChequeLeaf) || (isCheckedInvestorsNID && isCheckedBOFolio && isCheckedAuthorizationPOALetter && isCheckedAuthorizedPOAPersonNID && isCheckedAuthPOAChequeLeaf) || 
(isCheckedInvestorsNID && isCheckedBOFolio && isCheckedDeathCertificate && isCheckedInheritanceLetter && isCheckedSuccNomineeNID && isCheckedCourtCertificate && isCheckedSuccNomineeChequeLeaf))
}

                  >
                    Confirm
                  </Button> 
                 </form> 
                 <Button style={{margin:'5px'}}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    onClick={control2}
                  >
                    Cancel
                  </Button>
            <div>
              {/* <FormFileUploadComponent  handleClose ={control}/> */}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
