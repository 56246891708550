// import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Table from './Table';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


function FilterTableComponent() {
    const pdfRef = useRef();
  const pdfRef2 = useRef();
    const classes = useStyles();
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    
//   const [opened, setOpened] = useState(false);
//   const [modalData, setModalData] = useState('');

    // const controlModal = (e) => {
    //     setOpened((prevState) => !prevState);
    //     setModalData(e);
      
    // };
    const data = [
        {
          id: 1,
          name: 'Elenora',
          lastName: 'Wilkinson',
          issuer_name: 'Feest - Reilly',
          city: 'Hertaland',
          country: 'Qatar',
          bo_id:1234567899876543,
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 2,
          name: 'Berneice',
          lastName: 'Feil',
          issuer_name: 'Deckow, Leuschke and Jaskolski',
          city: 'Millcreek',
          country: 'Nepal',
          bo_id:1234567899876543,
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 3,
          name: 'Frieda',
          lastName: 'Baumbach',
          issuer_name: 'Heidenreich, Grady and Durgan',
          city: 'Volkmanside',
          country: 'Croatia',
          bo_id:1234567899876543,
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 4,
          name: 'Zachery',
          lastName: 'Brown',
          issuer_name: 'Cormier - Skiles',
          city: 'Faychester',
          country: 'Saint Pierre and Miquelon',
          bo_id:1234567899876543,
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 5,
          name: 'Kendra',
          lastName: 'Bins',
          issuer_name: 'Wehner - Wilderman',
          city: 'New Valentin',
          country: 'Senegal',
          bo_id:1234567899876543,
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 6,
          name: 'Lysanne',
          lastName: 'Fisher',
          issuer_name: 'Schmidt LLC',
          city: 'Malachitown',
          country: 'Costa Rica',
          bo_id:1234567899876543,
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 7,
          bo_id:1234567899876543,
          folio_number:"",
          entry_date:"2024/08/05",
          name: 'Garrick',
          lastName: 'Ryan',
          issuer_name: 'Ryan - Buckridge',
          city: 'East Pearl',
          country: 'Cocos (Keeling) Islands',
        },
        {
          id: 8,
          name: 'Hollis',
          lastName: 'Medhurst',
          issuer_name: 'Quitzon Group',
          city: 'West Sienna',
          country: 'Papua New Guinea',
          bo_id:"1234567899876543",
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 9,
          name: 'Arlo',
          lastName: 'Buckridge',
          issuer_name: 'Konopelski - Spinka',
          city: 'Chino',
          country: 'Congo',
          bo_id:"1234567899876543",
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 10,
          name: 'Rickie',
          lastName: 'Auer',
          issuer_name: 'Lehner - Walsh',
          city: 'Nyahfield',
          country: 'Sudan',
          bo_id:"1234567899876543",
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 11,
          name: 'Isidro',
          lastName: 'Larson',
          issuer_name: 'Reichert - Paucek',
          city: 'Fort Rosinaside',
          country: 'Belize',
          bo_id:"1234567899876543",
          folio_number:"",
          entry_date:"2024/08/05",
        },
        {
          id: 12,
          name: 'Bettie',
          lastName: 'Skiles',
          issuer_name: 'Zulauf, Flatley and Rolfson',
          city: 'West Feltonchester',
          country: 'Poland',
          bo_id:"1234567899876543",
          folio_number:"",
          entry_date:"2024/08/05",
        },
      ];
      

    const paramsData ={
        page: currentPage,
        take: showPageSize,
    }
  
    
    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        // axios.post('investor-informations/issuer-wise-inward-report', paramsData)
        // .then((res) =>{
            // setDataStore(data)
            setCurrentPage(page);
            setShowPageSize(take)
            
        // })
        // .catch((err) =>{
        //     alert("There was a error occured!")
        // })
    }
    
    
   
    return (
        <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={11} sm={12} md={12} lg={12} xl={12}>
                <Table 
                getDataSync={getDataSync} 
                showPageSize={showPageSize} 
                setShowPageSize={setShowPageSize} 
                setNumberOfpage={setNumberOfpage} 
                numberOfPage={numberOfPage} 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage} 
                // setDataStore={setDataStore} 
                columns={[]} 
                data={data} 
                params={params}
            />
             </Grid>
        </Grid>
             
           
        </div>
    )
}

export default FilterTableComponent;