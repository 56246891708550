import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button } from "../../components/Wrappers/Wrappers";
import FilterTableComponent from "./FilterTableComponent";

export default function ClaimListCmsf(){
    const { t } = useTranslation();

    return(
        <div>
            
            <PageTitle title={ t('claimListCmsf.title')}  />
    
            <FilterTableComponent />
        </div>
    )
}