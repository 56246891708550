import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import FilterTableComponent from "./FilterTableComponent";


export default function CompanySummary(){
 
    const { t } = useTranslation();
   

    return(
        <>
            <PageTitle title={ "Summary Report on Issuer Company's Dividend"}  />
            <FilterTableComponent />
        </>
    )
}