import { Button } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    height: '650px',
    width: '1300px',
    padding: theme.spacing(10, 10, 10),
    justifyContent: 'center',
    textAlign:'center',
    overflow: "scroll"

  },
}));

export default function FileViewInModal({ open,data, control}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoding] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);

  const history = useHistory();

  const submitHandler = (e) =>{
    
    e.preventDefault();
    control();
  }

  // const handleControl = () =>{
  //   history.push(`/app/inward-dividend/list`)
  // }  

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={control}
      >
        <Fade in={open}>
           
          <div className={classes.paper}>  
          <iframe style={{border:'2px'}} height="1000px" width="1200px"
      src="http://www.africau.edu/images/default/sample.pdf">
      </iframe>
            <form onSubmit={submitHandler}>

                <h4 style={{marginTop:"15px"}}> </h4>
              
                 </form> 
                 {/* <Button style={{margin:'5px'}}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    onClick={handleControl}
                  >
                    Cancel
                  </Button> */}
          
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
