import { Button, Grid } from '@material-ui/core';
import jsPDF from 'jspdf';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ResponsiveContainer } from 'recharts';
import CmsfLogo from '../../../reports/ClaimReport/logo.png';
import ReportStyle from '../../../reports/ClaimReport/Report.module.css';
import styles from '../../../reports/ClaimReport/Table.module.css';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import html2canvas from 'html2canvas';
import LoaderBar from '../../../components/LoaderBar/LoaderBar';

const UiOfCompanyStatusReport = ({ data,employeeName,reportType }) => {
  console.log(employeeName)
  data.sort(function (a, b) {
    return a.fullname.localeCompare(b.fullname);
})
  const [isLoading,setIsLoading] = useState(false);
  const params = useParams();
  const pdfRef = useRef();
  const pdfRef2 = useRef();
  const contentRef = useRef();

// const nameOfCompany = data[0].issuer_name;

// const generatePdf = () =>{
//   const doc = new jsPDF({
//     format: "a4",
//     unit: "mm",
//     margins: { top: 10, left: 10, bottom: 10, right: 10 }
//   });

//   // Adding the fonts
//   doc.setFont("Inter-Regular", "normal");

//   doc.html(pdfRef.current, {
//     async callback(doc) {
//       await doc.save("document");
//     }
//   });
// }



  // const generatePdf = () => {

  //   const pdf = new jsPDF('p', 'mm', 'a4');
  
  //   html2canvas(pdfRef.current, {
  //     scale:2,
  //     useCORS: true,
  //   }).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     pdf.addImage(imgData, 'JPEG', 10, 20, 170, 257); 
  //     pdf.save('Claim_Report.pdf');
  //   });
  // };
  // const generatePdf = () => {

  //   const pdf = new jsPDF('p', 'mm', 'a4'); // Create a new PDF document
  // const pdfWidth = pdf.internal.pageSize.getWidth();
  // const pdfHeight = pdf.internal.pageSize.getHeight();


  // html2canvas(pdfRef.current, {
  //       scale:2,
  //       useCORS: true,
  //     }).then(canvas => {
  //       const imgData = canvas.toDataURL('image/png');
  //       // pdf.addImage(imgData, 'JPEG', 10, 20, 170, 257); 
  //       // pdf.save('Claim_Report.pdf');
  //       const imgWidth = canvas.width;
  // const imgHeight = canvas.height;
  // const aspectRatio = imgHeight / imgWidth;
  // const pdfAspectRatio = pdfHeight / pdfWidth;
  // const scaleFactor = pdfWidth / imgWidth;
  // let remainingHeight = imgHeight;
  // let position = 0;
  // while (remainingHeight > 0) {
  //   // Calculate the current height of the content to be added to the current page
  //   const currentHeight = Math.min(remainingHeight, pdfHeight / scaleFactor);

  //   // Add the current portion of the content to the PDF
  //   pdf.addImage(
  //     imgData,
  //     'PNG',
  //     0,
  //     position,
  //     pdfWidth,
  //     pdfWidth * aspectRatio * (currentHeight / imgHeight),
  //     undefined,
  //     'FAST'
  //   );

  //   // Reduce the remaining height
  //   remainingHeight -= currentHeight;
  //   position = -((imgHeight - remainingHeight) / scaleFactor);

  //   // Add a new page if there is still remaining content
  //   // if (remainingHeight > 0) {
  //   //   pdf.addPage();
  //   // }
  // }
  // pdf.save('Settlement_Entry_Record_by_Employee_Report.pdf');
  
  //     });
  // };

  const generatePdf = () => {
    const pdf = new jsPDF('p', 'mm', 'a4'); // Create a new PDF document
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
  
    // Convert the header to an image
    html2canvas(pdfRef.current, {
      scale: 2,
      useCORS: true,
    }).then(headerCanvas => {
      const headerImgData = headerCanvas.toDataURL('image/png');
      const headerImgWidth = headerCanvas.width;
      const headerImgHeight = headerCanvas.height;
      
      // Render the main content to canvas
      html2canvas(contentRef.current, {
        scale: 2,
        useCORS: true,
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const aspectRatio = imgHeight / imgWidth;
        const pdfAspectRatio = pdfHeight / pdfWidth;
        const scaleFactor = pdfWidth / imgWidth;
        let remainingHeight = imgHeight;
        let position = 0;
        const currentHeight = Math.min(remainingHeight, pdfHeight / scaleFactor);
        // Helper function to add header to PDF
        const addHeader = (doc) => {
          doc.addImage(headerImgData, 'PNG', 0, 0, pdfWidth, headerImgHeight * pdfWidth / headerImgWidth, undefined, 'FAST');
        };
  
        while (remainingHeight > 0) {
          // Add a new page if this is not the first page
          if (position !== 0) {
            pdf.addPage();
          }
  
          // Add the header to the page
          addHeader(pdf);
  
          // Add the current portion of the content to the PDF
          pdf.addImage(
            imgData,
            'PNG',
            0,
            headerImgHeight * pdfWidth / headerImgWidth, // Position after header
            pdfWidth,
            pdfWidth * aspectRatio * (currentHeight / imgHeight),
            undefined,
            'FAST'
          );
  
          // Reduce the remaining height
          remainingHeight -= currentHeight;
          position = -((imgHeight - remainingHeight) / scaleFactor);
        }
  
        // Save the PDF
        pdf.save('Company_Status_Report.pdf');
      });
    });
  };

  
  function getPreviousDay() {
    var today = new Date();
    let day = today.getDate();

    let month = today.getMonth()+1;

    let year = today.getFullYear();
    var yesterday = day-1 + '/'+month+'/'+year;
    return yesterday;
}
function getCurrentDay() {
  var today = new Date();
  let day = today.getDate();

  let month = today.getMonth()+1;

  let year = today.getFullYear();
  var date = day + '/'+month+'/'+year;
  return date;
}

  return (
    <ResponsiveContainer width="100%">
      {
        isLoading
        ?
        <LoaderBar/>
        :
        <>
    <Grid ref={pdfRef2} className={ReportStyle.form}> 
    {/* Page- 1 */}
    </Grid>

    <Grid ref={pdfRef} className={ReportStyle.form}>

      <Grid className={ReportStyle.headerLogo}>
          <div className={ReportStyle.CmsfLogo}>
        {/* <img className={ReportStyle.Golden50} src={Golden50} alt='CMSF Logo' /> */}
          </div>
          <div className={ReportStyle.CmsfLogo}>
        <img className={ReportStyle.CmsfLogo} src={CmsfLogo} alt='CMSF Logo' />
          </div>
          <div className={ReportStyle.Mujib100}>
        {/* <img className={ReportStyle.Mujib100} src={Mujib100} alt='CMSF Logo' /> */}
          </div>

      </Grid>
      <Grid className={ReportStyle.address}>
        <span>Dhaka Chamber Building(3rd Floor), 65-66 Motijheel C/A, Dhaka-1000</span> <br />
        <span>Tel: +880241052430, Email: claim@cmsfbd.org</span>
      </Grid>

      <h1><strong>Settlement Entry Report</strong></h1>
      
      
    
      <h5 className={ReportStyle.GategoryName}>Settlement Entry by Employee</h5>
      <h5>'<b>{employeeName == 'All' ? 'Employees' : employeeName}</b>' has entered the following Cash and/or Stock Dividends Settlement to INSAF:</h5>
      <Grid className={ReportStyle.GategoryName2}>
      {/* <h1></h1> */}
      <Grid ref={contentRef}>
        {
          reportType == "summary"
          ?
          <table style={{width:"100%"}}>
          <thead>
            <tr>
              <th className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>Sl</th>
              <th className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>Employee ID</th>
              <th className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>Employee Name</th>
              <th className={styles.td} style={{textAlign:"center"}} colSpan={3}>Department</th>
              <th className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>As on {getPreviousDay()}</th>
              <th className={styles.td}  style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}}colSpan={3}>Today {getCurrentDay()} </th>
               <th className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>Total Entry </th>
               <th className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>Total Investors </th>

              {/*<th className={styles.td} colSpan={3}>Received Stock </th>
              <th className={styles.td} colSpan={3}>Settled Stock </th>
              <th className={styles.td} colSpan={3}>Outstanding Stock </th> */}
            </tr>
          </thead>
          
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className={styles.td} style={{textAlign:"center"}} colSpan={3}>{index+1}</td>
                <td className={styles.td} style={{textAlign:"center"}} colSpan={3}>{item.autoid}</td>
                <td className={styles.td} style={{textAlign:"center"}} colSpan={3}>{item.fullname}</td>
                <td className={styles.td} style={{textAlign:"center"}} colSpan={3}>{item.department}</td>
             

                
                <td className={styles.td} style={{textAlign:"center"}} colSpan={3}>{item.ason_today_entry}</td>
                <td className={styles.td} style={{textAlign:"center"}} colSpan={3}>{item.today_entry}</td>
                

                <td className={styles.td} style={{textAlign:"center"}} colSpan={3}>{item.total_entry}</td>
                <td className={styles.td} style={{textAlign:"center"}} colSpan={3}>{item.total_inv}</td>
                
              </tr>
            
            ))}
            <tr >
              <td className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={12}>Total </td> 
              <td className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>{data.reduce((acc, current) => acc + parseInt(current.ason_today_entry), 0)}</td> 
              <td className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>{data.reduce((acc, current) => acc + parseInt(current.today_entry), 0)}</td> 
              <td className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>{data.reduce((acc, current) => acc + parseInt(current.total_entry), 0)}</td> 
              <td className={styles.td} style={{textAlign:"center", fontWeight:"bold",fontSize:"20px"}} colSpan={3}>{data.reduce((acc, current) => acc + parseInt(current.total_inv), 0)}</td> 
            
            </tr>
          </tbody>
        </table>
          :
          reportType == "details"
          ?
          <table style={{width:"100%"}}>
          <thead>
            <tr>
              <th className={styles.td} colSpan={3}>Sl</th>
              <th className={styles.td} colSpan={3}>BOID</th>
              <th className={styles.td} colSpan={3}>FONO</th>
              <th className={styles.td} colSpan={3}>Investor's Name</th>
              <th className={styles.td} colSpan={3}>Issuer Name</th>
              <th className={styles.td} colSpan={3}>Entry Date </th>
               <th className={styles.td} colSpan={3}>Entry By </th>

              {/*<th className={styles.td} colSpan={3}>Received Stock </th>
              <th className={styles.td} colSpan={3}>Settled Stock </th>
              <th className={styles.td} colSpan={3}>Outstanding Stock </th> */}
            </tr>
          </thead>
          
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className={styles.td} colSpan={3}>{index+1}</td>
                <td className={styles.td} colSpan={3}>{item.boid}</td>
                <td className={styles.td} colSpan={3}>{item.folionumber}</td>
                <td className={styles.td} colSpan={3}>{item.name}</td>
             

                
                <td className={styles.td} colSpan={3}>{item.issuername}</td>
                <td className={styles.td} colSpan={3}>{item.createdat}</td>
                

                <td className={styles.td} colSpan={3}>{item.fullname}</td>
                
              </tr>
            
            ))}<tr>
              {/* <td className={styles.td} colSpan={6}></td> */}
              {/* <td className={styles.td} colSpan={3}></td> */}
              <td className={styles.td} colSpan={12} style={{fontSize:'1.3rem', textAlign:'Center',fontWeight:'bold'}}>Total</td>
               <td className={styles.td} colSpan={12}  style={{fontSize:'1rem', fontWeight:'bold', textAlign:'Center'}}>{data.length}</td>
             {/* <td className={styles.td} colSpan={3}  style={{fontSize:'1rem', fontWeight:'bold'}}>{"(grandTotal.totalSettleNet).toFixed(2)"}</td>
              <td className={styles.td} colSpan={3}  style={{fontSize:'1rem', fontWeight:'bold'}}>{"(grandTotal.totalNet-grandTotal.totalSettleNet).toFixed(2)"}</td>
              <td className={styles.td} colSpan={3}  style={{fontSize:'1rem', fontWeight:'bold'}}>{"(grandTotal.totalShare)"}</td>
              <td className={styles.td} colSpan={3}  style={{fontSize:'1rem', fontWeight:'bold'}}>{"(grandTotal.totalSettleShare)"}</td>
              <td className={styles.td} colSpan={3}  style={{fontSize:'1rem', fontWeight:'bold'}}>{"(grandTotal.totalShare-grandTotal.totalSettleShare)"}</td> */}
            </tr>
          </tbody>
        </table>
        :
        null
        }
      </Grid>
    </Grid>
    
      {/* <h5 className={ReportStyle.GategoryName}>Claimant Information</h5>
    
    <h1></h1>
    <h1></h1> */}

    {/* <h5 className={ReportStyle.GategoryName}>Dividend Information</h5> */}
    
    <br />
    <br />


    {/* <h5 className={ReportStyle.GategoryName}>Payment Information</h5>
    <div>
      
    </div> */}

      <br />
     

      <Grid className={styles.fileListContainer}>
      
      
    </Grid>

      <Grid className={ReportStyle.msgDiv}>
        <span className={ReportStyle.msg}><i>This is a system generated report.</i></span>
      </Grid>

    </Grid>
    
     <Button 
     style={{ margin: '10px', marginTop: '20px' , backgroundColor: '#45CFDD'}}  
     variant="contained" 
     color="default" 
     onClick={generatePdf}><FileDownloadIcon/>&nbsp;Download Settlement Entry by Employee Report&nbsp; </Button>
    </>
}
    </ResponsiveContainer>
  );
};

export default UiOfCompanyStatusReport;
