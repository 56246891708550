import { Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Swal from 'sweetalert2'
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "../../../utils/axios";
import { Switch } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'right',
  },


  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    height: '650px',
    width: '850px',
    padding: theme.spacing(2, 2, 2),
    overflow: "scroll"
  },
}));

export default function DirectUnassign({ open,data, control}) {

  const userpermission = localStorage.getItem("permission")
 

      const classes = useStyles();
      const { t } = useTranslation();
      var disableId = 10
      const {id} = data;
      const userId = id;
      
      const [state, setState] = useState(false);
      const [valuesUpdated, setValuesUpdated] = useState([]);

      function handleSwitchChange (e) {
        setState(e.target.checked);
        // Add actions here for when the switch is triggered
      };
    
      var text;
    
      if (state) {
        text = 'on';
      } else {
        text = 'off';
      };
      
      const [data2,setData2] = useState([]);
 
      const unassignHandler = (idd) => {
       
     
        
        const values = {
          "permissions":
            [idd]
          
        };
       
       
        axios.put(`/permissions/remove-user-extended-permissions/${id}`,values     
        ).then((res)=>{
          setValuesUpdated(res);

        }).catch((err)=>{
            console.log(err);
        });
        ; 
        Swal.fire({
          position: 'bottom-end',
          icon: 'success',
          title: 'Unassigned suscessfully',
          showConfirmButton: false,
          timer: 1500
        })
      };

     
    const getData2 = () =>{
      if (id){
      axios.get(`permissions/user-permissions/${id}`)
      .then((res) =>{
          setData2(res.data.payload)    
      })
      .catch((err) =>{
      })
    }
  }

  // console.log(data2);
  // data2.map((item)=>{
  //   console.log(item.title);
  //   console.log(userpermission.includes(item.title))
  // })  
    
    useEffect(()=>{
      getData2();
    },[userId,valuesUpdated]);
    
    //console.log(data2);

    const columns = [
      {
        name: "title",
        label: "Module / Panel",
        options: {
        filter: true,
        sort: true,
        }
      },
      {
        name: "permissionType.title",
        label: "Permission Type",
        options: {
        filter: true,
        sort: true,
        }
      },
      {
        name: "description",
        label: "Description",
        options: {
        filter: true,
        sort: true,
        }
      },
         

          {
            name: "id",
            label: "Action",
            options: {
         
                  customBodyRender: (value, tableMeta, updateValue) => {
                   //console.log(tableMeta.rowIndex);
                    return (
                      <div className={classes.root}>
                        
                      <Button style={{margin: '5px', backgroundColor:'maroon'}}  size="small"  variant="contained" color='primary' 
                      
                      onClick={()=> unassignHandler(value)
                      }>
                     
                      Unssign
                      </Button>
                      {/* <Switch
        checked={data2.map((item)=>{
          console.log(item.title);
          console.log(userpermission.includes(item.title))
        })}
        onChange={handleSwitchChange}
        color="primary"
      /> */}
                      </div>
                    )
                  }
            }
          }  
      ]
  
     
  
      const options = {
        selectableRows: 'none',
        enableNestedDataAccess: '.',
      };

 
  return (
    <div>
        
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={control}
          disableEscapeKeyDown
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
          timeout: 500,
          }}
        >
      
        <Fade in={open}>
          <div className={classes.paper}>
            <MUIDataTable
                  //title={"Permissions"}
                  data={data2}
                  columns = {columns}
                  options={options}
                  highlightOnHover={true}
                  fixedHeader
                  paginate={false}
                  pagination={false}
                  //rowsPerPage ={5} 
                  //isRowSelectable
                  caseSensitive={false} 
                  print={true}       
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
