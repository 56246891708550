import React from 'react';
import { Triangle } from 'react-loader-spinner';

const LoaderBar = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
    <Triangle
  visible={true}
  height="200"
  width="200"
  color="#0275d8"
  ariaLabel="triangle-loading"
  wrapperStyle={{ margin: '0 auto'}}
  wrapperClass=""
  />
  </div>
  );
};

export default LoaderBar;