import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Widget from "../../components/Widget/Widget";
import useStyles from "../../pages/dashboard/styles";
 

export default function GridItem({data, name,url}){
  var classes = useStyles();

    return(
      <Grid item xl={3} lg={2} md={4} sm={8} >
        {/* <Box style={{height:"100px",borderRadius:"15px"}} bgcolor="#77b3f4" >
          <Box p={2}>
          <Typography variant="h5" component="p" gutterBottom align="left" style={{color:"white"}}>
            {name}
            </Typography>
            <Typography variant="h2" component="h1" align="right" style={{ fontWeight: 'bold' }}>
      {data}
    </Typography>
    </Box>
      </Box> */}
      <Widget
          title={name}
          url={url}
          upperTitle
          bodyClass={classes.fullHeightBody}
          className={classes.card}
        >
          <div className={classes.visitsNumberContainer}>
            <Grid container item alignItems={"center"}>
              <Grid item xs={4}>
            <Typography size="xl" weight="medium" 
            // noWrap 
            style={{ fontWeight: "bold", fontSize: "2em" , align:"right"}}>
              {data}
            </Typography>
              </Grid>
            </Grid>
          </div>
         
        </Widget>
    </Grid>
    )
}