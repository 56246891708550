import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { store } from './app/store';
import App from "./components/App";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import './i18n';
import * as serviceWorker from "./serviceWorker";
import Themes from "./themes";
const root = createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={(<div>Loading</div>)}>
  <LayoutProvider>
    <UserProvider>
      <Provider store={store}>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <App />
      </ThemeProvider>
      </Provider>
    </UserProvider>
  </LayoutProvider>,
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
