import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import ReduceContext from "./ReduceContext";



export const DividendInformation = () => {

  const {data, setData} = useContext(ReduceContext);
  
    return (
      <>

<FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Claim Type</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={data.claimType}
    label="Age"
    onChange={(e) => setData({...data, claimType: e.target.value})}
  >
    <MenuItem value={"cash"}>Cash Dividend</MenuItem>
    <MenuItem value={"stock"}>Stock Dividend</MenuItem>
  </Select>
</FormControl>
<h4 style={{marginTop:"15px"}}> </h4>
<FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Claimant Type</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={data.claimantType}
    label="Age"
    onChange={(e) => setData({...data, claimantType: e.target.value})}
  >
    <MenuItem value={"self"}>Self</MenuItem>
    <MenuItem value={"authorizedPerson"}>Authorized Person</MenuItem>
    <MenuItem value={"powerOfAttorney"}>Power of Attorney</MenuItem>
    <MenuItem value={"moninee"}>Successor/Nominee (Death case)</MenuItem>
  </Select>
</FormControl>
  
       
            <TextField
              id="bo-id"
              label="BO ID"
              variant="outlined"
              required
              placeholder="Enter Your BO ID/Folio No."
              fullWidth
              margin="normal"
              value={data.boId}
              onChange={(e) => setData({...data, boId: e.target.value})}
            />
        
            <TextField
              id="issuerCompanyName"
              label="Issuer Company Name"
              variant="outlined"
              required
              placeholder="Enter the Name of your Issuer Company"
              fullWidth
              margin="normal"
              value={data.issuerCompanyName}
              onChange={(e) => setData({...data, issuerCompanyName: e.target.value})}
            />
     
            <TextField
              id="dividendYear"
              label="Dividend Year"
              variant="outlined"
              required
              placeholder="Enter Your Dividend Year"
              fullWidth
              type="number"
              margin="normal"
              value={data.dividendYear}
              onChange={(e) => setData({...data, dividendYear: e.target.value})}
            />

            <TextField
              id="amount"
              label="Amount"
              variant="outlined"
              placeholder="Enter Your Amount"
              fullWidth
              required
              type="number"
              margin="normal"
              value={data.amount}
              onChange={(e) => setData({...data, amount: e.target.value})}
            />
        
            <TextField
              id="nIdFile"
              label="NID File (JPG or PNG or JPEG or PDF)"
              variant="outlined"
              placeholder="Enter Your NID File"
              fullWidth
              focused
              margin="normal"
              type="file"
              onChange={(e) => setData({...data, nIdFile: e.target.files[0]})}
            />
            
            <div style={{marginBottom: "10px"}}>
                 {data.nIdFile.length === 0 ? null: data.nIdFile.name}
            </div>
        
       
            <TextField
              id="successorCertificateFile"
              label="Successor Certificate File (JPG or PNG or JPEG or PDF)"
              variant="outlined"
              placeholder="Enter Your Successor Certificate File"
              fullWidth
              focused
              margin="normal"
              type="file"
              onChange={(e) => setData({...data, successorCertificateFile: e.target.files[0]})}
            />
            
            <div style={{marginBottom: "10px"}}>
                 {data.successorCertificateFile.length === 0 ? null : data.successorCertificateFile.name}
            </div>
      
            <TextField
              id="deathCertificateFile"
              label="Death Certificate File (JPG or PNG or JPEG or PDF)"
              variant="outlined"
              placeholder="Enter Your Death Certificate File"
              fullWidth
              focused
              margin="normal"
              type="file"
              onChange={(e) => setData({...data, deathCertificateFile: e.target.files[0]})}
            />
            
            <div style={{marginBottom: "10px"}}>
                 {data.deathCertificateFile.length === 0 ? null : data.deathCertificateFile.name}
            </div>


            <TextField
              id="boAcknowledgementFile"
              label="BO Acknowledgement File"
              variant="outlined"
              placeholder="Enter Your NID File"
              fullWidth
              focused
              margin="normal"
              type="file"
              onChange={(e) => setData({...data, boAcknowledgementFile: e.target.files[0]})}
            />
            
            <div style={{marginBottom: "10px"}}>
                 {data.boAcknowledgementFile.length === 0 ? null: data.boAcknowledgementFile.name}
            </div>
             
      </>
    );
  };