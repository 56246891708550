import { Grid, Button, FormControl, InputLabel, MenuItem, Select,OutlinedInput } from "@material-ui/core";
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import axios from "../../utils/axios";

function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize,setNumberOfpage,params }) {

    const defaultColumn = React.useMemo(
        () => ({
            Filter: "",
        }),
        []
    )
    const { t } = useTranslation();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const nextPageHandler = () =>{        
        if(numberOfPage > currentPage){
            setCurrentPage(++currentPage);      
        }
    }

    const prevPageHandler = () =>{        
        if(currentPage > 1){
            setCurrentPage(--currentPage);
        }
    }
    
    const handleFirstPage = () =>{
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }

    const handleLastPage = () =>{
        if(currentPage !== numberOfPage){
            setCurrentPage(numberOfPage)
        }
    }

    const goToPageHandler = (e) =>{
        if( e === 0) {
        }
        else if(e < 0){
        }
        else if( e => 1){
            setCurrentPage(e);
        }else{
            setCurrentPage(1); 
        }
    }

   

    const pSize= [5, 10, 20, 30, 40, 50];

    const [fullName, setFullName] = useState("")
    const [folioNumberValue, setFolioNumberValue] = useState("")
    const [boIDValue, setBoIDValue] = useState("")

    const [dateValue, setDateValue] = useState("")
    const [issuerValue, setIssuerValue] = useState("")
    const [totalCashValue, setTotalCashValue] = useState("")
    const [totalFcValue, setTotalFcValue] = useState("")
    const [totalStockValue, setStockValue] = useState("")
    const [remarksValue, setRemarksValue] = useState("");
    const [statusValue, setStatusValue] = useState("");

    const searchResethandler = () =>{
        setFullName("")
        setBoIDValue("")
        setFolioNumberValue("")
        setStatusValue("")
        
    }
    
    const paramsData = {
        page: currentPage,
        take: showPageSize,
        fullName,
        boID: boIDValue,
        folioNumber: folioNumberValue,
        total_stock: totalStockValue,
        status: statusValue
    }

    useEffect(()=>{
            // axios.post(`claim-settlement/claim-list-by-issuer`,paramsData)
            axios.post(`claim-application/claim-list-by-issuer`,paramsData)
        .then((res) =>{
            setDataStore(res.data.payload)
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
            alert("There was an error occured. Internal Server Error!")
        })
           
    },[remarksValue,statusValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue, setDataStore,setCurrentPage,setNumberOfpage,currentPage,showPageSize,fullName,folioNumberValue,boIDValue, setCurrentPage])

    useEffect(()=>{
        setCurrentPage(1)
    },[statusValue,fullName,boIDValue,folioNumberValue])

    return (
        <>
            <Grid container spacing={2}>
                    <Grid item lg={2} md={2} sm={2} xs={12}>
                    <FormControl fullWidth >
                        <InputLabel id="issuer-id" >Search by claim status</InputLabel>
                            <Select 
                            labelId="status"
                            id="status"
                            value={statusValue}
                            label="Select status"
                            onChange={(e) => setStatusValue(e.target.value)}>
                            <MenuItem value='0' key='Initiated'>Initiated</MenuItem>
                            <MenuItem value='1' key='Received by Issuer'>Received by Issuer</MenuItem>
                            <MenuItem value='2' key='Approved by Issuer'>Approved by Issuer</MenuItem>
                            <MenuItem value='3' key='Cancelled by Issuer'>Cancelled by Issuer</MenuItem>
                            <MenuItem value='4' key='Received by CMSF'>Received by CMSF</MenuItem>
                            <MenuItem value='5' key='Scrutinized by CMSF'>Scrutinized by CMSF</MenuItem>
                            <MenuItem value='6' key='OMC Approved'>OMC Approved</MenuItem>
                            <MenuItem value='7' key='BOG Approved'>BOG Approved</MenuItem>
                            <MenuItem value='8' key='Cancelled'>Cancelled by CMSF</MenuItem>
                            <MenuItem value='9' key='Settled'>Settled</MenuItem>
                            </Select>
                        </FormControl> 
                        </Grid>
                <Grid item lg={2} md={2} sm={2} xs={12}>
                <span>
                    Full Name:{' '}
                    <input
                        className="form-control"
                        value={fullName || ""}
                        onChange={e => {
                            setFullName(e.target.value);
                        }}
                        placeholder={` search...`}
                    />
                </span>
                </Grid>
                {/* <Grid item lg={2} md={2} sm={2} xs={12}>
                <span>
                    Date:{' '}
                    <input
                        className="form-control"
                        value={dateValue || ""}
                        onChange={e => {
                            setDateValue(e.target.value);
                        }}
                        placeholder={` search...`}
                    />
                </span>
                </Grid> */}
                <Grid item lg={2} md={2} sm={2} xs={12}>
                <span>
                    BO Id:{' '}
                    <input
                        className="form-control"
                        value={boIDValue || ""}
                        onChange={e => {
                            setBoIDValue(e.target.value.trim());
                        }}
                        placeholder={` search...`}
                    />
                </span>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={12}>
                <span>
                    Folio Id:{' '}
                    <input
                        className="form-control"
                        value={folioNumberValue || ""}
                        onChange={e => {
                            setFolioNumberValue(e.target.value);
                        }}
                        placeholder={`search...`}
                    />
                </span>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={12}>
                    <span>
                <Button
                variant="contained"
                        color="primary"
                        size="medium"
                        style={{margin:"20px"}}
                        onClick={searchResethandler}
                        >{ t('common.reset')}</Button>
                </span>
                </Grid>
                {/* <Grid item lg={2} md={2} sm={2} xs={12}>
                <span>
                    Phone Number:{' '}
                    <input
                        className="form-control"
                        value={totalStockValue || ""}
                        onChange={e => {
                            setStockValue(e.target.value);
                        }}
                        placeholder={` search...`}
                    />
                </span>
                </Grid> */}
                {/* <Grid item lg={2} md={2} sm={2} xs={12}>
                <span>
                Warrant Number:{' '}
                    <input
                        className="form-control"
                        value={remarksValue || ""}
                        onChange={e => {
                            setRemarksValue(e.target.value);
                        }}
                        placeholder={` search...`}
                    />
                </span>
                </Grid> */}
            </Grid>

            {/* <Grid >
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </Grid> */}
             <Grid container style={{ overflowX: 'auto'}}>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
  {rows.map((row, i) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        {row.cells.map(cell => {
          return (
            <td 
             
                {...cell.getCellProps()} data-label={cell.column.render('Header')}>
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  })}
</tbody>
        
      </table>
            </Grid>

            <Grid >
            <ul className="pagination">
                <li className="page-item" onClick={handleFirstPage} disabled={!canPreviousPage}>
                    <span style={{cursor: "grab"}} className="page-link">First</span>
                </li>
                <li className="page-item" onClick={prevPageHandler}
                 disabled={!canPreviousPage}
                 >
                    <span style={{cursor: "grab"}} className="page-link">{'<'}</span>
                </li>
                <li className="page-item" onClick={nextPageHandler}
                 disabled={!canNextPage}
                 >
                    <span style={{cursor: "grab"}} className="page-link">{'>'}</span>
                </li>
                <li className="page-item" onClick={handleLastPage} disabled={!canNextPage}>
                    <span style={{cursor: "grab"}} className="page-link">Last</span>
                </li>
                <li>
                    <a className="page-link">
                        Page{' '}
                        <strong>
                            {currentPage} of {numberOfPage}
                        </strong>{' '}
                    </a>
                </li>
                <li>
                    <a className="page-link">
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                goToPageHandler(e.target.value)
                            }}
                            style={{ width: '100px', height: '20px' }}
                        />
                    </a>
                </li>{' '}
                <select
                    className="form-control"
                    value={showPageSize}
                    onChange={e => {
                        setShowPageSize(Number(e.target.value))
                    }}
                    style={{ width: '120px', height: '38px' }}
                >
                    {pSize.map(pageSize => (
                        <option key={pageSize} value={pageSize} >
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </ul>
            </Grid>
        </>
    )
}

export default Table;